import _ from 'lodash'
import { createSelector } from 'reselect'

import { ROLES } from 'constants/security'

import { isNurseryContext, isOrganizationContext } from 'services/security/selectors'

import { checkArrayItems, getAuthParams } from './helpers'

const isUserAuthenticated = (state) => state.authentication

export const getAuthenticationProfile = (state) => state.authentication?.common?.profile

export const getAuthenticationProfileUser = createSelector(
  [getAuthenticationProfile],
  (profile) => profile?.user,
)

export const getAuthenticationProfileRoles = createSelector(
  [getAuthenticationProfile],
  (profile) => profile?.roles,
)

export const getAuthenticationProfileOrderedRoles = createSelector(
  [getAuthenticationProfileRoles],
  (profileRoles) => {
    if (!profileRoles?.length) {
      return []
    }

    const profileRoleItems = []

    _.forEach(profileRoles, (role) => {
      const profileRoleOrderedItem = ROLES[role]

      if (profileRoleOrderedItem) {
        profileRoleItems.push(profileRoleOrderedItem)
      }
    })

    return _.map(_.orderBy(profileRoleItems, ['roleHierarchy'], ['desc']), 'value')
  },
)

export const getAuthenticationProfileFeatureFlags = createSelector(
  [getAuthenticationProfile],
  (profile) => profile?.featureFlags,
)

export const getIsLoggedIn = (state) => !!isUserAuthenticated(state)

export const getIsFinanceV3Enabled = (state) => state.nurseryContext.common.data?.newFinanceEnabled

export const getIsAuthorised = (state, authParams, withoutCheckingFlag) => {
  // NOTE: null means "access allowed"
  if (null === authParams) {
    return true
  }

  // NOTE: empty object means "access NOT allowed"
  if (true === _.isEmpty(authParams)) {
    return false
  }

  if (authParams.organizationContext && !isOrganizationContext(state)) {
    return false
  }

  if (authParams.nurseryContext && !isNurseryContext(state)) {
    return false
  }

  if (authParams.roles && !checkArrayItems(getAuthenticationProfileRoles(state), authParams.roles)) {
    return false
  }

  if (authParams.antiRoles) {
    const orderedRoles = getAuthenticationProfileOrderedRoles(state)

    if (orderedRoles?.length && authParams.antiRoles.includes(orderedRoles[0])) {
      return false
    }
  }

  const userFeatureFlags = getAuthenticationProfileFeatureFlags(state)

  if (authParams.flags && !checkArrayItems(userFeatureFlags, authParams.flags) && !withoutCheckingFlag) {
    return false
  }

  if (undefined !== authParams.allowFinanceF3 && null !== authParams.allowFinanceF3) {
    const f3Enabled = getIsFinanceV3Enabled(state)

    return authParams.allowFinanceF3 ? f3Enabled : !f3Enabled
  }

  return !(authParams.antiFlags && checkArrayItems(userFeatureFlags, authParams.antiFlags) && !withoutCheckingFlag)
}

export const getComponentIsAuthorised = (state, Component, withoutCheckingFlag) => {
  if (!Component) {
    return false
  }

  if (!isUserAuthenticated(state)) {
    return false
  }

  if (!Component.authParams) {
    return true
  }

  const authParams = getAuthParams(Component, state)

  return getIsAuthorised(state, authParams, withoutCheckingFlag)
}

export const getComponentIsAuthorisedButFlagIsMissing = (state, Component) => {
  const userHasCorrectRoles = getComponentIsAuthorised(state, Component, true)

  const authParams = getAuthParams(Component, state)
  const userFeatureFlags = getAuthenticationProfileFeatureFlags(state)
  const userHasCorrectFlags = !(authParams.flags && !checkArrayItems(userFeatureFlags, authParams.flags))

  return userHasCorrectRoles && !userHasCorrectFlags
}
