import { hide } from 'services/utils/modal/common/actions'

import {
  CLEAR_MEMBERSHIP,
  CONFIRM_GDPR,
  CONFIRM_GDPR_FAILED,
  CONFIRM_GDPR_SUCCESS,
  CREATE_MEMBERSHIP,
  CREATE_MEMBERSHIP_FAILED,
  CREATE_MEMBERSHIP_SUCCESS,
  GET_MEMBERSHIP,
  GET_MEMBERSHIP_FAILED,
  GET_MEMBERSHIP_SUCCESS,
  GET_ORGANIZATION_MEMBERSHIP,
  GET_ORGANIZATION_MEMBERSHIP_FAILED,
  GET_ORGANIZATION_MEMBERSHIP_SUCCESS,
  SET_SELECTED_MEMBERSHIP,
  UPDATE_MEMBERSHIP,
  UPDATE_MEMBERSHIP_FAILED,
  UPDATE_MEMBERSHIP_PASSWORD,
  UPDATE_MEMBERSHIP_PASSWORD_FAILED,
  UPDATE_MEMBERSHIP_PASSWORD_SUCCESS,
  UPDATE_MEMBERSHIP_SUCCESS,
} from './constants'

export const confirmGdpr = (options = {}) => ({ membershipsApiClient }) => (dispatch) => {
  const { params = {} } = options

  dispatch({ type: CONFIRM_GDPR })

  membershipsApiClient.confirmGdpr(params).then(
    (data) => {
      dispatch({
        payload: data,
        type: CONFIRM_GDPR_SUCCESS,
      })

      dispatch(hide())
    },
    ({ response }) => {
      dispatch({
        payload: response,
        type: CONFIRM_GDPR_FAILED,
      })
    },
  )
}

export const create = (options = {}) => ({ membershipsApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, payload = {} } = options

  dispatch({ type: CREATE_MEMBERSHIP })

  membershipsApiClient.create(payload).then(
    (response) => {
      dispatch({
        payload: response,
        type: CREATE_MEMBERSHIP_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_MEMBERSHIP_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const get = (id, options = {}) => ({ membershipsApiClient }) => async (dispatch) => {
  const { onFailed, onSuccess, onlyData, params = {} } = options

  if (!onlyData) {
    dispatch({ type: GET_MEMBERSHIP })
  }

  try {
    const response = await membershipsApiClient.get(id, params)

    if (!onlyData) {
      dispatch({
        payload: response,
        type: GET_MEMBERSHIP_SUCCESS,
      })
    }

    if (onSuccess) {
      onSuccess(response)
    }

    return response
  } catch (error) {
    if (!onlyData) {
      dispatch({
        error,
        type: GET_MEMBERSHIP_FAILED,
      })

      if (onFailed) {
        onFailed(error)
      }
    }
  }

  return null
}

export const getMe = (options = {}) => ({ membershipsApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {} } = options

  dispatch({ type: GET_MEMBERSHIP })

  membershipsApiClient.getMe(params).then(
    (response) => {
      dispatch({
        payload: response,
        type: GET_MEMBERSHIP_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_MEMBERSHIP_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const getOrganizationMembership = (id, options = {}) => ({ membershipsApiClient }) => (dispatch) => {
  const { addToList, onFailed, onSuccess, params } = options

  dispatch({
    id,
    type: GET_ORGANIZATION_MEMBERSHIP,
  })

  membershipsApiClient.get(id, params).then(
    (response) => {
      dispatch({
        addToList,
        payload: response,
        type: GET_ORGANIZATION_MEMBERSHIP_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_ORGANIZATION_MEMBERSHIP_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const update = (id, options = {}) => ({ membershipsApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {}, payload = {} } = options

  dispatch({ type: UPDATE_MEMBERSHIP })

  membershipsApiClient.update(id, payload, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: UPDATE_MEMBERSHIP_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_MEMBERSHIP_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updateMe = (options = {}) => ({ membershipsApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {}, payload = {} } = options

  dispatch({ type: UPDATE_MEMBERSHIP })

  membershipsApiClient.updateMe(payload, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: UPDATE_MEMBERSHIP_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_MEMBERSHIP_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updatePassword = (options = {}) => ({ membershipsApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, payload = {} } = options

  dispatch({ type: UPDATE_MEMBERSHIP_PASSWORD })

  membershipsApiClient.updatePassword(payload).then(
    (response) => {
      dispatch({
        payload: response,
        type: UPDATE_MEMBERSHIP_PASSWORD_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_MEMBERSHIP_PASSWORD_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const setSelectedStaff = (memberDetails) => ({
  payload: memberDetails,
  type: SET_SELECTED_MEMBERSHIP,
})

export const clear = () => ({
  type: CLEAR_MEMBERSHIP,
})
