import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import colors from 'constants/colors'

import { hasParentAppAccessSelector } from 'services/security/selectors'

import { OBSERVATION_STATUSES } from '../constants'

const getObservationsListState = (state) => state.observations.list

export const getObservationsListMetaState = createSelector([getObservationsListState], (state) => state.meta)

export const getObservationsIsExporting = createSelector([getObservationsListState], (state) => {
  if (!state) {
    return null
  }

  return !!state.isExporting
})

export const getErrorMessage = (error, params) => {
  if (!error || 409 !== error.code) {
    return error
  }

  const { criteria } = params

  if (_.find(criteria, { field: 'nurseryClass' })) {
    return 'The children in this room do not have any observations for the time period selected'
  }

  return 'The children in this key group do not have any observations for the time period selected.'
}

export const getObservationsListDataSelector = createSelector([getObservationsListState], ({ data }) => data)

export const getObservationsListSelector = createSelector(
  [getObservationsListState, hasParentAppAccessSelector],
  ({ data }, hasParentAppAccess) => _.map(data, (item) => {
    const {
      childObservationsCount,
      comments,
      customMontessoriActivity,
      feedbackCounter,
      homeObservationExists,
      id,
      montessoriActivity,
      observationDate,
      previewChildObservations,
      sharedWithParentCount,
      status,
      ...rest
    } = item

    const titleIcon = () => {
      if (!hasParentAppAccess) {
        return null
      }

      if (!sharedWithParentCount) {
        return null
      }

      const isHalf = sharedWithParentCount < childObservationsCount

      return isHalf ? 'star-half' : 'star'
    }

    return {
      ...rest,
      borderColor: _.get(_.find(OBSERVATION_STATUSES, (value, key) => key === status), 'color', () => '#808080')(),
      borderTitle: _.get(_.find(OBSERVATION_STATUSES, (value, key) => key === status), 'label', null),
      childObservationsCount,
      comments,
      content: customMontessoriActivity || montessoriActivity?.name,
      extraTitle: status === OBSERVATION_STATUSES.CHANGES_REQUESTED.value && (
        `Changes requested ${feedbackCounter} times`
      ),
      extraTitleColor: _.get(_.find(OBSERVATION_STATUSES, (value, key) => key === status), 'color')(),
      homeObservationExists,
      id,
      observationDate,
      previewChildObservations,
      previewChildren: _.map(previewChildObservations, ({ child }) => child),
      titleIcon: titleIcon(),
      titleIconColor: colors.gold,
    }
  }),
)

export const getCriteria = (params) => {
  const { author, childId, childSearch, date, keyPerson, room, status } = params

  const criteria = []

  if (author) {
    criteria.push({
      field: 'author',
      value: author,
    })
  }

  if (date) {
    criteria.push({
      field: 'observationDate[after]',
      value: moment(date).startOf('day').toISOString(),
    })

    criteria.push({
      field: 'observationDate[before]',
      value: moment(date).endOf('day').toISOString(),
    })
  }

  if (keyPerson) {
    criteria.push({
      field: 'childObservations.child.keyWorkers',
      value: keyPerson,
    })
  }

  if (room) {
    criteria.push({
      field: 'childObservations.child.nurseryClass',
      value: room,
    })
  }

  if (status) {
    if (_.isArray(status)) {
      _.forEach(status, (statusValue) => {
        criteria.push({
          field: 'status[]',
          value: statusValue,
        })
      })
    } else {
      criteria.push({
        field: 'status[]',
        value: status,
      })
    }
  }

  if (childSearch) {
    criteria.push({
      field: 'concatenable',
      value: childSearch,
    })
  }

  if (childId) {
    criteria.push({
      field: 'childObservations.child',
      value: childId,
    })
  }

  return criteria
}
