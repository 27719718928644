import _ from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'

import { ENQUIRY_VIEWING_FILTER_STATUSES } from '../constants'

const COMPARE_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

const getEnquiries = (state) => state.enquiries

export const getListSelector = createSelector(
  [getEnquiries],
  (state) => state?.list,
)

export const getListDataSelector = createSelector(
  [getListSelector],
  (state) => state.data,
)

export const getListMetaSelector = createSelector(
  [getListSelector],
  (state) => state.meta,
)

export const getCriteria = (filters) => {
  const {
    archived = false,
    enquiryDate,
    membership,
    not,
    search,
    startDate,
    status,
    viewingStatus,
  } = filters || {}

  const criteria = []

  if (null !== archived && undefined !== archived) {
    criteria.push({
      field: 'archived',
      value: +archived,
    })
  }

  if (search) {
    criteria.push({
      field: 'concatenable',
      value: search,
    })
  }

  if (enquiryDate?.after && enquiryDate?.before) {
    if (enquiryDate?.after) {
      criteria.push({
        comparator: 'after',
        field: 'enquiryDate',
        value: moment(enquiryDate.after).startOf('day').format(COMPARE_DATE_TIME_FORMAT),
      })
    }

    if (enquiryDate?.before) {
      criteria.push({
        comparator: 'before',
        field: 'enquiryDate',
        value: moment(enquiryDate.before).endOf('day').format(COMPARE_DATE_TIME_FORMAT),
      })
    }
  }

  if (startDate?.after && startDate?.before) {
    if (startDate?.after) {
      criteria.push({
        comparator: 'after',
        field: 'startDate',
        value: moment(startDate.after).startOf('day').format(COMPARE_DATE_TIME_FORMAT),
      })
    }

    if (startDate?.before) {
      criteria.push({
        comparator: 'before',
        field: 'startDate',
        value: moment(startDate.before).endOf('day').format(COMPARE_DATE_TIME_FORMAT),
      })
    }
  }

  if (status) {
    criteria.push({
      field: 'status',
      value: status,
    })
  }

  if (membership) {
    criteria.push({
      field: 'membership',
      value: membership,
    })
  }

  if (viewingStatus) {
    criteria.push({
      field: 'showArounds[exists]',
      value: +(ENQUIRY_VIEWING_FILTER_STATUSES.BOOKED === viewingStatus),
    })

    criteria.push({
      field: 'showArounds.archived',
      value: 0,
    })
  }

  if (not) {
    const { status: statusNotValue } = not

    if (statusNotValue) {
      if (_.isArray(statusNotValue)) {
        _.each(statusNotValue, (value) => {
          criteria.push({
            field: 'not[status.code][]',
            value,
          })
        })
      } else {
        criteria.push({
          field: 'not[status.code]',
          value: statusNotValue,
        })
      }
    }
  }

  return criteria
}
