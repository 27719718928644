import _ from 'lodash'
import moment from 'moment'

import React, { PropsWithChildren } from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'

import { Collapse, Typography } from 'components'

import { StyledCollapseContent, StyledListItemRow } from './CommentDrawerStyled'
import AvatarItem from './components/AvatarItem'

interface CommentDrawerListItemProps {
  avatars?: Array<{
    author?: {
      name?: string
      photo?: string
    }
    label?: string
  }>
  defaultIsOpen?: boolean
  details?: Array<{
    label?: string
    text?: string
  }>
  header?: {
    date?: string
    text?: string
  }
  headerColor?: string
  headerIcon?: IconType
}

const CommentDrawerListItem: React.FC<PropsWithChildren<CommentDrawerListItemProps>> = ({
  avatars = [],
  defaultIsOpen,
  details = [],
  header,
  headerColor = NEUTRAL_COLOURS.SALMON,
  headerIcon = 'feedback',
}) => {
  const collapseTitle = () => (
    <React.Fragment>
      <Typography
        color={headerColor}
        fontSize={14}
        margin="0 10px 0 0"
        variant="span"
        bold
      >
        {header?.date && moment(header.date).format(DISPLAY_SHORT_DATE_FORMAT)}
      </Typography>
      <Typography
        fontSize={14}
        variant="span"
      >
        {header?.text}
      </Typography>
    </React.Fragment>
  )

  const collapseContent = () => (
    <StyledCollapseContent>
      {_.map(avatars, ({ author, label }) => (
        <AvatarItem
          author={author}
          label={label}
        />
      ))}
      {_.map(details, ({ label, text }) => (
        <StyledListItemRow>
          <Typography margin="0 0 2px" bold>
            {label}
          </Typography>
          <Typography>
            {text}
          </Typography>
        </StyledListItemRow>
      ))}
    </StyledCollapseContent>
  )

  return (
    <Collapse.Row
      content={collapseContent()}
      defaultIsOpen={defaultIsOpen}
      icon={headerIcon as IconType}
      iconBackground={headerColor}
      title={collapseTitle()}
    />
  )
}

export default CommentDrawerListItem
