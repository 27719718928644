import {
  CLEAR_MEMBERSHIPS_LIST,
  GET_MEMBERSHIPS_LIST,
  GET_MEMBERSHIPS_LIST_FAILED,
  GET_MEMBERSHIPS_LIST_SUCCESS,
  SEND_REPORT,
  SEND_REPORT_FAILED,
  SEND_REPORT_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ membershipsApiClient }) => async (dispatch) => {
  const { mergeResult, onFailed, onSuccess, params = {} } = options

  dispatch({ type: GET_MEMBERSHIPS_LIST })

  await membershipsApiClient.list(params).then(
    (response) => {
      dispatch({
        mergeResult,
        payload: response,
        type: GET_MEMBERSHIPS_LIST_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_MEMBERSHIPS_LIST_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const sendReport = (moduleType, type, options = {}) => ({ membershipsApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {} } = options

  dispatch({ type: SEND_REPORT })

  return membershipsApiClient.sendReport(moduleType, type, params).then(
    () => {
      dispatch({ type: SEND_REPORT_SUCCESS })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({ type: SEND_REPORT_FAILED })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const exportStaffList = (options = {}) => ({ membershipsApiClient }) => (dispatch) => {
  const { onSuccess, params = {} } = options

  dispatch({ type: SEND_REPORT })

  membershipsApiClient.exportStaffList(params).then(
    () => {
      dispatch({ type: SEND_REPORT_SUCCESS })

      if (onSuccess) {
        onSuccess()
      }
    },
    () => {
      dispatch({ type: SEND_REPORT_FAILED })
    },
  )
}

export const clearList = () => ({
  type: CLEAR_MEMBERSHIPS_LIST,
})
