import styled from 'styled-components'

import { NEUTRAL_COLOURS } from 'constants/colors'

interface StyledContainerProps {
  $noBorder?: boolean
  $noMargin?: boolean
  $noPadding?: boolean
  $padding?: string
  $verticalCenter?: boolean
}

export const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  align-items: flex-start;
  border-bottom: 1px dashed ${NEUTRAL_COLOURS.SILVER};

  ${({ $noPadding }) => !$noPadding && `
    padding: 20px 0;
  `}
  
  ${({ $verticalCenter }) => $verticalCenter && `
    align-items: center;
  `}

  ${({ $noBorder }) => $noBorder && `
    &:not(:last-child) {
      border-bottom: none;
    }
  `}

  ${({ $padding }) => $padding && `
    padding: ${$padding};
  `}

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    margin-bottom: 40px;
  }

  ${({ $noMargin }) => $noMargin && `
    &:last-child {
      margin-bottom: 0;
    }
  `}
`

export const StyledLeftContainer = styled.div`
  display: flex;
  flex: 1;

  @media (max-width: 600px) {
    flex-direction: column;
  }
  
  ${({ $inline }) => $inline && `
    flex-direction: column;
  `}
`

export const StyledRightContainer = styled.div`
  display: flex;
  margin-left: 20px;
`

export const StyledLabelContainer = styled.div`
  display: flex;
  font-weight: 600;
  width: 160px;
    
  ${({ $inline }) => $inline && `
    width: auto;
  `}
`

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 15px;
  text-overflow: ellipsis;

  @media (max-width: 600px) {
    margin-left: 0;
    margin-top: 20px;
  }

  & > div ~ div {
    margin-top: 20px;
  }
  
  ${({ $inline }) => $inline && `
    margin-left: 0;
    margin-top: 25px;
  `}
`
