import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withCarersService } from 'services/legacy/carers'

import { Select } from 'components'

import i18n from 'translations'

import withInfiniteDropdownHelpers from './withInfiniteDropdown'

const CARER_GROUPS = {
  read: [
    'carer.carerChildRelations',
    'carerChildRelation.details',
    'carerChildRelation.child',
  ],
}

class CarersDropdown extends Component {
  componentDidMount() {
    const { carersActions, infiniteDropdownHelpers, value, withChildRelation } = this.props

    if (!_.isArray(value)) {
      return infiniteDropdownHelpers.handleComponentDidMount({
        action: () => carersActions.get({
          onFailed: this.notFoundValue,
          onSuccess: (data) => {
            const { fullName, relations } = data

            return infiniteDropdownHelpers.handleComponentDidMountOnSuccess({
              ...data,
              name: fullName,
              sublabel: withChildRelation ? this.getChildRelation(relations) : null,
            })
          },
          params: [(value?.value || value), { groups: CARER_GROUPS }],
        }),
        value,
      })
    }

    return null
  }

  componentWillUnmount() {
    const { carersActions } = this.props

    carersActions.clear()
  }

  getChildRelation = (relations) => {
    const relationLabels = _.map(relations, ({ child = {}, relation }) => {
      const { displayName } = child

      return `${displayName} (${relation || '-'})`
    })

    return _.join(relationLabels, ', ')
  }

  handleLoadMoreElements = async (phrase) => {
    const {
      carersActions,
      carersSelectors,
      childId,
      infiniteDropdownHelpers,
      limit,
      parentalResponsibilityOnly,
      skipIds,
      withChildRelation,
    } = this.props

    return infiniteDropdownHelpers.handleLoadMoreElements({
      clearAction: carersActions.clear,
      listAction: ({ newPage, newSearchPhrase, result }) => carersActions.dropdownList({
        onSuccess: (response) => {
          const { data, meta } = response
          let filteredData = data

          if (skipIds) {
            filteredData = _.filter(data, ({ id }) => (!skipIds.includes(id)))
          }

          const newData = _.map(filteredData, (item) => ({
            ...item,
            name: item.fullName,
            sublabel: withChildRelation ? this.getChildRelation(item.carerChildRelations) : null,
          }))

          const newResponse = {
            data: newData,
            meta,
          }

          return infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
            extraFields: ['sublabel'],
            newPage,
            newSearchPhrase,
            response: newResponse,
            result,
          })
        },
        onlyData: true,
        params: {
          criteria: carersSelectors.getListCriteria({ childId, parentalResponsibilityOnly, search: phrase }),
          groups: withChildRelation ? CARER_GROUPS : null,
          limit,
          page: newPage,
        },
      }),
      phrase,
    })
  }

  notFoundValue = () => {
    const { onChange } = this.props

    onChange(null)
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  render() {
    const {
      clearable,
      disabled,
      error,
      infiniteDropdownState,
      maxMenuHeight,
      multi,
      placeholder,
      rawValue,
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        clearable={clearable}
        disabled={disabled}
        error={error}
        loadOptions={this.handleLoadMoreElements}
        maxMenuHeight={maxMenuHeight}
        multi={multi}
        options={options}
        placeholder={placeholder || i18n.t('module:Children:Child:Contacts:Form:chooseContact')}
        rawValue={rawValue || selectedValue || value}
        value={value}
        searchable
        withAvatars
        onChange={this.handleChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, carersListState }) => ({
  isFetching: appSelectors.getIsFetching(carersListState),
})

const enhance = compose(
  withAppService,
  withCarersService,
  withInfiniteDropdownHelpers,
  connect(mapState),
)

export default enhance(CarersDropdown)
