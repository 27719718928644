import { createSelector } from 'reselect'
import moment from 'moment'

import { DRINKING_METHOD } from 'services/legacy/child/constants'
import { DAILY_DIARY_DATE_FORMAT, TIME_TYPE } from 'services/legacy/dailyDiary/constants'

import { changeHours, isPastDay } from 'utils/date'

const getDailyDiaryBottles = (state) => state.dailyDiaryBottles

export const getDailyDiaryBottlesList = createSelector([getDailyDiaryBottles], (state) => state.list)

export const getDailyDiaryBottlesListData = createSelector([getDailyDiaryBottlesList], (state) => state.data)

export const getListCriteria = (query = {}) => {
  const {
    childrenWithoutRecords,
    entryDate = moment().format(DAILY_DIARY_DATE_FORMAT),
    not,
    recordsAndPlaceholders,
    room,
    timeType,
  } = query
  const criteria = []

  criteria.push({
    comparator: 'after',
    field: 'entryDate',
    value: entryDate,
  })

  criteria.push({
    comparator: 'before',
    field: 'entryDate',
    value: entryDate,
  })

  if (room) {
    criteria.push({
      field: 'child.nurseryClass',
      value: room,
    })
  }

  if (childrenWithoutRecords) {
    criteria.push({
      field: 'childRegisterDiaryRecordStatistics.bottleRecordsCount[lt]',
      value: 1,
    })
  }

  if (recordsAndPlaceholders) {
    criteria.push({
      field: 'or[0][childRegisterDiaryRecordStatistics.bottleRecordsCount][gt]',
      value: 0,
    })

    criteria.push({
      field: 'or[0][child.information.drinkingMethod][eq]',
      value: DRINKING_METHOD.BOTTLE,
    })
  }

  if (not) {
    const { drinkingMethod: notDrinkingMethod } = not

    if (notDrinkingMethod) {
      criteria.push({
        field: 'not[child.information.drinkingMethod]',
        value: notDrinkingMethod,
      })
    }
  }

  if (timeType === TIME_TYPE.CURRENTLY_TIMED_IN && !isPastDay(entryDate)) {
    criteria.push({
      comparator: 'after',
      field: 'signIns.signedOutAt',
      value: changeHours(entryDate, moment()).toISOString(),
    })
  }

  criteria.push({
    field: 'present',
    value: true,
  })

  criteria.push({
    field: 'order[child.firstName]',
    value: 'ASC',
  })

  return criteria
}
