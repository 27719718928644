import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { EVENTS, logEvent } from 'analytics'

import { DAILY_DIARY_DATE_FORMAT, DAILY_DIARY_LIST_GROUPS } from 'services/legacy/dailyDiary/constants'
import { DAILY_DIARY_RECORD_MODAL_VARIANTS } from 'module/Modals/DailyDiaryRecordModal/DailyDiaryRecordModalContainer'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withDailyDiarySleepsService } from 'services/legacy/dailyDiarySleeps'
import { withModalService } from 'services/utils/modal'
import { withSnackbarService } from 'services/utils/snackbar'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRegisterService } from 'services/legacy/register'
import { withRouterUtils } from 'services/utils/router'
import { withRouter } from 'services/router'

import i18n from 'translations'

import SleepsView from './SleepsView'

import { SLEEP_RECORD_EDIT_FORM } from './components/SleepRecord/SleepRecord'

const LIST_LIMIT = 10

class SleepsContainer extends Component {
  constructor(props) {
    super(props)

    const {
      paginationUtils: { setPageLocationQuery },
    } = props

    this.state = {
      isFetchingChildrenWithNoRecords: true,
    }

    setPageLocationQuery(false)
  }

  componentDidMount() {
    const { paginationUtils } = this.props
    const { setPerPage } = paginationUtils

    logEvent(EVENTS.DIARY_PAGE_VIEWED, { context: 'sleeps' })

    setPerPage(LIST_LIMIT, this.fetch)
  }

  shouldComponentUpdate(nextProps) {
    const { location: { query: nextQuery } } = nextProps
    const { location: { query } } = this.props

    if (
      query.date !== nextQuery.date
      || query.room !== nextQuery.room
      || query.timeType !== nextQuery.timeType
    ) {
      this.fetch(nextQuery)
    }

    return true
  }

  componentWillUnmount() {
    const { dailyDiarySleepsActions } = this.props

    dailyDiarySleepsActions.clearList()
  }

  fetch = (nextQuery, silent) => {
    const {
      dailyDiarySleepsActions,
      dailyDiarySleepsSelectors,
      location: { query },
      paginationUtils,
      setLocationQuery,
    } = this.props
    const { page } = paginationUtils
    const { date, room, timeType } = (nextQuery || query)

    setLocationQuery({ date, room, timeType })

    dailyDiarySleepsActions.list({
      mergeResult: 1 !== page,
      params: {
        criteria: dailyDiarySleepsSelectors.getListCriteria({
          entryDate: date,
          recordsAndPlaceholders: true,
          room,
          timeType,
        }),
        groups: DAILY_DIARY_LIST_GROUPS,
        limit: LIST_LIMIT,
        page,
      },
      silent,
    })

    if (1 === page) {
      this.fetchWithoutRecordList()
    }
  }

  fetchWithoutRecordList = (nextQuery) => {
    const {
      dailyDiarySleepsActions,
      dailyDiarySleepsSelectors,
      location: { query },
    } = this.props

    const { date } = (nextQuery || query)

    this.setState({ isFetchingChildrenWithNoRecords: true })

    dailyDiarySleepsActions.childrenListWithoutRecords({
      mergeResult: false,
      onSuccess: () => {
        this.setState({ isFetchingChildrenWithNoRecords: false })
      },
      params: {
        criteria: dailyDiarySleepsSelectors.getListCriteria({
          childrenWithoutRecords: true,
          entryDate: date,
          not: {
            daytimeSleepingBehavior: true,
          },
        }),
        groups: DAILY_DIARY_LIST_GROUPS,
        limit: 0,
        page: 1,
      },
    })
  }

  handleRemoveRecordSuccess = () => {
    const { snackbarActions } = this.props

    logEvent(EVENTS.DIARY_ITEM_REMOVED, { context: 'bottles' })

    this.fetchWithoutRecordList()

    snackbarActions.show({
      message: i18n.t('module:DailyDiary:Sleeps:removedSuccess'),
    })
  }

  handleRemoveRecordConfirmed = (sleep) => {
    const { dailyDiarySleepsActions } = this.props

    dailyDiarySleepsActions.removeRecord(
      sleep,
      this.handleRemoveRecordSuccess,
    )
  }

  handleRemoveRecord = (sleep) => {
    const { modalActions, modalConsts } = this.props

    logEvent(EVENTS.DIARY_REMOVE_ITEM_BTN_CLICKED, { context: 'bottles' })

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: () => this.handleRemoveRecordConfirmed(sleep),
      text: i18n.t('module:DailyDiary:Sleeps:confirmRemove'),
    })
  }

  handleAddRecordSuccess = ({ records }) => {
    const { dailyDiarySleepsActions, snackbarActions } = this.props

    dailyDiarySleepsActions.addToList(records)
    this.fetchWithoutRecordList()

    logEvent(EVENTS.DIARY_ITEM_ADDED, { context: 'sleeps' })

    snackbarActions.show({
      message: i18n.t('module:DailyDiary:Sleeps:addedSuccess'),
    })
  }

  handleAddRecord = (child) => {
    const {
      location: {
        query: {
          date = moment().format(DAILY_DIARY_DATE_FORMAT),
          timeType,
        },
      },
      modalActions,
      modalConsts,
    } = this.props

    logEvent(EVENTS.DIARY_ADD_ITEM_BTN_CLICKED, { context: 'sleeps' })

    modalActions.show(modalConsts.TYPES.DAILY_DIARY_RECORD, {
      date,
      onSuccess: this.handleAddRecordSuccess,
      selectedChildren: child ? [child] : [],
      timeType,
      variant: DAILY_DIARY_RECORD_MODAL_VARIANTS.SLEEP,
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleUpdateRecordFailed = (response, record, callback) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    callback(true)

    return injectValidation(`${SLEEP_RECORD_EDIT_FORM}_${record.id}`, errors)
  }

  handleUpdateRecordSuccess = (callback) => {
    const { snackbarActions } = this.props

    logEvent(EVENTS.DIARY_ITEM_UPDATED, { context: 'bottles' })

    if (callback) {
      callback()
    }

    snackbarActions.show({
      message: i18n.t('module:DailyDiary:Sleeps:updatedSuccess'),
    })
  }

  handleUpdateRecord = (record, values, callback) => {
    const { dailyDiarySleepsActions, dailyDiarySleepsSelectors, injectValidation } = this.props
    const { didNotSleep, endTime, startTime } = values

    if (!didNotSleep && moment(startTime).isSameOrAfter(endTime)) {
      injectValidation(`${SLEEP_RECORD_EDIT_FORM}_${record.id}`, {
        _error: i18n.t('module:DailyDiary:Sleeps:sleepTimeError'),
      })

      callback(true)

      return
    }

    const body = dailyDiarySleepsSelectors.getValuesForm(values)

    logEvent(EVENTS.DIARY_EDIT_ITEM_BTN_CLICKED, { context: 'bottles' })

    dailyDiarySleepsActions.updateRecord(
      record.id,
      body,
      () => this.handleUpdateRecordSuccess(callback),
      (response) => this.handleUpdateRecordFailed(response, record, callback),
    )
  }

  render() {
    const {
      hasNoRecordsData,
      isFetching,
      isOffline,
      isSubmitting,
      location: { query },
      paginationUtils,
      records,
      registers,
      totalResults,
    } = this.props
    const { isFetchingChildrenWithNoRecords } = this.state
    const { room } = query
    const { getPageCount, page } = paginationUtils

    const pageCount = getPageCount(totalResults)

    return (
      <SleepsView
        hasNoRecordsData={hasNoRecordsData}
        isFetching={isFetching}
        isFetchingChildrenWithNoRecords={isFetchingChildrenWithNoRecords}
        isOffline={isOffline}
        isSubmitting={isSubmitting}
        page={page}
        pageCount={pageCount}
        records={records}
        registers={registers}
        room={room && JSON.parse(room)?.value}
        totalResults={totalResults}
        onAddRecord={this.handleAddRecord}
        onPageChange={this.handlePageChange}
        onRemoveRecord={this.handleRemoveRecord}
        onUpdateRecord={this.handleUpdateRecord}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (formName, params) => stopSubmit(formName, params),
}

const mapState = (state, {
  appSelectors,
  dailyDiarySleepsListState,
  dailyDiarySleepsSelectors,
  dailyDiarySleepsSingleState,
  registerSelectors,
  registerState,
}) => ({
  hasNoRecordsData: dailyDiarySleepsSelectors.hasDailyDiarySleepsListWithoutRecordsData(state),
  isFetching: appSelectors.getIsFetching(
    dailyDiarySleepsListState,
    registerState,
  ),
  isOffline: appSelectors.getAppIsOffline(state),
  isSubmitting: appSelectors.getIsSubmitting(
    dailyDiarySleepsSingleState,
  ),
  records: dailyDiarySleepsSelectors.getDailyDiarySleepsListData(state),
  registers: registerSelectors.getRegisterDataSelector(state),
  totalResults: appSelectors.getTotalResults(dailyDiarySleepsListState),
})

const enhance = compose(
  withAppService,
  withDailyDiarySleepsService,
  withModalService,
  withPaginationUtils,
  withRegisterService,
  withRouter,
  withRouterUtils,
  withSnackbarService,
  connect(mapState, mapDispatch),
)

export default enhance(SleepsContainer)
