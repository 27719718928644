import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { Button, CircleIcon, FooterActions, Form, GridChildrenPicker, Section, Tooltip } from 'components'

export const GENERATE_INVOICE_FORM = 'GenerateInvoicesForm'

const GenerateInvoicesForm = ({
  childrenPickerOptions,
  childrenPickerRoomValue,
  childrenPickerSelectedAll,
  childrenPickerValue,
  handleSubmit,
  invoicePeriodDropdown,
  invoicePeriodOptions,
  isChildrenLoading,
  isChildrenPickerVisible,
  onChildrenPickerChange,
  onChildrenPickerSelectAll,
  onIssueDateChange,
  onPageChange,
  onRoomChange,
  onSubmit,
  page,
  pageCount,
  selectChildrenOptions,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Section title="Generate invoices">
      <p>
        Select options to calculate your invoices.
      </p>
      <Form.Row label="Invoice name" required>
        <Form.Row.FlexItem flex="0 0 200px">
          <Field
            component={Form.TextField}
            name="invoiceName"
            placeholder="Invoice name"
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label="Invoice period" required>
        <Form.Row.Item>
          <Field component={Form.Select} name="invoicePeriodDropdown" options={invoicePeriodOptions} />
        </Form.Row.Item>
        <Form.Row.TextItem flex="0 0 150px" text="Or select a period" />
        <Form.Row.Item>
          <Field
            component={Form.DatePicker}
            disabled={!!invoicePeriodDropdown}
            name="invoicePeriod"
            clearable
            range
          />
        </Form.Row.Item>
        <Form.Row.Item margin="0 0 0 5px">
          <Tooltip
            placement="right"
            title={
              'Selecting the start and end dates of a month (a full month) will calculate the invoice '
                .concat('with the option of average over ‘x’ weeks. \n\nIf you select custom dates the invoice ')
                .concat('will be calculated using actual sessions and extra items.')
            }
          >
            <CircleIcon
              cursor="pointer"
              icon="info"
              iconSize={16}
              size={24}
            />
          </Tooltip>
        </Form.Row.Item>
      </Form.Row>
      <Form.Row label="Invoice date" required>
        <Form.Row.Item>
          <Field
            component={Form.DatePicker}
            name="issueDate"
            placeholder="Invoice date"
            clearable
            onChange={onIssueDateChange}
          />
        </Form.Row.Item>
        <Form.Row.TextItem flex="0 0 150px" text="Due date" required />
        <Form.Row.Item>
          <Field
            component={Form.DatePicker}
            name="dueDate"
            placeholder="Due date"
            clearable
          />
        </Form.Row.Item>
      </Form.Row>
      <Form.Row label="Select children">
        <Form.Row.FlexItem>
          <Field
            component={Form.RadioGroup}
            name="selectChildren"
            options={selectChildrenOptions}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </Section>
    {isChildrenPickerVisible && (
      <Section title="Select the children to generate invoices for">
        <GridChildrenPicker
          isLoading={isChildrenLoading}
          options={childrenPickerOptions}
          page={page}
          pageCount={pageCount}
          roomValue={childrenPickerRoomValue}
          selectedAll={childrenPickerSelectedAll}
          value={childrenPickerValue}
          withEmptyState
          onChange={onChildrenPickerChange}
          onPageChange={onPageChange}
          onRoomChange={onRoomChange}
          onSelectAllClick={onChildrenPickerSelectAll}
        />
      </Section>
    )}
    <FooterActions>
      <FooterActions.Item>
        <Button label="Generate Invoices" negativeMargins submit />
      </FooterActions.Item>
    </FooterActions>
  </Form>
)

const validate = (values) => {
  const errors = {}

  if (!values.invoiceName) {
    errors.invoiceName = 'Enter invoice name e.g. September 2018'
  }

  // Validate invoice period dropdown and invoice period date
  if (!values.invoicePeriodDropdown && !values.invoicePeriod) {
    errors.invoicePeriodDropdown = 'Either select the period from drop down or enter the invoice period date'
  }

  // Invoice period dropdown
  if (values.invoicePeriod && (!values.invoicePeriod[0] || !values.invoicePeriod[1])) {
    errors.invoicePeriod = 'Select a invoice date range'
  }

  if (!values.issueDate) {
    errors.issueDate = 'Select invoice date'
  }

  if (!values.dueDate) {
    errors.dueDate = 'Select due date'
  }

  if (!values.selectChildren) {
    errors.selectChildren = 'Select children to generate invoices'
  }

  return errors
}

export default reduxForm({ form: GENERATE_INVOICE_FORM, validate })(GenerateInvoicesForm)
