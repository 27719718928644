import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { STATUS_OPTIONS_DROPDOWN, STATUS_OPTIONS_VALUES } from 'services/users/constants'
import { ROLES } from 'constants/security'

import { EVENTS, logEvent } from 'analytics'

import { withAppService } from 'services/app'
import { withMembershipsService } from 'services/legacy/memberships'
import { withModalService } from 'services/utils/modal'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSecurityService } from 'services/security'
import { getAuthOrganization, isOrganisationNursery, isOrganizationContext } from 'services/security/selectors'

import i18n from 'translations'

import StaffListView from './StaffListView'

const GROUPS_NURSERY = {
  read: [
    'membership.primaryManager',
    'membership.user',
    'membership.details',
    'membership.currentContract',
    'contract',
  ],
}

const GROUPS_ORGANIZATION = {
  read: [
    'membership.profile',
    'membership.nursery',
    'membership.organization',
    'membership.user',
    'membership.details',
    'nursery',
    'organization',
  ],
}

const SEND_REPORT_GROUPS = {
  empty: ['export:membership:staff'],
}

class StaffListContainer extends Component {
  constructor(props) {
    super(props)

    const { isAdministrationContext, location } = props
    const { nursery, organization, role, search, status = STATUS_OPTIONS_VALUES.ACTIVE } = location.query

    this.state = {
      filters: {
        nursery,
        organization,
        role,
        search,
        status: !isAdministrationContext ? status : null,
      },
    }
  }

  componentDidMount() {
    logEvent(EVENTS.STAFF_LIST_PAGE_VIEWED)

    this.fetch()
  }

  componentWillUnmount() {
    const { membershipsActions } = this.props

    membershipsActions.clearList()
  }

  fetch = () => {
    const {
      isAdministrationContext,
      isOrganizationContext: isOrganizationContextValue,
      membershipsActions,
      membershipsSelectors,
      paginationUtils: { page },
      setLocationQuery,
    } = this.props
    const { filters } = this.state
    const { search } = filters

    const criteria = membershipsSelectors.getMembershipsListCriteria(
      isAdministrationContext
        ? { nonTeaching: true, search, type: 'superAdmin' }
        : { ...filters, nonTeaching: true },
    )

    setLocationQuery(filters)

    return membershipsActions.list({
      params: {
        criteria,
        groups: isOrganizationContextValue ? GROUPS_ORGANIZATION : GROUPS_NURSERY,
        order: 'id ASC',
        page,
      },
    })
  }

  handleExportSuccess = (onSuccess) => {
    onSuccess()

    logEvent(EVENTS.STAFF_LIST_EXPORTED)
  }

  handleSendReport = () => {
    const { membershipsActions, modalActions, modalConsts } = this.props
    const { filters } = this.state
    const { search, status } = filters

    logEvent(EVENTS.STAFF_LIST_EXPORT_BTN_CLICKED)

    const criteria = {
      displayName: search,
    }

    if (STATUS_OPTIONS_VALUES.ACTIVE === status) {
      criteria.archived = false
      criteria.accepted = true
    }

    if (STATUS_OPTIONS_VALUES.PENDING === status) {
      criteria.archived = false
      criteria.accepted = false
    }

    if (STATUS_OPTIONS_VALUES.DISABLED === status) {
      criteria.archived = true
    }

    modalActions.show(modalConsts.TYPES.EXPORT_REPORT, {
      filters: { type: 'staff' },
      onSendReport: (params, onSuccess) => membershipsActions.exportStaffList({
        onSuccess: () => this.handleExportSuccess(onSuccess),
        params,
      }),
      params: {
        criteria,
        groups: SEND_REPORT_GROUPS,
      },
      title: i18n.t('module:Staff:StaffLists:exportReportStaffListTitle'),
      userLabel: i18n.t('module:Modals:ExportReportModal:User:Label:pdf'),
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSearchChange = (search) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        search,
      },
    }), () => setTimeout(() => {
      onPageChange(this.fetch)(1)
    }))
  }

  handleChangeNursery = (nursery) => {
    const { paginationUtils: { onPageChange } } = this.props

    this.setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        nursery: nursery?.value,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handleOrganizationChange = (organization) => {
    const { authOrganization, paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        organization: organization ? authOrganization.id : null,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handleRoleChange = (role) => {
    const { paginationUtils: { onPageChange } } = this.props

    this.setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        role: role?.value,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handleStatusChange = (status) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        status: status && status.value,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  render() {
    const {
      appProperties,
      currentUser,
      isAdministrationContext,
      isFetching,
      isNurseryContext,
      isOrganizationContext: isOrganizationContextValue,
      memberships,
      paginationUtils,
      totalResults,
    } = this.props
    const { filters } = this.state
    const { nursery, organization, role, status } = filters
    const { getPageCount, page, perPage } = paginationUtils

    const pageCount = getPageCount(totalResults)

    return (
      <StaffListView
        appProperties={appProperties}
        currentUser={currentUser}
        isAdministrationContext={isAdministrationContext}
        isFetching={isFetching}
        isNurseryContext={isNurseryContext}
        isOrganizationContext={isOrganizationContextValue}
        nursery={nursery}
        organization={organization}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        role={role}
        status={status}
        statusOptions={STATUS_OPTIONS_DROPDOWN}
        totalResults={totalResults}
        users={memberships}
        onChangeNursery={this.handleChangeNursery}
        onOrganizationChange={this.handleOrganizationChange}
        onPageChange={this.handlePageChange}
        onRoleChange={this.handleRoleChange}
        onSearchChange={this.handleSearchChange}
        onSendReport={this.handleSendReport}
        onStatusChange={this.handleStatusChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  membershipsListState,
  membershipsSelectors,
  securitySelectors,
}) => ({
  appProperties: appSelectors.getApplicationProperties(state),
  authOrganization: getAuthOrganization(state),
  currentUser: securitySelectors.getAuthUser(state),
  isAdministrationContext: securitySelectors.isAdministrationContext(state),
  isFetching: appSelectors.getIsFetching(membershipsListState),
  isNurseryContext: securitySelectors.isNurseryContext(state),
  isOrganizationContext: securitySelectors.isOrganizationContext(state),
  memberships: membershipsSelectors.getMembershipsListDataWithIsEditable(state),
  totalResults: appSelectors.getTotalResults(membershipsListState),
})

const enhance = compose(
  withAppService,
  withMembershipsService,
  withModalService,
  withPaginationUtils,
  withSecurityService,
  connect(mapState),
)

StaffListContainer.authParams = (state) => {
  const isOrganization = isOrganizationContext(state)

  if (isOrganization) {
    return {
      roles: [
        ROLES.SUPER_ADMIN,
        ROLES.ORGANIZATION_DIRECTOR,
        ROLES.ORGANIZATION_NATIONAL_ADMIN,
      ],
    }
  }

  if (isOrganisationNursery(state.authentication)) {
    return {
      roles: [
        ROLES.SUPER_ADMIN,
        ROLES.ORGANIZATION_DIRECTOR,
        ROLES.ORGANIZATION_FINANCE_ADMIN,
        ROLES.ORGANIZATION_NATIONAL_ADMIN,
        ROLES.ORGANIZATION_LINE_MANAGER,
        ROLES.DEPUTY_MANAGER,
        ROLES.NURSERY_MANAGER,
        ROLES.NURSERY_ADMIN,
      ],
    }
  }

  return {
    roles: [
      ROLES.SUPER_ADMIN,
      ROLES.NURSERY_ADMIN,
      ROLES.NURSERY_MANAGER,
      ROLES.DEPUTY_MANAGER,
    ],
  }
}

export default enhance(StaffListContainer)
