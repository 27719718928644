import { generateBasicActions } from 'utils/service'
import { BasicActionOptionsProps } from 'constants/service'

import {
  CLEAR_NURSERY,
  CREATE_NURSERY,
  CREATE_NURSERY_FAILED,
  CREATE_NURSERY_SUCCESS,
  GET_NURSERY,
  GET_NURSERY_FAILED,
  GET_NURSERY_FLAGS,
  GET_NURSERY_FLAGS_FAILED,
  GET_NURSERY_FLAGS_SUCCESS,
  GET_NURSERY_SUCCESS,
  UPDATE_CHILD_CODE_REFERENCE_SETTINGS,
  UPDATE_CHILD_CODE_REFERENCE_SETTINGS_FAILED,
  UPDATE_CHILD_CODE_REFERENCE_SETTINGS_SUCCESS,
  UPDATE_FINANCE_MIGRATION,
  UPDATE_FINANCE_MIGRATION_FAILED,
  UPDATE_FINANCE_MIGRATION_SUCCESS,
  UPDATE_NURSERY,
  UPDATE_NURSERY_FAILED,
  UPDATE_NURSERY_FLAG,
  UPDATE_NURSERY_FLAG_FAILED,
  UPDATE_NURSERY_FLAG_SUCCESS,
  UPDATE_NURSERY_SUCCESS,
} from './constants'

interface NurseriesSingleActionsProps extends BasicActionOptionsProps {
  headers?: any
  payload?: any
}

export const create = (options: NurseriesSingleActionsProps) => ({ nurseriesApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {}, payload } = options

  dispatch({
    type: CREATE_NURSERY,
  })

  nurseriesApiClient.create(payload, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: CREATE_NURSERY_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_NURSERY_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const get = (id, options: NurseriesSingleActionsProps) => ({ nurseriesApiClient }) => async (dispatch) => {
  const { headers, onSuccess, onlyData, params = {} } = options || {}

  if (!onlyData) {
    dispatch({
      id,
      params,
      type: GET_NURSERY,
    })
  }

  await nurseriesApiClient.get(id, params, headers).then(
    (response) => {
      if (!onlyData) {
        dispatch({
          payload: response,
          type: GET_NURSERY_SUCCESS,
        })
      }

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      if (!onlyData) {
        dispatch({
          error: response,
          type: GET_NURSERY_FAILED,
        })
      }
    },
  )
}

export const listFlags = (id, options: NurseriesSingleActionsProps = {}) => ({ nurseriesApiClient }) => (dispatch) => {
  const { onSuccess, params = {} } = options

  dispatch({
    id,
    params,
    type: GET_NURSERY_FLAGS,
  })

  nurseriesApiClient.listFlags(id, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: GET_NURSERY_FLAGS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_NURSERY_FLAGS_FAILED,
      })
    },
  )
}

export const update = (id, options: NurseriesSingleActionsProps) => ({ nurseriesApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {}, payload = {}, silent } = options

  if (!silent) {
    dispatch({
      id,
      params,
      payload,
      type: UPDATE_NURSERY,
    })
  }

  return nurseriesApiClient.update(id, payload, params).then(
    (response) => {
      if (!silent) {
        dispatch({
          payload: response,
          type: UPDATE_NURSERY_SUCCESS,
        })
      }

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      if (!silent) {
        dispatch({
          error: response,
          type: UPDATE_NURSERY_FAILED,
        })
      }

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updateFlag = (
  id,
  flag,
  options: NurseriesSingleActionsProps,
) => ({ nurseriesApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {}, payload = {} } = options

  dispatch({
    flag,
    id,
    params,
    payload,
    type: UPDATE_NURSERY_FLAG,
  })

  return nurseriesApiClient.updateFlag(id, flag, payload, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: UPDATE_NURSERY_FLAG_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_NURSERY_FLAG_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updateFinanceMigration = (options: BasicActionOptionsProps) => ({
  nurseriesApiClient,
}) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_FINANCE_MIGRATION_FAILED,
      init: UPDATE_FINANCE_MIGRATION,
      success: UPDATE_FINANCE_MIGRATION_SUCCESS,
    },
    options,
    service: nurseriesApiClient,
    serviceMethod: 'updateNewFinanceMigration',
  })
)

export const updateChildCodeReferenceSettings = (options: BasicActionOptionsProps) => ({
  nurseriesApiClient,
}) => (
  generateBasicActions.update({
    constants: {
      failed: UPDATE_CHILD_CODE_REFERENCE_SETTINGS_FAILED,
      init: UPDATE_CHILD_CODE_REFERENCE_SETTINGS,
      success: UPDATE_CHILD_CODE_REFERENCE_SETTINGS_SUCCESS,
    },
    options,
    service: nurseriesApiClient,
    serviceMethod: 'updateChildCodeReferenceSettings',
  })
)

export const clearSingle = () => ({
  type: CLEAR_NURSERY,
})
