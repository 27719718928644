import { compose } from 'recompose'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { FILE_TYPES } from 'constants/mimetypes'
import { ALLERGY_TYPES } from 'services/legacy/child/constants'

import { withChildService } from 'services/legacy/child'
import { withMembershipsService } from 'services/legacy/memberships'
import { withSecurityService } from 'services/security'
import { withModalService } from 'services/utils/modal'

import i18n from 'translations'

import {
  ALLERGIES_AND_DIETARY_PREFERENCES,
  ALLERGIES_ONLY,
  ALL_CHILDREN,
  ONLY_CHILDREN_WITH_ALLERGIES_OR_DIETARY_PREFERENCES,
} from './constants'

import { DIETARY_REPORT_EXPORT_FORM } from './components/DietaryReportExportForm'

import DietaryReportExportModalView from './DietaryReportExportModalView'

class DietaryReportExportModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      exportIsProcessed: false,
      reportType: ALLERGIES_AND_DIETARY_PREFERENCES,
      showAllOrSomeChildren: ALL_CHILDREN,
    }
  }

  componentWillUnmount() {
    const { membershipsActions } = this.props

    membershipsActions.clear()
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleReportTypeChange = () => {
    const { reportType } = this.state

    this.setState({ reportType: reportType === ALLERGIES_AND_DIETARY_PREFERENCES
      ? ALLERGIES_ONLY
      : ALLERGIES_AND_DIETARY_PREFERENCES })
  }

  handleShowAllOrSomeChildrenChange = () => {
    const { showAllOrSomeChildren } = this.state

    this.setState({ showAllOrSomeChildren: showAllOrSomeChildren === ALL_CHILDREN
      ? ONLY_CHILDREN_WITH_ALLERGIES_OR_DIETARY_PREFERENCES
      : ALL_CHILDREN })
  }

  handleSendReportSuccess = (membership) => () => {
    const { membershipsActions, modalActions, modalConsts } = this.props

    this.setState({ exportIsProcessed: false })

    if (!membership?.email) {
      membershipsActions.get(membership.value, {
        onSuccess: ({ data }) => modalActions.show(modalConsts.TYPES.ALERT, {
          text: i18n.t('module:Modals:ExportReportModal:alert', { email: data.email }),
        }),
      })
    } else {
      modalActions.show(modalConsts.TYPES.ALERT, {
        text: i18n.t('module:Modals:ExportReportModal:alert', { email: membership.email }),
      })
    }
  }

  handleSendReportFailed = ({ message }) => {
    const { stopSubmitForm } = this.props

    this.setState({ exportIsProcessed: false })

    stopSubmitForm({ user: message })
  }

  handleSendReport = ({ membership, nurseryClass }) => {
    const {
      childActions,
      childSelectors,
      filters,
      onSendReport,
    } = this.props
    const { reportType, showAllOrSomeChildren } = this.state

    const hasAllergy = ALLERGIES_ONLY === reportType ? { allergies: ALLERGY_TYPES.HAS_ALLERGY } : {}
    const criteriaParams = {
      ...filters,
      ...hasAllergy,
      membership,
      onlyAllergiesOrDietaryPreferences: showAllOrSomeChildren === ONLY_CHILDREN_WITH_ALLERGIES_OR_DIETARY_PREFERENCES,
      room: nurseryClass?.value,
      type: reportType,
    }

    const criteria = childSelectors.getCriteria(criteriaParams)
    const headers = { Accept: FILE_TYPES.PDF.acceptHeader }
    const SEND_REPORT_GROUPS = {
      read: ['child', 'child.childrenSessions:array'],
    }
    const params = { groups: SEND_REPORT_GROUPS }

    const body = { ...params, criteria, headers }

    this.setState({ exportIsProcessed: true })

    if (onSendReport) {
      return onSendReport(
        body,
        this.handleSendReportSuccess(membership),
        this.handleSendReportFailed,
      )
    }

    return childActions.sendReport(
      body,
      this.handleSendReportSuccess(membership),
      this.handleSendReportFailed,
    )
  }

  render() {
    const { currentUser } = this.props
    const { exportIsProcessed, reportType, showAllOrSomeChildren } = this.state

    const initialValues = {
      membership: currentUser
        ? { value: currentUser.membership.id }
        : null,
      reportType,
      showAllOrSomeChildren,
    }

    return (
      <DietaryReportExportModalView
        initialValues={initialValues}
        isProcessing={exportIsProcessed}
        isReportTypeAllergiesAndDietaryPreferences={ALLERGIES_AND_DIETARY_PREFERENCES === reportType}
        title={i18n.t('module:Modals:DietaryReportExportModal:title')}
        onCloseClick={this.handleCloseClick}
        onReportTypeChange={this.handleReportTypeChange}
        onShowAllOrSomeChildrenChange={this.handleShowAllOrSomeChildrenChange}
        onSubmit={this.handleSendReport}
      />
    )
  }
}

const mapDispatch = {
  stopSubmitForm: (params) => stopSubmit(DIETARY_REPORT_EXPORT_FORM, params),
}

const mapState = (state, { securitySelectors }) => ({
  currentUser: securitySelectors.getAuthUser(state),
})

const enhance = compose(
  withChildService,
  withMembershipsService,
  withModalService,
  withSecurityService,
  connect(mapState, mapDispatch),
)

export default enhance(DietaryReportExportModalContainer)
