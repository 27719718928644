import React from 'react'

import { Callout, Page, Spinner } from 'components'

import ManagementNurseryEditForm from './components/ManagementNurseryEditForm'

const ManagementNurseryEditView = ({
  daysOptions,
  errorMessages,
  financeLocked,
  initialValues,
  isFormLoading,
  isLoading,
  isOrganizationNursery,
  onApplyAll,
  onSubmit,
  openPeriodOptions,
}) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <ManagementNurseryEditForm
        daysOptions={daysOptions}
        financeLocked={financeLocked}
        initialValues={initialValues}
        isLoading={isFormLoading}
        isOrganizationNursery={isOrganizationNursery}
        openPeriodOptions={openPeriodOptions}
        showPrimaryManager
        onApplyAll={onApplyAll}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <Page isLoading={isLoading}>
      <Page.Content>
        <Callout content={errorMessages} error />
        {renderContent()}
      </Page.Content>
    </Page>
  )
}

export default ManagementNurseryEditView
