import { DOCUMENT_FILES_COLOURS } from 'constants/colors'

export const FILE_TYPES = {
  CSV: {
    acceptHeader: 'text/csv',
    color: DOCUMENT_FILES_COLOURS.SPREADSHEET,
    extension: '.csv',
    icon: 'spreadsheet',
    label: '.CSV',
    mimeTypes: ['text/csv'],
  },
  DOC: {
    acceptHeader: '',
    color: DOCUMENT_FILES_COLOURS.DOC,
    extension: '.doc',
    icon: 'doc',
    label: '.DOC',
    mimeTypes: ['application/msword'],
  },
  DOCX: {
    acceptHeader: '',
    color: DOCUMENT_FILES_COLOURS.DOC,
    extension: '.docx',
    icon: 'doc',
    label: '.DOCX',
    mimeTypes: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  },
  DOT: {
    acceptHeader: '',
    color: DOCUMENT_FILES_COLOURS.DOC,
    extension: '.dot',
    icon: 'doc',
    label: '.DOT',
    mimeTypes: ['application/msword', 'application/msword-template'],
  },
  HEIC: {
    acceptHeader: '',
    color: '',
    extension: '.heic',
    icon: 'image',
    label: '.HEIC',
    mimeTypes: ['image/heic', 'image/heic-sequence'],
  },
  HEIF: {
    acceptHeader: '',
    color: '',
    extension: '.heif',
    icon: 'image',
    label: '.HEIF',
    mimeTypes: ['image/heif', 'image/heif-sequence'],
  },
  JPEG: {
    acceptHeader: '',
    color: '',
    extension: '.jpeg',
    icon: '',
    label: '.JPEG',
    mimeTypes: ['image/jpeg'],
  },
  JPG: {
    acceptHeader: '',
    color: '',
    extension: '.jpg',
    icon: '',
    label: '.JPG',
    mimeTypes: ['image/jpg'],
  },
  MOV: {
    acceptHeader: '',
    color: '',
    extension: '.mov',
    icon: '',
    label: '.MOV',
    mimeTypes: ['.mov', 'video/quicktime'],
  },
  MP4: {
    acceptHeader: '',
    color: '',
    extension: '.mp4',
    icon: '',
    label: '.MP4',
    mimeTypes: ['video/mp4'],
  },
  PDF: {
    acceptHeader: 'application/pdf',
    color: DOCUMENT_FILES_COLOURS.PDF,
    extension: '.pdf',
    icon: 'pdf',
    label: '.PDF',
    mimeTypes: ['application/pdf'],
  },
  PNG: {
    acceptHeader: '',
    color: '',
    extension: '.png',
    icon: '',
    label: '.PNG',
    mimeTypes: ['image/png'],
  },
  PPT: {
    acceptHeader: '',
    color: DOCUMENT_FILES_COLOURS.PRESENTATION,
    extension: '.ppt',
    icon: 'presentation',
    label: '.PPT',
    mimeTypes: ['application/vnd.ms-powerpoint'],
  },
  PPTX: {
    acceptHeader: '',
    color: DOCUMENT_FILES_COLOURS.PRESENTATION,
    extension: '.pptx',
    icon: 'presentation',
    label: '.PPTX',
    mimeTypes: ['application/vnd.openxmlformats-officedocument.presentationml.presentation'],
  },
  WEBM: {
    acceptHeader: '',
    color: '',
    extension: '.webm',
    icon: '',
    label: '.WEBM',
    mimeTypes: ['video/webm'],
  },
  XLS: {
    acceptHeader: '',
    color: DOCUMENT_FILES_COLOURS.SPREADSHEET,
    extension: '.xls',
    icon: 'spreadsheet',
    label: '.XLS',
    mimeTypes: ['application/vnd.ms-excel'],
  },
  XLSX: {
    acceptHeader: '',
    color: DOCUMENT_FILES_COLOURS.SPREADSHEET,
    extension: '.xlsx',
    icon: 'spreadsheet',
    label: '.XLSX',
    mimeTypes: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  },
}

export const SUPPORTED_FILE_TYPES = {
  DOCUMENTS: [
    FILE_TYPES.DOC,
    FILE_TYPES.DOCX,
    FILE_TYPES.DOT,
    FILE_TYPES.PDF,
    FILE_TYPES.PPT,
    FILE_TYPES.PPTX,
    FILE_TYPES.XLS,
    FILE_TYPES.XLSX,
    FILE_TYPES.CSV,
  ],
  IMAGES: [
    FILE_TYPES.JPG,
    FILE_TYPES.JPEG,
    FILE_TYPES.PNG,
    FILE_TYPES.HEIC,
    FILE_TYPES.HEIF,
  ],
  PDF: [
    FILE_TYPES.PDF,
  ],
  VIDEO: [
    FILE_TYPES.MP4,
    FILE_TYPES.MOV,
    FILE_TYPES.WEBM,
  ],
}
