import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { formValueSelector, stopSubmit } from 'redux-form'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withModalService } from 'services/utils/modal'
import { withFormativeReportsService } from 'services/legacy/formativeReports'
import { withRouter } from 'services/router'

import i18n from 'translations'

import FormativeReportsSendModalView from './FormativeReportsSendModalView'

import { FORMATIVE_REPORTS_SEND_FORM } from './components/FormativeReportsSendForm/FormativeReportsSendForm'

class FormativeReportsSendModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSentToParents: false,
      isSentToStaffMembers: false,
    }
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmitSuccess = () => {
    const { selectedParents = [], selectedStaffMembers = [] } = this.props
    const { isSentToParents, isSentToStaffMembers } = this.state

    let displaySentPopup = false

    if (selectedParents.length && isSentToParents && !selectedStaffMembers.length) {
      displaySentPopup = true
    }

    if (!selectedParents.length && selectedStaffMembers.length && isSentToStaffMembers) {
      displaySentPopup = true
    }

    if (selectedParents.length && isSentToParents && selectedStaffMembers.length && isSentToStaffMembers) {
      displaySentPopup = true
    }

    if (displaySentPopup) {
      const { modalActions, modalConsts } = this.props

      modalActions.show(modalConsts.TYPES.ALERT, {
        icon: 'send',
        text: i18n.t('module:Modals:FormativeReportsSendModal:alert'),
      })
    }
  }

  handleSubmitSuccessStaffMembers = () => {
    this.setState({ isSentToStaffMembers: true }, this.handleSubmitSuccess)
  }

  handleSubmitSuccessParents = () => {
    this.setState({ isSentToParents: true }, this.handleSubmitSuccess)
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(FORMATIVE_REPORTS_SEND_FORM, errors)
  }

  handleSubmit = (fields) => {
    const {
      childActions,
      formativeReportsActions,
      formativeReportsSelectors,
      report,
    } = this.props
    const { child, period: { label } } = report
    const { carers, memberships } = fields

    // NOTE: sending formative report to carers/parents
    if (carers && carers.length) {
      const carerFields = {
        carers,
        label: { value: label },
      }

      const carersCriteria = formativeReportsSelectors.getExportCriteria({ fields: carerFields })

      childActions.sendFormativeReport(
        child.id,
        { criteria: carersCriteria },
        this.handleSubmitSuccessParents,
        this.handleSubmitFailed,
      )
    }

    // NOTE: sending formative report to memberships/staff
    if (memberships && memberships.length) {
      const usersFields = {
        label: { value: label },
        memberships,
      }

      const usersCriteria = formativeReportsSelectors.getExportCriteria({ child, fields: usersFields })

      formativeReportsActions.exportReport(
        { criteria: usersCriteria },
        this.handleSubmitSuccessStaffMembers,
        this.handleSubmitFailed,
      )
    }
  }

  render() {
    const { report } = this.props

    return (
      <FormativeReportsSendModalView
        child={report.child}
        report={report}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (formName, errors) => stopSubmit(formName, errors),
}

const mapState = (state) => ({
  selectedParents: formValueSelector(FORMATIVE_REPORTS_SEND_FORM)(state, 'carers'),
  selectedStaffMembers: formValueSelector(FORMATIVE_REPORTS_SEND_FORM)(state, 'memberships'),
})

const enhance = compose(
  withAppService,
  withChildService,
  withModalService,
  withRouter,
  withFormativeReportsService,
  connect(mapState, mapDispatch),
)

export default enhance(FormativeReportsSendModalContainer)

