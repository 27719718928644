import moment from 'moment'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { STATUS_TYPES } from 'services/legacy/invoices/constants'

import { Callout, Section, Space, Spinner, Typography } from 'components'
import InvoiceTable from 'module/Finance/components/InvoiceTable'
import InvoiceBadDebtHighlight from 'module/Finance/components/InvoiceBadDebtHighlight'
import InvoiceDeliveryRefNo from 'module/Finance/components/InvoiceDeliveryRefNo'

import i18n from 'translations'

import InvoiceHeader from './components/InvoiceHeader'

const FinanceInvoicesPreviewView = ({
  child,
  childFinanceDetails,
  errorMessages,
  invoicePreview,
  isLoading,
  onDownloadButtonClick,
  onEditBadDebtClick,
  onIssueCreditNote,
  onMarkAsBadDebtClick,
  onMarkAsSentClick,
  onRepeatClick,
  onSendButtonClick,
}) => {
  if (isLoading) {
    return (
      <Spinner />
    )
  }

  const renderInvoice = () => {
    const { creditNotes, invoice = {} } = invoicePreview || {}
    const {
      badDebts,
      endDate,
      id,
      name = 'Draft invoice',
      startDate,
      status,
    } = invoice
    const { id: childId } = child
    const subtitle = `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`
    const issuedCreditNote = !!(creditNotes && creditNotes.length)
    const isBadDebtExists = !!(badDebts && badDebts.length)
    const to = generateRoute('CHILDREN.CHILD.FINANCE.INVOICE.EDIT', { childId, invoiceId: id })

    const actions = (
      <Section.Actions
        options={[{
          auth: status !== STATUS_TYPES.BAD_DEBT && status !== STATUS_TYPES.CANCELLED,
          icon: 'copy',
          label: i18n.t('global:Repeat'),
          onClick: onRepeatClick,
        }, {
          auth: status === STATUS_TYPES.DRAFT,
          icon: 'mark-as-sent',
          label: 'Mark as sent',
          onClick: onMarkAsSentClick,
        }, {
          onClick: onDownloadButtonClick,
          type: 'download',
        }, {
          auth: !(status === STATUS_TYPES.CANCELLED || status === STATUS_TYPES.BAD_DEBT),
          icon: status === STATUS_TYPES.DRAFT ? 'send' : 'resend',
          label: status === STATUS_TYPES.DRAFT ? 'Send' : 'Resend',
          onClick: onSendButtonClick,
        }, {
          auth: !isBadDebtExists
            && status !== STATUS_TYPES.DRAFT
            && status !== STATUS_TYPES.PAID
            && status !== STATUS_TYPES.CANCELLED,
          icon: 'bad-debt',
          label: status === STATUS_TYPES.BAD_DEBT ? 'Unmark as bad debt' : 'Mark as bad debt',
          onClick: onMarkAsBadDebtClick,
        }, {
          auth: !issuedCreditNote && status !== STATUS_TYPES.DRAFT && status !== STATUS_TYPES.BAD_DEBT,
          icon: 'credit-note',
          label: 'Issue credit note',
          onClick: onIssueCreditNote,
        }]}
        tertiary={{
          auth: status === STATUS_TYPES.DRAFT,
          to,
        }}
      />
    )

    return (
      <Section
        actions={actions}
        subtitle={subtitle}
        title={name}
      >
        <InvoiceHeader invoicePreview={invoicePreview} />
        {status === STATUS_TYPES.DRAFT && (
          <React.Fragment>
            <Typography>{i18n.t('module:Children:Child:Finance:Invoice:draftInvoiceNumberCopy')}</Typography>
            <Space space="20px" />
          </React.Fragment>
        )}
        <InvoiceTable child={childFinanceDetails} invoicePreview={invoicePreview} preview />
        <InvoiceBadDebtHighlight invoicePreview={invoicePreview} onEditClick={onEditBadDebtClick} />
        <InvoiceDeliveryRefNo invoicePreview={invoicePreview} />
      </Section>
    )
  }

  return (
    <div>
      <Callout content={errorMessages} error />
      {renderInvoice()}
    </div>
  )
}

export default FinanceInvoicesPreviewView
