import React from 'react'

import colors, { NEUTRAL_COLOURS } from 'constants/colors'

import { convertToPercent, toFloat } from 'utils/data'

import {
  Chart,
  Currency,
  DatePicker,
  EmptyState,
  InfiniteScroll,
  Page,
  SearchBar,
  Section,
  Select,
  Spinner,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

import StatisticsTable from './components/StatisticsTable'
import { StyledChartContainer } from './FinanceReportDetailedRevenueStyled'

const FinanceReportDetailedRevenueView = ({
  dateRange,
  dateType,
  dateTypeOptions,
  invoices,
  isDepositEnabled,
  isFinanceV3Enabled,
  isLoading,
  isLoadingStatistics,
  onDateChange,
  onDateTypeChange,
  onPageChange,
  onSearchChange,
  onSendReport,
  onStatusChange,
  page,
  pageCount,
  search,
  statistics,
  status,
  statusOptions,
}) => {
  const renderLabel = (amount, percentage) => (
    <React.Fragment>
      <Currency value={amount} oneSize />
      {' '}
      <Typography color={NEUTRAL_COLOURS.GRAY} fontSize="inherit" variant="span">
        {`(${percentage}%)`}
      </Typography>
    </React.Fragment>
  )

  const renderStatistics = () => {
    if (isLoadingStatistics) {
      return null
    }

    const totalIncome = statistics?.totalIncome || 0
    const invoicePercentage = convertToPercent(totalIncome, statistics.totalInvoices)
    const fundingIncomePercentage = convertToPercent(totalIncome, statistics.totalFundingIncome)

    const chartData = statistics && [
      {
        amount: renderLabel(statistics.totalInvoices, invoicePercentage),
        color: colors.standardPieChartColors[0],
        id: i18n.t('global:Finance:FeeIncome'),
        isCurrencyValue: true,
        percentage: invoicePercentage,
        value: toFloat(statistics.totalInvoices),
      },
      {
        amount: renderLabel(statistics.totalFundingIncome, fundingIncomePercentage),
        color: colors.standardPieChartColors[1],
        id: i18n.t('global:Finance:FundingIncome'),
        isCurrencyValue: true,
        percentage: fundingIncomePercentage,
        value: toFloat(statistics.totalFundingIncome),
      },
    ]

    return (
      <StyledChartContainer>
        <Chart.Pie
          data={chartData}
          legendValueField="amount"
          subtitle={(
            <Currency value={totalIncome} oneSize />
          )}
          title={i18n.t('global:Finance:TotalIncome')}
        />
      </StyledChartContainer>
    )
  }

  const renderTable = () => {
    if ((isLoading || isLoadingStatistics) && 1 === page) {
      return <Spinner />
    }

    return (
      <div>
        <InfiniteScroll
          dataLength={invoices ? invoices.length : 0}
          hasMore={page < pageCount}
          next={() => onPageChange((+page) + 1)}
        >
          <StatisticsTable
            invoices={invoices}
            isDepositEnabled={isDepositEnabled}
            isFinanceV3Enabled={isFinanceV3Enabled}
            statistics={statistics}
          />
        </InfiniteScroll>
      </div>
    )
  }

  const renderContent = () => {
    if (!isLoading && !isLoadingStatistics && (invoices && !invoices.length)) {
      return (
        <EmptyState
          icon="financial-reports"
          text1={i18n.t('module:Finance:Reports:DetailedRevenue:emptyStateText')}
        />
      )
    }

    return (
      <div>
        <Typography margin="20px 0">
          {i18n.t('module:Finance:Reports:DetailedRevenue:description')}
        </Typography>
        {statistics && renderStatistics()}
        {renderTable()}
      </div>
    )
  }

  const actions = <Section.Actions options={[{ onClick: onSendReport, type: 'export' }]} />

  return (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t('global:searchPlaceholder')}
        value={search}
        onChange={onSearchChange}
      />
      <Page subMenu>
        <Page.Content>
          <Section
            actions={actions}
            title={i18n.t('module:Finance:Reports:DetailedRevenue:title')}
          >
            <Toolbar bottomLine>
              <Toolbar.Group>
                <Toolbar.Item>
                  <Select
                    clearable={false}
                    options={dateTypeOptions}
                    placeholder="Date type"
                    searchable={false}
                    value={dateType}
                    onChange={onDateTypeChange}
                  />
                </Toolbar.Item>
                <Toolbar.Item>
                  <DatePicker
                    value={dateRange}
                    clearable
                    range
                    onChange={onDateChange}
                  />
                </Toolbar.Item>
                <Toolbar.Item>
                  <Select
                    options={statusOptions}
                    placeholder="Status"
                    value={status}
                    onChange={onStatusChange}
                  />
                </Toolbar.Item>
              </Toolbar.Group>
            </Toolbar>
            {renderContent()}
          </Section>
        </Page.Content>
      </Page>
    </React.Fragment>
  )
}

export default FinanceReportDetailedRevenueView
