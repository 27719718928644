import {
  CLEAR_INVOICE_LIST,
  EXPORT_INVOICES,
  EXPORT_INVOICES_FAILED,
  EXPORT_INVOICES_SUCCESS,
  LIST_INVOICES,
  LIST_INVOICES_FAILED,
  LIST_INVOICES_SUCCESS,
} from './constants'

export const list = (params, mergeResult, onSuccess) => ({ invoicesApiClient }) => (dispatch) => {
  dispatch({
    params,
    type: LIST_INVOICES,
  })

  invoicesApiClient.list(params).then(
    (data) => {
      dispatch({
        mergeResult,
        payload: data,
        type: LIST_INVOICES_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(data)
      }
    },
    (error) => {
      dispatch({
        error: error.response,
        type: LIST_INVOICES_FAILED,
      })
    },
  )
}

export const exportInvoice = (reportType, options = {}) => ({ invoicesApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {} } = options

  dispatch({
    params,
    type: EXPORT_INVOICES,
  })

  invoicesApiClient.export(reportType, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: EXPORT_INVOICES_SUCCESS,
      })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: EXPORT_INVOICES_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const clearList = () => ({
  type: CLEAR_INVOICE_LIST,
})
