import _ from 'lodash'
import React, { PropsWithChildren, useState } from 'react'
import ReactPaginate from 'react-paginate'

import { DEFAULT_PER_PAGE } from 'constants/pagination'

import { Button, Toolbar } from 'components'

import i18n from 'translations'

import { properties } from 'app-config'

import { StyledContainer, StyledDetails } from './PaginationStyled'

const DEFAULT_SINGULAR_TITLE = i18n.t('global:pagination:entry')
const DEFAULT_PLURAL_TITLE = i18n.t('global:pagination:entries')

export interface PaginationProps {
  onPageChange?: (page?: number, next?: string) => void
  page?: number
  pageCount?: number
  perPage?: number
  titlePlural?: string
  titleSingular?: string
  totalResults?: number
}

const Pagination: React.FC<PropsWithChildren<PaginationProps>> = ({
  onPageChange,
  page,
  pageCount,
  perPage,
  titlePlural = DEFAULT_PLURAL_TITLE,
  titleSingular = DEFAULT_SINGULAR_TITLE,
  totalResults,
}) => {
  const [previousAriaDisabled, setPreviousAriaDisabled] = useState<boolean>(1 === page)
  const [nextAriaDisabled, setNextAriaDisabled] = useState<boolean>(pageCount === page)

  const debouncePageChange = _.debounce((currentPage) => onPageChange(currentPage), 500)

  const handlePageChange = ({ selected }) => {
    setPreviousAriaDisabled(0 === selected)
    setNextAriaDisabled(pageCount - 1 === selected)

    if (onPageChange) {
      debouncePageChange(selected + 1)
    }
  }

  const { marginPagesDisplayed, pageRangeDisplayed } = properties.pagination

  const previousLabel = (
    <Button
      disabled={previousAriaDisabled}
      hierarchy="tertiary"
      icon="chevron-left"
      size="small"
      negativeMargins
    />
  )
  const nextLabel = (
    <Button
      disabled={nextAriaDisabled}
      hierarchy="tertiary"
      icon="chevron-right"
      size="small"
      negativeMargins
    />
  )

  const props = {
    marginPagesDisplayed,
    nextLabel,
    pageRangeDisplayed,
    previousLabel,
  }

  const forcePage = page - 1 || 0

  const renderPaginationDetails = () => {
    if (!perPage && !page && !totalResults) {
      return null
    }

    const title = (
      1 === totalResults ? titleSingular : titlePlural
    )

    if ((!perPage && !page && totalResults) || 1 === pageCount) {
      return (
        <StyledDetails>
          {`${totalResults} ${title}`}
        </StyledDetails>
      )
    }

    const finalPerPage = perPage || DEFAULT_PER_PAGE
    const result = page * finalPerPage
    const from = result - finalPerPage
    const to = result > totalResults ? totalResults : result

    return finalPerPage && page && totalResults ? (
      <StyledDetails>
        {`${from || 1} - ${to} / ${totalResults} ${title}`}
      </StyledDetails>
    ) : null
  }

  return (
    <Toolbar noMargin>
      <Toolbar.Group>
        {renderPaginationDetails()}
      </Toolbar.Group>
      {1 < pageCount ? (
        <Toolbar.Group>
          <Toolbar.Item>
            <StyledContainer>
              <ReactPaginate {...props} forcePage={forcePage} pageCount={pageCount} onPageChange={handlePageChange} />
            </StyledContainer>
          </Toolbar.Item>
        </Toolbar.Group>
      ) : null}
    </Toolbar>
  )
}

export default Pagination
