import _ from 'lodash'

import React from 'react'

import { ORDERED_ROLES, ROLES, RolesDetails } from 'constants/security'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { EVENTS, logEvent } from 'analytics'

import { getNurseryPublicUrl } from 'services/nurseries/single/selectors/single'

import {
  Avatar,
  Button,
  Checkbox,
  EmptyState,
  Icon,
  InfiniteDropdowns,
  Page,
  Pagination,
  SearchBar,
  Section,
  Select,
  Spinner,
  Table,
  Toolbar,
  Tooltip,
  Typography,
} from 'components'

import i18n from 'translations'

import { getActionLabel, getTableColumns } from './helpers'

import { StyledContactContent } from './StaffListStyled'

const StaffListView = ({
  appProperties,
  currentUser,
  isAdministrationContext,
  isFetching,
  isNurseryContext,
  isOrganizationContext,
  nursery,
  onChangeNursery,
  onOrganizationChange,
  onPageChange,
  onRoleChange,
  onSearchChange,
  onSendReport,
  onStatusChange,
  organization: organizationOption,
  page,
  pageCount,
  perPage,
  role,
  status,
  statusOptions,
  totalResults,
  users,
}) => {
  const filteredRolesOptions = _.filter(ORDERED_ROLES, ({ value }) => ROLES.SUPER_ADMIN !== value)

  const renderItem = ({
    accepted,
    archived,
    currentContract,
    email,
    firstName,
    id,
    isEditableForCurrentUser,
    isPrimaryManager,
    membershipProfile,
    name,
    nursery: itemNursery,
    organization,
    photo,
    roles,
    surname,
    user,
  }) => {
    const rolesLabels = _.map(roles, (roleItem) => RolesDetails[roleItem]?.label)
    const linkTarget = isOrganizationContext && !organization ? '_blank' : null
    const telephone = membershipProfile?.telephone || membershipProfile?.mobile
    const jobTitle = currentContract?.jobTitle

    const getUserName = () => {
      let nameToDisplay = name

      if (!name) {
        nameToDisplay = i18n.t('module:Staff:StaffLists:notSpecified')
      }

      if (user?.id === currentUser.id) {
        nameToDisplay += ` (${i18n.t('module:Staff:StaffLists:me')})`
      }

      if (!accepted && !archived && !isAdministrationContext) {
        return `${nameToDisplay} (${i18n.t('global:pending')})`
      }

      if (archived && !isAdministrationContext) {
        return `${nameToDisplay} (${i18n.t('global:archived')})`
      }

      return nameToDisplay
    }

    const getRole = () => (isPrimaryManager && isNurseryContext
      ? i18n.t('module:Management:Nursery:primaryManager')
      : rolesLabels.join(', ') || '-')

    const getProfileRoute = (baseRoute) => {
      if (!isOrganizationContext) {
        return baseRoute
      }

      if (!organization) {
        return `${getNurseryPublicUrl(itemNursery, appProperties)}${baseRoute}`
      }

      return baseRoute
    }

    const renderAvatar = () => (
      <Avatar
        avatarSize="medium"
        cursor={!isEditableForCurrentUser ? 'not-allowed' : undefined}
        disabled={!isEditableForCurrentUser}
        initials={[firstName, surname]}
        src={photo}
        subTitle={isOrganizationContext
          && <Typography color={NEUTRAL_COLOURS.BASIC}>{getRole()}</Typography>}
        target={linkTarget}
        title={(
          <Typography
            fontSize={isOrganizationContext ? 16 : 14}
            hoverOpacity={isOrganizationContext && 0.5}
            primary={isOrganizationContext}
          >
            {getUserName()}
          </Typography>
        )}
        to={isEditableForCurrentUser && getProfileRoute(generateRoute('STAFF.PROFILE.ABOUT', { userId: id }))}
      />
    )

    return ({
      contact: (
        <React.Fragment>
          {telephone && (
            <StyledContactContent isFirstItem>
              <Icon
                color={NEUTRAL_COLOURS.GRAY}
                height={18}
                icon="call"
              />
              <Typography
                margin="0 0 0 8px"
                variant="span"
              >
                {telephone}
              </Typography>
            </StyledContactContent>
          )}
          {email && (
            <StyledContactContent>
              <Icon
                color={NEUTRAL_COLOURS.GRAY}
                height={18}
                icon="email"
              />
              <Typography
                margin="0 0 0 8px"
                variant="span"
              >
                {email}
              </Typography>
            </StyledContactContent>
          )}
        </React.Fragment>
      ),
      edit: (
        <Tooltip
          placement="left"
          title={isEditableForCurrentUser
            ? ''
            : i18n.t('module:Staff:StaffLists:disabledViewButtonTooltip', { role: rolesLabels })}
        >
          <Button.TableAction
            disabled={!isEditableForCurrentUser}
            target={linkTarget}
            to={getProfileRoute(generateRoute('STAFF.PROFILE.ABOUT', { userId: id }))}
            onClick={() => logEvent(EVENTS.STAFF_EDIT_PAGE_VIEWED)}
          />
        </Tooltip>
      ),
      email,
      id,
      jobTitle,
      location: <Typography bold={!!organization?.name}>{organization?.name || itemNursery?.name}</Typography>,
      roles: getRole(),
      user: isEditableForCurrentUser
        ? renderAvatar()
        : (
          <Tooltip
            placement="left"
            title={i18n.t('module:Staff:StaffLists:disabledViewButtonTooltip', { role: rolesLabels })}
          >
            {renderAvatar()}
          </Tooltip>
        ),
    })
  }

  const renderPagination = () => !isFetching && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('module:Staff:StaffLists:staffMembers')}
      titleSingular={i18n.t('module:Staff:StaffLists:staffMember')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    if (!isFetching && !users?.length) {
      return (
        <EmptyState
          icon="staff"
          text1={i18n.t('module:Staff:StaffLists:notFound')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={getTableColumns(isAdministrationContext, isOrganizationContext)}
          data={_.map(users, renderItem)}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = (
    <Section.Actions
      options={[{
        auth: isNurseryContext,
        onClick: onSendReport,
        type: 'export',
      }]}
      primary={[{
        label: getActionLabel(isAdministrationContext, isOrganizationContext),
        onClick: () => logEvent(EVENTS.STAFF_ADD_BTN_CLICKED),
        to: generateRoute('STAFF.ADD'),
      }]}
    />
  )

  return (
    <React.Fragment>
      <SearchBar
        placeholder={isAdministrationContext
          ? i18n.t('module:Staff:StaffLists:searchAdministrators')
          : i18n.t('module:Staff:StaffLists:searchStaff')}
        onChange={onSearchChange}
      />
      <Page.Section
        actions={actions}
        isLoading={isFetching}
        title={isAdministrationContext
          ? i18n.t('module:Staff:StaffLists:administrators')
          : i18n.t('module:Staff:StaffLists:title')}
      >
        <Toolbar>
          <Toolbar.Group>
            {!isAdministrationContext && (
              <Toolbar.Item>
                <Select
                  options={statusOptions}
                  placeholder={i18n.t('module:Staff:StaffLists:currentStaff')}
                  value={status}
                  onChange={onStatusChange}
                />
              </Toolbar.Item>
            )}
            {isOrganizationContext && (
              <React.Fragment>
                <Toolbar.Item>
                  <InfiniteDropdowns.Nurseries
                    disabled={!!organizationOption}
                    value={nursery}
                    onChange={onChangeNursery}
                  />
                </Toolbar.Item>
                <Toolbar.Item>
                  <Select
                    options={filteredRolesOptions}
                    placeholder={i18n.t('global:Permission')}
                    value={role}
                    onChange={onRoleChange}
                  />
                </Toolbar.Item>
                <Toolbar.Item>
                  <Checkbox
                    disabled={!!nursery}
                    label={i18n.t('module:Staff:StaffLists:organisationUsersFilter')}
                    value={!!organizationOption}
                    onChange={onOrganizationChange}
                  />
                </Toolbar.Item>
              </React.Fragment>
            )}
          </Toolbar.Group>
        </Toolbar>
        {renderContent()}
      </Page.Section>
    </React.Fragment>
  )
}

export default StaffListView
