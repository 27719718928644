import i18n from 'translations'

import React, { useMemo } from 'react'
import { Field, reduxForm } from 'redux-form'

import { generateRoute } from 'utils/routing'

import { Button, Form, Space, Toolbar, Typography } from 'components'
import InvoiceTable from 'module/Finance/components/InvoiceTable'

export const CHILD_FINANCE_INVOICE_FORM = 'ChildFinanceInvoiceForm'

const ChildFinanceInvoiceForm = ({
  authAccessMap,
  child,
  displayAutoCalculate,
  handleSubmit,
  invoiceId,
  invoicePeriodOptions,
  invoicePreview,
  isDraft,
  isEdit,
  isInsideModal,
  isLoading,
  onAddLineItemClick,
  onAutoCalculateClick,
  onEditLineItemClick,
  onIssueDateChange,
  onModalCancelClick,
  onRemoveLineItemClick,
  onSendButtonClick,
  onSubmit,
  onSubmitForm,
  repeatInvoiceId,
}) => {
  const { id: childId } = child || {}

  const getCancelRedirectTo = useMemo(() => {
    if (isEdit) {
      return generateRoute('CHILDREN.CHILD.FINANCE.SINGLE_INVOICE', { childId, invoiceId })
    }

    return generateRoute('CHILDREN.CHILD.FINANCE.INVOICING', { childId })
  }, [childId, invoiceId, isEdit])

  const renderFooter = () => {
    if (isInsideModal) {
      return (
        <Form.FooterActions
          isSubmitting={isLoading}
          submitDisabled={!invoicePreview?.items?.length}
          submitLabel={i18n.t('global:Save')}
          onCancelClick={onModalCancelClick}
          onSubmitClick={onSubmitForm}
        />
      )
    }

    return (
      <Form.FooterActions
        cancelLink={getCancelRedirectTo}
        isSubmitting={isLoading}
        submitDisabled={!(invoicePreview?.items?.length)}
        submitLabel={isDraft || !isEdit ? i18n.t('global:Send') : i18n.t('global:Resend')}
        onSubmitClick={onSendButtonClick}
      />
    )
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Toolbar bottomLine>
        <Toolbar.Group>
          <Toolbar.Item>
            <Field
              component={Form.TextField}
              disabled={isInsideModal && isEdit}
              name="name"
              placeholder={i18n.t('module:Children:Child:Finance:Invoice:Add:invoiceNamePlaceholder')}
            />
          </Toolbar.Item>
          <Toolbar.Item>
            <Field
              component={Form.Select}
              disabled={isInsideModal && isEdit}
              name="invoicePeriodDropdown"
              options={invoicePeriodOptions}
            />
          </Toolbar.Item>
          <Toolbar.Item>
            <Field
              component={Form.DatePicker}
              disabled={isInsideModal && isEdit}
              name="invoicePeriod"
              placeholder={i18n.t('module:Children:Child:Finance:Invoice:Add:invoicePeriodPlaceholder')}
              width="255px"
              clearable
              range
            />
          </Toolbar.Item>
          {!repeatInvoiceId && !isEdit && authAccessMap.section.FinanceAutomation && (
            <Toolbar.Item>
              <Button
                disabled={!displayAutoCalculate || (isInsideModal && isEdit)}
                hierarchy="tertiary"
                icon="auto-calculate"
                label={i18n.t('global:Calculate')}
                negativeMargins
                onClick={onAutoCalculateClick}
              />
            </Toolbar.Item>
          )}
        </Toolbar.Group>
      </Toolbar>
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <Field
              component={Form.TextField}
              disabled={isEdit}
              name="number"
              placeholder={isEdit ? '-' : i18n.t('module:Children:Child:Finance:Invoice:Add:invoiceNumberPlaceholder')}
            />
          </Toolbar.Item>
        </Toolbar.Group>
        <Toolbar.Group>
          <Toolbar.Item>
            <Form.Row.TextItem text="Invoice date" />
          </Toolbar.Item>
          <Toolbar.Item>
            <Field
              component={Form.DatePicker}
              name="issueDate"
              placeholder={i18n.t('module:Children:Child:Finance:Invoice:Add:invoiceDatePlaceholder')}
              clearable
              onChange={onIssueDateChange}
            />
          </Toolbar.Item>
          <Toolbar.Item>
            <Form.Row.TextItem text={i18n.t('module:Children:Child:Finance:Invoice:Add:invoiceDueDateLabel')} />
          </Toolbar.Item>
          <Toolbar.Item>
            <Field
              component={Form.DatePicker}
              name="dueDate"
              placeholder={i18n.t('module:Children:Child:Finance:Invoice:Add:invoiceDueDatePlaceholder')}
              clearable
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      {isEdit && isDraft && (
        <React.Fragment>
          <Typography>{i18n.t('module:Children:Child:Finance:Invoice:draftInvoiceNumberCopy')}</Typography>
          <Space space="20px" />
        </React.Fragment>
      )}
      <InvoiceTable
        child={child}
        invoicePreview={invoicePreview}
        isEdit={isEdit}
        onAddLineItemClick={onAddLineItemClick}
        onEditLineItemClick={onEditLineItemClick}
        onRemoveLineItemClick={onRemoveLineItemClick}
      />
      {renderFooter()}
    </Form>
  )
}

export default reduxForm({ form: CHILD_FINANCE_INVOICE_FORM })(ChildFinanceInvoiceForm)
