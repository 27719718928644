import React from 'react'

import { DIARY_AUTO_SEND_VALUES } from 'services/nurseries/constants'

import InlineEditableField from 'module/Management/components/InlineEditableField'
import { Typography } from 'components'

import i18n from 'translations'

import DiaryForm, { DIARY_FORM } from './DiaryForm'

interface DiaryProps {
  onSubmit: (values: any) => void
  value: boolean
}

const Diary: React.FC<DiaryProps> = ({ onSubmit, value }) => {
  const initialValues = { autoSendDiary: value ? DIARY_AUTO_SEND_VALUES.AUTOMATIC : DIARY_AUTO_SEND_VALUES.MANUAL }

  return (
    <InlineEditableField
      description={
        value
          ? i18n.t('module:Management:General:SharingWithParents:Diary:Automatic')
          : i18n.t('module:Management:General:SharingWithParents:Diary:Manual')
      }
      editableComponent={(callback) => <DiaryForm initialValues={initialValues} onSubmit={callback} />}
      formName={DIARY_FORM}
      subtitle={i18n.t('module:Management:General:SharingWithParents:Diary:subtitle', {
        components: [<Typography fontSize={14} variant="span" bold />],
      })}
      title={i18n.t('module:Management:General:SharingWithParents:Diary:title')}
      inline
      onSubmit={onSubmit}
    />
  )
}

export default Diary
