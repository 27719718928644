import moment from 'moment'

import { createSelector } from 'reselect'

import { DAILY_DIARY_DATE_FORMAT } from 'services/legacy/dailyDiary/constants'

import { changeHours } from 'utils/date'

import { REFUND_TYPE } from '../constants'

const getInvoicePaymentsList = (state) => state.invoicePayments.list.data

export const getInvoicePaymentsListData = createSelector([getInvoicePaymentsList], (state) => {
  if (!state) {
    return null
  }

  return state.data
})

export const getPaymentsListCountSelectors = createSelector([getInvoicePaymentsList], (state) => {
  if (!state) {
    return 0
  }

  return state.length
})

const getFilters = (filters) => filters

export const getCriteriaSelector = createSelector([getFilters], (filters) => {
  if (!filters) {
    return null
  }

  const { child, endDate, paymentType, room, searchPhrase, startDate } = filters
  const criteria = []

  if (startDate) {
    criteria.push({
      comparator: 'after',
      field: 'paymentDate',
      value: moment(changeHours(moment(startDate, DAILY_DIARY_DATE_FORMAT), new Date()))
        .utc()
        .startOf('day')
        .toISOString(),
    })
  }

  if (endDate) {
    criteria.push({
      comparator: 'before',
      field: 'paymentDate',
      value: moment(changeHours(moment(endDate, DAILY_DIARY_DATE_FORMAT), new Date()))
        .utc()
        .endOf('day')
        .toISOString(),
    })
  }

  if (child) {
    criteria.push({
      field: 'child',
      value: child,
    })
  }

  if (paymentType) {
    criteria.push({
      field: paymentType === REFUND_TYPE ? 'type' : 'paymentType',
      value: paymentType,
    })
  }

  if (room) {
    criteria.push({
      field: 'child.nurseryClass',
      value: room.value,
    })
  }

  if (searchPhrase) {
    criteria.push({
      field: 'concatenable',
      value: searchPhrase,
    })
  }

  return criteria
})
