import i18n from 'translations'

import { FLAG_COLOURS } from 'constants/colors'

export const PAYMENT_TYPE_VALUES = {
  CHILD_CARE_VOUCHER: 'childcareVouchers',
}

export const PAYMENT_STATUSES = {
  CANCELED: 'CANCELED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  MANUALLY_ADDED: 'MANUALLY_ADDED',
  PROCESSING: 'PROCESSING',
  REFUNDED: 'REFUNDED',
}

export const PAYMENT_STATUSES_OPTIONS = [
  {
    color: FLAG_COLOURS.WARNING,
    label: i18n.t('services:InvoicePayments:paymentStatuses:canceled'),
    value: PAYMENT_STATUSES.CANCELED,
  },
  {
    color: FLAG_COLOURS.SUCCESS,
    label: i18n.t('services:InvoicePayments:paymentStatuses:completed'),
    value: PAYMENT_STATUSES.COMPLETED,
  },
  {
    color: FLAG_COLOURS.ERROR,
    label: i18n.t('services:InvoicePayments:paymentStatuses:failed'),
    value: PAYMENT_STATUSES.FAILED,
  },
  {
    color: FLAG_COLOURS.SUCCESS,
    label: i18n.t('services:InvoicePayments:paymentStatuses:manuallyAdded'),
    value: PAYMENT_STATUSES.MANUALLY_ADDED,
  },
  {
    color: FLAG_COLOURS.INFO,
    label: i18n.t('services:InvoicePayments:paymentStatuses:processing'),
    value: PAYMENT_STATUSES.PROCESSING,
  },
  {
    color: FLAG_COLOURS.INFO,
    label: i18n.t('services:InvoicePayments:paymentStatuses:refunded'),
    value: PAYMENT_STATUSES.REFUNDED,
  },
]

export const REFUND_TYPE = 'refund'
export const PAYMENT_TYPE = 'payment'

export const REFUND_TYPE_OPTION = {
  label: i18n.t('services:InvoicePayments:type:refund'),
  value: REFUND_TYPE,
}

export default {
  PAYMENT_TYPE_VALUES,
}
