import _ from 'lodash'

import React from 'react'

import { ZINDEX_ORDER } from 'constants/layout'

import { MODAL_COMPONENTS } from 'modals'

import Modal from 'module/Shell/components/Modal'

const ModalRootView = ({
  modalStack,
  onHideModal,
}) => {
  if (!modalStack?.length) {
    return null
  }

  const renderModal = ({ modalProps = {}, modalType }, index) => {
    const getOnRequestClose = () => {
      const { onRequestClose } = modalProps

      if (false === onRequestClose) {
        return () => null
      }

      return onRequestClose ? () => onRequestClose(onHideModal) : onHideModal
    }

    const SpecificModal = MODAL_COMPONENTS[modalType]

    return (
      <Modal
        key={modalType}
        // eslint-disable-next-line no-unsafe-optional-chaining
        overlayClassName={index === modalStack?.length - 1
          ? 'ReactModal__Overlay'
          : 'ReactModal__Overlay--hidden'}
        style={{
          overlay: {
            zIndex: modalProps.zIndex || ZINDEX_ORDER.MODAL,
          },
        }}
        open
        onRequestClose={getOnRequestClose()}
      >
        <SpecificModal {...modalProps} hideModal={getOnRequestClose()} />
      </Modal>
    )
  }

  return _.map(modalStack, renderModal)
}

export default ModalRootView
