import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { reset, stopSubmit } from 'redux-form'

import { ROOM_TYPES } from 'services/legacy/rooms/constants'
import { FEATURE_FLAGS } from 'constants/security'

import { getBackendErrors } from 'utils/backendErrors'
import { hasMoreRecords } from 'utils/pagination'

import { withRoomsService } from 'services/legacy/rooms'
import { withRoomMoveService } from 'services/legacy/roomMove'
import { withModalService } from 'services/utils/modal'

import DefaultRoomMovesView from './DefaultRoomMovesView'
import { ROOM_MOVE_FORM } from './components/RoomMove/RoomMove'

const ROOM_LIST_GROUPS = {
  read: ['nurseryClass.nurseryClassMove'],
}

class DefaultRoomMovesContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      newElementIsCreated: false,
      roomListLoaded: false,
      roomMoveListLoaded: false,
      someElementIsEdited: false,
    }
  }

  componentDidMount() {
    this.fetchAll()
  }

  fetchAll = () => {
    const { roomMoveActions, roomsActions } = this.props
    this.setState({
      roomListLoaded: false,
      roomMoveListLoaded: false,
    })

    roomsActions.clear()
    roomMoveActions.clear()
    this.getRoomLists(1)
    this.getRoomMoveLists(1)
  }

  componentWillUnmount() {
    const { roomMoveActions, roomsActions } = this.props

    roomsActions.clear()
    roomMoveActions.clear()
  }

  validateRoomMoves = () => {
    const { roomMoveActions, roomsActions } = this.props

    roomMoveActions.validateRoomMoves()
    const { roomsMoveLists } = this.props

    roomsActions.validateRooms(roomsMoveLists)
  }

  getRoomMoveLists = (page) => {
    const { roomMoveActions } = this.props
    const apiParams = {
      page,
    }

    /* eslint-disable camelcase */
    roomMoveActions.list(apiParams, true, ({ meta }) => {
      if (hasMoreRecords(meta)) {
        this.getRoomMoveLists(meta.start + 1)
      } else {
        this.setState({ roomMoveListLoaded: true }, () => {
          const { roomListLoaded, roomMoveListLoaded } = this.state

          if (roomListLoaded && roomMoveListLoaded) {
            this.validateRoomMoves()
          }
        })
      }
    })
  }

  getRoomLists = (page) => {
    const { roomsActions } = this.props
    const apiParams = {
      criteria: [
        {
          field: 'archived',
          value: false,
        },
        {
          field: 'type',
          value: ROOM_TYPES.TEACHING,
        },
      ],
      groups: ROOM_LIST_GROUPS,
      page,
    }

    roomsActions.listRecurrency(apiParams, ({ meta }) => {
      if (hasMoreRecords(meta)) {
        this.getRoomLists(meta.start + 1)
      } else {
        this.setState({ roomListLoaded: true }, () => {
          const { roomListLoaded, roomMoveListLoaded } = this.state

          if (roomListLoaded && roomMoveListLoaded) {
            this.validateRoomMoves()
          }
        })
      }
    })
  }

  handleChangeRoomMoveSuccess = (callbackSuccess, roomMove) => {
    if (roomMove && roomMove.id) {
      this.handleCancelEdit(roomMove)
    } else {
      this.handleCancelNewElement()
    }

    this.fetchAll()

    return callbackSuccess()
  }

  handleChangeRoomMoveFailed = (response, roomMove, callback) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    callback(true)

    if (!errors) {
      return false
    }

    if (roomMove && !roomMove.id) {
      return injectValidation(`${ROOM_MOVE_FORM}`, errors)
    }

    return injectValidation(`${ROOM_MOVE_FORM}_${roomMove.id}`, errors)
  }

  handleSomeElementIsEdited = (someElementIsEdited) => {
    this.setState({ someElementIsEdited })
  }

  handleAddNewRoomMove = () => {
    this.setState({ newElementIsCreated: true })
  }

  handleCancelNewElement = () => {
    const { resetForm } = this.props

    resetForm(ROOM_MOVE_FORM)
    this.setState({ newElementIsCreated: false })
  }

  handleCancelEdit = ({ id }) => {
    const { resetForm } = this.props

    resetForm(`${ROOM_MOVE_FORM}_${id}`)
  }

  handleRemoveRoomMoveConfirmed = ({ id }) => {
    const { roomMoveActions } = this.props

    roomMoveActions.deleteRoomMove(id, this.fetchAll)
  }

  handleRemoveRoomMove = (roomMove) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: () => this.handleRemoveRoomMoveConfirmed(roomMove),
      text: 'Are you sure you want to delete this default room move?',
    })
  }

  handleSubmit = (roomMove = {}, values, callback) => {
    const { id, toNurseryClass } = roomMove
    const { toNurseryClass: newToNurseryClass = {} } = values

    if (id && toNurseryClass) {
      const { modalActions, modalConsts } = this.props
      const { id: toNurseryClassId } = toNurseryClass
      const { label: newToNurseryClassLabel, value: newToNurseryClassvalue } = newToNurseryClass

      if (toNurseryClassId !== newToNurseryClassvalue) {
        return modalActions.show(modalConsts.TYPES.CONFIRM, {
          confirmButtonLabel: 'Confirm',
          icon: 'default-room-moves',
          onCancel: () => callback('to remove spinner on tick'),
          onConfirm: () => this.save(roomMove, values, callback),
          text: `
            Are you sure you want to update this room to ${newToNurseryClassLabel}? Please note:
            editing this date will unconfirm and recalculate any confirmed room moves.
          `,
        })
      }

      return this.save(roomMove, values, callback)
    }

    if (!id && !toNurseryClass && !newToNurseryClass.value) {
      return this.save(roomMove, {
        ...values,
        toNurseryClass: { value: 0 },
      }, callback)
    }

    return this.save(roomMove, values, callback)
  }

  save = (roomMove = {}, values, callback) => {
    const { roomMoveActions, roomMoveSelectors } = this.props
    const { id } = roomMove
    const body = roomMoveSelectors.getRoomMoveValuesForm(values)

    if (id) {
      return roomMoveActions.updateRoomMove(
        id,
        body,
        () => this.handleChangeRoomMoveSuccess(callback, roomMove),
        (response) => this.handleChangeRoomMoveFailed(response, roomMove, callback),
      )
    }

    return roomMoveActions.createRoomMove(
      body,
      () => this.handleChangeRoomMoveSuccess(callback),
      (response) => this.handleChangeRoomMoveFailed(response, roomMove, callback),
    )
  }

  render() {
    const {
      rooms,
      roomsDropdown,
      roomsDropdownWithLeaving,
      roomsMoveLists,
      titleMessage,
    } = this.props
    const {
      newElementIsCreated,
      roomListLoaded,
      roomMoveListLoaded,
      someElementIsEdited,
    } = this.state

    return (
      <DefaultRoomMovesView
        isLoading={!roomListLoaded || !roomMoveListLoaded}
        newElementIsCreated={newElementIsCreated}
        rooms={rooms}
        roomsDropdown={roomsDropdown}
        roomsDropdownWithLeaving={roomsDropdownWithLeaving}
        roomsMoveLists={roomsMoveLists}
        someElementIsEdited={someElementIsEdited}
        titleMessage={titleMessage}
        onAddNewRoomMove={this.handleAddNewRoomMove}
        onCancelEdit={this.handleCancelEdit}
        onCancelNewElement={this.handleCancelNewElement}
        onRemoveRoomMove={this.handleRemoveRoomMove}
        onSomeElementIsEdited={this.handleSomeElementIsEdited}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

DefaultRoomMovesContainer.authParams = {
  flags: [FEATURE_FLAGS.OCCUPANCY_ENABLED],
}

const mapState = (state, { roomMoveSelectors, roomsSelectors }) => ({
  rooms: roomsSelectors.getRoomsListDataSelector(state),
  roomsDropdown: roomsSelectors.getRoomsOptionsDropdownData(state),
  roomsDropdownWithLeaving: roomsSelectors.getRoomsOptionsDropdownWithLeavingData(state),
  roomsMoveLists: roomMoveSelectors.getRoomsMoveListDataSelector(state),
  titleMessage: roomMoveSelectors.getErrorMessage(state),
})

const mapDispatch = {
  injectValidation: (formName, data) => stopSubmit(formName, data),
  resetForm: (formName) => reset(formName),
}

const enhance = compose(
  withRoomsService,
  withRoomMoveService,
  withModalService,
  connect(mapState, mapDispatch),
)

export default enhance(DefaultRoomMovesContainer)
