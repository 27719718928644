import {
  CLEAR_MEMBERSHIP_REGISTER,
  CREATE_MEMBERSHIP_REGISTER,
  CREATE_MEMBERSHIP_REGISTER_ENTRY,
  CREATE_MEMBERSHIP_REGISTER_ENTRY_FAILED,
  CREATE_MEMBERSHIP_REGISTER_ENTRY_SUCCESS,
  CREATE_MEMBERSHIP_REGISTER_FAILED,
  CREATE_MEMBERSHIP_REGISTER_SUCCESS,
  GET_MEMBERSHIP_REGISTER,
  GET_MEMBERSHIP_REGISTER_FAILED,
  GET_MEMBERSHIP_REGISTER_SUCCESS,
  SELECTED_MEMBERSHIP_REGISTER_STATUS,
  UPDATE_BATCH_MEMBERSHIP_REGISTER,
  UPDATE_BATCH_MEMBERSHIP_REGISTER_FAILED,
  UPDATE_BATCH_MEMBERSHIP_REGISTER_SUCCESS,
  UPDATE_MEMBERSHIP_REGISTER,
  UPDATE_MEMBERSHIP_REGISTER_ENTRY,
  UPDATE_MEMBERSHIP_REGISTER_ENTRY_FAILED,
  UPDATE_MEMBERSHIP_REGISTER_ENTRY_SUCCESS,
  UPDATE_MEMBERSHIP_REGISTER_FAILED,
  UPDATE_MEMBERSHIP_REGISTER_MANUALLY,
  UPDATE_MEMBERSHIP_REGISTER_SUCCESS,
} from './constants'

export const get = (id, date, options = {}) => ({ membershipRegistersApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {} } = options

  dispatch({ type: GET_MEMBERSHIP_REGISTER })

  membershipRegistersApiClient.get(id, date, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: GET_MEMBERSHIP_REGISTER_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: GET_MEMBERSHIP_REGISTER_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const create = (options = {}) => ({ membershipRegistersApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {}, payload = {} } = options

  dispatch({ type: CREATE_MEMBERSHIP_REGISTER })

  membershipRegistersApiClient.create(payload, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: CREATE_MEMBERSHIP_REGISTER_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_MEMBERSHIP_REGISTER_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const update = (id, options = {}) => ({ membershipRegistersApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {}, payload = {} } = options

  dispatch({ type: UPDATE_MEMBERSHIP_REGISTER })

  membershipRegistersApiClient.update(id, payload, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: UPDATE_MEMBERSHIP_REGISTER_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_MEMBERSHIP_REGISTER_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updateBatch = (options = {}) => ({ membershipRegistersApiClient }) => (dispatch) => {
  const { body, onFailed, onSuccess } = options

  dispatch({ type: UPDATE_BATCH_MEMBERSHIP_REGISTER })

  membershipRegistersApiClient.updateBatch(body).then(
    (response) => {
      dispatch({
        payload: response,
        type: UPDATE_BATCH_MEMBERSHIP_REGISTER_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_BATCH_MEMBERSHIP_REGISTER_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const createEntry = (options = {}) => ({ membershipRegistersApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {}, payload = {} } = options

  dispatch({ type: CREATE_MEMBERSHIP_REGISTER_ENTRY })

  membershipRegistersApiClient.createEntry(payload, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: CREATE_MEMBERSHIP_REGISTER_ENTRY_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_MEMBERSHIP_REGISTER_ENTRY_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updateEntry = (id, options = {}) => ({ membershipRegistersApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {}, payload = {} } = options

  dispatch({ type: UPDATE_MEMBERSHIP_REGISTER_ENTRY })

  membershipRegistersApiClient.updateEntry(id, payload, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: UPDATE_MEMBERSHIP_REGISTER_ENTRY_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_MEMBERSHIP_REGISTER_ENTRY_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updateManually = (response) => ({
  payload: response,
  type: UPDATE_MEMBERSHIP_REGISTER_MANUALLY,
})

export const setStatus = (status) => ({
  payload: status,
  type: SELECTED_MEMBERSHIP_REGISTER_STATUS,
})

export const clear = () => ({
  type: CLEAR_MEMBERSHIP_REGISTER,
})
