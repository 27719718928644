import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withAppService } from 'services/app'
import { withDepositsService } from 'services/legacy/deposits'

import { Select } from 'components'

import i18n from 'translations'

import withInfiniteDropdownHelpers from './withInfiniteDropdown'

const GROUPS = {
  read: [
    'deposit',
  ],
}

class DepositsDropdown extends Component {
  componentDidMount() {
    const { depositsActions, infiniteDropdownHelpers, value } = this.props

    if (value?.value && value?.label) {
      return null
    }

    if (!_.isArray(value)) {
      return infiniteDropdownHelpers.handleComponentDidMount({
        action: () => depositsActions.get({
          addToList: true,
          onFailed: this.notFoundValue,
          onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
          params: [(value?.value || value)],
        }),
        value,
      })
    }

    return null
  }

  componentWillUnmount() {
    const { depositsActions } = this.props

    depositsActions.clear()
  }

  handleLoadMoreElements = async (phrase) => {
    try {
      const {
        childId,
        depositsActions,
        depositsSelectors,
        extraFields = ['amount'],
        includeUsedDepositIds,
        infiniteDropdownHelpers,
        limit,
        pendingOnly,
        skipPendingDepositIds,
        type,
        value,
      } = this.props

      return infiniteDropdownHelpers.handleLoadMoreElements({
        clearAction: depositsActions.clear,
        listAction: ({ newPage, newSearchPhrase, result }) => depositsActions.dropdownList({
          mergeResult: true,
          onSuccess: (response) => infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
            extraFields,
            newPage,
            newSearchPhrase,
            response: {
              ...response,
              data: _.map(response.data, ({ resource }) => ({ ...resource })),
            },
            result,
          }),
          params: {
            criteria: depositsSelectors.getDropdownFilterCriteria({
              childId,
              depositId: value?.value || value,
              includeUsedDepositIds,
              pendingOnly,
              search: newSearchPhrase,
              skipPendingDepositIds,
              type,
            }),
            groups: GROUPS,
            limit,
            page: newPage,
          },
          silent: true,
        }),
        phrase,
      })
    } catch (err) {
      throw new Error(err)
    }
  }

  notFoundValue = () => {
    const { onChange } = this.props

    onChange(null)
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  render() {
    const {
      disabled,
      infiniteDropdownState,
      isFetching,
      placeholder,
      rawValue,
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        disabled={disabled || isFetching}
        loadOptions={this.handleLoadMoreElements}
        options={options}
        placeholder={placeholder || i18n.t('global:KeyPerson')}
        rawValue={rawValue || selectedValue || value}
        value={value}
        searchable
        onChange={this.handleChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, depositsSingleState }) => ({
  isFetching: appSelectors.getIsFetching(depositsSingleState),
})

const enhance = compose(
  withAppService,
  withDepositsService,
  withInfiniteDropdownHelpers,
  connect(mapState),
)

export default enhance(DepositsDropdown)
