import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { stopSubmit } from 'redux-form'

import { GLOBAL_NUMBER_SETTINGS } from 'services/organization/constants'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withNurseriesService } from 'services/nurseries'
import { withRouter } from 'services/router'

import i18n from 'translations'

import ManagementInvoiceV3NumbersEditView, { FORM_NAME } from './ManagementInvoiceV3NumbersEditView'

const NURSERY_INVOICE_NUMBER_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
    'nurseryInvoiceSettings.numbers',
    'invoiceNumbers',
    'nurseryInvoiceSettings.paymentReminders',
    'nurseryInvoiceSettings.accountCodes',
    'accountCodes',
    'nurseryInvoiceSettings.annualisedInvoiceSettings',
    'annualisedInvoiceSettings',
    'nursery.organizationSettings',
    'organizationSettings',
    'organizationSettings.invoice',
    'organizationInvoiceSettings',
    'organizationInvoiceSettings.numbers',
  ],
}

const formLabelsMap = {
  'nurserySettings.invoice.invoiceNumbers.creditNoteNumber': i18n.t(
    'components:Settings:InvoiceSettings:InvoiceNumbersSetting:creditNoteNumber',
  ),
  'nurserySettings.invoice.invoiceNumbers.creditNoteNumberPrefix': i18n.t(
    'components:Settings:InvoiceSettings:InvoiceNumbersSetting:creditNoteNumberPrefix',
  ),
  'nurserySettings.invoice.invoiceNumbers.invoiceNumber': i18n.t(
    'components:Settings:InvoiceSettings:InvoiceNumbersSetting:invoiceNumber',
  ),
  'nurserySettings.invoice.invoiceNumbers.invoiceNumberPrefix': i18n.t(
    'components:Settings:InvoiceSettings:InvoiceNumbersSetting:invoiceNumberPrefix',
  ),
  'nurserySettings.invoice.invoiceNumbers.receiptNumber': i18n.t(
    'components:Settings:InvoiceSettings:InvoiceNumbersSetting:receiptNumber',
  ),
  'nurserySettings.invoice.invoiceNumbers.receiptNumberPrefix': i18n.t(
    'components:Settings:InvoiceSettings:InvoiceNumbersSetting:receiptNumberPrefix',
  ),
}

class ManagementInvoiceV3NumbersEditContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      nurseryData: null,
    }
  }

  componentDidMount() {
    const { nurseriesActions, nurseryOptions } = this.props

    nurseriesActions.get(nurseryOptions.id, {
      onSuccess: ({ data }) => {
        this.setState({ nurseryData: data })
      },
      onlyData: true,
      params: {
        groups: NURSERY_INVOICE_NUMBER_SETTINGS_GROUPS,
      },
    })
  }

  componentWillUnmount() {
    const { nurseriesActions } = this.props

    nurseriesActions.clearSingle()
  }

  handleSuccess = () => {
    const { navigate } = this.props

    navigate(generateRoute('MANAGEMENT.FINANCE_SETTINGS.INVOICES'))
  }

  handleFail = (error) => {
    const { appSelectors, stopSubmitForm } = this.props

    const fieldValidations = appSelectors.getFieldValidationFromError(error)

    stopSubmitForm(fieldValidations)
  }

  handleSubmit = (fields) => {
    const { nurseriesActions, nurseriesSelectors, nurseryOptions } = this.props
    const { nurseryData } = this.state

    const apiParams = { groups: NURSERY_INVOICE_NUMBER_SETTINGS_GROUPS }
    const payload = nurseriesSelectors.getInvoiceNumberSettingsPayload({
      fields,
      nurseriesSingleState: {
        data: nurseryData,
      },
    })

    nurseriesActions.update(nurseryOptions.id, {
      onFailed: this.handleFail,
      onSuccess: this.handleSuccess,
      params: apiParams,
      payload,
    })
  }

  render() {
    const {
      errorMessages,
      isFetching,
      nurseriesSingleState,
    } = this.props
    const { nurseryData } = this.state
    const { organizationSettings } = nurseryData || {}
    const { invoice } = organizationSettings || {}
    const { invoiceNumbers } = invoice || {}
    const { invoiceNumbersSequencingType } = invoiceNumbers || {}

    const { isSubmitting } = nurseriesSingleState
    const initialValues = nurseryData?.nurserySettings?.invoice?.invoiceNumbers
    const isInvoiceNumberReadonly = invoiceNumbersSequencingType === GLOBAL_NUMBER_SETTINGS.ACROSS_ORGANIZATION

    return (
      <ManagementInvoiceV3NumbersEditView
        errorMessages={errorMessages}
        initialValues={initialValues}
        isFormLoading={isSubmitting}
        isInvoiceNumberReadonly={isInvoiceNumberReadonly}
        isLoading={isFetching || !initialValues}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nurseriesSingleState,
  params,
}) => ({
  errorMessages: appSelectors.getErrorMassagesWithLabels([nurseriesSingleState], formLabelsMap),
  isFetching: appSelectors.getIsFetching(nurseriesSingleState),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const mapDispatch = {
  stopSubmitForm: (fieldErrors) => stopSubmit(FORM_NAME, fieldErrors),
}

const enhance = compose(
  withRouter,
  withAppService,
  withNurseriesService,
  connect(mapState, mapDispatch),
)

export default enhance(ManagementInvoiceV3NumbersEditContainer)
