import React from 'react'

import { InfiniteScroll, Page, SearchBar, Section, Select, Spinner, Toolbar, Typography } from 'components'

import i18n from 'translations'

import ForecastChart from './components/ForecastChart'
import ForecastTable from './components/ForecastTable'

const FinanceReportForecastingView = ({
  chart,
  forecastData,
  isFetchingChartData,
  isFetchingTableData,
  isFinanceV3Enabled,
  onPageChange,
  onSearch,
  onSendReport,
  onYearChange,
  page,
  pageCount,
  search,
  statisticsData,
  year,
  yearsOptions,
}) => {
  const renderContent = () => {
    if (isFetchingChartData && isFetchingTableData && 1 === page) {
      return <Spinner />
    }

    const renderChart = () => {
      if (isFetchingChartData && 1 === page) {
        return <Spinner />
      }

      return <ForecastChart chart={chart} year={year && year.value} />
    }

    const renderTable = () => {
      if (isFetchingTableData && 1 === page) {
        return <Spinner />
      }

      return (
        <InfiniteScroll
          dataLength={forecastData ? forecastData.length : 0}
          hasMore={page < pageCount}
          next={() => onPageChange(+page + 1)}
        >
          <ForecastTable
            forecastData={forecastData}
            isFinanceV3Enabled={isFinanceV3Enabled}
            monthList={year && year.monthList}
            statisticsData={statisticsData}
            year={year}
          />
        </InfiniteScroll>
      )
    }

    return (
      <React.Fragment>
        {renderChart()}
        {renderTable()}
      </React.Fragment>
    )
  }

  const actions = <Section.Actions options={[{ onClick: onSendReport, type: 'export' }]} />

  return (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t('global:searchPlaceholder')}
        value={search}
        onChange={onSearch}
      />
      <Page.Section
        actions={actions}
        isLoading={isFetchingChartData && isFetchingTableData && 1 === page}
        title={i18n.t('module:Finance:Reports:Forecasting:title')}
      >
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <Select
                clearable={false}
                options={yearsOptions}
                searchable={false}
                value={year}
                onChange={onYearChange}
              />
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
        <Typography margin="20px 0">
          {i18n.t('module:Finance:Reports:Forecasting:reportTitle')}
        </Typography>
        {renderContent()}
      </Page.Section>
    </React.Fragment>
  )
}

export default FinanceReportForecastingView
