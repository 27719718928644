import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import { Form } from 'components'
import { DIARY_AUTO_SEND_OPTIONS, DIARY_AUTO_SEND_VALUES } from 'services/nurseries/constants'
import { isRequired } from 'utils/fieldValidation'

export const DIARY_FORM = 'learning_diary'

interface DiaryFormValues {
  autoSendDiary: DIARY_AUTO_SEND_VALUES
}

interface DiaryFormProps {
  onSubmit: (values: DiaryFormValues) => void
}

const DiaryForm: React.FC<InjectedFormProps<{}, DiaryFormProps> & DiaryFormProps> = ({
  handleSubmit,
  onSubmit,
}) => (
  <Form flex={1} onSubmit={handleSubmit(onSubmit)}>
    <Form.Row>
      <Form.Row.Item>
        <Field
          component={Form.RadioGroup}
          name="autoSendDiary"
          options={DIARY_AUTO_SEND_OPTIONS}
          validate={isRequired}
        />
      </Form.Row.Item>
    </Form.Row>
  </Form>
)

export default reduxForm<{}, DiaryFormProps>({
  form: DIARY_FORM,
})(DiaryForm)
