import _ from 'lodash'

import React from 'react'

import { getBrandingColor } from 'utils/branding'
import { generateRoute } from 'utils/routing'

import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import {
  AmountSummary,
  Banner,
  Callout,
  DatePicker,
  EmptyState,
  Form,
  Pagination,
  Section,
  Select,
  Spinner,
  Table,
  Toolbar,
} from 'components'
import CreditNoteIcon from 'module/Finance/components/CreditNoteIcon'

import i18n from 'translations'

import { getStatusOptions } from './ChildFinanceDetailsInvoicesHelpers'

const ChildFinanceDetailsInvoicesView = ({
  cannotBePaidOverTheParentApp,
  childFinanceDetails,
  childId,
  dateRange,
  errorMessages,
  invoiceDateRange,
  invoices,
  isEmpty,
  isLoading,
  onDateChange,
  onInvoiceDateChange,
  onPageChange,
  onSortChange,
  onStatusChange,
  onTypeChange,
  page,
  pageCount,
  perPage,
  sortField,
  sortOrder,
  status,
  totalResults,
  type,
  typeOptions,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderSummary = () => {
    if (!childFinanceDetails) {
      return null
    }

    const { balance, totalBadDebt, totalInvoices } = childFinanceDetails

    return (
      <React.Fragment>
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <AmountSummary
                amount={Math.abs(balance)}
                color={getBrandingColor('tertiary-color')}
                label="Total outstanding"
                suffix={0 < balance && ' CR'}
              />
            </Toolbar.Item>
            <Toolbar.Item>
              <AmountSummary
                amount={totalInvoices}
                label="Total billed to date"
                margin="0 0 0 15px"
              />
            </Toolbar.Item>
            {0 < totalBadDebt && (
              <Toolbar.Item>
                <AmountSummary
                  amount={totalBadDebt}
                  color={getBrandingColor('quaternary-color')}
                  label="Bad debt"
                  margin="0 0 0 15px"
                />
              </Toolbar.Item>
            )}
          </Toolbar.Group>
        </Toolbar>
        {cannotBePaidOverTheParentApp && (
          <SubdomainCurrencyProvider>
            {({ getFormattedCurrencyValue }) => (
              <Banner.Info>
                {i18n.t('module:Children:Child:Finance:Invoicing:cannotBePaidOverTheParentApp', {
                  value: getFormattedCurrencyValue(0.50),
                })}
              </Banner.Info>
            )}
          </SubdomainCurrencyProvider>
        )}
      </React.Fragment>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="invoice"
          text1="There are no invoices."
          text2="A history of all invoices created for this parent account will show here."
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={[
            {
              field: 'issueDate',
              sortable: true,
              title: i18n.t('module:Children:Child:Finance:Invoicing:date'),
            },
            {
              field: 'number',
              sortable: true,
              title: i18n.t('module:Children:Child:Finance:Invoicing:refNumber'),
            },
            {
              align: 'left',
              field: 'name',
              sortable: true,
              title: i18n.t('module:Children:Child:Finance:Invoicing:titleTable'),
            },
            {
              field: 'total',
              sortable: true,
              title: i18n.t('module:Children:Child:Finance:Invoicing:amount'),
            },
            {
              field: 'status',
              sortable: true,
              title: i18n.t('module:Children:Child:Finance:Invoicing:status'),
            },
            {
              field: 'totalOutstanding',
              sortable: true,
              title: i18n.t('module:Children:Child:Finance:Invoicing:unpaidAmount'),
            },
            {
              align: 'center',
              field: 'rowToButton',
            },
          ]}
          data={invoices}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = (
    <Section.Actions
      primary={[{
        label: i18n.t('module:Children:Child:Finance:Invoicing:invoice'),
        to: generateRoute('CHILDREN.CHILD.FINANCE.INVOICE.ADD', { childId }),
      }, {
        label: 'Payment',
        to: generateRoute('CHILDREN.CHILD.FINANCE.PAYMENT', { childId }),
      }]}
    />
  )

  const renderToolbar = () => (
    <Toolbar>
      <Toolbar.Group alignItems="flex-start">
        <Toolbar.Item>
          <Form.Row
            label={i18n.t('module:Children:Child:Finance:Invoicing:invoiceDate')}
            verticalLabel
          >
            <DatePicker
              value={dateRange}
              clearable
              range
              onChange={onDateChange}
            />
          </Form.Row>
        </Toolbar.Item>
        {invoiceDateRange[0] && invoiceDateRange[1] && (
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('module:Children:Child:Finance:Invoicing:invoicePeriod')}
              verticalLabel
            >
              <DatePicker
                value={invoiceDateRange}
                disabled
                range
                onChange={onInvoiceDateChange}
              />
            </Form.Row>
          </Toolbar.Item>
        )}
        <Toolbar.Item>
          <Form.Row
            label={i18n.t('module:Children:Child:Finance:Invoicing:status')}
            verticalLabel
          >
            <Select
              options={_.filter(getStatusOptions(), (option) => 'ALL' !== option.value)}
              placeholder={i18n.t('module:Children:Child:Finance:Invoicing:selectStatus')}
              specificWidth={240}
              value={status}
              multi
              onChange={onStatusChange}
            />
          </Form.Row>
        </Toolbar.Item>
        <Toolbar.Item>
          <Form.Row
            label={i18n.t('module:Children:Child:Finance:Invoicing:type')}
            verticalLabel
          >
            <Select
              options={typeOptions}
              placeholder={i18n.t('module:Children:Child:Finance:Invoicing:selectType')}
              value={type}
              onChange={onTypeChange}
            />
          </Form.Row>
        </Toolbar.Item>
        <Toolbar.Item>
          {_.find(invoices, (invoice) => invoice.containCreditNotes) && (
            <CreditNoteIcon
              label={i18n.t('module:Children:Child:Finance:Invoicing:creditNoteLinked')}
            />
          )}
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  return (
    <Section actions={actions} title={i18n.t('module:Children:Child:Finance:Invoicing:title')}>
      <Callout content={errorMessages} error />
      {renderSummary()}
      {renderToolbar()}
      {renderContent()}
    </Section>
  )
}

export default ChildFinanceDetailsInvoicesView
