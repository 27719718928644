import _ from 'lodash'

import React, { useRef } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { SUPPORTED_FILE_TYPES } from 'constants/mimetypes'
import ImagesSize from 'constants/imagesSize'

import { formatBytes } from 'utils/data'
import { getOptimizedImage } from 'utils/image'
import { isImageMimeType, isPdfMimeType, isVideoMimeType } from 'utils/attachment'

import { Button, FieldError, Icon, InfiniteScroll, Space, Table, Typography } from 'components'

import i18n from 'translations'

import Carousel from './components/Carousel'
import FileRow from './components/FileRow'
import {
  StyledMediaPickerWrapper,
  StyledRemoveButton,
  StyledSectionActionButton,
  StyledSize,
  StyledTile,
  StyledTileContent,
  StyledTileOverlay,
  StyledTilesContainer,
  StyledTitle,
} from './MediaPickerStyled'

const getTableColumns = (disableTagging, preview, uploadDateColumn) => {
  let nameWidth = 40

  if (disableTagging && preview) {
    nameWidth = 85
  } else if (disableTagging || preview) {
    nameWidth = 50
  }

  const table = [
    {
      align: 'left',
      alignTh: 'left',
      field: 'name',
      title: i18n.t('global:Name'),
      width: `${nameWidth}%`,
    },
  ]

  if (!disableTagging) {
    table.push({
      align: 'left',
      alignTh: 'left',
      field: 'childrenTagged',
      title: i18n.t('components:MediaPicker:childrenTagged'),
      width: '25%',
    })
  }

  table.push({
    align: 'left',
    alignTh: 'left',
    field: 'size',
    title: i18n.t('components:MediaPicker:size'),
    width: uploadDateColumn ? '20%' : '25%',
  })

  if (uploadDateColumn) {
    table.push({
      align: 'left',
      alignTh: 'left',
      field: 'uploadDate',
      sortKey: 'createdAt',
      sortable: true,
      title: i18n.t('components:MediaPicker:uploadDate'),
      width: '20%',
    })
  }

  if (!preview) {
    table.push({
      field: 'action',
      title: '',
      width: '10%',
    })
  }

  return table
}

const MediaPickerView = ({
  accept = [SUPPORTED_FILE_TYPES.IMAGES, SUPPORTED_FILE_TYPES.VIDEO],
  authAccessMap,
  buttonLabel,
  buttonSecondary,
  carouselFiles,
  childrenList,
  disableSticky,
  disableTagging,
  disabled,
  editMode,
  emptyState,
  error,
  filesLimit,
  hideButtonWhenFileLimitExceeded,
  hideEditOption,
  hideStatistics,
  hideSupportedFileTypes,
  isFetching,
  isOffline,
  isSyncProcessing,
  lightBoxCurrentImage,
  lightBoxIsOpen,
  maxFileSizeInMb,
  minWidth,
  noCloudUpload,
  onDescriptionChange,
  onDownloadFile,
  onEditFile,
  onFilesAdded,
  onLightBoxClickNext,
  onLightBoxClickPrev,
  onLightBoxClose,
  onLightBoxOpen,
  onRemoveFile,
  onSortChange,
  pagination,
  preview,
  sectionMode,
  sortField,
  sortOrder,
  tableFiles,
  tiledMode,
  totalResults,
  uploadDateColumn,
}) => {
  const fileLimitExceeded = totalResults >= filesLimit
  const selectedFileRef = useRef(null)
  let acceptFileTypes = _.flattenDeep(accept)

  if (!authAccessMap.section.VideoEnabled) {
    _.each(SUPPORTED_FILE_TYPES.VIDEO, (item) => {
      acceptFileTypes = _.filter(acceptFileTypes, ({ extension }) => extension !== item.extension)
    })
  }

  const renderUploadButton = () => {
    if (hideButtonWhenFileLimitExceeded && fileLimitExceeded) {
      return null
    }

    return (
      <React.Fragment>
        <input
          accept={_.flatMap(_.map(acceptFileTypes, ({ extension, mimeTypes }) => [extension, ...mimeTypes])).join(', ')}
          disabled={isSyncProcessing || fileLimitExceeded}
          ref={selectedFileRef}
          style={{ display: 'none' }}
          type="file"
          multiple
          onChange={onFilesAdded}
          onClick={() => {
            selectedFileRef.current.value = null
          }}
        />
        <Button
          component="span"
          disabled={isSyncProcessing || fileLimitExceeded}
          hierarchy={buttonSecondary && 'secondary'}
          icon="upload"
          isLoading={isSyncProcessing}
          label={buttonLabel || i18n.t('components:MediaPicker:addFiles')}
          onClick={() => selectedFileRef.current.click()}
        />
      </React.Fragment>
    )
  }

  const renderModal = () => {
    if (!carouselFiles?.length) {
      return null
    }

    return (
      <Carousel
        carouselFiles={carouselFiles}
        childrenList={childrenList}
        disableTagging={disableTagging}
        lightBoxCurrentImage={lightBoxCurrentImage}
        lightBoxIsOpen={lightBoxIsOpen}
        preview={preview}
        tiledMode={tiledMode}
        onEditFile={onEditFile}
        onLightBoxClickNext={onLightBoxClickNext}
        onLightBoxClickPrev={onLightBoxClickPrev}
        onLightBoxClose={onLightBoxClose}
      />
    )
  }

  const renderStatistics = () => {
    if ((!filesLimit && !totalResults) || hideStatistics) {
      return null
    }

    return (
      <Typography margin={sectionMode ? '' : '20px 0 0'}>
        {totalResults}
        {' '}
        {1 === totalResults
          ? i18n.t('components:MediaPicker:file')
          : i18n.t('components:MediaPicker:files')}
        {' '}
        <Typography color={NEUTRAL_COLOURS.GRAY} variant="span">
          {'('}
          {i18n.t('components:MediaPicker:filesMax', { limit: filesLimit })}
          {')'}
        </Typography>
      </Typography>
    )
  }

  const renderTable = () => {
    if (!tableFiles?.length) {
      if (emptyState) {
        return emptyState
      }

      return null
    }

    const renderTableContent = (
      <Table
        columns={getTableColumns(disableTagging, preview, uploadDateColumn)}
        disableSticky={disableSticky}
        minWidth={minWidth}
        noMargin={!editMode || hideButtonWhenFileLimitExceeded}
        sortField={sortField}
        sortOrder={sortOrder}
        visualType="transparent"
        onSortChange={onSortChange}
      >
        <Table.Tbody>
          {_.map(tableFiles, (file) => (
            <FileRow
              childrenList={childrenList}
              disableTagging={disableTagging}
              disabled={disabled}
              file={file}
              getTableColumns={getTableColumns}
              hideEditOption={hideEditOption}
              isOffline={isOffline}
              key={file.id}
              noCloudUpload={noCloudUpload}
              preview={preview}
              uploadDateColumn={uploadDateColumn}
              onDescriptionChange={onDescriptionChange}
              onDownloadFile={onDownloadFile}
              onEditFile={onEditFile}
              onLightBoxOpen={onLightBoxOpen}
              onRemoveFile={onRemoveFile}
            />
          ))}
        </Table.Tbody>
      </Table>
    )

    const { onPageChange, page, pageCount } = pagination || {}

    return pagination
      ? (
        <InfiniteScroll
          dataLength={tableFiles ? tableFiles.length : 0}
          hasMore={page < pageCount}
          isFetching={isFetching}
          next={() => onPageChange((+page) + 1)}
        >
          {renderTableContent}
        </InfiniteScroll>
      )
      : renderTableContent
  }

  const renderPDFTileBody = ({ file, size, url }) => (
    <a href={url || file} rel="noopener noreferrer" target="_blank">
      <StyledTileContent>
        <Icon color="#F70000" height={46} icon="pdf" />
        <StyledTitle>
          {/* eslint-disable-next-line */}
          {url?.replace(/^.*[\\\/]/, '').replace(/\?(.*)/, '')}
        </StyledTitle>
        <StyledSize>{formatBytes(size)}</StyledSize>
      </StyledTileContent>
    </a>
  )

  const renderRemoveButton = (file) => (
    <StyledRemoveButton>
      <Button
        icon="trash"
        size="small"
        onClick={(e) => {
          e.stopPropagation()
          onRemoveFile(file)
        }}
      />
    </StyledRemoveButton>
  )

  const renderTileRow = (item) => {
    const { file, id, mimeType, thumbnail, url } = item

    const isImage = isImageMimeType(mimeType)
    const isPdf = isPdfMimeType(mimeType)
    const isVideo = isVideoMimeType(mimeType)

    return (
      <StyledTile key={url || file} onClick={() => !isPdf && onLightBoxOpen(id)}>
        {(isImage || isVideo) && (
          <LazyLoadImage
            effect="opacity"
            height={ImagesSize.MEDIA_PICKER_TILE_THUMBNAIL.height}
            src={isImage ? getOptimizedImage((url || file), ImagesSize.MEDIA_PICKER_TILE_THUMBNAIL) : thumbnail}
            threshold={500}
            width={ImagesSize.MEDIA_PICKER_TILE_THUMBNAIL.width}
            wrapperClassName="mediaPickerImage"
          />
        )}
        {editMode && renderRemoveButton(item)}
        {isVideo && (
          <StyledTileOverlay>
            <Icon
              color={NEUTRAL_COLOURS.WHITE}
              height={46}
              icon="play"
            />
          </StyledTileOverlay>
        )}
        {isPdf && renderPDFTileBody(item)}
      </StyledTile>
    )
  }

  const renderTiles = () => {
    if (!tableFiles?.length) {
      return null
    }

    return (
      <StyledTilesContainer>
        {_.map(tableFiles, renderTileRow)}
      </StyledTilesContainer>
    )
  }

  return (
    <React.Fragment>
      <StyledMediaPickerWrapper>
        {editMode && sectionMode && (
          <StyledSectionActionButton>
            {renderUploadButton()}
          </StyledSectionActionButton>
        )}
        {editMode && !sectionMode && !hideSupportedFileTypes && (
          <Typography
            color={NEUTRAL_COLOURS.GRAY}
            fontSize={14}
          >
            {i18n.t('components:MediaPicker:supportedFileTypes')}
            {': '}
            {_.map(acceptFileTypes, (i) => i.label).join(', ')}
            {'. '}
            {maxFileSizeInMb && (
              <React.Fragment>
                {i18n.t('components:MediaPicker:maxFileSize')}
                {': '}
                {maxFileSizeInMb}
                {'Mb'}
              </React.Fragment>
            )}
          </Typography>
        )}
        {editMode && !hideSupportedFileTypes && <Space margin="10px 0 0" />}
        {editMode && !sectionMode && renderUploadButton()}
        {renderModal()}
        {renderStatistics()}
        {tiledMode ? renderTiles() : renderTable()}
        <Space space="20px" />
      </StyledMediaPickerWrapper>
      <FieldError error={error} />
    </React.Fragment>
  )
}

export default MediaPickerView
