import moment from 'moment'
import _ from 'lodash'

import React from 'react'

import { Button, Form, GridLayout, Page, Spinner, Typography } from 'components'

import RoomLists from './components/RoomLists'
import RoomMove from './components/RoomMove'
import { ROOM_MOVE_FORM } from './components/RoomMove/RoomMove'
import { getRoomMoveFormValues } from './DefaultRoomMovesHelpers'
import {
  StyledFooterAction,
  StyledHeader,
  StyledMessage,
  StyledMoveRoomListContainer,
  StyledMoveRoomListContent,
} from './DefaultRoomMovesStyled'

const DefaultRoomMovesView = ({
  isLoading,
  newElementIsCreated,
  onAddNewRoomMove,
  onCancelEdit,
  onCancelNewElement,
  onRemoveRoomMove,
  onSomeElementIsEdited,
  onSubmit,
  rooms = [],
  roomsDropdown = [],
  roomsDropdownWithLeaving = [],
  roomsMoveLists,
  someElementIsEdited,
  titleMessage,
}) => {
  const renderRoomLists = () => {
    if (!rooms?.length) {
      return null
    }

    return (
      <RoomLists>
        {_.map(rooms, ({ containMovesFrom, id, invalid, name }) => (
          <RoomLists.Row
            containMovesFrom={containMovesFrom}
            invalid={invalid}
            key={id}
            name={name}
          />
        ))}
      </RoomLists>
    )
  }

  const renderRoomMoveHeader = () => (
    <StyledHeader>
      <GridLayout>
        <GridLayout.Group disableRwd>
          <GridLayout.Item flex={1} padding="0" disableRwd>
            <Form.Row.FlexItem flex="0 0 200px">
              <strong>
                Move from
              </strong>
            </Form.Row.FlexItem>
          </GridLayout.Item>
          <GridLayout.Item flex={1} disableRwd>
            <Form.Row.FlexItem flex="0 0 200px">
              <strong>
                Move to
              </strong>
            </Form.Row.FlexItem>
          </GridLayout.Item>
          <GridLayout.Item flex={2} disableRwd>
            <Form.Row.FlexItem flex="0 0 340px">
              <strong>
                Move on
              </strong>
            </Form.Row.FlexItem>
          </GridLayout.Item>
        </GridLayout.Group>
      </GridLayout>
    </StyledHeader>
  )

  const renderMoveRoomsList = () => (
    <StyledMoveRoomListContainer>
      <StyledMoveRoomListContent>
        {roomsMoveLists?.length ? renderRoomMoveHeader() : null}
        {_.map(roomsMoveLists, (roomMove) => (
          <RoomMove
            form={`${ROOM_MOVE_FORM}_${roomMove.id}`}
            id={moment().format('x')}
            initialValues={getRoomMoveFormValues(roomMove)}
            key={roomMove.id}
            roomMove={roomMove}
            rooms={roomsDropdown}
            roomsWithLeaving={roomsDropdownWithLeaving}
            someElementIsEdited={newElementIsCreated || someElementIsEdited}
            onCancelEdit={onCancelEdit}
            onRemoveRoomMove={onRemoveRoomMove}
            onSomeElementIsEdited={onSomeElementIsEdited}
            onSubmit={onSubmit}
          />
        ))}
        {newElementIsCreated && (
          <RoomMove
            form={`${ROOM_MOVE_FORM}`}
            rooms={roomsDropdown}
            roomsWithLeaving={roomsDropdownWithLeaving}
            onCancelNewElement={onCancelNewElement}
            onSubmit={onSubmit}
          />
        )}
        {!newElementIsCreated && (
          <StyledFooterAction>
            <Button.ActionButton
              disabled={someElementIsEdited}
              label="Add default room move"
              onClick={onAddNewRoomMove}
            />
          </StyledFooterAction>
        )}
      </StyledMoveRoomListContent>
    </StyledMoveRoomListContainer>
  )

  const renderContent = () => {
    if (isLoading) {
      return (
        <Spinner />
      )
    }

    return (
      <div>
        <Typography margin="20px 0">
          <StyledMessage invalid={titleMessage.error}>
            {titleMessage.message}
          </StyledMessage>
          <br />
          Setting up your room moves will ensure more accurate occupancy reporting.
        </Typography>
        {renderRoomLists()}
        {renderMoveRoomsList()}
      </div>
    )
  }

  return (
    <Page.Section
      isLoading={isLoading}
      title="Default room moves"
    >
      {renderContent()}
    </Page.Section>
  )
}

export default DefaultRoomMovesView
