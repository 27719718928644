import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { getNurseryData } from 'services/nurseries/single/selectors/single'
import { getNurserySessionsList } from 'services/nurserySessions/list/selectors'
import { getSessionCalculationWeeksAndDays } from 'services/nurseries/single/selectors/settings'

import i18n from 'translations'

import constants from './constants'

const getChildSessions = (state) => state.childSessions

export const getChildSessionsList = createSelector(
  [getChildSessions],
  (childSession) => childSession.list.data,
)

export const getChildSessionsSingleState = createSelector(
  [getChildSessions],
  (childSession) => childSession.single,
)

export const getChildSessionsSingleData = createSelector(
  [getChildSessions],
  (childSession) => childSession.single.data,
)

export const getFormattedChildSessions = ({
  formattedOpeningDays,
  grouped,
  isFormContext,
}) => _.map(formattedOpeningDays, (day) => {
  if (!grouped[day] || !grouped[day].length) {
    return {
      dayOfWeek: day,
      plans: [],
    }
  }

  const dayPlans = grouped[day]

  const formattedPlans = _.map(dayPlans, (plan) => {
    const { endTime, nurserySession, sessionId, startTime } = plan

    let archived
    let sessionName
    let sessionStartTime
    let sessionEndTime

    if (isFormContext) {
      sessionName = sessionId.label
      sessionStartTime = sessionId.startTime
      sessionEndTime = sessionId.endTime
    } else {
      archived = nurserySession.archived
      sessionName = nurserySession.name
      sessionStartTime = nurserySession.startTime
      sessionEndTime = nurserySession.endTime
    }

    const newStartTime = startTime || sessionStartTime
    const newEndTime = endTime || sessionEndTime

    return {
      archived,
      endTime: newEndTime,
      sessionName,
      startTime: newStartTime,
    }
  })

  return {
    dayOfWeek: day,
    plans: formattedPlans,
  }
})

const getFormattedPlans = (plans, formattedOpeningDays) => {
  if (!plans) {
    return {}
  }

  // tranform array to objects by dayOfWeek
  const grouped = _.groupBy(plans, 'dayOfWeek')

  // get formatted child sessions
  return getFormattedChildSessions({ formattedOpeningDays, grouped })
}

const getFormattedSessions = (sessions, formattedOpeningDays) => {
  if (!sessions || !sessions.length) {
    return undefined
  }

  return _.map(sessions, (session) => ({
    ...session,
    formattedPlans: getFormattedPlans(session.plans, formattedOpeningDays),
  }))
}

export const getChildSessionsListSelectors = createSelector(
  [getChildSessionsList, getNurseryData],
  (state, settings) => {
    if (!state || !settings || !settings.nurserySettings) {
      return null
    }

    const { formattedOpeningDays } = settings.nurserySettings

    const past = getFormattedSessions(state.past, formattedOpeningDays)
    const current = getFormattedSessions(state.current, formattedOpeningDays)
    const future = getFormattedSessions(state.future, formattedOpeningDays)

    return {
      current,
      future,
      past,
    }
  },
)

const getSessionSummaryBySessionName = (sessions) => {
  if (!sessions || !sessions.length || !sessions[0].plans || !sessions[0].plans.length) {
    return undefined
  }

  const groupedSessions = _.groupBy(sessions[0].plans, 'nurserySession.id')

  return _.map(groupedSessions, (value) => {
    const { nurserySession } = value[0]

    return {
      ...nurserySession,
      sessionCount: value.length,
    }
  })
}

export const getChildCurrentSessionsSummaryBySessionNameSelectors = createSelector(
  [getChildSessionsList],
  (state) => {
    if (!state) {
      return null
    }

    return getSessionSummaryBySessionName(state.current)
  },
)

export const getNurserySessionsOptions = createSelector(
  [getNurserySessionsList],
  (nurserySessionsList) => {
    if (!nurserySessionsList?.data?.length) {
      return null
    }

    const mapNurserySession = (nurserySession) => {
      const { endTime, id, isHourly, name, startTime } = nurserySession

      return {
        endTime,
        isHourly,
        label: name,
        startTime,
        value: id,
      }
    }

    return _.map(nurserySessionsList.data, mapNurserySession)
  },
)

const getPlans = (formattedOpeningDays, grouped = {}) => {
  const mapPlan = (plan) => {
    const { endTime, nurserySession, startTime } = plan
    const { archived, endTime: sessionEndTime, id, isHourly, name, startTime: sessionStartTime } = nurserySession

    return {
      ...plan,
      endTime,
      sessionId: {
        endTime: sessionEndTime,
        isHourly,
        label: `${name}${archived ? ` (${i18n.t('global:archived')})` : ''}`,
        startTime: sessionStartTime,
        value: id,
      },
      startTime,
    }
  }

  return _.reduce(formattedOpeningDays, (result, openingDay) => {
    const newResult = { ...result }

    newResult[openingDay] = grouped[openingDay] ? grouped[openingDay].map(mapPlan) : [{}]

    return newResult
  }, {})
}

export const getInitialValues = createSelector(
  [(values) => values],
  ({
    childSession,
    formattedOpeningDays,
    isEdit,
    isSessionAverageCostSelected,
    periodOption,
    sessionCalculationEnabled,
  }) => {
    if ((isEdit && !childSession) || !formattedOpeningDays || !periodOption) {
      return null
    }

    if (!isEdit) {
      if (null === isSessionAverageCostSelected) {
        return null
      }

      const { SESSION_CALCULATION } = constants

      const getSessionCalculationValue = () => {
        if (!sessionCalculationEnabled) {
          return undefined
        }

        return isSessionAverageCostSelected
          ? SESSION_CALCULATION.AVERAGE_COST
          : SESSION_CALCULATION.ACTUAL_SESSIONS
      }

      return {
        attendancePeriod: periodOption,
        plans: getPlans(formattedOpeningDays),
        sessionCalculation: getSessionCalculationValue(),
      }
    }

    const {
      attendancePeriod,
      endDate,
      isOngoing,
      plans,
      sessionCalculation,
      startDate,
    } = childSession

    const grouped = _.groupBy(plans, 'dayOfWeek')

    return {
      attendancePeriod: {
        label: `${attendancePeriod?.name}${attendancePeriod?.isArchived ? ' (archived)' : ''}`,
        value: attendancePeriod?.id,
      },
      endDate,
      isOngoing,
      plans: getPlans(formattedOpeningDays, grouped),
      sessionCalculation,
      startDate,
    }
  },
)

export const getPayload = createSelector(
  [(fields) => fields],
  (fields) => {
    const getPayloadPlans = (plans) => {
      if (!plans) {
        return null
      }

      const mapPlan = (dayOfWeek) => (plan) => {
        if (_.isEmpty(plan)) {
          return null
        }

        const { endTime, id, sessionId, startTime } = plan

        return {
          dayOfWeek,
          endTime: endTime && sessionId.isHourly ? endTime : null,
          id,
          sessionId: sessionId ? sessionId.value : null,
          startTime: startTime && sessionId.isHourly ? startTime : null,
        }
      }

      const payloadPlans = []

      _.forEach(plans, (value, key) => {
        const filteredValues = _.filter(value, (valueItem) => !!valueItem.sessionId)

        if (filteredValues && filteredValues.length) {
          payloadPlans.push(..._.map(filteredValues, mapPlan(key)))
        }
      })

      return payloadPlans
    }

    const { attendancePeriod, endDate, isInvoiceAssigned, isOngoing, plans, startDate, ...other } = fields

    if (isInvoiceAssigned) {
      return {
        endDate: isOngoing ? null : moment(endDate).format(DEFAULT_DATE_FORMAT),
        isOngoing: !!isOngoing,
        startDate: startDate ? moment(startDate).format(DEFAULT_DATE_FORMAT) : null,
      }
    }

    return {
      ...other,
      attendancePeriod: attendancePeriod?.value || attendancePeriod,
      endDate: isOngoing ? null : moment(endDate).format(DEFAULT_DATE_FORMAT),
      isOngoing: !!isOngoing,
      plans: getPayloadPlans(plans),
      startDate: startDate ? moment(startDate).format(DEFAULT_DATE_FORMAT) : null,
    }
  },
)

export const getChildKeyPerson = createSelector([(child) => child], (child) => (
  child.keyWorkers && child.keyWorkers.length ? child.keyWorkers[0].fullName : null
))

export const getChildRoom = createSelector([(child) => child], (child) => (child.class ? child.class.name : null))

export const getSessionCalculationsOptions = createSelector(
  [getSessionCalculationWeeksAndDays],
  (sessionCalculationWeeksAndDays) => {
    if (!sessionCalculationWeeksAndDays) {
      return null
    }

    const { days, weeks } = sessionCalculationWeeksAndDays

    return [
      {
        label: i18n.t('services:ChildSessions:CalculationOptionLabels:actualSessions'),
        value: constants.SESSION_CALCULATION.ACTUAL_SESSIONS,
      },
      {
        label: i18n.t('services:ChildSessions:CalculationOptionLabels:averageCost', {
          days,
          weeks,
        }),
        value: constants.SESSION_CALCULATION.AVERAGE_COST,
      },
    ]
  },
)

export const isSessionsEmpty = createSelector(
  [(fields) => fields],
  (fields) => {
    if (!fields || !fields.plans) {
      return true
    }

    const { plans } = fields

    return !_.some(plans, (day) => _.some(day, (plan) => !!plan.sessionId))
  },
)

export const getCriteria = (filters) => {
  const { archived, ids, sessionDate } = filters
  const criteria = []

  if (ids && ids.length) {
    criteria.push({
      comparator: 'in',
      field: 'id',
      value: ids.join(','),
    })
  }

  if (sessionDate) {
    criteria.push({
      comparator: 'lte',
      field: 'startDate',
      value: moment(sessionDate).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (archived || 0 === archived) {
    criteria.push({
      field: 'archived',
      value: archived,
    })
  }

  return criteria
}

const getAllExistingPlans = (plans) => {
  const existingPlans = []

  _.forEach(plans, (dayPlans) => {
    const filteredDayPlans = _.filter(dayPlans, ({ id }) => !!id)

    existingPlans.push(...filteredDayPlans)
  })

  return existingPlans
}

const getPlanDetail = (plan) => {
  if (!plan?.sessionId) {
    return null
  }

  const { endTime, isHourly, sessionId, startTime } = plan
  const { endTime: planSessionEndTime, startTime: planSessionStartTime } = sessionId

  return {
    endTime: planSessionEndTime || endTime,
    isHourly,
    startTime: planSessionStartTime || startTime,
  }
}

const isDateFieldChange = (fields, formInitialValues) => {
  if (!fields || !formInitialValues) {
    return false
  }

  const { endDate, isOngoing, startDate } = fields
  const {
    endDate: initialEndDate,
    isOngoing: initialIsOngoing,
    startDate: initialStartDate,
  } = formInitialValues

  // Check Child session
  return (
    moment(startDate).format(DEFAULT_DATE_FORMAT) !== moment(initialStartDate).format(DEFAULT_DATE_FORMAT)
    || moment(endDate).format(DEFAULT_DATE_FORMAT) !== moment(initialEndDate).format(DEFAULT_DATE_FORMAT)
    || isOngoing !== initialIsOngoing
  )
}

export const isDateFieldChangedSelector = createSelector(
  [(params) => params || {}],
  ({ fields, formInitialValues }) => isDateFieldChange(fields, formInitialValues),
)

export const isAnyFieldChangedSelector = createSelector(
  [(params) => params || {}],
  ({ fields, formInitialValues }) => {
    if (isDateFieldChange(fields, formInitialValues)) {
      return true
    }

    const { plans } = fields
    const {
      plans: initialPlans,
    } = formInitialValues

    // **** Check Child session plans ****
    // - check if any plan removed
    const initialExistingPlans = getAllExistingPlans(initialPlans)
    const initialPlanIds = _.map(initialExistingPlans, ({ id }) => id)

    const existingPlans = getAllExistingPlans(plans)
    const planIds = _.map(existingPlans, ({ id }) => id)

    if (JSON.stringify(initialPlanIds) !== JSON.stringify(planIds)) {
      return true
    }

    // - check startTime, endTime and isHourly for each plan
    return _.some(initialPlanIds, (initialPlanId) => {
      const initialPlan = _.find(initialExistingPlans, ['id', initialPlanId])
      const plan = _.find(existingPlans, ['id', initialPlanId])

      const { endTime, isHourly, startTime } = getPlanDetail(plan) || {}
      const {
        endTime: initialEndTime,
        isHourly: initialIsHourly,
        startTime: initialStartTime,
      } = getPlanDetail(initialPlan) || {}

      return startTime !== initialStartTime || endTime !== initialEndTime || isHourly !== initialIsHourly
    })
  },
)

export const isInvoiceAssigned = createSelector(
  [getChildSessionsSingleData],
  (singleData) => singleData?.invoiced,
)

export const isFundingAssigned = createSelector(
  [getChildSessionsSingleData],
  (singleData) => singleData?.fundingAssigned,
)

export const getOverlappingTimeRangeDaysFromPlans = (plans) => {
  const timeRangeErrorDays = []

  _.forEach(plans, (planItems, key) => {
    const mappedTimeRange = _.map(planItems, (item) => {
      const {
        endTime,
        sessionId,
        startTime,
      } = item
      const {
        endTime: sessionEndTime,
        startTime: sessionStartTime,
      } = sessionId || {}

      return {
        endTime: sessionEndTime || endTime,
        startTime: sessionStartTime || startTime,
      }
    })

    const sortedPlanItems = _.sortBy(mappedTimeRange, 'startTime')

    let prevRange = null

    _.forEach(sortedPlanItems, (item) => {
      if (prevRange) {
        const { endTime: prevEndTime } = prevRange
        const { startTime: currentStartTime } = item

        if (prevEndTime > currentStartTime) {
          timeRangeErrorDays.push(_.upperFirst(key))

          return
        }
      }

      prevRange = item
    })
  })

  return timeRangeErrorDays
}
