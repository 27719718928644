import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { DAILY_DIARY_DATE_FORMAT } from 'services/legacy/dailyDiary/constants'

import { changeHours } from 'utils/date'

import { FOOD_CATEGORY, FOOD_TYPE } from '../constants'

const getFoodMenu = (state) => state.foodMenu

export const getFoodMenuList = createSelector([getFoodMenu], (state) => state.list)

export const getFoodMenuData = createSelector([getFoodMenuList], (state) => _.last(state.data))

export const getInitialValues = (dailyDiaryDate = moment(), category, session) => createSelector(
  [getFoodMenuData],
  (menu = {}) => {
    const currentDate = moment()
    const defaultDate = moment(dailyDiaryDate, DAILY_DIARY_DATE_FORMAT)
      .hours(currentDate.hours())
      .minutes(currentDate.minutes())
      .seconds(currentDate.seconds())

    const { items } = menu

    if (!items) {
      return {
        date: defaultDate,
      }
    }

    const getItems = (type) => {
      const filteredItems = _.filter(items, (item) => {
        const itemFoodCategoryParts = item.category.split('-')

        if (itemFoodCategoryParts[0] === FOOD_CATEGORY.MEAL.value) {
          const [itemCategory, itemType, itemSession] = itemFoodCategoryParts

          return !(
            type !== itemType
            || category !== itemCategory
            || session.value !== itemSession
          )
        }

        if (itemFoodCategoryParts[0] === FOOD_CATEGORY.SNACK.value) {
          const [itemCategory, itemSession] = itemFoodCategoryParts

          return !(
            category !== itemCategory
            || session.value !== itemSession
          )
        }

        return false
      })

      return _.map(filteredItems, ({ foodType }) => ({
        meal: {
          label: foodType.name,
          value: foodType.id,
        },
      }))
    }

    return {
      [FOOD_TYPE.MEATS]: getItems(FOOD_TYPE.MEATS),
      [FOOD_TYPE.VEGGIES]: getItems(FOOD_TYPE.VEGGIES),
      [FOOD_TYPE.SNACKS]: getItems(FOOD_TYPE.SNACKS),
      date: defaultDate,
    }
  },
)

export const getListCriteria = (query = {}) => {
  const { date = moment() } = query
  const criteria = []

  criteria.push({
    comparator: 'gte',
    field: 'date',
    value: moment(changeHours(moment(date, DAILY_DIARY_DATE_FORMAT), new Date()))
      .utc()
      .startOf('day')
      .toISOString(),
  })

  criteria.push({
    comparator: 'lt',
    field: 'date',
    value: moment(changeHours(moment(date, DAILY_DIARY_DATE_FORMAT), new Date()))
      .utc()
      .endOf('day')
      .toISOString(),
  })

  return criteria
}
