import _ from 'lodash'
import moment from 'moment'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { SUPPORTED_FILE_TYPES } from 'constants/mimetypes'

import { getOverlappingTimeRangeDaysFromPlans } from 'services/legacy/childSessions/selectors'

import i18n from 'translations'

export const isNumberGreaterOrEqualThan = (minValue: number) => (value) => {
  if (+value < minValue) {
    return i18n.t('utils:Validation:isNumberGreaterOrEqualThan', { minValue })
  }

  return null
}

export const isNumberLessOrEqualThan = (maxValue: number) => (value) => {
  if (+value > maxValue) {
    return i18n.t('utils:Validation:isNumberLessOrEqualThan', { maxValue })
  }

  return null
}

export const isMaxLengthLessThan = (maxLength: number) => (value) => {
  if (value?.length > maxLength) {
    return i18n.t('utils:Validation:isMaxLengthLessThan', { maxLength })
  }

  return null
}

export const isMinLengthGreaterThan = (minLength: number) => (value) => {
  if (value?.length < minLength) {
    return i18n.t('utils:Validation:isMinLengthLessThan', { minLength })
  }

  return null
}

export const isRequired = (value) => {
  if (null === value || undefined === value || '' === value) {
    return i18n.t('utils:Validation:isRequired')
  }

  return null
}

export const isRequiredForTrimmed = (value) => {
  if (null === value || undefined === value || '' === value || '' === value.trim()) {
    return i18n.t('utils:Validation:isRequired')
  }

  return null
}

export const isRequiredDateRange = (value) => {
  if (!value || !value[0] || !value[1]) {
    return i18n.t('utils:Validation:isRequired')
  }

  return null
}

export const isValidDateRange = (value) => {
  if (value) {
    if (value[0] && value[1] && value[0] > value[1]) {
      return i18n.t('utils:Validation:isValidDateRange:endDateSmallerThanStart')
    }

    if ((value[0] && !value[1]) || (!value[0] && value[1])) {
      return i18n.t('utils:Validation:isValidDateRange:invalid')
    }
  }

  return null
}

export const isStartDateBeforeEndDate = (startDate, endDate) => {
  if (startDate && endDate && startDate > endDate) {
    return i18n.t('utils:Validation:isValidDateRange:endDateSmallerThanStart')
  }

  return null
}

export const isSortCodeValid = (value) => {
  if (value && !/^(\d){2}-(\d){2}-(\d){2}$/i.test(value)) {
    return i18n.t('utils:Validation:isSortCodeValid')
  }

  return null
}

export const isValidBankAccountNumber = (value) => {
  if (value && 8 !== value.length) {
    return i18n.t('utils:Validation:isValidBankAccountNumber')
  }

  return null
}

export const isChildSession = (value) => {
  if (!value) {
    return null
  }

  const { endTime, sessionId, startTime } = value

  if (!sessionId) {
    return i18n.t('utils:Validation:isChildSession:sessionRequired')
  }

  if (sessionId.isHourly && (null === startTime || null === endTime)) {
    return i18n.t('utils:Validation:isChildSession:startTimeFinishTimeRequired')
  }

  return null
}

export const isEmailValid = (email) => {
  if (email && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) { // eslint-disable-line
    return i18n.t('utils:Validation:isEmailValid')
  }

  return null
}

export const isSubdomainValid = (subdomain) => {
  if (subdomain && !/^[a-z0-9-]+$/.test(subdomain)) {
    return i18n.t('utils:Validation:isSubdomainValid')
  }

  return null
}

export const isFullMonth = (startDate, endDate) => {
  const startOfMonth = moment(startDate).startOf('month').toDate()
  const endOfMonth = moment(endDate).endOf('month').toDate()

  return !(
    moment(startOfMonth).format('YYYYMMDD') !== moment(startDate).format('YYYYMMDD')
    || moment(endOfMonth).format('YYYYMMDD') !== moment(endDate).format('YYYYMMDD')
  )
}

export const isValidInteger = (value) => {
  if (null === value || undefined === value) {
    return null
  }

  if (!Number.isInteger(+value)) {
    return i18n.t('utils:Validation:isValidInteger')
  }

  return null
}

export const isValidMinutes = (value) => {
  if (null === value || undefined === value) {
    return null
  }

  const isIntegerMessage = isValidInteger(+value)

  if (isIntegerMessage) {
    return isIntegerMessage
  }

  if (60 < +value) {
    return i18n.t('utils:Validation:isValidMinutes')
  }

  return null
}

const countDecimals = (currentValue) => {
  if (!currentValue) {
    return 0
  }

  return currentValue % 1 ? currentValue.toString().split('.')[1].length : 0
}

export const isValidNumber = (precision) => (value) => {
  if (!value) {
    return null
  }

  const newValue = _.toNumber(value)

  if (!_.isNumber(newValue)) {
    return i18n.t('utils:Validation:isValidNumber:invalid')
  }

  const currentPrecision = countDecimals(newValue)

  if (currentPrecision > precision) {
    if (!precision) {
      return i18n.t('utils:Validation:isValidNumber:noPrecision')
    }

    return i18n.t('utils:Validation:isValidNumber:precision', { precision })
  }

  return null
}

export const isPositiveNumber = (precision) => (value) => {
  const isNumberMessage = isValidNumber(precision)(+value)

  if (isNumberMessage) {
    return isNumberMessage
  }

  if (0 > +value) {
    return i18n.t('utils:Validation:isPositiveNumber')
  }

  return null
}

export const isNegativeNumber = (precision) => (value) => {
  const isNumberMessage = isValidNumber(precision)(+value)

  if (isNumberMessage) {
    return isNumberMessage
  }

  if (0 < +value) {
    return i18n.t('utils:Validation:isNegativeNumber')
  }

  return null
}

export const isEndTimeGreaterThanStartTime = (value) => {
  const { endTime, startTime } = value || {}

  if (_.isInteger(startTime) && _.isInteger(endTime) && startTime > endTime) {
    return i18n.t('utils:Validation:isEndTimeGreaterThanStartTime')
  }

  return null
}

export const isTimeAvailableInHourlySession = (value) => {
  const { endTime, sessionId, startTime } = value || {}

  if (sessionId?.isHourly && (!_.isInteger(startTime) || !_.isInteger(endTime))) {
    return i18n.t('utils:Validation:isChildSession:startTimeFinishTimeRequired')
  }

  return null
}

export const isRequiredTime = (value) => {
  if (undefined === value || null === value || !moment(value).isValid()) {
    return i18n.t('utils:Validation:isRequired')
  }

  return null
}

export const sessionFieldValidations = (fields) => {
  const errors = {} as any

  if (!fields) {
    return errors
  }

  if (!fields.endDate && !fields.isOngoing) {
    errors.endDate = i18n.t('module:Children:Child:BookingPattern:Sessions:Add:dateError')
  }

  if (fields.startDate && fields.endDate && !fields.isOngoing) {
    if (moment(fields.startDate).format(DEFAULT_DATE_FORMAT) > moment(fields.endDate).format(DEFAULT_DATE_FORMAT)) {
      errors.endDate = i18n.t('module:Children:Child:BookingPattern:Sessions:Add:dateRangeConflictError')
    }
  }

  const { plans } = fields

  const timeRangeErrorDays = getOverlappingTimeRangeDaysFromPlans(plans)

  if (timeRangeErrorDays.length) {
    errors._error = i18n.t('module:Children:Child:BookingPattern:Sessions:Add:timeOverlappingError', {
      days: timeRangeErrorDays.join(', '),
    })
  }

  return errors
}

export const isValidBigInt = (value) => {
  if (
    19 < value?.toString()?.length
    || '9000000000000000000' < value?.toString()?.padStart(19, 0)
  ) {
    return i18n.t('utils:Validation:isValidBigInt')
  }

  return null
}

export const isValidPhotoUploadType = (value) => {
  if (!value?.file || true === value?.file?.isUploaded) {
    return null
  }

  const validMimeTypes = _.flatMap(_.map(SUPPORTED_FILE_TYPES.IMAGES, ({ mimeTypes }) => mimeTypes))

  if (!_.some(validMimeTypes, (item) => item === value?.file?.mimeType)) {
    return i18n.t('utils:Validation:isValidFileType')
  }

  return null
}
