import React from 'react'

import { ModalBox, Spinner } from 'components'

import i18n from 'translations'

import ChildLeaveModalForm from './components/ChildLeaveModalForm'

const ChildLeaveModalView = ({
  isEdit,
  isFetching,
  onCloseClick,
  ...rest
}) => {
  const renderContent = () => {
    if (isFetching) {
      return <Spinner />
    }

    return (
      <ChildLeaveModalForm
        isEdit={isEdit}
        onCloseClick={onCloseClick}
        {...rest}
      />
    )
  }

  return (
    <ModalBox
      title={i18n.t('module:Modals:ChildLeave:title', {
        action: isEdit ? i18n.t('global:Edit') : i18n.t('global:Add'),
      })}
      autoHeight
      onCloseClick={onCloseClick}
    >
      {renderContent()}
    </ModalBox>
  )
}

export default ChildLeaveModalView
