import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { INJURIES, INJURIES_STATUS } from '../constants'

const getInjuries = (state) => state.injuries

export const getInjuriesListSelector = createSelector([getInjuries], (state) => state.list.data)

const getInjurySignatureStatistics = ({ injurySignatureStatistics, status, type }) => {
  if (status === INJURIES_STATUS.DRAFT.value) {
    return null
  }

  const statistics = injurySignatureStatistics

  if (type === INJURIES.HOME_ACCIDENT && 2 >= statistics.witness.all) {
    statistics.witness.all = 2
  }

  return _.filter(_.map(statistics, (value, key) => ({ ...value, key })), ({ all }) => !!all)
}

export const getDataRowOfInjuriesListSelector = createSelector(
  [getInjuriesListSelector],
  (data) => _.map(data, (record) => {
    const { date, id, injured, injurySignatureStatistics, status, type } = record

    const statusValue = _.find(INJURIES_STATUS, ({ value }) => value === status)

    return {
      accidentDetails: _.map(injured, ({ accidentDetails }) => accidentDetails),
      borderColor: _.get(statusValue, 'color', () => '#F5F5F5')(),
      borderTitle: _.get(statusValue, 'label', null),
      childArray: _.map(injured, ({ child }) => child),
      date,
      description: _.map(injured, ({ description }) => description),
      id,
      injurySignatureStatistics: getInjurySignatureStatistics({ injurySignatureStatistics, status, type }),
      type,
    }
  }),
)

export const getCriteria = (filters = {}, flag) => {
  if (!filters) {
    return null
  }

  const criteria = []

  const {
    child,
    childSearch,
    createdby,
    date,
    injuryType,
    room,
    status,
    supervisor,
    type,
  } = filters

  if (date) {
    const { date: { after, before } } = filters

    if (after) {
      criteria.push({
        comparator: 'after',
        field: 'date',
        value: moment(after).startOf('day').toISOString(),
      })
    }

    if (before) {
      criteria.push({
        comparator: 'before',
        field: 'date',
        value: moment(before).endOf('day').toISOString(),
      })
    }
  }

  if (type) {
    criteria.push({
      field: 'type',
      value: type,
    })
  }

  if (childSearch) {
    criteria.push({
      field: 'concatenable',
      value: childSearch,
    })
  }

  if (injuryType) {
    criteria.push({
      field: 'injured.accidentDetails.injuryType',
      value: injuryType,
    })
  }

  if (createdby) {
    criteria.push({
      field: 'child.id',
      value: createdby,
    })
  }

  if (status) {
    criteria.push({
      field: 'status',
      value: status,
    })
  }

  if (supervisor) {
    criteria.push({
      field: 'supervisor',
      value: supervisor,
    })
  }

  if (room) {
    criteria.push({
      field: 'injured.child.nurseryClass',
      value: room,
    })
  }

  if (child) {
    criteria.push({
      field: 'injured.child',
      value: child,
    })
  }

  if (flag?.isHomeAccidents) {
    criteria.push({
      field: 'type',
      value: INJURIES.HOME_ACCIDENT,
    })
  } else if (!type) {
    criteria.push({
      field: 'type[]',
      value: INJURIES.ACCIDENT,
    },
    {
      field: 'type[]',
      value: INJURIES.INCIDENT,
    })
  }

  return criteria
}
