import { nest } from 'utils/flatnest'
import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { NEXT_STEPS_FILTERS } from 'services/legacy/nextSteps/constants'
import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { withAppService } from 'services/app'
import { withNextMontessoriActivitiesService } from 'services/legacy/nextMontessoriActivities'
import { withNextStepsService } from 'services/legacy/nextSteps'
import { withSecurityService } from 'services/security'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withSnackbarService } from 'services/utils/snackbar'
import { withRouter } from 'services/router'

import i18n from 'translations'

import NextStepsListView from './NextStepsListView'

const NEXT_STEPS_GROUPS = {
  read: [
    'child',
    'childObservation.child',
    'childObservation.observation',
    'frameworkArea',
    'nextStep',
    'nextStep.childObservation',
    'nextStep.frameworkAreas',
    'nextStep.nextObservation',
    'observation',
    'observation.author',
    'user',
  ],
}

const CHILD_PROFILE_NEXT_STEPS_GROUPS = {
  read: [
    'childObservation.observation',
    'frameworkArea',
    'nextStep',
    'nextStep.childObservation',
    'nextStep.frameworkAreas',
    'nextStep.nextObservation',
    'observation',
    'observation.author',
    'user',
  ],
}

const MONTESSORI_GROUPS = {
  read: [
    'montessoriActivity',
    'nextMontessoriActivity',
    'nextMontessoriActivity.childObservation',
    'nextMontessoriActivity.montessoriActivity',
  ],
}

const CHILD_PROFILE_NEXT_STEPS_MONTESSORI_GROUPS = {
  read: [...CHILD_PROFILE_NEXT_STEPS_GROUPS.read, ...MONTESSORI_GROUPS.read],
}

const NEXT_STEPS_GROUPS_MONTESSORI = {
  read: [...NEXT_STEPS_GROUPS.read, ...MONTESSORI_GROUPS.read],
}

class NextStepsListContainer extends Component {
  constructor(props) {
    super(props)

    const {
      currentUser,
      currentUserIsSuperAdmin,
      location: { query },
      params: { childId },
    } = props

    const { date, keyPerson, room, search, type } = _.mapValues(nest(query), (filter) => {
      try {
        return JSON.parse(filter)
      } catch (e) {
        return filter
      }
    })

    const dateFrom = date?.[0] ? moment(date[0]).format(DEFAULT_DATE_FORMAT) : undefined
    const dateTo = date?.[1] ? moment(date[1]).format(DEFAULT_DATE_FORMAT) : undefined

    let keyWorker = null

    try {
      if (!this.isChildContext() && keyPerson && JSON.parse(keyPerson)?.value) {
        keyWorker = +JSON.parse(keyPerson)
      } else if (!this.isChildContext() && currentUser && !currentUserIsSuperAdmin) {
        keyWorker = currentUser.id
      }
    } catch (e) {
      keyWorker = currentUser.id
    }

    const nextStepApiGroups = this.getFetchReadGroups(childId)

    this.state = {
      filters: {
        date: [dateFrom, dateTo],
        keyPerson: keyWorker,
        room,
        search,
        type: this.isChildContext() ? type : (
          type || NEXT_STEPS_FILTERS.WORKING_ON
        ),
      },
      nextStepApiGroups,
    }
  }

  componentDidMount() {
    this.fetch()
  }

  componentWillUnmount() {
    const { nextMontessoriActivitiesActions, nextStepsActions } = this.props

    nextStepsActions.clearNextSteps()
    nextStepsActions.clear()
    nextMontessoriActivitiesActions.clear()
  }

  getFetchReadGroups = (childId) => {
    const { isMontessori } = this.props

    let groups = childId ? CHILD_PROFILE_NEXT_STEPS_GROUPS : NEXT_STEPS_GROUPS

    if (isMontessori) {
      groups = childId ? CHILD_PROFILE_NEXT_STEPS_MONTESSORI_GROUPS : NEXT_STEPS_GROUPS_MONTESSORI
    }

    return groups
  }

  isChildContext = () => {
    const { params: { childId } } = this.props

    return !!childId
  }

  fetch = () => {
    const { nextStepsActions, nextStepsSelectors, paginationUtils, params: { childId }, setLocationQuery } = this.props
    const { filters, nextStepApiGroups } = this.state
    const { page } = paginationUtils

    setLocationQuery(_.mapValues(filters, (filter) => filter?.value || filter), ['search'])

    const criteria = nextStepsSelectors.getNextStepsListCriteria({
      ...filters,
      childId,
    })

    return nextStepsActions.list({
      params: {
        criteria,
        groups: nextStepApiGroups,
        page,
      },
    })
  }

  handleDateChange = ([from, to]) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        date: [
          from ? moment(from).format(DEFAULT_DATE_FORMAT) : undefined,
          to ? moment(to).format(DEFAULT_DATE_FORMAT) : undefined,
        ],
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handleFilterChange = (name) => (value) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [name]: value?.value || value,
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleResetSuccess = (callback) => {
    const { snackbarActions } = this.props

    snackbarActions.show({
      message: i18n.t('module:Learning:NextSteps:NextStepsList:itemReset'),
    })

    if (callback) {
      callback()
    }

    this.fetch()
  }

  handleSubmitSuccess = (callback) => {
    const { snackbarActions } = this.props

    snackbarActions.show({
      message: i18n.t('module:Learning:NextSteps:NextStepsList:dateChanged'),
    })

    if (callback) {
      callback()
    }
  }

  handleReset = (nextStep, callback) => {
    const { nextMontessoriActivitiesActions, nextStepsActions } = this.props
    const { nextStepApiGroups } = this.state
    const { id, montessoriActivity } = nextStep

    const body = {
      achievedAt: null,
    }

    if (montessoriActivity) {
      return nextMontessoriActivitiesActions.update({
        body,
        onFailed: callback,
        onSuccess: () => this.handleResetSuccess(callback),
        params: [id, { groups: nextStepApiGroups }],
      })
    }

    return nextStepsActions.update({
      body,
      onFailed: callback,
      onSuccess: () => this.handleResetSuccess(callback),
      params: [id, { groups: nextStepApiGroups }],
    })
  }

  handleMarkAsAchievedSuccess = () => {
    const { snackbarActions } = this.props

    snackbarActions.show({
      message: i18n.t('module:Learning:NextSteps:NextStepsList:markAsAchievedSuccess'),
    })
  }

  handleMarkAsAchieved = (nextStep) => {
    const { nextMontessoriActivitiesActions, nextStepsActions } = this.props
    const { nextStepApiGroups } = this.state
    const { id, montessoriActivity } = nextStep

    const body = {
      achievedAt: moment().toISOString(),
    }

    if (montessoriActivity) {
      return nextMontessoriActivitiesActions.update({
        body,
        onSuccess: this.handleMarkAsAchievedSuccess,
        params: [id, { groups: nextStepApiGroups }],
      })
    }

    return nextStepsActions.update({
      body,
      onSuccess: this.handleMarkAsAchievedSuccess,
      params: [id, { groups: nextStepApiGroups }],
    })
  }

  handleSubmitAchieved = (nextStep, values, callback) => {
    const { nextMontessoriActivitiesActions, nextStepsActions } = this.props
    const { nextStepApiGroups } = this.state
    const { id, montessoriActivity } = nextStep

    const body = {
      achievedAt: values?.date ? moment(values.date).toISOString() : moment().toISOString(),
    }

    if (montessoriActivity) {
      return nextMontessoriActivitiesActions.update({
        body,
        onFailed: callback,
        onSuccess: () => this.handleSubmitSuccess(callback),
        params: [id, { groups: nextStepApiGroups }],
      })
    }

    return nextStepsActions.update({
      body,
      onFailed: callback,
      onSuccess: () => this.handleSubmitSuccess(callback),
      params: [id, { groups: nextStepApiGroups }],
    })
  }

  render() {
    const {
      errorMessages,
      isLoading,
      nextSteps,
      paginationUtils,
      singleItemIsFetching,
      totalResults,
    } = this.props
    const { filters } = this.state
    const { getPageCount, page, perPage } = paginationUtils
    const pageCount = getPageCount(totalResults)

    return (
      <NextStepsListView
        errorMessages={errorMessages}
        filters={filters}
        isChildContext={this.isChildContext()}
        isLoading={isLoading}
        nextSteps={nextSteps}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        singleItemIsFetching={singleItemIsFetching}
        totalResults={totalResults}
        onDateChange={this.handleDateChange}
        onFilterChange={this.handleFilterChange}
        onMarkAsAchieved={this.handleMarkAsAchieved}
        onPageChange={this.handlePageChange}
        onReset={this.handleReset}
        onSubmitAchieved={this.handleSubmitAchieved}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  nextMontessoriActivitiesSingleState,
  nextStepsListState,
  nextStepsSelectors,
  nextStepsSingleState,
  securitySelectors,
}) => ({
  currentUser: securitySelectors.getAuthUser(state),
  currentUserIsSuperAdmin: securitySelectors.isSuperAdmin(state),
  errorMessages: appSelectors.getErrorMessages(
    nextStepsListState,
    nextStepsSingleState,
    nextMontessoriActivitiesSingleState,
  ),
  isLoading: appSelectors.getIsFetching(nextStepsListState),
  isMontessori: securitySelectors.isMontessori(state),
  nextSteps: nextStepsSelectors.getNextStepsListWithMontessori(state),
  singleItemIsFetching: appSelectors.getIsFetching(nextStepsSingleState, nextMontessoriActivitiesSingleState),
  totalResults: appSelectors.getTotalResults(nextStepsListState),
})

const enhance = compose(
  withAppService,
  withNextMontessoriActivitiesService,
  withNextStepsService,
  withPaginationUtils,
  withRouter,
  withRouterUtils,
  withSnackbarService,
  withSecurityService,
  connect(mapState),
)

export default enhance(NextStepsListContainer)
