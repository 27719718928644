import i18n from 'translations'

export const COLUMNS = [
  {
    align: 'left',
    field: 'child',
    sortKey: 'child.firstName',
    sortable: true,
    title: i18n.t('module:Modals:DailyDiary:TableHeaders:child'),
    width: '45%',
  },
  {
    field: 'sent',
    title: i18n.t('module:Modals:DailyDiary:TableHeaders:sent'),
    width: '15%',
  },
  {
    field: 'lastSentAt',
    title: i18n.t('module:Modals:DailyDiary:TableHeaders:lastSentOn'),
    width: '25%',
  },
  {
    field: 'send',
    title: i18n.t('module:Modals:DailyDiary:TableHeaders:send'),
    width: '15%',
  },
]
