import _ from 'lodash'
import { nest } from 'utils/flatnest'

import { createSelector } from 'reselect'

import { ERROR_VERSION, RESPONSE } from 'constants/http'

import { getAuthNursery } from 'services/security/selectors'

import i18n from 'translations'

const getNurseryId = (state, params) => params && params.nurseryId

export const getTotalResults = (state): number => {
  if (!state || !state.meta) {
    return 0
  }

  return state.meta.total_results || 0
}

export const getTotalUnfilteredResults = (state) => {
  if (!state || !state.meta) {
    return 0
  }

  return state.meta.totalUnfilteredResults || 0
}

export const getIsFetching = (...states) => {
  const flags = states.map((state) => state.isFetching)

  return !!flags.find((flag) => flag)
}

export const getIsSubmitting = (...states) => {
  const flags = states.map((state) => state.isSubmitting)

  return !!flags.find((flag) => flag)
}

const getErrorBasicMessage = (state, labels?: Record<string, string>[]) => {
  if (!state?.error) {
    return null
  }

  if (RESPONSE.HTTP_500_INTERNAL_SERVER_ERROR === state?.error?.code) {
    return i18n.t('global:ServiceError')
  }

  if (!state?.error?.extra) {
    return state.error.message
  }

  if (ERROR_VERSION.V2 === state.error?.version) {
    const errors = []

    _.forEach(state.error.extra, (item) => {
      _.forEach(item, ({ message, payload }) => {
        if (!payload) {
          errors.push(message)
        }
      })
    })

    return errors
  }

  const renderLabel = (key: string) => {
    const label = labels?.[key]

    if (label) {
      return label
    }

    return _.startCase(key)
  }

  return _.join(_.map(state.error.extra, (value, key) => `${renderLabel(key)}: ${value}`), '\n')
}

export const getErrorMessage = (state) => getErrorBasicMessage(state)

export const getErrorMassagesWithLabels = (states = [], labels) => {
  const errors = _.compact(states.map((state) => getErrorBasicMessage(state, labels)))

  if (!errors.length) {
    return null
  }

  return errors
}

export const getErrorMessages = (...states) => {
  const errors = _.compact(states.map(getErrorMessage))

  if (!errors.length) {
    return null
  }

  return errors
}

const getCustomCodes = (state) => {
  if (!state?.error) {
    return null
  }

  const errors = []

  if (ERROR_VERSION.V2 === state.error?.version) {
    _.forEach(state.error.extra, (item) => {
      _.forEach(item, ({ payload }) => {
        if (payload?.code) {
          errors.push(payload.code)
        }
      })
    })
  }

  return errors
}

export const getErrorMessagesCustomCodes = (...states) => {
  const errors = _.compact(states.map(getCustomCodes))

  if (!errors.length) {
    return null
  }

  return errors
}

export const getIsInitialized = (state) => !!state.data

export const getContextNurseryRouterConfig = createSelector(
  [getNurseryId, getAuthNursery],
  (nurseryId, authNursery) => ({
    id: authNursery ? authNursery.id : nurseryId,
    prefix: authNursery ? '/management' : `/nursery/${nurseryId}/management`,
  }),
)

export const getFieldValidationFromError = (error, options) => {
  if (!error || !error.extra) {
    return null
  }

  const { doNotSplitKeys = false } = options || {}

  if (doNotSplitKeys) {
    return nest(error.extra)
  }

  return _.mapKeys(error.extra, (value, key) => {
    if (!value) {
      return null
    }

    return key.split('.').pop()
  })
}
