import _ from 'lodash'

import { createSelector } from 'reselect'

import { getNurseryFrameworkRelationsListDataState } from 'services/legacy/nurseryFrameworkRelations/selectors'

import i18n from 'translations'

const getFrameworks = (state) => state.frameworks

export const getFrameworksLegacyListState = createSelector([getFrameworks], (state) => state.legacyList)

export const getFrameworksLegacyListDataState = createSelector([getFrameworksLegacyListState], (state) => state.data)

export const getFrameworksLegacyListDataWithMontessori = createSelector(
  [getFrameworksLegacyListDataState],
  (data) => {
    if (!data?.length) {
      return null
    }

    return [
      ...data,
      {
        id: 0,
        isMontessori: true,
        name: i18n.t('global:Montessori'),
      },
    ]
  },
)

export const getFrameworksLegacyListWithEnabledFlag = createSelector(
  [getFrameworksLegacyListDataWithMontessori, getNurseryFrameworkRelationsListDataState],
  (frameworks, nurseryRelations) => {
    if (!frameworks?.length) {
      return null
    }

    const isMontessoriRelationFound = _.find(nurseryRelations, (relation) => 0 === relation?.id)

    return _.map(frameworks, (item) => {
      const nurseryRelationRecord = _.find(nurseryRelations, (relation) => relation?.framework?.id === item.id)

      if (item.isMontessori) {
        return {
          ...item,
          enabled: isMontessoriRelationFound?.enabled || false,
        }
      }

      return {
        ...item,
        enabled: nurseryRelationRecord?.enabled,
        nurseryRelationId: nurseryRelationRecord?.id,
      }
    })
  },
)
