import {
  GET_LEAVE_TYPES_DROPDOWN_ITEM,
  GET_LEAVE_TYPES_DROPDOWN_ITEM_FAILED,
  GET_LEAVE_TYPES_DROPDOWN_ITEM_SUCCESS,
  LEAVE_TYPES_DROPDOWN_LIST,
  LEAVE_TYPES_DROPDOWN_LIST_FAILED,
  LEAVE_TYPES_DROPDOWN_LIST_SUCCESS,
} from './constants'

export const dropdownList = (options) => ({ leaveTypesApiClient }) => async (dispatch) => {
  const { onSuccess, params = {} } = options

  dispatch({ payload: null, type: LEAVE_TYPES_DROPDOWN_LIST })

  await leaveTypesApiClient.list(params).then(
    (response) => {
      dispatch({
        type: LEAVE_TYPES_DROPDOWN_LIST_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: LEAVE_TYPES_DROPDOWN_LIST_FAILED,
      })
    },
  )
}

export const getDropdownItem = (id, options) => ({ leaveTypesApiClient }) => (dispatch) => {
  const { onFailed, onSuccess } = options

  dispatch({
    type: GET_LEAVE_TYPES_DROPDOWN_ITEM,
  })

  leaveTypesApiClient.get(id).then(
    (response) => {
      dispatch({
        type: GET_LEAVE_TYPES_DROPDOWN_ITEM_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        type: GET_LEAVE_TYPES_DROPDOWN_ITEM_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}
