import { Property } from 'csstype'

import React, { PropsWithChildren, useEffect, useRef } from 'react'

import layout from 'constants/layout'
import { IS_HEADER_PINNED_LS } from 'services/shell/constants'
import { CALCULATE_STICKY_POSITION_DATA_TABLE_ROW } from 'components/DataTable/DataTable'

import eventBus from 'utils/eventBus'

import { StyledRow } from './DataTableStyled'

interface DataTableRowProps {
  alignItems?: Property.AlignItems
  background?: string
  border?: string
  borderTransparent?: boolean
  column?: boolean
  darkGray?: boolean
  full?: boolean
  fullWidth?: boolean
  gray?: boolean
  height?: number
  justifyContent?: Property.JustifyContent
  marginBottom?: boolean
  minWidth?: number
  noBorder?: boolean
  noPadding?: boolean
  onClick?: () => void
  padding?: string
  quinary?: boolean
  rows?: number
  stickyElement?: boolean
  style?: any
  textAlign?: Property.TextAlign
  width?: number
}

const DataTableRow: React.FC<PropsWithChildren<DataTableRowProps>> = ({
  alignItems,
  background,
  border,
  borderTransparent,
  children,
  column,
  darkGray,
  full,
  fullWidth,
  gray,
  height,
  justifyContent,
  marginBottom,
  minWidth,
  noBorder,
  noPadding,
  onClick,
  padding,
  quinary,
  rows,
  stickyElement,
  style,
  textAlign,
  width,
}) => {
  const originalElement = useRef()
  const stickyElementRef = useRef()

  const calculateStickyPosition = () => {
    // @ts-ignore
    const position = originalElement.current?.getBoundingClientRect()
    // @ts-ignore
    const parentDataTable = originalElement.current?.closest('[data-datatable]')
    const positionDataTable = parentDataTable?.getBoundingClientRect()
    let topOffset = 0

    if (position?.top && positionDataTable?.top && positionDataTable.top < position.top) {
      topOffset = position.top - positionDataTable.top
    }

    const isHeaderPinned = +window.localStorage.getItem(IS_HEADER_PINNED_LS)
    const isOriginalRowInViewport = !!position
      && (
        (isHeaderPinned ? layout.menuBarHeight : 0) + layout.topBarHeight <= (
          position.bottom - position.height - topOffset
        )
      )
      && 0 <= position.right
      && position.top <= document.documentElement.clientHeight
      && position.left <= document.documentElement.clientWidth

    if (!originalElement.current || isOriginalRowInViewport || position.top >= window.scrollY) {
      // @ts-ignore
      if (originalElement?.current?.style && 'none' !== stickyElementRef.current.style.display) {
        // @ts-ignore
        stickyElementRef.current.style.display = 'none'
      }

      return null
    }

    let show = false

    // @ts-ignore
    if ('flex' !== stickyElementRef.current.style.display) {
      show = true
    }

    // @ts-ignore
    if (show && stickyElementRef.current?.style) {
      // @ts-ignore
      stickyElementRef.current.style.display = 'flex'
    }

    // @ts-ignore
    stickyElementRef.current.style.top = (
      `${layout.topBarHeight + (isHeaderPinned ? layout.menuBarHeight : 0) + topOffset}px`
    )
    // @ts-ignore
    stickyElementRef.current.style.left = `${position.left}px`
    // @ts-ignore
    stickyElementRef.current.style.right = `${position.right}px`
    // @ts-ignore
    stickyElementRef.current.style.width = `${position.width}px`
    // @ts-ignore
    stickyElementRef.current.style.height = `${position.height}px`

    return false
  }

  useEffect(() => {
    if (stickyElement) {
      eventBus.on(CALCULATE_STICKY_POSITION_DATA_TABLE_ROW, calculateStickyPosition)
    }

    return () => {
      if (stickyElement) {
        eventBus.remove(CALCULATE_STICKY_POSITION_DATA_TABLE_ROW, calculateStickyPosition)
      }
    }
  }, [stickyElement])

  const renderRow = (isSticky?) => (
    <StyledRow
      $alignItems={alignItems}
      $background={background}
      $border={border}
      $borderTransparent={borderTransparent}
      $column={column}
      $darkGray={darkGray}
      $full={full}
      $fullWidth={fullWidth}
      $gray={gray}
      $height={height}
      $isSticky={isSticky}
      $justifyContent={justifyContent}
      $marginBottom={marginBottom}
      $minWidth={minWidth}
      $noBorder={noBorder}
      $noPadding={noPadding}
      $padding={padding}
      $quinary={quinary}
      $rows={rows}
      $textAlign={textAlign}
      $width={width}
      ref={isSticky ? stickyElementRef : originalElement}
      style={style}
      onClick={onClick}
    >
      {children}
    </StyledRow>
  )

  return (
    <React.Fragment>
      {renderRow()}
      {stickyElement && renderRow(true)}
    </React.Fragment>
  )
}

export default DataTableRow
