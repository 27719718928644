import React, { useEffect, useRef } from 'react'
import { Field, InjectedFormProps, change, formValueSelector, reduxForm } from 'redux-form'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { Option } from 'constants/models'
import { RootState } from 'core/reducers'

import { isRequired } from 'utils/fieldValidation'
import { convertPayloadToDisplayValue, toFloat } from 'utils/data'

import { Form } from 'components'

import i18n from 'translations'

import { LINE_ITEM_TYPES } from 'services/legacy/invoices/constants'
import { LineItemOptionProps } from '../AddInvoiceItemModalV3View'
import FeesItemFormFields, { FeesItemFormProps } from './FeesItemFormFields'
import DepositFormFields, { DepositFormProps } from './DepositFormFields'
import DepositRefundFormFields, { DepositRefundFormProps } from './DepositRefundFormFields'
import DiscountFormFields, { DiscountFormProps } from './DiscountFormFields'
import ExtraSessionFormFields from './ExtraSessionFormFields'
import ExtraItemFormFields from './ExtraItemFormFields'
import FundingFormFields from './FundingFormFields'

export const ADD_ITEM_MODAL_FORM = 'AddItemModalForm'

export interface AddInvoiceItemModalV3FormValues extends FeesItemFormProps,
  DepositFormProps,
  DepositRefundFormProps,
  DiscountFormProps {
    type?: Option
  }

interface AddInvoiceItemModalV3FormProps {
  childId?: string
  includeUsedDepositIds?: number[]
  isItemEdit?: boolean
  isLegacyItemSelected?: boolean
  isLoading?: boolean
  isReadOnly?: boolean
  lineItemOptions?: LineItemOptionProps[]
  onCancelClick: () => void
  onSubmit: (values: AddInvoiceItemModalV3FormValues) => void
  skipPendingDepositIds?: number[]
}

const mapState = (state: RootState) => ({
  customExtraItemChildProduct: formValueSelector(ADD_ITEM_MODAL_FORM)(state, 'customExtraItemChildProduct'),
  customExtraSessionChildProduct: formValueSelector(ADD_ITEM_MODAL_FORM)(state, 'customExtraSessionChildProduct'),
  customOneOffDiscountChildProduct: formValueSelector(ADD_ITEM_MODAL_FORM)(state, 'customOneOffDiscountChildProduct'),
  customOneOffFundingChildProduct: formValueSelector(ADD_ITEM_MODAL_FORM)(state, 'customOneOffFundingChildProduct'),
  deposit: formValueSelector(ADD_ITEM_MODAL_FORM)(state, 'deposit'),
  quantity: formValueSelector(ADD_ITEM_MODAL_FORM)(state, 'quantity'),
  type: formValueSelector(ADD_ITEM_MODAL_FORM)(state, 'type'),
  unitPrice: formValueSelector(ADD_ITEM_MODAL_FORM)(state, 'unitPrice'),
})

const mapDispatch = {
  updateField: (fieldName, fieldValue) => change(ADD_ITEM_MODAL_FORM, fieldName, fieldValue),
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type AddInvoiceItemModalV3FormFullProps = AddInvoiceItemModalV3FormProps
  & InjectedFormProps<{}, AddInvoiceItemModalV3FormProps>
  & PropsFromRedux

const AddInvoiceItemModalV3Form: React.FC<AddInvoiceItemModalV3FormFullProps> = ({
  childId,
  customExtraItemChildProduct,
  customExtraSessionChildProduct,
  customOneOffDiscountChildProduct,
  customOneOffFundingChildProduct,
  deposit,
  handleSubmit,
  includeUsedDepositIds,
  isItemEdit,
  isLegacyItemSelected,
  isLoading,
  isReadOnly,
  lineItemOptions,
  onCancelClick,
  onSubmit,
  quantity,
  skipPendingDepositIds,
  type,
  unitPrice,
  updateField,
}) => {
  const allowInitialRendering = useRef(!isItemEdit)
  const prevType = useRef()
  const depositValue = deposit?.value
  const customOneOffDiscountChildProductValue = customOneOffDiscountChildProduct?.value
  const customOneOffFundingChildProductValue = customOneOffFundingChildProduct?.value
  const customExtraSessionChildProductValue = customExtraSessionChildProduct?.value
  const customExtraItemChildProductValue = customExtraItemChildProduct?.value

  useEffect(() => {
    if (type && prevType.current !== type?.value && allowInitialRendering.current) {
      updateField('customOneOffFundingChildProduct', null)
      updateField('customExtraSessionChildProduct', null)
      updateField('customExtraItemChildProduct', null)
      updateField('customOneOffDiscountChildProduct', null)
      updateField('deposit', null)
      updateField('unitPrice', null)
      updateField('name', null)
      updateField('unitPrice', null)
      updateField('quantity', null)
      updateField('total', null)

      prevType.current = type.value
    }
  }, [type?.value])

  useEffect(() => {
    if (allowInitialRendering.current) {
      if (type.value === LINE_ITEM_TYPES.EXTRA_SESSION_CHILD_PRODUCT && customExtraSessionChildProductValue) {
        updateField('total', convertPayloadToDisplayValue(customExtraSessionChildProduct.price?.total))
        updateField('quantity', customExtraSessionChildProduct.quantity || 1)
        updateField('unitPrice',
          convertPayloadToDisplayValue(customExtraSessionChildProduct.price?.total)
          / customExtraSessionChildProduct.quantity || 1,
        )
      }

      if (type.value === LINE_ITEM_TYPES.EXTRA_ITEM_CHILD_PRODUCT && customExtraItemChildProductValue) {
        updateField('total', convertPayloadToDisplayValue(customExtraItemChildProduct.price?.total))
        updateField('quantity', customExtraItemChildProduct.quantity || 1)
      }

      if ((
        type.value === LINE_ITEM_TYPES.ITEM
        || type.value === LINE_ITEM_TYPES.OTHER
      ) && unitPrice && quantity) {
        const parsedUnitPrice = toFloat(unitPrice) || 0
        const parsedQuantity = toFloat(quantity) || 0
        const total = toFloat(parsedUnitPrice * parsedQuantity)

        updateField('total', total)
      }

      if ((type.value === LINE_ITEM_TYPES.DEPOSIT || type.value === LINE_ITEM_TYPES.REFUND_DEPOSIT) && depositValue) {
        updateField('total', Math.abs(deposit.amount))
      }

      if (type.value === LINE_ITEM_TYPES.ONE_OFF_DISCOUNT && customOneOffDiscountChildProductValue) {
        updateField('total', convertPayloadToDisplayValue(
          Math.abs(customOneOffDiscountChildProduct?.product?.settings?.value)),
        )
      }

      if (type.value === LINE_ITEM_TYPES.ONE_OFF_FUNDING && customOneOffFundingChildProductValue) {
        updateField('total', convertPayloadToDisplayValue(
          Math.abs(customOneOffFundingChildProduct?.product?.settings?.total)),
        )
      }
    } else {
      allowInitialRendering.current = true
    }
  }, [
    type?.value,
    unitPrice,
    quantity,
    depositValue,
    customOneOffDiscountChildProductValue,
    customOneOffFundingChildProductValue,
    customExtraSessionChildProductValue,
    customExtraItemChildProductValue,
  ])

  const renderFormSection = () => {
    switch (type?.value) {
      case LINE_ITEM_TYPES.ITEM:
        return (
          <FeesItemFormFields
            isReadOnly={isReadOnly}
            showCost={type?.showCost}
          />
        )

      case LINE_ITEM_TYPES.DEPOSIT:
        return (
          <DepositFormFields
            childId={childId}
            includeUsedDepositIds={includeUsedDepositIds}
            isReadOnly={isReadOnly}
            skipPendingDepositIds={skipPendingDepositIds}
          />
        )

      case LINE_ITEM_TYPES.REFUND_DEPOSIT:
        return (
          <DepositRefundFormFields
            childId={childId}
            isReadOnly={isReadOnly}
          />
        )

      case LINE_ITEM_TYPES.EXTRA_SESSION_CHILD_PRODUCT:
        return (
          <ExtraSessionFormFields
            childId={childId}
            isReadOnly={isReadOnly}
          />
        )

      case LINE_ITEM_TYPES.EXTRA_ITEM_CHILD_PRODUCT:
        return (
          <ExtraItemFormFields
            childId={childId}
            isReadOnly={isReadOnly}
          />
        )

      case LINE_ITEM_TYPES.ONE_OFF_DISCOUNT:
        return (
          <DiscountFormFields
            childId={childId}
            isReadOnly={isReadOnly}
          />
        )

      case LINE_ITEM_TYPES.ONE_OFF_FUNDING:
        return (
          <FundingFormFields
            childId={childId}
            isReadOnly={isReadOnly}
          />
        )

      default:
        return (
          <FeesItemFormFields
            isReadOnly={isReadOnly}
            showCost={type?.showCost}
          />
        )
    }
  }

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Field
        component={Form.TextField}
        name="id"
        type="hidden"
      />
      <Form.Row
        label={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:ItemType:label')}
        required
      >
        <Form.Row.Item>
          <Field
            clearable={false}
            component={Form.Select}
            disabled={isReadOnly || isLegacyItemSelected}
            maxMenuHeight={200}
            name="type"
            options={lineItemOptions}
            placeholder={i18n.t('module:Modals:Invoice:AddInvoiceItemModal:Form:ItemType:placeholder')}
            searchable={false}
            validate={isRequired}
          />
        </Form.Row.Item>
      </Form.Row>
      {renderFormSection()}
      <Form.FooterActions
        isSubmitting={isLoading}
        submitDisabled={isReadOnly}
        submitLabel={isItemEdit ? i18n.t('global:Update') : i18n.t('global:AddItem')}
        onCancelClick={onCancelClick}
      />
    </Form>
  )
}

const enhance = compose(
  connector,
)

export default reduxForm<{}, AddInvoiceItemModalV3FormProps>({ form: ADD_ITEM_MODAL_FORM })(
  enhance(AddInvoiceItemModalV3Form),
)
