import _ from 'lodash'
import { combineReducers } from 'redux'

import { mergeData } from 'utils/data'

import global from './global/reducer'
import {
  CLEAR_REGISTER,
  CREATE_REGISTER,
  CREATE_REGISTER_FAILED,
  CREATE_REGISTER_SUCCESS,
  UPDATE_REGISTER,
  UPDATE_REGISTER_FAILED,
  UPDATE_REGISTER_SUCCESS,
} from './single/constants'
import {
  ADD_OR_UPDATE_ABSENCE_TO_LIST,
  IMPORT_GLOBAL_LIST,
  LIST_REGISTERS,
  LIST_REGISTERS_BY_CHILD,
  LIST_REGISTERS_BY_CHILD_FAILED,
  LIST_REGISTERS_BY_CHILD_SUCCESS,
  LIST_REGISTERS_FAILED,
  LIST_REGISTERS_SUCCESS,
  LOAD_TODAY_DATA,
  REMOVE_ABSENCE_FROM_LIST,
  REMOVE_ITEM_FROM_LIST,
  RESET_LIST_ITEM,
} from './list/constants'

import {
  addOrUpdateAbsenceToList,
  getUpdateRegisterData,
  getUpdateRegisterSuccessData,
  removeAbsenceFromList,
  removeItemFromList,
  resetListItem,
} from './helpers'

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  meta: null,
  offlineTodayData: null,
}

const commonReducer = (state = initialState, {
  absenceResponse,
  childId,
  error,
  id,
  isAdd,
  isChildRegister,
  mergeResult,
  payload,
  resetResponse,
  silent,
  type,
}) => {
  switch (type) {
    case LIST_REGISTERS:
    case LIST_REGISTERS_BY_CHILD:
      return { ...state, error: initialState.error, isFetching: !silent }
    case LIST_REGISTERS_SUCCESS:
    case LIST_REGISTERS_BY_CHILD_SUCCESS:
      return {
        ...state,
        data: mergeData(state.data, payload.data, mergeResult),
        isFetching: false,
        meta: payload.meta,
      }
    case LIST_REGISTERS_FAILED:
    case LIST_REGISTERS_BY_CHILD_FAILED:
      return { ...state, error, isFetching: false }
    case CREATE_REGISTER:
      return {
        ...state,
        data: getUpdateRegisterData(state, childId, id),
        error: initialState.error,
      }
    case CREATE_REGISTER_SUCCESS:
      return {
        ...state,
        data: getUpdateRegisterSuccessData(state, childId, id, payload, isChildRegister),
        error,
      }
    case CREATE_REGISTER_FAILED:
      return {
        ...state,
        data: (state.data || []).map((child) => {
          if (child.id === childId) {
            return {
              ...child,
              isSubmitting: false,
            }
          }

          return child
        }),
        error,
      }
    case UPDATE_REGISTER:
      return {
        ...state,
        data: getUpdateRegisterData(state, childId, id),
        error: initialState.error,
      }
    case UPDATE_REGISTER_SUCCESS:
      return {
        ...state,
        data: getUpdateRegisterSuccessData(state, childId, id, payload, isChildRegister),
        error,
      }
    case UPDATE_REGISTER_FAILED:
      return {
        ...state,
        data: (state.data || []).map((child) => {
          if (child.id === childId) {
            return {
              ...child,
              isSubmitting: false,
            }
          }

          return child
        }),
        error,
      }
    case ADD_OR_UPDATE_ABSENCE_TO_LIST:
      return {
        ...state,
        data: addOrUpdateAbsenceToList(state, childId, absenceResponse),
        error: initialState.error,
        meta: {
          ...state.meta,
          // eslint-disable-next-line camelcase
          total_scheduled: (state?.meta?.total_scheduled || 0) - (isAdd ? 1 : 0),
        },
      }
    case REMOVE_ABSENCE_FROM_LIST:
      return {
        ...state,
        data: removeAbsenceFromList(state, childId, absenceResponse),
        error: initialState.error,
        meta: {
          ...state.meta,
          // eslint-disable-next-line camelcase
          total_scheduled: (state?.meta?.total_scheduled || 0) + 1,
        },
      }
    case RESET_LIST_ITEM:
      return {
        ...state,
        data: resetListItem(state, childId, id, resetResponse),
        error: initialState.error,
      }
    case REMOVE_ITEM_FROM_LIST:
      return {
        ...state,
        data: removeItemFromList(state, childId),
        error: initialState.error,
      }
    case IMPORT_GLOBAL_LIST: {
      const { isToday, payload: data } = payload

      if (isToday) {
        return {
          ...state,
          ...data,
          data: _.map(data.data, (item) => {
            const originalItem = _.find(state.data, (subItem) => subItem.id === item.id)

            if (originalItem?.date && +originalItem.date > +item.date) {
              return originalItem
            }

            return item
          }),
        }
      }

      return {
        ...state,
        offlineTodayData: data,
      }
    }
    case LOAD_TODAY_DATA: {
      return {
        ...state,
        data: state.offlineTodayData.data,
        offlineTodayData: null,
      }
    }
    case CLEAR_REGISTER:
      return initialState
    default:
      return state
  }
}

export default combineReducers({
  common: commonReducer,
  global,
})
