import _ from 'lodash'
import { flatten } from 'utils/flatnest'

import React from 'react'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { StatisticProps } from 'components/OverallStatistics/OverallStatistics'
import { TableColumn } from 'components/Table/Table'

import { toFloat } from 'utils/data'

import { Currency, DateString, ReportView } from 'components'

import i18n from 'translations'

import {
  ChildrenSignedUpReportBasicData,
  ChildrenSignedUpReportChildrenData,
} from 'services/childrenSignedUpReport/models'

import { GetTableDataParamProps } from 'hooks/reports/useBasicReport'
import { NURSERY_TABLE_COLUMNS, ORGANIZATION_TABLE_COLUMNS } from 'module/Reports/ChildrenSignedUp/constants'
import { TableDataReport } from '../models'

export interface TableDataProps extends TableDataReport {
  signups: number
}

export const getColumns = (isOrganizationContext: boolean): TableColumn[] => {
  if (isOrganizationContext) {
    return ORGANIZATION_TABLE_COLUMNS
  }

  return NURSERY_TABLE_COLUMNS
}

export const getTableData = ({
  data,
  filters,
  hasError,
  isOrganizationContext,
}: GetTableDataParamProps<ChildrenSignedUpReportChildrenData[]>) => {
  if (!data?.length || hasError) {
    return null
  }

  const queryString = new URLSearchParams(flatten({
    age: filters?.age,
    date: filters?.date ? {
      after: filters.date.after.format(DEFAULT_DATE_FORMAT),
      before: filters.date.before.format(DEFAULT_DATE_FORMAT),
    } : undefined,
  }) as any).toString()

  return _.map(data, ({
    depositPaid,
    fte,
    resource,
    signups,
    weeklyFee,
  }): TableDataProps => ({
    ageMonths: i18n.t('global:ageMonths', { ageMonths: resource.ageMonths }),
    codeReference: resource.codeReference,
    depositPaid: <Currency value={depositPaid} />,
    dob: <DateString date={resource.birthDate} />,
    fte: toFloat(fte),
    leavingDate: <DateString date={resource.leavingDate} />,
    name: isOrganizationContext
      ? (
        <ReportView.NurseryLink
          nursery={resource}
          queryString={queryString}
          routeKey="REPORTS.CHILDREN_SIGNED_UP"
        />
      )
      : resource.name,
    registeredAt: <DateString date={resource.registeredAt} />,
    roomName: resource?.nurseryClass?.name,
    signups,
    startDate: <DateString date={resource.startDate} />,
    weeklyFee: <Currency value={weeklyFee} />,
  }))
}

export const getStatistics = (
  {
    data,
    hasError,
    isOrganizationContext,
  }: GetTableDataParamProps<ChildrenSignedUpReportBasicData>,
): StatisticProps[] => {
  if (!data || hasError) {
    return null
  }

  if (isOrganizationContext) {
    return [
      {
        label: i18n.t('module:Reports:ChildrenSignedUp:Statistics:totalSignups'),
        value: data.signups,
      },
      {
        label: i18n.t('module:Reports:Common:totalFte'),
        value: toFloat(data.fte),
      },
      {
        label: i18n.t('module:Reports:Common:totalWeeklyFee'),
        value: <Currency value={data.weeklyFee} />,
      },
    ]
  }

  return [
    {
      label: i18n.t('module:Reports:ChildrenSignedUp:Statistics:totalSignups'),
      value: data.signups,
    },
    {
      label: i18n.t('module:Reports:Common:totalFte'),
      value: toFloat(data.fte),
    },
    {
      label: i18n.t('module:Reports:ChildrenSignedUp:Statistics:totalDepositPaid'),
      value: <Currency value={data.depositPaid} />,
    },
    {
      label: i18n.t('module:Reports:Common:totalWeeklyFee'),
      value: <Currency value={data.weeklyFee} />,
    },
  ]
}

