import moment from 'moment'

import React from 'react'
import { InjectedFormProps, reduxForm } from 'redux-form'

import { Option } from 'constants/models'
import { FeeCalculationType } from 'services/booking/childBooking/constants'

import { Banner, Button, Callout, FooterActions, Form, Page, Spinner, StagesIndicator, StickyFooter } from 'components'

import i18n from 'translations'

import { TAB_SECTIONS } from '../../RegularBookingsAddContainer'
import SessionAndRegularItems from './components/SessionAndRegularItems/SessionAndRegularItems'
import Funding from './components/Funding'
import Discounts from './components/Discounts'

export const REGULAR_BOOKINGS_ADD_FORM = 'RegularBookingsAddForm'

export interface RegularBookingsAddFormValues {
  alternates?: number
  attendancePeriod?: Option
  childProducts?: any[]
  endDate?: moment.Moment
  id?: number
  settings?: {
    calculationMonths?: number
    calculationWeeks?: number
    excludedMonths?: Option[]
    feeCalculation?: FeeCalculationType
    packageLineItemDeduction?: string
    packageLineItemDisplay?: string
    packageLineItemLevelOfDetail?: string
    packageLineItemName?: string
    packagePrice?: number
  }
  startDate?: moment.Moment
  untilChildLeaves?: boolean
}

export interface RegularBookingsAddFormProps {
  activeTab: number
  childLeavingDate?: Date
  disabledEditing: boolean
  errors: string[]
  existChildProductAddedToNotOpenedDay: boolean
  formValues?: RegularBookingsAddFormValues
  hasAccessToViewPrice?: boolean
  isEdit?: boolean
  isFetching?: boolean
  isSubmitting?: boolean
  notOpenedDaysWithAddedChildProduct: string[]
  onAddDiscount: (index: number) => void
  onAddFunding: (index: number) => void
  onAddRegularItem: (index: number) => void
  onAddSession: (alternate: number) => void
  onCancel: () => void
  onChangeActiveTab: any
  onChangeAlternateWeeks: (option: Option) => void
  onChangeUntilChildLeaves: (value: boolean) => void
  onEditFunding: (id: string) => void
  onGoNextTab?: () => void
  onRecalculateAllocations: () => void
  onRemoveChildProduct: (id: string) => void
  onSubmit: (values: any) => void
  onValidFailed: () => void
}

type RegularBookingsAddFormFullProps = InjectedFormProps<{}, RegularBookingsAddFormProps> & RegularBookingsAddFormProps

const RegularBookingsAddForm: React.FC<RegularBookingsAddFormFullProps> = ({
  activeTab,
  childLeavingDate,
  disabledEditing,
  errors,
  existChildProductAddedToNotOpenedDay,
  formValues,
  handleSubmit,
  hasAccessToViewPrice,
  isEdit,
  isFetching,
  isSubmitting,
  notOpenedDaysWithAddedChildProduct,
  onAddDiscount,
  onAddFunding,
  onAddRegularItem,
  onAddSession,
  onCancel,
  onChangeActiveTab,
  onChangeAlternateWeeks,
  onChangeUntilChildLeaves,
  onEditFunding,
  onGoNextTab,
  onRecalculateAllocations,
  onRemoveChildProduct,
  onSubmit,
  onValidFailed,
  valid,
}) => {
  const STAGES = [
    {
      label: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:sessionAndRegularItems'),
    },
    {
      disabled: !formValues?.startDate || !formValues?.attendancePeriod,
      label: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:funding'),
    },
    {
      disabled: !formValues?.startDate || !formValues?.attendancePeriod,
      label: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:discounts'),
    },
  ]

  const renderFooter = () => (
    <StickyFooter>
      <Button
        disabled={isSubmitting}
        hierarchy="tertiary"
        label={i18n.t('global:Cancel')}
        onClick={onCancel}
      />
      <FooterActions.Flex />
      <Button
        disabled={(disabledEditing && activeTab === TAB_SECTIONS.DISCOUNTS) || isSubmitting}
        isLoading={isSubmitting}
        label={
          activeTab === TAB_SECTIONS.DISCOUNTS
            ? i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:saveBookingPattern')
            : i18n.t('global:Continue')
        }
        submit
      />
    </StickyFooter>
  )

  const renderContent = () => {
    if (isFetching) {
      return (
        <Spinner />
      )
    }

    return (
      <Form
        noValidate
        // @ts-ignore
        onSubmit={(e) => {
          onGoNextTab()

          if (!valid) {
            onValidFailed()
          }

          return handleSubmit(onSubmit)(e)
        }}
      >
        {disabledEditing && (
          <Banner.Info>
            {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:existInvoices')}
          </Banner.Info>
        )}
        <Callout content={errors} error />
        {existChildProductAddedToNotOpenedDay && (
          <Banner.Warning>
            {i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:existChildProductAddedToNotOpenedDay', {
              weekdays: notOpenedDaysWithAddedChildProduct.join(', '),
            })}
          </Banner.Warning>
        )}
        <StagesIndicator
          currentStage={activeTab}
          disabled={isSubmitting}
          stages={STAGES}
          onStageChange={onChangeActiveTab}
        />
        {activeTab === TAB_SECTIONS.SESSION && (
          <SessionAndRegularItems
            childLeavingDate={childLeavingDate}
            disabledEditing={disabledEditing}
            formValues={formValues}
            hasAccessToViewPrice={hasAccessToViewPrice}
            isSubmitting={isSubmitting}
            onAddRegularItem={onAddRegularItem}
            onAddSession={onAddSession}
            onChangeAlternateWeeks={onChangeAlternateWeeks}
            onChangeUntilChildLeaves={onChangeUntilChildLeaves}
            onRecalculateAllocations={onRecalculateAllocations}
            onRemoveChildProduct={onRemoveChildProduct}
          />
        )}
        {activeTab === TAB_SECTIONS.FUNDING && (
          <Funding
            disabledEditing={disabledEditing}
            formValues={formValues}
            isEdit={isEdit}
            isSubmitting={isSubmitting}
            onAddFunding={onAddFunding}
            onEditFunding={onEditFunding}
            onRemoveChildProduct={onRemoveChildProduct}
          />
        )}
        {activeTab === TAB_SECTIONS.DISCOUNTS && (
          <Discounts
            disabledEditing={disabledEditing}
            formValues={formValues}
            hasAccessToViewPrice={hasAccessToViewPrice}
            isSubmitting={isSubmitting}
            onAddDiscount={onAddDiscount}
            onRemoveChildProduct={onRemoveChildProduct}
          />
        )}
        {renderFooter()}
      </Form>
    )
  }

  return (
    <Page.Section>
      {renderContent()}
    </Page.Section>
  )
}

export default reduxForm<{}, RegularBookingsAddFormProps>({
  form: REGULAR_BOOKINGS_ADD_FORM,
})(RegularBookingsAddForm)
