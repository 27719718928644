import {
  CREATE_BATCH_MEMBERSHIPS_SHIFTS,
  CREATE_BATCH_MEMBERSHIPS_SHIFTS_FAILED,
  CREATE_BATCH_MEMBERSHIPS_SHIFTS_SUCCESS,
  EXPORT_MEMBERSHIPS_SHIFTS,
  EXPORT_MEMBERSHIPS_SHIFTS_FAILED,
  EXPORT_MEMBERSHIPS_SHIFTS_SUCCESS,
  GET_MEMBERSHIPS_LIST_SHIFT,
  GET_MEMBERSHIPS_LIST_SHIFT_FAILED,
  GET_MEMBERSHIPS_LIST_SHIFT_SUCCESS,
} from './constants'

export const list = (options = {}) => ({ membershipsShiftsApiClient }) => async (dispatch) => {
  const { mergeResult, onFailed, onSuccess, onlyData, params } = options

  if (!onlyData) {
    dispatch({ type: GET_MEMBERSHIPS_LIST_SHIFT })
  }

  return membershipsShiftsApiClient.list(params).then(
    (response) => {
      if (!onlyData) {
        dispatch({
          mergeResult,
          payload: response,
          type: GET_MEMBERSHIPS_LIST_SHIFT_SUCCESS,
        })
      }

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      if (!onlyData) {
        dispatch({
          error: response,
          type: GET_MEMBERSHIPS_LIST_SHIFT_FAILED,
        })
      }

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const listGroupedByShifts = (options = {}) => ({ membershipsShiftsApiClient }) => async (dispatch) => {
  const { mergeResult, onFailed, onSuccess, onlyData, params } = options

  if (!onlyData) {
    dispatch({ type: GET_MEMBERSHIPS_LIST_SHIFT })
  }

  return membershipsShiftsApiClient.listGroupedByShifts(params).then(
    (response) => {
      if (!onlyData) {
        dispatch({
          mergeResult,
          payload: response,
          type: GET_MEMBERSHIPS_LIST_SHIFT_SUCCESS,
        })
      }

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      if (!onlyData) {
        dispatch({
          error: response,
          type: GET_MEMBERSHIPS_LIST_SHIFT_FAILED,
        })
      }

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const createBatch = (options = {}) => ({ membershipsShiftsApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params, payload = {} } = options
  dispatch({ type: CREATE_BATCH_MEMBERSHIPS_SHIFTS })

  return membershipsShiftsApiClient.createBatch(payload, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: CREATE_BATCH_MEMBERSHIPS_SHIFTS_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_BATCH_MEMBERSHIPS_SHIFTS_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const exportReport = (type, options = {}) => ({ membershipsShiftsApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {}, payload = {} } = options

  dispatch({ type: EXPORT_MEMBERSHIPS_SHIFTS })

  membershipsShiftsApiClient.exportReport(type, params, payload).then(
    () => {
      dispatch({ type: EXPORT_MEMBERSHIPS_SHIFTS_SUCCESS })

      if (onSuccess) {
        onSuccess()
      }
    },
    ({ response }) => {
      dispatch({ type: EXPORT_MEMBERSHIPS_SHIFTS_FAILED })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}
