import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'
import { RootState } from 'core/reducers'

import { injectActions } from 'utils/service'

import actions from './actions'
import selectors from './selectors'

export interface withNurseryPaymentTypesServiceProps {
  nurseryPaymentTypesActions: typeof actions
  nurseryPaymentTypesListState: any
  nurseryPaymentTypesSelectors: typeof selectors
  nurseryPaymentTypesSingleState: any
}

const withNurseryPaymentTypesService = <P extends withNurseryPaymentTypesServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ nurseryPaymentTypes }: RootState) => ({ nurseryPaymentTypes })

  const mapDispatch = injectActions('nurseryPaymentTypesActions', actions)

  const Component = (props) => {
    const { nurseryPaymentTypes, nurseryPaymentTypesActions, ...other } = props

    return (
      <WrappedComponent
        nurseryPaymentTypesActions={nurseryPaymentTypesActions}
        nurseryPaymentTypesListState={nurseryPaymentTypes.list}
        nurseryPaymentTypesSelectors={selectors}
        nurseryPaymentTypesSingleState={nurseryPaymentTypes.single}
        {...other as P}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withNurseryPaymentTypesService
