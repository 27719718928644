import React, { PropsWithChildren, useState } from 'react'
import useCollapse from 'react-collapsed'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { getBrandingColor } from 'utils/branding'

import { Icon } from 'components'

import {
  StyledBox,
  StyledCollapseContainer,
  StyledContent,
  StyledHeader,
  StyledIcon,
  StyledTitle,
} from './CollapseStyled'

interface CollapseRowProps {
  content: string | React.ReactNode
  defaultIsOpen?: boolean
  icon?: IconType
  iconBackground?: string
  title: string | React.ReactNode
}

const CollapseRow: React.FC<PropsWithChildren<CollapseRowProps>> = ({
  content,
  defaultIsOpen,
  icon,
  iconBackground = getBrandingColor('primary-color'),
  title,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultIsOpen)
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
  })

  return (
    <StyledCollapseContainer $withIcon={icon}>
      <StyledHeader
        {...getToggleProps({
          onClick: () => setIsExpanded((oldExpanded) => !oldExpanded),
        })}
      >
        {icon && (
          <StyledIcon $background={iconBackground}>
            <Icon
              color={NEUTRAL_COLOURS.WHITE}
              height={19}
              icon={icon}
            />
          </StyledIcon>
        )}
        <StyledBox>
          <StyledTitle>
            {title}
          </StyledTitle>
          <Icon
            color="#D7D7D7"
            height={10}
            icon={isExpanded ? 'chevron-up' : 'chevron-down'}
          />
        </StyledBox>
      </StyledHeader>
      <StyledContent $withIcon={icon} {...getCollapseProps()}>
        {content}
      </StyledContent>
    </StyledCollapseContainer>
  )
}

export default CollapseRow
