import styled from 'styled-components'

import colors, { FLAG_COLOURS, NEUTRAL_COLOURS } from 'constants/colors'
import layout from 'constants/layout'

import { getBrandingColor } from 'utils/branding'
import { typeByObject } from 'utils/typescript'

import { VISUAL_TYPE } from './TimePicker'

interface StyledTimePickerProps {
  $error?: string
  $visualType?: typeByObject<typeof VISUAL_TYPE>
}

export const StyledTimePicker = styled.div<StyledTimePickerProps>`
  .rc-time-picker-input {
    width: ${({ $width }) => ($width ? `${$width}px` : '130px')};
    height: 42px;
    border-color: ${colors.inputBorderColor};
    padding: 10px;
    font-size: 14px;
    font-family: ${layout.fontFamily};
    color: #333;
    
    ${({ $visualType }) => VISUAL_TYPE.MODERN === $visualType && `
      width: 90px;
      height: 44px;
      border: 2px solid ${NEUTRAL_COLOURS.GRAY_TERTIARY};
      border-radius: 0;
    `}

    ${({ $visualType }) => VISUAL_TYPE.TRANSPARENT === $visualType && `
      width: 90px;
      border: none;
      border-radius: 0;
      padding: 0;
      color: ${getBrandingColor('primary-color')};
      font-size: 16px;
      font-weight: 600;
    `}
    
    &[disabled] {
      color: #545454;
      background-color: ${colors.disabled};
      
      &::placeholder {
        color: #CACACA;
      }
    }
    
    ${({ $error }) => $error && `
      border-color: ${FLAG_COLOURS.ERROR};
    `}
    
    ${({ $error, $visualType }) => VISUAL_TYPE.MODERN === $visualType && $error && `
      position: relative;
      z-index: 20;
    `}
  }
`

interface StyledIconProps {
  $disabled?: boolean
}

export const StyledIcon = styled.div<StyledIconProps>`
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
  background: ${NEUTRAL_COLOURS.WHITE};
  cursor: pointer;
  transition: .2s;
  
  ${({ $disabled }) => $disabled && `
    cursor: pointer;
    background: ${colors.disabled};
  `}
`

interface StyledCloseIconProps {
  $disabled?: boolean
}

export const StyledCloseIcon = styled.div<StyledCloseIconProps>`
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  display: flex;
  align-items: center;
  background: ${NEUTRAL_COLOURS.WHITE};
  padding: 0 8px;
  border-radius: ${layout.borderRadius};
  cursor: pointer;
  
  ${({ $disabled }) => $disabled && `
    cursor: pointer;
    background: ${colors.disabled};
  `}
`

export const StyledTimePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
