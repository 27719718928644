import _ from 'lodash'

import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

import layout from 'constants/layout'
import { IS_HEADER_PINNED_LS } from 'services/shell/constants'
import { CALCULATE_STICKY_POSITION_DATA_TABLE_ROW } from 'components/DataTable/DataTable'

import { useWindowSize } from 'utils/hooks'
import eventBus from 'utils/eventBus'

import { Icon } from 'components'

import DataTable from './index'
import {
  StyledAbsoluteWrapper,
  StyledContentScrollable,
  StyledCustomScrollbarWrapper,
  StyledEndOfContent,
  StyledImitationContentWidth,
  StyledSeparator,
  StyledStickyHeader,
  StyledStickyHeaderContainer,
  StyledWrapper,
} from './DataTableStyled'

const useForceUpdate = () => {
  const [, setState] = useState()

  // @ts-ignore
  return () => setState({})
}

interface DataTableContentProps {
  data: any
  defaultCollapse?: boolean
  enableCustomScrollbar?: boolean
  groupHeaders?: any
  hideSeparator?: boolean
  showSeparatorOnLastGroup?: boolean
}

const DataTableContentScrollable: React.FC<PropsWithChildren<DataTableContentProps>> = ({
  data,
  defaultCollapse,
  enableCustomScrollbar,
  groupHeaders,
  hideSeparator,
  showSeparatorOnLastGroup,
}) => {
  const forceUpdate = useForceUpdate()
  const contentScrollableRef = useRef(null)
  const endOfContentRef = useRef(null)
  const contentArtificialScrollableRef = useRef(null)
  const [originalScrollbarInView, setOriginalScrollbarInView] = useState(false)
  const windowSize = useWindowSize()
  const stickyElementRef = []
  const contentElementRef = []

  _.times(data.length, (index) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    stickyElementRef[index] = useRef()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    contentElementRef[index] = useRef()
  })

  const [hiddenArray, setHiddenArray] = useState([])
  const [contentScrollWidth, setContentScrollWidth] = useState(0)
  const [contentWidth, setContentWidth] = useState(0)

  const getHeaderContainer = (e) => e.current.querySelector('div').querySelector('div')

  useEffect(() => {
    if (contentScrollableRef?.current && endOfContentRef?.current?.style) {
      endOfContentRef.current.style.width = `${getHeaderContainer(contentScrollableRef).offsetWidth}px`
    }
  }, [data, endOfContentRef, contentScrollableRef])

  const calculateStickyScrollbar = () => {
    const positionOfContent = contentScrollableRef.current?.getBoundingClientRect()
    const positionOfCustomScrollbar = contentArtificialScrollableRef?.current?.getBoundingClientRect()

    if (contentArtificialScrollableRef?.current?.style && positionOfContent && positionOfCustomScrollbar) {
      if (positionOfContent.top > positionOfCustomScrollbar.top - 100) {
        if (0 !== contentArtificialScrollableRef.current.style.opacity) {
          contentArtificialScrollableRef.current.style.opacity = 0
        }
      } else if (1 !== contentArtificialScrollableRef.current.style.opacity) {
        contentArtificialScrollableRef.current.style.opacity = 1
      }
    }
  }

  const calculateStickyPosition = (scrollEvent) => {
    const position = contentScrollableRef.current?.getBoundingClientRect()

    const isHeaderPinned = +window.localStorage.getItem(IS_HEADER_PINNED_LS)
    const isScrollableWrapperInViewport = !!position
      && (
        (isHeaderPinned ? layout.menuBarHeight : 0) + layout.topBarHeight <= (
          position.top + 1
        )
      )
      && 0 <= position.right
      && position.top <= document.documentElement.clientHeight
      && position.left <= document.documentElement.clientWidth

    /* eslint-disable no-param-reassign */
    _.each(stickyElementRef, (stickyHeader) => {
      if (scrollEvent && stickyHeader?.current) {
        stickyHeader.current.scrollLeft = scrollEvent.target?.scrollLeft
      }

      if (stickyHeader?.current) {
        stickyHeader.current.style.height = `${stickyHeader.current.querySelector('div').offsetHeight}px`
      }

      if (!contentScrollableRef.current || isScrollableWrapperInViewport || position.top >= window.scrollY) {
        if (
          contentScrollableRef?.current?.style
          && stickyHeader?.current?.style?.display
          && 'none' !== stickyHeader?.current?.style?.display
        ) {
          stickyHeader.current.style.display = 'none'
        }

        return null
      }

      if (stickyHeader?.current?.style) {
        let show = false

        if ('flex' !== stickyHeader.current.style.display) {
          show = true
        }

        if (show) {
          stickyHeader.current.style.display = 'flex'
          stickyHeader.current.scrollLeft = contentScrollableRef.current.scrollLeft
        }

        const newTop = `${layout.topBarHeight + (isHeaderPinned ? layout.menuBarHeight : 0)}px`

        if (newTop !== stickyHeader.current.style.top) {
          stickyHeader.current.style.top = newTop
        }

        const contentScrollablePosition = contentScrollableRef.current.getBoundingClientRect()
        const stickyHeaderPosition = stickyHeader.current.getBoundingClientRect()

        if (contentScrollablePosition.right < stickyHeaderPosition.right) {
          stickyHeader.current.style.width = (
            `${contentScrollablePosition.right - (parseInt(stickyHeader.current.style.left.replace('px', '')) || 0)}px`
          )
        } else if (`${position.width}px` !== stickyHeader.current.style.width) {
          stickyHeader.current.style.width = `${position.width}px`
        }
      }

      return true
    })
    /* eslint-enable no-param-reassign */
  }

  const setScrollWidth = () => {
    setTimeout(() => {
      if (enableCustomScrollbar && contentScrollableRef?.current) {
        const { offsetWidth } = contentScrollableRef.current || {}
        const contentElement = contentScrollableRef.current.querySelectorAll('[data-staticitem]')

        let scrollWidth = 0

        _.each(contentElement, (item) => {
          scrollWidth += item.offsetWidth
        })

        endOfContentRef.current.style.width = `${scrollWidth}px`
        setContentWidth(offsetWidth)
        setContentScrollWidth(scrollWidth)
      }
    })
  }

  /* eslint-disable no-param-reassign */
  const updateStickyHeaders = (e?) => {
    if (!contentScrollableRef.current) {
      return null
    }

    const contentElement = contentScrollableRef.current.querySelectorAll('[data-staticitem]')
    const contentScrollablePosition = contentScrollableRef.current.getBoundingClientRect()
    const scrollLeft = e?.target?.scrollLeft || contentScrollableRef.current?.scrollLeft

    _.each(stickyElementRef, (stickyHeader, index) => {
      if (stickyHeader?.current) {
        let previousWidth = 0

        _.each(contentElement, (item) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          if (+item.dataset?.staticitemcontent === index) {
            return false
          }

          previousWidth += item.offsetWidth

          return true
        })

        let transform
        let left = null
        const contentPosition = contentElementRef[index].current?.getBoundingClientRect()
        const stickyHeaderPosition = stickyHeader.current.getBoundingClientRect()
        const rightOffset = scrollLeft + contentScrollableRef.current.offsetWidth
        const widthFromLeftToEndOfCurrentItem = contentElementRef[index].current.offsetWidth + previousWidth
        const secondCondition = (
          previousWidth <= scrollLeft && scrollLeft < widthFromLeftToEndOfCurrentItem
        )

        if (
          (0 < contentPosition.x && 0 < index && rightOffset > contentElementRef[index].current.offsetWidth)
          || secondCondition
        ) {
          const difference = scrollLeft - previousWidth
          left = contentPosition.x + (secondCondition ? difference : 0)

          stickyHeader.current.style.left = `${left}px`
        }

        if (rightOffset > widthFromLeftToEndOfCurrentItem) {
          const newWidth = contentScrollableRef.current.offsetWidth - (rightOffset - widthFromLeftToEndOfCurrentItem)
          stickyHeader.current.style.width = `${newWidth}px`

          if (0 === index) {
            let finalTransform = rightOffset - widthFromLeftToEndOfCurrentItem

            if (0 > scrollLeft - finalTransform) {
              finalTransform += scrollLeft - finalTransform
            }

            transform = (
              `translateX(-${finalTransform}px)`
            )
          }
        }

        const condition = (
          previousWidth <= scrollLeft && scrollLeft < widthFromLeftToEndOfCurrentItem
        )

        transform = `translateX(${condition ? (-1 * (scrollLeft - previousWidth)) : 0}px)`

        if (
          !_.isNull(transform)
          && getHeaderContainer(stickyHeader).style.transform !== transform) {
          stickyHeader.current.querySelector('div').querySelector('div').style.transform = transform
        }

        if (contentScrollablePosition.right < stickyHeaderPosition.right) {
          if (
            (contentScrollablePosition.right < stickyHeaderPosition.right - stickyHeader.current.offsetWidth)
            && 'none' !== stickyHeader.current.style.display
          ) {
            stickyHeader.current.style.display = 'none'
          } else {
            if ('none' === stickyHeader.current.style.display) {
              stickyHeader.current.style.display = 'flex'
            }

            stickyHeader.current.style.right = `${contentScrollablePosition.right}px`
            stickyHeader.current.style.width = `${contentScrollablePosition.right - (left || 0)}px`
          }
        }
      }
    })

    return true
  }
  /* eslint-enable no-param-reassign */

  const checkOriginalScrollbarIsInView = () => {
    const bounding = endOfContentRef.current?.getBoundingClientRect()
    const result = !!bounding
      && 0 <= bounding.top
      && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)

    setOriginalScrollbarInView(result)
  }

  const changeVisible = (index) => {
    hiddenArray[index] = !hiddenArray[index]

    setHiddenArray(hiddenArray)
    forceUpdate()
    setScrollWidth()

    setTimeout(() => {
      updateStickyHeaders()
      checkOriginalScrollbarIsInView()
    })
  }

  const calculateElements = (e) => {
    checkOriginalScrollbarIsInView()
    calculateStickyScrollbar()
    calculateStickyPosition(e)
    updateStickyHeaders()
  }

  const calculateElementsEventCallback = (e) => {
    calculateElements(e.detail)
  }

  useEffect(() => {
    const isSticky = _.find(data, (item) => item?.stickyHeader)

    checkOriginalScrollbarIsInView()

    if (isSticky) {
      eventBus.on(CALCULATE_STICKY_POSITION_DATA_TABLE_ROW, calculateElementsEventCallback)
    }

    return () => {
      if (isSticky) {
        eventBus.remove(CALCULATE_STICKY_POSITION_DATA_TABLE_ROW, calculateElementsEventCallback)
      }
    }
  }, [])

  useEffect(() => {
    if (defaultCollapse) {
      _.forEach(data, (content, i) => {
        changeVisible(i)
      })
    }
  }, [defaultCollapse, data])

  useEffect(setScrollWidth, [contentScrollableRef, windowSize, enableCustomScrollbar])

  const handleOriginalScroll = (e) => {
    calculateStickyPosition(e)

    if (!enableCustomScrollbar) {
      return null
    }

    if (contentArtificialScrollableRef.current) {
      contentArtificialScrollableRef.current.scrollLeft = e.target.scrollLeft
    }
    updateStickyHeaders(e)

    return true
  }

  const handleArtificialScroll = (e) => {
    if (!enableCustomScrollbar) {
      return null
    }

    calculateStickyPosition(e)

    contentScrollableRef.current.scrollLeft = e.target.scrollLeft
    updateStickyHeaders(e)

    return true
  }

  const renderStickyHeader = (stickyHeader, index) => {
    const result = stickyHeader()
    const [visibleVersion, hiddenVersion] = result || []

    if (hiddenArray[index]) {
      if (hiddenVersion) {
        return (
          <StyledStickyHeader ref={stickyElementRef[index]}>
            <StyledAbsoluteWrapper>
              <StyledStickyHeaderContainer>
                {hiddenVersion}
              </StyledStickyHeaderContainer>
            </StyledAbsoluteWrapper>
          </StyledStickyHeader>
        )
      }

      return null
    }

    return (
      <StyledStickyHeader ref={stickyElementRef[index]}>
        <StyledAbsoluteWrapper>
          <StyledStickyHeaderContainer>
            {visibleVersion}
          </StyledStickyHeaderContainer>
        </StyledAbsoluteWrapper>
      </StyledStickyHeader>
    )
  }

  return (
    <StyledWrapper>
      <StyledContentScrollable
        ref={contentScrollableRef}
        onScroll={handleOriginalScroll}
      >
        {_.map(data, (contentPart, index) => (
          <React.Fragment key={index}>
            <DataTable.Content
              data-staticItemContent={index}
              groupHeader={groupHeaders && groupHeaders.length && groupHeaders[index]}
              hideContent={hiddenArray[index]}
              // @ts-ignore
              ref={contentElementRef[index]}
              data-staticItem
            >
              {contentPart?.stickyHeader && renderStickyHeader(contentPart.stickyHeader, index)}
              {contentPart?.data || contentPart}
            </DataTable.Content>
            {(!hideSeparator && (index + 1 !== data.length || showSeparatorOnLastGroup)) && (
              <StyledSeparator data-staticItem data-staticItemSeparator onClick={() => changeVisible(index)}>
                <Icon height={20} icon={!hiddenArray[index] ? 'chevron-left' : 'chevron-right'} />
              </StyledSeparator>
            )}
          </React.Fragment>
        ))}
        {enableCustomScrollbar && (
          <React.Fragment>
            <StyledEndOfContent ref={endOfContentRef} />
            <StyledCustomScrollbarWrapper
              $isVisible={originalScrollbarInView || (contentWidth === contentScrollWidth)}
              $width={contentWidth}
              ref={contentArtificialScrollableRef}
              onScroll={handleArtificialScroll}
            >
              <StyledImitationContentWidth $width={contentScrollWidth} />
            </StyledCustomScrollbarWrapper>
          </React.Fragment>
        )}
      </StyledContentScrollable>
    </StyledWrapper>
  )
}

export default DataTableContentScrollable
