import { compose } from 'recompose'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { getAuthUser } from 'services/security/selectors'
import { withChildService } from 'services/legacy/child'
import { withMembershipsService } from 'services/legacy/memberships'
import { withModalService } from 'services/utils/modal'

import i18n from 'translations'

import EmergencyContactListsModalView from './EmergencyContactListsModalView'
import { EMERGENCY_CONTACTS_LIST_FORM } from './components/EmergencyContactsListForm'

const EMERGENCY_LIST_GROUPS = {
  empty: ['export:child:emergency'],
}

export const ALL_CHILDREN = {
  label: 'All children',
  value: 0,
}

class EmergencyContactListsModalContainer extends Component {
  componentWillUnmount() {
    const { membershipsActions } = this.props

    membershipsActions.clear()
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSendReportSuccess = (membership) => () => {
    const { membershipsActions, modalActions, modalConsts } = this.props

    if (!membership?.email) {
      membershipsActions.get(membership.value, {
        onSuccess: ({ data }) => modalActions.show(modalConsts.TYPES.ALERT, {
          text: i18n.t('module:Modals:ExportReportModal:alert', { email: data.email }),
        }),
      })
    } else {
      modalActions.show(modalConsts.TYPES.ALERT, {
        text: i18n.t('module:Modals:ExportReportModal:alert', { email: membership.email }),
      })
    }
  }

  handleSendReportFailed = ({ message }) => {
    const { stopSubmitForm } = this.props
    stopSubmitForm({ membership: message })
  }

  handleSendReport = (originalValues) => {
    const { childActions, childSelectors } = this.props

    const values = { ...originalValues }

    if (values?.room) {
      if (Number.isInteger(values.room.value)) {
        values.room = values.room.value
      } else {
        delete values.room
      }
    }

    const criteria = childSelectors.getCriteria({ ...values })
    criteria.push({
      field: 'type',
      value: 'emergency',
    })

    const params = {
      criteria,
      groups: EMERGENCY_LIST_GROUPS,
    }

    childActions.sendReport(
      params,
      this.handleSendReportSuccess(values.membership),
      this.handleSendReportFailed,
    )
  }

  render() {
    const { currentUser, reportIsFetching } = this.props

    const initialValues = {
      membership: currentUser
        ? { value: currentUser.membership.id }
        : null,
      room: 0,
    }

    return (
      <EmergencyContactListsModalView
        initialValues={initialValues}
        isProcessing={reportIsFetching}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSendReport}
      />
    )
  }
}

const mapDispatch = {
  stopSubmitForm: (params) => stopSubmit(EMERGENCY_CONTACTS_LIST_FORM, params),
}

const mapState = (state, { childSelectors }) => ({
  currentUser: getAuthUser(state),
  reportIsFetching: childSelectors.reportIsFetching(state),
})

const enhance = compose(
  withChildService,
  withMembershipsService,
  withModalService,
  connect(mapState, mapDispatch),
)

export default enhance(EmergencyContactListsModalContainer)
