import _ from 'lodash'

import React from 'react'

import PlanView from 'module/Finance/components/PlanView'

import i18n from 'translations'

const PlanDetailDiscounts = ({ childDiscounts }) => {
  if (!childDiscounts || !childDiscounts.length) {
    return (
      <PlanView>
        <PlanView.Icon icon="discount" />
        <PlanView.Content>
          <PlanView.SubTitle
            subtitle={i18n.t('module:Children:Child:Finance:PlanView:Summary:discountEmptyState')}
          />
        </PlanView.Content>
      </PlanView>
    )
  }

  const renderDiscount = ({ description: customDescription, id, nurseryDiscount = {} }) => {
    const { description } = nurseryDiscount

    return (
      <PlanView.Item key={id} wrapText>
        {customDescription || description}
      </PlanView.Item>
    )
  }

  return (
    <PlanView>
      <PlanView.Icon icon="discount" />
      <PlanView.Content>
        <PlanView.Title
          title={i18n.t('module:Children:Child:Finance:PlanView:Summary:discount')}
        />
        {_.map(childDiscounts, renderDiscount)}
      </PlanView.Content>
    </PlanView>
  )
}

export default PlanDetailDiscounts
