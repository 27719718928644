import React from 'react'

import { TRAVEL_PERMISSIONS } from 'services/legacy/child/constants'

import { DetailsBreakdown } from 'components'

import i18n from 'translations'

import { CHILD_EDIT_FORM_ROUTE_TYPE } from '../../../constants'

import BreakdownHeader from './BreakdownHeader'
import BreakdownSubHeader from './BreakdownSubHeader'
import BreakdownItem from './BreakdownItem'

const TRANS_PREFIX = 'module:Children:Child:Add:Permissions'

const PermissionsBreakdown = ({
  child,
  isFormContext,
  permissionToEdit,
}) => {
  const { id, information } = child
  const {
    canBePhotographed,
    canBeTaggedInGroupPhotos,
    closedSocialMediaPhotoSharingPermitted,
    otherMarketingMaterialPhotoSharingPermitted,
    socialMediaPhotoSharingPermitted,
    travelPermissions = [],
    useOnSiteMediaPermitted,
  } = information || {}

  const getTravelPermissionsValue = (fieldName) => {
    if (isFormContext) {
      return information[fieldName]
    }

    return travelPermissions.includes(fieldName)
  }

  const renderMedia = () => (
    <React.Fragment>
      <BreakdownSubHeader
        subtitle={i18n.t(`${TRANS_PREFIX}:Subsections:Media:title`)}
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Media:Form:CanBePhotographed:label`)}
        padding="15px 0"
        value={canBePhotographed ? i18n.t('global:Yes') : i18n.t('global:No')}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Media:Form:CanBeTaggedInGroupPhotos:label`)}
        padding="15px 0"
        value={canBeTaggedInGroupPhotos ? i18n.t('global:Yes') : i18n.t('global:No')}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Media:Form:SocialMediaPhotoSharing:label`)}
        padding="15px 0"
        value={socialMediaPhotoSharingPermitted ? i18n.t('global:Yes') : i18n.t('global:No')}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Media:Form:ClosedSocialMediaPhotoSharingPermitted:label`)}
        padding="15px 0"
        value={closedSocialMediaPhotoSharingPermitted ? i18n.t('global:Yes') : i18n.t('global:No')}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Media:Form:UseOnSiteMediaPermitted:label`)}
        padding="15px 0"
        value={useOnSiteMediaPermitted ? i18n.t('global:Yes') : i18n.t('global:No')}
        withoutBorder
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Media:Form:OtherMarketingMaterialPhotoSharing:label`)}
        padding="15px 0"
        value={otherMarketingMaterialPhotoSharingPermitted ? i18n.t('global:Yes') : i18n.t('global:No')}
        withoutBorder
      />
    </React.Fragment>
  )

  const renderSafety = () => (
    <React.Fragment>
      <BreakdownSubHeader
        subtitle={i18n.t(`${TRANS_PREFIX}:Subsections:Safety:title`)}
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Safety:Form:CanTakeTheBus:label`)}
        padding="15px 0"
        value={getTravelPermissionsValue(TRAVEL_PERMISSIONS.CAN_TAKE_THE_BUS)
          ? i18n.t('global:Yes')
          : i18n.t('global:No')}
        withoutBorder
      />
    </React.Fragment>
  )

  const renderPlay = () => (
    <React.Fragment>
      <BreakdownSubHeader
        subtitle={i18n.t(`${TRANS_PREFIX}:Subsections:Play:title`)}
      />
      <BreakdownItem
        label={i18n.t(`${TRANS_PREFIX}:Subsections:Play:Form:CanGoToExternalOutgoings:label`)}
        padding="15px 0"
        value={getTravelPermissionsValue(TRAVEL_PERMISSIONS.CAN_GO_TO_EXTERNAL_OUTGOINGS)
          ? i18n.t('global:Yes')
          : i18n.t('global:No')}
        withoutBorder
      />
    </React.Fragment>
  )

  return (
    <DetailsBreakdown marginBottom>
      <DetailsBreakdown.Section>
        <BreakdownHeader
          childFormRouteType={CHILD_EDIT_FORM_ROUTE_TYPE.PERMISSIONS_INFO}
          childId={id}
          isFormContext={isFormContext}
          permissionToEdit={permissionToEdit}
          title={i18n.t(`${TRANS_PREFIX}:title`)}
        />
        {renderMedia()}
        {renderSafety()}
        {renderPlay()}
      </DetailsBreakdown.Section>
    </DetailsBreakdown>
  )
}

export default PermissionsBreakdown
