import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { Field as FormField, change, getFormValues, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { DAILY_DIARY_RECORD_MODAL_VARIANTS } from 'module/Modals/DailyDiaryRecordModal/DailyDiaryRecordModalContainer'
import { DAILY_DIARY_DATE_FORMAT } from 'services/legacy/dailyDiary/constants'
import { REFERENCE_ASYNC_PAGE_TYPES, UPLOADED_FILE_STATUS } from 'services/legacy/upload/constants'
import { noop } from 'constants/global'

import { isRequired } from 'utils/fieldValidation'
import { generateRoute } from 'utils/routing'

import { withModalService } from 'services/utils/modal'
import { withSnackbarService } from 'services/utils/snackbar'
import { withDailyDiaryActivitiesService } from 'services/legacy/dailyDiaryActivities'
import { withUploadService } from 'services/legacy/upload'
import { withRouter } from 'services/router'

import {
  Avatar,
  Button,
  DefinitionList,
  Field,
  Form,
  GroupAvatars,
  List,
  MediaPicker,
  TimeString,
  Typography,
} from 'components'

import i18n from 'translations'

import { properties } from 'app-config'

import withDailyDiaryRecordHandlers from '../../../withDailyDiaryRecordHandlers'
import {
  StyledBottomBody,
  StyledChildRow,
  StyledChildrenList,
  StyledFieldWrapper,
  StyledTopBody,
} from './ActivityRecordStyled'

export const ACTIVITY_RECORD_EDIT_FORM = 'ActivityRecordEditForm'

class ActivityRecord extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEditMode: false,
    }
  }

  handleEditMode = (isEditMode) => {
    const { item, onCancelEdit } = this.props

    if (!isEditMode && onCancelEdit) {
      onCancelEdit(item)
    }

    this.setState({ isEditMode })
  }

  handleSubmitClick = (e) => {
    const { dailyDiaryRecordHelpers: { handleSubmitClick } } = this.props

    handleSubmitClick(e, {
      onFailed: this.handleEditMode,
    })
  }

  handleRemoveChildAccepted = (childId) => {
    const { changeFieldValue, formValues, item } = this.props
    const { children } = formValues

    const filteredChildrenList = _.filter(children, (child) => child?.id !== childId)
    changeFieldValue(`${ACTIVITY_RECORD_EDIT_FORM}_${item.id}`, 'children', filteredChildrenList)
  }

  handleRemoveChild = (childId) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: () => this.handleRemoveChildAccepted(childId),
      text: i18n.t('module:DailyDiary:Activities:removeActivity'),
    })
  }

  handleAddChildrenSuccess = ({ selectedChildren }) => {
    const { changeFieldValue, item } = this.props

    changeFieldValue(`${ACTIVITY_RECORD_EDIT_FORM}_${item.id}`, 'children', selectedChildren)
  }

  handleAddChild = () => {
    const {
      formValues,
      location: {
        query: {
          date = moment().format(DAILY_DIARY_DATE_FORMAT),
        },
      },
      modalActions,
      modalConsts,
    } = this.props
    const { children } = formValues

    modalActions.show(modalConsts.TYPES.DAILY_DIARY_RECORD, {
      date,
      onSuccess: this.handleAddChildrenConfirm,
      onlyChildPicker: true,
      selectedChildren: children?.length ? children : [],
      variant: DAILY_DIARY_RECORD_MODAL_VARIANTS.ACTIVITY,
    })
  }

  handleAddChildrenConfirm = (children) => {
    const { modalActions, modalConsts } = this.props

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      onConfirm: () => this.handleAddChildrenSuccess(children),
      text: i18n.t('module:DailyDiary:Activities:changeChildren'),
    })
  }

  handleCheckProcessingFiles = async (readyFiles) => {
    const { changeFieldValue, formValues, item: activity } = this.props
    let { media: currentMedia } = formValues

    currentMedia = _.map(currentMedia, (item) => {
      const newFile = _.find(readyFiles, ({ id }) => id === item.id)

      if (newFile) {
        return {
          ...item,
          status: UPLOADED_FILE_STATUS.READY,
        }
      }

      return item
    })

    changeFieldValue(`${ACTIVITY_RECORD_EDIT_FORM}_${activity.id}`, 'media', currentMedia)
  }

  handleReloadMedia = async (newFiles) => {
    const { changeFieldValue, formValues, item } = this.props
    const { media } = formValues
    const updatedNewFiles = _.map(newFiles, (file) => ({ ...file, itemFromBe: true }))

    changeFieldValue(`${ACTIVITY_RECORD_EDIT_FORM}_${item.id}`, 'media', _.uniqBy([
      ...updatedNewFiles,
      ...media,
    ], ({ id }) => id))
  }

  handleUpdateMediaSuccess = (file) => {
    const { changeFieldValue, formValues, item, snackbarActions } = this.props
    const { media } = formValues
    const updatedMedia = _.map(media, (element) => {
      if (file.id === element.id) {
        return {
          ...element,
          children: file.children,
          name: file.name,
        }
      }

      return element
    })

    snackbarActions.show({
      message: i18n.t('services:Files:fileDetailsHasBeenChanged'),
    })

    changeFieldValue(`${ACTIVITY_RECORD_EDIT_FORM}_${item.id}`, 'media', updatedMedia)
  }

  handleUpdateMedia = (file) => {
    const { dailyDiaryActivitiesActions, item } = this.props
    const { children, id: fileId, itemFromBe, name } = file

    if (itemFromBe) {
      const body = [{
        children: _.map(children, ({ id }) => ({ id })),
        id: fileId,
        name,
      }]

      dailyDiaryActivitiesActions.updateActivityMedia({
        body,
        onSuccess: () => this.handleUpdateMediaSuccess(file),
        params: [item.id],
      })
    }
  }

  handleRemoveFileSuccess = (file) => {
    const { changeFieldValue, formValues, item, snackbarActions } = this.props
    const { media } = formValues
    const updatedMedia = _.filter(media, ({ id }) => id !== file.id)

    snackbarActions.show({
      message: i18n.t('services:Files:fileRemoved'),
    })

    changeFieldValue(`${ACTIVITY_RECORD_EDIT_FORM}_${item.id}`, 'media', updatedMedia)
  }

  handleRemoveFile = async (file) => {
    const { dailyDiaryActivitiesActions, item: activity } = this.props
    const { id } = file
    const body = [{ id }]

    dailyDiaryActivitiesActions.removeActivityMedia({
      body,
      onSuccess: () => this.handleRemoveFileSuccess(file),
      params: activity.id,
    })
  }

  render() {
    const { childArray, dailyDiaryRecordState, formValues, item, onRemoveRecord } = this.props
    const { isProcessing } = dailyDiaryRecordState
    const { isEditMode } = this.state
    const { children: itemChildren, id, media, name, record } = item || {}
    const { comments, recordedAt } = record || {}
    const { children, media: formMedia } = formValues

    const actions = (
      <Field.InlineEdit.Actions
        isEdit={isEditMode}
        isProcessing={isProcessing}
        onCancelClick={() => this.handleEditMode(false)}
        onDeleteClick={() => onRemoveRecord(id)}
        onEditClick={() => this.handleEditMode(true)}
        onSaveClick={this.handleSubmitClick}
      />
    )

    const renderEditableComponent = (
      <React.Fragment>
        <StyledTopBody>
          <DefinitionList vertical>
            <DefinitionList.Item
              label={(
                <Typography bold primary>
                  {i18n.t('module:DailyDiary:Activities:activityName')}
                </Typography>
              )}
              margin="0 0 10px 0"
              value={(
                <FormField
                  component={Form.TextField}
                  disabled={isProcessing}
                  name="name"
                  placeholder={i18n.t('module:DailyDiary:Activities:activityName')}
                  validate={isRequired}
                />
              )}
              whiteSpace="pre-line"
            />
          </DefinitionList>
          <DefinitionList vertical>
            <DefinitionList.Item
              label={(
                <Typography bold primary>
                  {i18n.t('global:Time')}
                </Typography>
              )}
              margin="0 0 10px 0"
              value={(
                <FormField
                  component={Form.TimePicker}
                  disabled={isProcessing}
                  name="recordedAt"
                  validate={isRequired}
                />
              )}
            />
          </DefinitionList>
        </StyledTopBody>
        <StyledBottomBody>
          <DefinitionList vertical>
            <DefinitionList.Item
              label={(
                <Typography bold primary>
                  {i18n.t('global:Children')}
                </Typography>
              )}
              margin="0 0 10px 0"
              value={(
                <React.Fragment>
                  <StyledChildrenList>
                    {_.map(children, (props) => {
                      const { firstName, id: childId, photo, surname } = props || {}

                      return (
                        <StyledChildRow
                          key={childId}
                        >
                          <Avatar
                            avatarSize="small"
                            initials={[firstName, surname]}
                            src={photo}
                            title={`${firstName} ${surname}`}
                          />
                          {1 < children?.length ? (
                            <Button
                              color="error"
                              hierarchy="tertiary"
                              icon="close"
                              size="small"
                              negativeVerticalMargins
                              onClick={() => this.handleRemoveChild(childId)}
                            />
                          ) : null}
                        </StyledChildRow>
                      )
                    })}
                  </StyledChildrenList>
                  <Button.ActionButton
                    label={i18n.t('module:DailyDiary:global:addChildren')}
                    onClick={this.handleAddChild}
                  />
                </React.Fragment>
              )}
              whiteSpace="pre-line"
            />
          </DefinitionList>
          <DefinitionList vertical>
            <DefinitionList.Item
              label={(
                <Typography bold primary>
                  {i18n.t('module:DailyDiary:global:additionalNotes')}
                </Typography>
              )}
              value={(
                <FormField
                  component={Form.TextAreaField}
                  disabled={isProcessing}
                  height={90}
                  maxCharacters={2000}
                  name="comments"
                  placeholder={i18n.t('module:DailyDiary:global:enterAdditionalNotesHere')}
                  autosize
                />
              )}
              whiteSpace="pre-line"
            />
          </DefinitionList>
          <DefinitionList vertical>
            <DefinitionList.Item
              label={(
                <Typography bold primary>
                  {i18n.t('module:DailyDiary:Activities:Media')}
                </Typography>
              )}
              value={(
                <FormField
                  childrenList={children || itemChildren}
                  component={Form.MediaPicker}
                  disabled={isProcessing}
                  filesLimit={properties.upload.limits.activity}
                  name="media"
                  referencePage={[REFERENCE_ASYNC_PAGE_TYPES.ACTIVITY, id]}
                  totalResults={formMedia?.length || 0}
                  validate={isRequired}
                  onCheckProcessingFiles={this.handleCheckProcessingFiles}
                  onReloadMedia={this.handleReloadMedia}
                  onRemoveFile={this.handleRemoveFile}
                  onUpdateMedia={this.handleUpdateMedia}
                />
              )}
              whiteSpace="normal"
            />
          </DefinitionList>
        </StyledBottomBody>
      </React.Fragment>
    )

    const renderVisibleComponent = (
      <React.Fragment>
        <StyledTopBody>
          <DefinitionList vertical>
            <DefinitionList.Item
              label={(
                <Typography bold primary>
                  {i18n.t('module:DailyDiary:Activities:activityName')}
                </Typography>
              )}
              margin="0 0 10px 0"
              value={name}
              whiteSpace="pre-line"
            />
          </DefinitionList>
          <DefinitionList vertical>
            <DefinitionList.Item
              label={(
                <Typography bold primary>
                  {i18n.t('global:Time')}
                </Typography>
              )}
              margin="0 0 10px 0"
              value={(
                <TimeString date={recordedAt} />
              )}
            />
          </DefinitionList>
        </StyledTopBody>
        <StyledBottomBody>
          <DefinitionList vertical>
            <DefinitionList.Item
              label={(
                <Typography bold primary>
                  {i18n.t('module:DailyDiary:global:additionalNotes')}
                </Typography>
              )}
              margin="0 0 15px 0"
              value={comments || '-'}
              whiteSpace="pre-line"
              wordBreak="break-word"
            />
          </DefinitionList>
          <DefinitionList vertical>
            <DefinitionList.Item
              label={(
                <Typography bold primary>
                  {i18n.t('module:DailyDiary:Activities:Media')}
                </Typography>
              )}
              value={(
                media?.length ? (
                  <MediaPicker
                    childrenList={children || itemChildren}
                    referencePage={[REFERENCE_ASYNC_PAGE_TYPES.ACTIVITY, id]}
                    value={formMedia}
                    preview
                    onCheckProcessingFiles={this.handleCheckProcessingFiles}
                    onReloadMedia={this.handleReloadMedia}
                  />
                ) : '-'
              )}
            />
          </DefinitionList>
        </StyledBottomBody>
      </React.Fragment>
    )

    return (
      <List.Standard.Row
        content={(
          <Form onSubmit={noop}>
            <StyledFieldWrapper>
              <Field.InlineEdit
                actions={actions}
                description={renderVisibleComponent}
                editableComponent={renderEditableComponent}
                isEditMode={isEditMode}
                noPadding
              />
            </StyledFieldWrapper>
          </Form>
        )}
        key={id}
        leftColumn={(
          <GroupAvatars
            borderColor={NEUTRAL_COLOURS.SILVER}
            persons={isEditMode ? children : childArray}
            renderUrl={(element) => generateRoute('CHILDREN.CHILD', { childId: element.id })}
          />
        )}
      />
    )
  }
}

const mapDispatch = {
  changeFieldValue: (formName, field, value) => change(formName, field, value),
}

const mapState = (state, { item }) => ({
  formValues: getFormValues(`${ACTIVITY_RECORD_EDIT_FORM}_${item.id}`)(state),
})

const enhance = compose(
  withDailyDiaryActivitiesService,
  withDailyDiaryRecordHandlers,
  withModalService,
  withUploadService,
  withSnackbarService,
  withRouter,
)

export default reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
})(enhance(connect(mapState, mapDispatch)(ActivityRecord)))
