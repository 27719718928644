import styled from 'styled-components'

export const StyledContainer = styled.div`
  height: 350px;
  display: flex;
  align-items: center;
`

export const StyledTableContainer = styled.div`
  height: 315px;
  overflow: auto;
  padding-right: 5px;
`

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
