import moment from 'moment'
import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { TOILET_TRAINED } from 'services/legacy/child/constants'
import { DAILY_DIARY_RECORD_MODAL_VARIANTS } from 'module/Modals/DailyDiaryRecordModal/DailyDiaryRecordModalContainer'
import { DAILY_DIARY_DATE_FORMAT, DAILY_DIARY_LIST_GROUPS } from 'services/legacy/dailyDiary/constants'

import { EVENTS, logEvent } from 'analytics'

import { convertToArray } from 'utils/data'

import { withAppService } from 'services/app'
import { withDailyDiaryNappiesService } from 'services/legacy/dailyDiaryNappies'
import { withDailyDiaryToiletTrainingService } from 'services/legacy/dailyDiaryToiletTraining'
import { withModalService } from 'services/utils/modal'
import { withSnackbarService } from 'services/utils/snackbar'
import { withRegisterService } from 'services/legacy/register'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withRouter } from 'services/router'

import i18n from 'translations'

import NappiesView from './NappiesView'
import { NAPPY_RECORD_EDIT_FORM } from './components/NappyRecord/NappyRecord'
import { TOILET_TRAINING_RECORD_EDIT_FORM } from './components/ToiltetTrainingRecord/ToiletTrainingRecord'

const LIST_LIMIT = 10

class NappiesContainer extends Component {
  constructor(props) {
    super(props)

    const {
      paginationUtils: { setPageLocationQuery },
    } = props

    setPageLocationQuery(false)
  }

  componentDidMount() {
    const { paginationUtils } = this.props
    const { setPerPage } = paginationUtils

    logEvent(EVENTS.DIARY_PAGE_VIEWED, { context: 'nappies' })

    setPerPage(LIST_LIMIT, this.fetch)
  }

  shouldComponentUpdate(nextProps) {
    const { location: { query: nextQuery } } = nextProps
    const { location: { query }, paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    if (
      query.date !== nextQuery.date
      || query.room !== nextQuery.room
      || query.timeType !== nextQuery.timeType
    ) {
      onPageChange(() => this.fetch(nextQuery))(1)
    }

    return true
  }

  componentWillUnmount() {
    const { dailyDiaryNappiesActions, dailyDiaryToiletTrainingActions } = this.props

    dailyDiaryNappiesActions.clearList()
    dailyDiaryToiletTrainingActions.clearList()
    dailyDiaryNappiesActions.clearListWithoutRecords()
    dailyDiaryToiletTrainingActions.clearListWithoutRecords()
  }

  fetch = (nextQuery, silent) => {
    const {
      dailyDiaryNappiesActions,
      dailyDiaryNappiesSelectors,
      dailyDiaryToiletTrainingActions,
      dailyDiaryToiletTrainingSelectors,
      location: { query },
      paginationUtils,
      setLocationQuery,
    } = this.props
    const { page } = paginationUtils

    const { date, room, timeType } = (nextQuery || query)

    setLocationQuery({ date, room, timeType })

    dailyDiaryNappiesActions.list({
      mergeResult: 1 !== page,
      params: {
        criteria: dailyDiaryNappiesSelectors.getListCriteria({
          entryDate: date,
          recordsAndPlaceholders: true,
          room,
          timeType,
        }),
        groups: DAILY_DIARY_LIST_GROUPS,
        limit: LIST_LIMIT,
        page,
      },
      silent,
    })

    dailyDiaryToiletTrainingActions.list({
      mergeResult: 1 !== page,
      params: {
        criteria: dailyDiaryToiletTrainingSelectors.getListCriteria({
          entryDate: date,
          recordsAndPlaceholders: true,
          room,
          timeType,
        }),
        groups: DAILY_DIARY_LIST_GROUPS,
        limit: LIST_LIMIT,
        page,
      },
    })

    if (1 === page) {
      this.fetchWithoutRecordList(nextQuery)
    }
  }

  fetchWithoutRecordList = (nextQuery) => {
    const {
      dailyDiaryNappiesActions,
      dailyDiaryNappiesSelectors,
      dailyDiaryToiletTrainingActions,
      dailyDiaryToiletTrainingSelectors,
      location: { query },
    } = this.props

    const { date } = (nextQuery || query)

    dailyDiaryNappiesActions.childrenListWithoutRecords({
      params: {
        criteria: dailyDiaryNappiesSelectors.getListCriteria({
          childrenWithoutRecords: true,
          entryDate: date,
          not: {
            toiletTrained: TOILET_TRAINED.NAPPIES,
          },
        }),
        groups: DAILY_DIARY_LIST_GROUPS,
        limit: 0,
        page: 1,
      },
    })

    dailyDiaryToiletTrainingActions.childrenListWithoutRecords({
      params: {
        criteria: dailyDiaryToiletTrainingSelectors.getListCriteria({
          childrenWithoutRecords: true,
          entryDate: date,
        }),
        groups: DAILY_DIARY_LIST_GROUPS,
        limit: 0,
        page: 1,
      },
    })
  }

  handleRemoveRecordSuccess = () => {
    const { snackbarActions } = this.props

    this.fetchWithoutRecordList()

    snackbarActions.show({
      message: i18n.t('module:DailyDiary:Nappies:removedSuccess'),
    })
  }

  handleRemoveToiletTrainingRecordSuccess = () => {
    const { snackbarActions } = this.props

    logEvent(EVENTS.DIARY_ITEM_REMOVED, { context: 'nappies' })

    snackbarActions.show({
      message: i18n.t('module:DailyDiary:Nappies:toiletTrainingRemovedSuccess'),
    })
  }

  handleRemoveRecordConfirmed = (nappy) => {
    const { dailyDiaryNappiesActions } = this.props

    dailyDiaryNappiesActions.removeRecord(
      nappy,
      this.handleRemoveRecordSuccess,
    )
  }

  handleRemoveToiletTrainingRecordConfirmed = (toiletTraining) => {
    const { dailyDiaryToiletTrainingActions } = this.props

    logEvent(EVENTS.DIARY_ITEM_REMOVED, { context: 'toilet training' })

    dailyDiaryToiletTrainingActions.removeRecord(
      toiletTraining,
      {
        onSuccess: this.handleRemoveToiletTrainingRecordSuccess,
      },

    )
  }

  handleRemoveRecord = (nappy) => {
    const { modalActions, modalConsts } = this.props

    logEvent(EVENTS.DIARY_REMOVE_ITEM_BTN_CLICKED, { context: 'nappies' })

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: () => this.handleRemoveRecordConfirmed(nappy),
      text: i18n.t('module:DailyDiary:Nappies:confirmRemove'),
    })
  }

  handleRemoveToiletTrainingRecord = (toiletTraining) => {
    const { modalActions, modalConsts } = this.props

    logEvent(EVENTS.DIARY_REMOVE_ITEM_BTN_CLICKED, { context: 'toilet training' })

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: () => this.handleRemoveToiletTrainingRecordConfirmed(toiletTraining),
      text: i18n.t('module:DailyDiary:Nappies:toiletTrainingConfirmRemove'),
    })
  }

  handleAddRecordSuccess = ({ records, selectedChildren, trackToiletTraining }) => {
    const { dailyDiaryNappiesActions, snackbarActions } = this.props

    dailyDiaryNappiesActions.addToList(records)
    this.fetchWithoutRecordList()

    snackbarActions.show({
      message: i18n.t('module:DailyDiary:Nappies:addedSuccess'),
    })

    logEvent(EVENTS.DIARY_ITEM_ADDED, { context: 'nappies' })

    if (trackToiletTraining) {
      this.handleAddToiletTraining(selectedChildren)
    }
  }

  handleAddToiletTrainingRecordSuccess = ({ addNappyChange, records, selectedChildren }) => {
    const { dailyDiaryToiletTrainingActions, nappiesRecords, snackbarActions } = this.props

    const finalRecords = _.map(records, (record) => {
      const nappyRecord = _.find(nappiesRecords, (item) => item.childRegister.child.id === record.data.child.id)

      return {
        ...record,
        record: record.record || {
          childRegister: nappyRecord?.childRegister,
          diaryRecords: [],
        },
      }
    })

    dailyDiaryToiletTrainingActions.addToList(finalRecords)
    this.fetchWithoutRecordList()

    snackbarActions.show({
      message: i18n.t('module:DailyDiary:Nappies:toiletTrainingAddedSuccess'),
    })

    logEvent(EVENTS.DIARY_ITEM_ADDED, { context: 'toilet training' })

    if (addNappyChange) {
      this.handleAddRecord(selectedChildren)
    }
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleAddRecord = (children) => {
    const {
      location: {
        query: {
          date = moment().format(DAILY_DIARY_DATE_FORMAT),
          timeType,
        },
      },
      modalActions,
      modalConsts,
    } = this.props

    const selectedChildrenArray = convertToArray(children)

    logEvent(EVENTS.DIARY_ADD_ITEM_BTN_CLICKED, { context: 'nappies' })

    modalActions.show(modalConsts.TYPES.DAILY_DIARY_RECORD, {
      date,
      onSuccess: this.handleAddRecordSuccess,
      selectedChildren: selectedChildrenArray,
      timeType,
      variant: DAILY_DIARY_RECORD_MODAL_VARIANTS.NAPPY,
    })
  }

  handleAddToiletTraining = (children) => {
    const {
      location: {
        query: {
          date = moment().format(DAILY_DIARY_DATE_FORMAT),
        },
      },
      modalActions,
      modalConsts,
    } = this.props

    const selectedChildrenArray = convertToArray(children)

    logEvent(EVENTS.DIARY_ADD_ITEM_BTN_CLICKED, { context: 'toilet training' })

    modalActions.show(modalConsts.TYPES.DAILY_DIARY_RECORD, {
      date,
      onSuccess: this.handleAddToiletTrainingRecordSuccess,
      selectedChildren: selectedChildrenArray,
      variant: DAILY_DIARY_RECORD_MODAL_VARIANTS.TOILET_TRAINING,
    })
  }

  handleUpdateRecordFailed = (response, record, callback) => {
    const { injectValidation } = this.props

    if (!response) {
      return false
    }

    if (callback) {
      callback(true)
    }

    return injectValidation(`${NAPPY_RECORD_EDIT_FORM}_${record.id}`, response)
  }

  handleUpdateToiletTrainingRecordFailed = (response, record, callback) => {
    const { injectValidation } = this.props

    if (!response) {
      return false
    }

    if (callback) {
      callback(true)
    }

    return injectValidation(`${TOILET_TRAINING_RECORD_EDIT_FORM}_${record.id}`, response)
  }

  handleUpdateRecordSuccess = (callback) => {
    const { snackbarActions } = this.props

    logEvent(EVENTS.DIARY_ITEM_UPDATED, { context: 'nappies' })

    if (callback) {
      callback()
    }

    snackbarActions.show({
      message: i18n.t('module:DailyDiary:Nappies:updatedSuccess'),
    })
  }

  handleUpdateToiletTrainingRecordSuccess = (callback) => {
    const { snackbarActions } = this.props

    logEvent(EVENTS.DIARY_ITEM_UPDATED, { context: 'toilet training' })

    if (callback) {
      callback()
    }

    snackbarActions.show({
      message: i18n.t('module:DailyDiary:Nappies:toiletTrainingUpdatedSuccess'),
    })
  }

  handleUpdateRecord = (record, values, callback) => {
    const { dailyDiaryNappiesActions, dailyDiaryNappiesSelectors } = this.props
    const body = dailyDiaryNappiesSelectors.getValuesForm(values)

    logEvent(EVENTS.DIARY_EDIT_ITEM_BTN_CLICKED, { context: 'nappies' })

    dailyDiaryNappiesActions.updateRecord(
      record.id,
      body,
      () => this.handleUpdateRecordSuccess(callback),
      (response) => this.handleUpdateRecordFailed(response, record, callback),
    )
  }

  handleUpdateToiletTrainingRecord = (record, values, callback) => {
    const { dailyDiaryToiletTrainingActions, dailyDiaryToiletTrainingSelectors } = this.props
    const body = dailyDiaryToiletTrainingSelectors.getValuesForm(values)

    logEvent(EVENTS.DIARY_EDIT_ITEM_BTN_CLICKED, { context: 'toilet training' })

    dailyDiaryToiletTrainingActions.updateRecord(
      record.id,
      {
        body,
        onFailed: (response) => this.handleUpdateToiletTrainingRecordFailed(response, record, callback),
        onSuccess: () => this.handleUpdateToiletTrainingRecordSuccess(callback),
      },

    )
  }

  render() {
    const {
      hasNoRecordsData,
      isFetching,
      isFetchingChildrenWithNoRecords,
      isFetchingChildrenWithNoToiletTrainingRecords,
      isFetchingToiletTraining,
      isOffline,
      isSubmitting,
      isSubmittingToiletTraining,
      location: { query },
      paginationUtils,
      records,
      registers,
      totalResults,
    } = this.props
    const { room } = query
    const { getPageCount, page } = paginationUtils

    const pageCount = getPageCount(totalResults)

    return (
      <NappiesView
        hasNoRecordsData={hasNoRecordsData}
        isFetching={isFetching || isFetchingToiletTraining}
        isFetchingChildrenWithNoRecords={
          isFetchingChildrenWithNoRecords || isFetchingChildrenWithNoToiletTrainingRecords
        }
        isOffline={isOffline}
        isSubmitting={isSubmitting || isSubmittingToiletTraining}
        page={page}
        pageCount={pageCount}
        records={records}
        registers={registers}
        room={room && JSON.parse(room)?.value}
        totalResults={totalResults}
        onAddRecord={this.handleAddRecord}
        onAddToiletTraining={this.handleAddToiletTraining}
        onPageChange={this.handlePageChange}
        onRemoveRecord={this.handleRemoveRecord}
        onRemoveToiletTrainingRecord={this.handleRemoveToiletTrainingRecord}
        onUpdateRecord={this.handleUpdateRecord}
        onUpdateToiletTrainingRecord={this.handleUpdateToiletTrainingRecord}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (formName, params) => stopSubmit(formName, params),
}

const mapState = (state, {
  appSelectors,
  dailyDiaryNappiesListState,
  dailyDiaryNappiesListWithoutRecordsState,
  dailyDiaryNappiesSelectors,
  dailyDiaryNappiesSingleState,
  dailyDiaryToiletTrainingListState,
  dailyDiaryToiletTrainingListWithoutRecordsState,
  dailyDiaryToiletTrainingSelectors,
  dailyDiaryToiletTrainingSingleState,
  registerSelectors,
  registerState,
}) => ({
  hasNoRecordsData: dailyDiaryNappiesSelectors.hasDailyDiaryNappiesListWithoutRecordsData(state),
  isFetching: appSelectors.getIsFetching(
    dailyDiaryNappiesListState,
    registerState,
  ),
  isFetchingChildrenWithNoRecords: appSelectors.getIsFetching(
    dailyDiaryNappiesListWithoutRecordsState,
  ),
  isFetchingChildrenWithNoToiletTrainingRecords: appSelectors.getIsFetching(
    dailyDiaryToiletTrainingListWithoutRecordsState,
  ),
  isFetchingToiletTraining: appSelectors.getIsFetching(
    dailyDiaryToiletTrainingListState,
    registerState,
  ),
  isOffline: appSelectors.getAppIsOffline(state),
  isSubmitting: appSelectors.getIsSubmitting(
    dailyDiaryNappiesSingleState,
  ),
  isSubmittingToiletTraining: appSelectors.getIsSubmitting(
    dailyDiaryToiletTrainingSingleState,
  ),
  nappiesRecords: dailyDiaryNappiesSelectors.getDailyDiaryNappiesListData(state),
  records: dailyDiaryToiletTrainingSelectors.getDailyDiaryMergedNappiesToiletTrainingList(state),
  registers: registerSelectors.getRegisterDataSelector(state),
  totalResults: appSelectors.getTotalResults(dailyDiaryNappiesListState),
})

const enhance = compose(
  withAppService,
  withDailyDiaryNappiesService,
  withDailyDiaryToiletTrainingService,
  withModalService,
  withPaginationUtils,
  withRegisterService,
  withRouter,
  withRouterUtils,
  withSnackbarService,
  connect(mapState, mapDispatch),
)

export default enhance(NappiesContainer)
