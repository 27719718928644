import { ThemeProvider } from '@material-ui/core/styles'

import React, { PropsWithChildren } from 'react'

import { typeByObject } from 'utils/typescript'

import { Icon, Spinner } from 'components'

import { BUTTON_EDGE, BUTTON_HIERARCHY, BUTTON_SIZE, COLOR_THEME } from './constants'
import { getComponent, getCustomColor, getVariant } from './helpers'
import ActionButton from './ActionButton'
import BigButton from './BigButton'
import RadioButton from './RadioButton'
import TableAction from './ButtonTableAction'
import { ICON_SIZE_LARGE, ICON_SIZE_SMALL, StyledButton, StyledButtonWrapper, theme } from './ButtonStyled'

interface ButtonRoot<T> extends React.FC<PropsWithChildren<T>> {
  ActionButton?: typeof ActionButton
  Big?: typeof BigButton
  RadioButton?: typeof RadioButton
  TableAction?: typeof TableAction
}

export interface ButtonProps {
  color?: string
  component?: string
  disableElevation?: boolean
  disabled?: boolean
  edge?: typeByObject<typeof BUTTON_EDGE>
  fullWidth?: boolean
  hierarchy?: typeByObject<typeof BUTTON_HIERARCHY>
  icon?: IconType
  iconRight?: boolean
  inverted?: boolean
  isLoading?: boolean
  label?: string | React.ReactNode
  margin?: string
  negativeMargins?: boolean
  negativeVerticalMargins?: boolean
  onClick?: (e: any) => any
  size?: typeByObject<typeof BUTTON_SIZE>
  submit?: boolean
  target?: string
  to?: string
}

const Button: ButtonRoot<ButtonProps> = ({
  color,
  disableElevation,
  disabled,
  edge,
  fullWidth,
  hierarchy = BUTTON_HIERARCHY.PRIMARY,
  icon,
  iconRight,
  inverted,
  isLoading,
  label,
  margin,
  negativeMargins,
  negativeVerticalMargins,
  onClick,
  size = BUTTON_SIZE.MEDIUM,
  submit,
  target,
  to,
  ...rest
}: any) => {
  const type = submit ? 'submit' : undefined
  const colorTheme = inverted ? COLOR_THEME.INVERTED : COLOR_THEME.MAIN
  const customColor = color ? getCustomColor(color) : undefined
  const finalComponent = getComponent(target, to)
  const variant = getVariant(hierarchy)

  const renderIcon = () => icon && (
    <Icon
      color="inherit"
      height={BUTTON_SIZE.SMALL === size ? ICON_SIZE_SMALL : ICON_SIZE_LARGE}
      icon={icon}
    />
  )

  const startIcon = !iconRight && !isLoading && label && renderIcon()
  const endIcon = iconRight && !isLoading && label && renderIcon()
  const iconOnly = !label && icon

  const renderLabel = () => {
    if (isLoading) {
      return (
        <Spinner
          size={BUTTON_SIZE.SMALL === size ? 'small' : 'standard'}
          light
        />
      )
    }

    if (!label) {
      return renderIcon()
    }

    return label
  }

  const renderButton = () => (
    <StyledButtonWrapper
      $disabled={disabled}
      $fullWidth={fullWidth}
      $iconOnly={iconOnly}
      $isLoading={isLoading}
      $margin={margin}
      $negativeMargins={negativeMargins}
      $negativeVerticalMargins={negativeVerticalMargins}
      $size={size}
    >
      <StyledButton
        $customColor={customColor}
        $iconOnly={!!iconOnly}
        $inverted={inverted}
        $isLoading={isLoading}
        $margin={margin}
        color={colorTheme}
        component={finalComponent}
        disableElevation={disableElevation}
        disabled={disabled || isLoading}
        edge={edge}
        endIcon={endIcon}
        fullWidth={fullWidth}
        href={to || undefined}
        size={size}
        startIcon={startIcon}
        target={target}
        to={to || undefined}
        type={type}
        variant={variant}
        onClick={onClick}
        {...rest}
      >
        {renderLabel()}
      </StyledButton>
    </StyledButtonWrapper>
  )

  return (
    <ThemeProvider theme={theme}>
      {renderButton()}
    </ThemeProvider>
  )
}

export default Button
