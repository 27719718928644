import _ from 'lodash'
import moment from 'moment'

import React from 'react'
import { createSelector } from 'reselect'

import { CHILD_AGES_OPTIONS } from 'services/legacy/child/constants'
import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { ALL_CHILDREN } from 'module/Modals/ChildrenExportModal/constants'

import { AGES_MAP } from 'services/legacy/cohortTracking/constants'
import { getRoomName } from 'services/legacy/rooms/selectors'

import { Avatar, Currency, DateString, Typography } from 'components'

import i18n from 'translations'

import { getToBeInvoicedFromForecastList } from './helpers'

const getChild = (state) => state.child

export const getChildListDataSelector = createSelector([getChild], (state) => state.list.data)
export const getChildListIsFetchingSelector = createSelector([getChild], (state) => state.list.isFetching)

export const getChildListMetaSelector = createSelector([getChild], (state) => state.list.meta)

export const getChildListMetaSelectorTotalResults = createSelector(
  [getChildListMetaSelector],
  (state = {}) => state.total_results,
)

export const reportIsFetching = createSelector([getChild], (state) => state.list.reportIsFetching)

export const getChildReportTableListsSelector = createSelector(
  [getChildListDataSelector],
  (data) => _.map(data, (child) => {
    const {
      FTE,
      ageMonths,
      birthDate,
      childCurrentSessionsAsString,
      firstName,
      id,
      leavingDate,
      nurseryClass,
      photo,
      startDate,
      surname,
    } = child

    return {
      birthDate: birthDate ? (
        <Typography>
          <DateString date={birthDate} />
          <br />
          {i18n.t('global:ageMonths', { ageMonths })}
        </Typography>
      ) : '-',
      child: (
        <Avatar
          avatarSize="small"
          initials={[firstName, surname]}
          src={photo}
          title={(
            <Typography fontSize={14} primary>
              {`${firstName} ${surname}`}
            </Typography>
          )}
        />
      ),
      firstName,
      fte: FTE,
      id,
      leavingDate: leavingDate ? <DateString date={leavingDate} /> : '-',
      room: getRoomName(nurseryClass),
      session: childCurrentSessionsAsString,
      startDate: startDate ? <DateString date={startDate} /> : '-',
    }
  }),
)

export const getAgeDebtStatistics = createSelector(
  [getChildListDataSelector],
  (data) => _.map(data, (child) => {
    const { agedDebtStatistics } = child

    if (!agedDebtStatistics) {
      return {}
    }

    const { agedDebts, total } = agedDebtStatistics

    return {
      '0-30': <Currency value={agedDebts['0-30']} />,
      120: <Currency value={agedDebts['120+']} />,
      '30-60': <Currency value={agedDebts['31-60']} />,
      '60-90': <Currency value={agedDebts['61-90']} />,
      '90-120': <Currency value={agedDebts['91-120']} />,
      total: <Currency value={total} />,
    }
  }),
)

const updateAgeRanges = (value, criteria) => {
  const { from, to } = value

  if (from) {
    criteria.push({
      comparator: 'before',
      field: 'birthDate',
      value: moment()
        .add(-from, 'months')
        .format(DEFAULT_DATE_FORMAT),
    })
  }

  if (to) {
    criteria.push({
      comparator: 'after',
      field: 'birthDate',
      value: moment()
        .add(-to, 'months')
        .format(DEFAULT_DATE_FORMAT),
    })
  }
}

export const getCriteria = createSelector([(filters) => filters], (filters) => {
  if (!filters) {
    return null
  }

  const {
    accepted,
    ageRange,
    ageValue,
    allergies,
    archived,
    archivedAtAfter,
    children,
    dietaryReq,
    displayName,
    forecastDateRange,
    isArchived = false,
    isDisabled,
    keyWorker,
    leavingDate,
    membership,
    membershipIsAccepted,
    missingInfo,
    noAllergies,
    noPreference,
    notSpecifiedAllergies,
    nursery,
    onlyAllergiesOrDietaryPreferences,
    onlyOutstanding,
    other,
    parentalResponsibility,
    pescatarian,
    registerDate = null,
    room,
    search,
    startDate,
    type,
    user,
    uuid,
    vegan,
    vegetarian,
  } = filters
  const ageRanges = _.find(AGES_MAP, (e) => e.id === ageValue)
  const criteria = []

  if ('undefined' !== typeof isDisabled && null !== isDisabled) {
    criteria.push({
      field: 'isDisabled',
      value: isDisabled,
    })
  }

  if (null !== isArchived && undefined !== isArchived) {
    criteria.push({
      field: 'isArchived',
      value: +isArchived,
    })
  }

  if (null !== archived && undefined !== archived) {
    criteria.push({
      field: 'archived',
      value: archived,
    })
  }

  if (null !== accepted && undefined !== accepted) {
    criteria.push({
      field: 'accepted',
      value: accepted,
    })
  }

  if (archivedAtAfter) {
    criteria.push({
      comparator: 'after',
      field: 'archivedAt',
      value: moment(archivedAtAfter).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (type) {
    criteria.push({
      field: 'type',
      value: type,
    })
  }

  if (_.isNumber(membershipIsAccepted)) {
    criteria.push({
      field: 'membershipIsAccepted',
      value: membershipIsAccepted,
    })
  }

  if (onlyAllergiesOrDietaryPreferences) {
    criteria.push({
      field: 'onlyAllergiesOrDietaryPreferences',
      value: true,
    })
  }

  if (search) {
    criteria.push({
      field: 'concatenable',
      value: search,
    })
  }

  if (displayName) {
    criteria.push({
      field: 'displayName',
      value: displayName,
    })
  }

  if (room) {
    criteria.push({
      field: registerDate ? 'registerClass' : 'nurseryClass',
      value: room,
    })
  }

  if (0 === dietaryReq) {
    criteria.push({
      field: 'information.dietaryReq[exists]',
      value: false,
    })
  }

  if (dietaryReq) {
    criteria.push({
      field: 'information.dietaryReq',
      value: dietaryReq,
    })
  }

  if (noPreference) {
    criteria.push({
      field: 'or[0][information.dietaryReq][eq][]',
      value: noPreference,
    })
  }

  if (pescatarian) {
    criteria.push({
      field: 'or[0][information.dietaryReq][eq][]',
      value: pescatarian,
    })
  }

  if (vegetarian) {
    criteria.push({
      field: 'or[0][information.dietaryReq][eq][]',
      value: vegetarian,
    })
  }

  if (vegan) {
    criteria.push({
      field: 'or[0][information.dietaryReq][eq][]',
      value: vegan,
    })
  }

  if (other) {
    criteria.push({
      field: 'or[0][information.dietaryReq][eq][]',
      value: other,
    })
  }

  if (missingInfo) {
    criteria.push({
      field: 'or[0][information.dietaryReq][eq][]',
      value: null,
    })
  }

  if (uuid?.length) {
    _.each(uuid, (value) => {
      criteria.push({
        field: 'not[uuid][]',
        value,
      })
    })
  }

  if (ageRanges?.value && ageRange?.value) {
    updateAgeRanges(ageRange.value, criteria)
  }

  if (ageRange) {
    const { from, to } = ageRange

    updateAgeRanges({ from, to: (to && +to + 1) }, criteria)
  }

  if (startDate) {
    const { startDate: { after, before } } = filters

    if (after) {
      criteria.push({
        comparator: 'after',
        field: 'startDate',
        value: after,
      })
    }

    if (before) {
      criteria.push({
        comparator: 'before',
        field: 'startDate',
        value: before,
      })
    }
  }

  if (forecastDateRange) {
    const { after, before } = forecastDateRange

    if (after) {
      criteria.push({
        comparator: 'after',
        field: 'childMonthlyForecastStatistics.date',
        value: moment(after).format(DEFAULT_DATE_FORMAT),
      })
    }

    if (before) {
      criteria.push({
        comparator: 'before',
        field: 'childMonthlyForecastStatistics.date',
        value: moment(before).format(DEFAULT_DATE_FORMAT),
      })
    }
  }

  if (registerDate) {
    criteria.push({
      field: 'registerDate',
      value: registerDate,
    })
  }

  if (leavingDate) {
    const { leavingDate: { after, before } } = filters

    if (after) {
      criteria.push({
        comparator: 'after',
        field: 'leavingDate',
        value: after,
      })
    }

    if (before) {
      criteria.push({
        comparator: 'before',
        field: 'leavingDate',
        value: before,
      })
    }
  }

  if (user) {
    criteria.push({
      field: 'user',
      value: user,
    })
  }

  if (membership?.value) {
    criteria.push({
      field: 'membership',
      value: membership.value,
    })
  }

  if (0 === keyWorker) {
    criteria.push({
      field: 'keyWorkers[exists]',
      value: false,
    })
  }

  if (keyWorker) {
    criteria.push({
      field: 'keyWorkers[]',
      value: keyWorker,
    })
  }

  if (parentalResponsibility) {
    criteria.push({
      field: 'carerChildRelations.hasParentalResponsibility',
      value: parentalResponsibility,
    })
  }

  if (children && children.length) {
    _.forEach(children, (childId) => {
      criteria.push({
        field: 'id[]',
        value: childId,
      })
    })
  }

  if (allergies) {
    criteria.push({
      field: 'or[0][information.allergies][eq][]',
      value: allergies,
    })
  }

  if (noAllergies) {
    criteria.push({
      field: 'or[0][information.allergies][eq][]',
      value: noAllergies,
    })
  }

  if (notSpecifiedAllergies) {
    criteria.push({
      field: 'or[0][information.allergies][eq][]',
      value: notSpecifiedAllergies,
    })
  }

  if (nursery) {
    criteria.push({
      field: 'nursery',
      value: nursery,
    })
  }

  if (onlyOutstanding) {
    criteria.push({
      field: 'or[0][balance][lt]',
      value: 0,
    })
  }

  return criteria
})

export const getInitialValues = (users, currentUser) => ({
  user: _.find(users, (user) => user.value === currentUser.id),
})

export const getChildrenDropdownData = createSelector(
  [getChildListDataSelector],
  (children) => {
    if (!children || 0 < !children.length) {
      return null
    }

    return _.map(children, ({ firstName, id, photo, surname }) => ({
      avatar: photo,
      label: `${firstName} ${surname}`,
      value: id,
    }))
  },
)

export const getForecastingReportTableDataSelector = createSelector(
  [getChildListDataSelector],
  (children) => {
    if (!children || !children.length) {
      return null
    }

    return getToBeInvoicedFromForecastList(children)
  },
)

export const getAgeRange = (ageValue) => (
  CHILD_AGES_OPTIONS.find((ageOption) => ageOption.value === ageValue)?.ageRange
)

export const getExportChildrenReportCriteriaSelector = (params) => {
  const {
    archived,
    child,
    dateRange,
    membership,
  } = params

  const criteria = []

  if (child && ALL_CHILDREN.value !== child.value) {
    criteria.push({
      field: 'id',
      value: child.value,
    })
  }

  if (dateRange?.length && ALL_CHILDREN.value === child.value) {
    const [startDate, endDate] = dateRange

    criteria.push({
      field: 'startDate[after]',
      value: moment(startDate).format(DEFAULT_DATE_FORMAT),
    }, {
      field: 'startDate[before]',
      value: moment(endDate).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (membership) {
    criteria.push({
      field: 'membership',
      value: membership.value,
    })
  }

  if (!archived) {
    criteria.push({
      field: 'isArchived',
      value: '0',
    })
  }

  return criteria
}

export const getExportFundingLoopReportCriteria = (params) => {
  const {
    dateRange,
    dateRangeWeek,
    sendTo,
  } = params

  const criteria = []

  if (dateRange?.length) {
    const [fundingStartDate, fundingEndDate] = dateRange

    criteria.push({
      field: 'fundingStartDate',
      value: moment(fundingStartDate).format(DEFAULT_DATE_FORMAT),
    }, {
      field: 'fundingEndDate',
      value: moment(fundingEndDate).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (dateRangeWeek?.length) {
    const [headCountStartDate, headCountEndDate] = dateRangeWeek

    criteria.push({
      field: 'headCountStartDate',
      value: moment(headCountStartDate).format(DEFAULT_DATE_FORMAT),
    }, {
      field: 'headCountEndDate',
      value: moment(headCountEndDate).format(DEFAULT_DATE_FORMAT),
    })
  }

  if (sendTo) {
    criteria.push({
      field: 'membership',
      value: sendTo.value,
    })
  }

  return criteria
}
