import _ from 'lodash'
import moment from 'moment'

import { toFloat } from 'utils/data'
import { hoursAndMinutesToFloat } from 'utils/date'

import { DISCOUNT_TYPES, FUNDING_CALCULATION_TYPES, FUNDING_FILTERS, TYPES } from './constants'

const DATE_FORMAT = 'YYYY-MM-DD'

const getFormattedExcludedPeriods = (type, feesCalculation, excludedPeriods) => {
  if (
    type === TYPES.NO_DEDUCT
    || type === TYPES.SUBTRACT_AMOUNT_FROM_INVOICE
    || feesCalculation === FUNDING_CALCULATION_TYPES.AVERAGE_COST
    || !excludedPeriods
    || !excludedPeriods.length
  ) {
    return undefined
  }

  return _.map(excludedPeriods, ([startDate, endDate]) => ({
    finishDate: moment(endDate).format(DATE_FORMAT),
    startDate: moment(startDate).format(DATE_FORMAT),
  }))
}

const getFormattedPerWeekValues = (perWeekValues) => {
  const { allowOverride, hours, minutes } = perWeekValues

  return {
    allowOverride,
    hours: toFloat(hours) || 0,
    minutes: toFloat(minutes) || 0,
  }
}

export const getPayload = (fields, nurseryId) => {
  if (!fields) {
    return null
  }

  const {
    fundingType,
    hourlyRate,
    period: [startDate, endDate],
    settings: {
      deductedFromInvoice,
      deficitCharged,
      deficitLineItemName,
      excludedPeriods,
      feesAllocation,
      fundingCalculation,
      hoursPerWeek,
      invoiceLineItemDeduction,
      invoiceLineItemDisplay,
      maxHoursPerDay,
    },
    total,
    totalHours,
  } = fields
  const { hours, minutes = 0 } = totalHours

  const type = deductedFromInvoice === DISCOUNT_TYPES.DISCOUNT_NOT_APPLIED ? TYPES.NO_DEDUCT : feesAllocation

  return {
    ...fields,
    endDate: endDate ? moment(endDate).format(DATE_FORMAT) : null,
    fundingType: { id: fundingType.value },
    hourlyRate: toFloat(hourlyRate) || 0,
    nursery: { id: nurseryId },
    period: undefined,
    settings: {
      deficitCharged,
      deficitLineItemName: deficitCharged ? deficitLineItemName : undefined,
      excludedPeriods: getFormattedExcludedPeriods(type, fundingCalculation, excludedPeriods),
      fundingCalculation,
      hoursPerWeek: type === TYPES.MANUAL_HOURS ? getFormattedPerWeekValues(hoursPerWeek) : undefined,
      invoiceLineItemDeduction,
      invoiceLineItemDisplay,
      maxHoursPerDay: type === TYPES.MANUAL_HOURS ? getFormattedPerWeekValues(maxHoursPerDay) : undefined,
    },
    startDate: startDate ? moment(startDate).format(DATE_FORMAT) : null,
    total: toFloat(total) || 0,
    totalHours: hoursAndMinutesToFloat(hours, minutes),
    totalMinutes: undefined,
    type,
  }
}

export const getCriteria = (filters) => {
  if (!filters) {
    return null
  }

  const { search, status } = filters

  const criteria = []

  if (status && status !== FUNDING_FILTERS.ALL) {
    criteria.push({
      field: 'archived',
      value: status === FUNDING_FILTERS.ARCHIVED,
    })
  }

  if (search) {
    criteria.push({
      comparator: 'like',
      field: 'or[0][label]',
      value: `%${search}%`,
    })
    criteria.push({
      comparator: 'like',
      field: 'or[0][customName]',
      value: `%${search}%`,
    })
    criteria.push({
      comparator: 'like',
      field: 'or[0][fundingType.name]',
      value: `%${search}%`,
    })
  }

  return criteria
}

export const updateNurseryFundingDateFieldError = (errors) => {
  if (!errors || !(errors.startDate || errors.endDate)) {
    return errors
  }

  const { endDate, startDate } = errors

  return {
    ...errors,
    invoicePeriod: [
      startDate ? `Start date: ${startDate} ` : null,
      endDate ? `End date: ${endDate}` : null],
  }
}
