import _ from 'lodash'

import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'

import { typeByObject } from 'utils/typescript'

import { Icon } from 'components'

import {
  StyledClearIconContainer,
  StyledContainer,
  StyledContainerWrapper,
  StyledIconContainer,
  StyledInput,
  StyledInputContainer,
} from './SearchBarStyled'

const SEARCH_BAR_VARIANTS = {
  EXPANDED: 'expanded',
  STANDARD: 'standard',
} as const

export interface SearchBarProps {
  disabled?: boolean
  noBackground?: boolean
  onChange: (value: string) => void
  placeholder?: string
  value?: string
  variant?: typeByObject<typeof SEARCH_BAR_VARIANTS>
}

const SearchBar: React.FC<PropsWithChildren<SearchBarProps>> = ({
  disabled,
  noBackground,
  onChange,
  placeholder,
  value,
  variant = SEARCH_BAR_VARIANTS.EXPANDED,
}) => {
  const [isUpdated, setIsUpdated] = useState<boolean>(false)
  const inputCallback = useCallback(
    _.debounce((e) => {
      if (onChange) {
        onChange(e.target.value ? e.target.value.trim() : '')
      }
    }, 500),
    [],
  )
  const input = useRef<HTMLInputElement>()

  useEffect(() => {
    if (!isUpdated && input) {
      input.current.value = value || ''
      setIsUpdated(true)
    }
  }, [isUpdated, input, value])

  const onSearchChange = (e) => {
    e.persist()

    if (inputCallback) {
      inputCallback(e)
    }
  }

  const standard = SEARCH_BAR_VARIANTS.STANDARD === variant

  return (
    <StyledContainerWrapper>
      <StyledContainer
        $noBackground={noBackground}
        $standard={standard}
      >
        <StyledInputContainer $standard={standard}>
          <StyledIconContainer>
            <Icon
              color="#989898"
              height={20}
              icon="search"
            />
          </StyledIconContainer>
          <StyledInput
            disabled={disabled}
            placeholder={placeholder}
            ref={input}
            onChange={onSearchChange}
          />
          {input.current?.value && (
            <StyledClearIconContainer>
              <Icon
                color="#989898"
                height={12}
                icon="close"
                onClick={() => {
                  input.current.value = ''
                  onChange('')
                }}
              />
            </StyledClearIconContainer>
          )}
        </StyledInputContainer>
      </StyledContainer>
    </StyledContainerWrapper>
  )
}

export default SearchBar
