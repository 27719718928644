import _ from 'lodash'

import React from 'react'

import { Button, Chart } from 'components'

import { StyledButtonContainer, StyledChart, StyledContainer } from './StatisticsChartStyled'
import { CHART_NURSERIES_LIMIT } from '../../GroupRevenueContainer'

const StatisticsChart = ({
  nurseriesChart = {},
  onChangePage,
  page,
}) => {
  if (!nurseriesChart.header.length) {
    return null
  }

  const from = (page - 1) * CHART_NURSERIES_LIMIT
  const to = from + CHART_NURSERIES_LIMIT

  return (
    <StyledContainer>
      <StyledButtonContainer>
        <Button
          disabled={0 === from}
          hierarchy="secondary"
          icon="chevron-left"
          size="medium"
          onClick={() => onChangePage(-1)}
        />
      </StyledButtonContainer>
      <StyledChart>
        <Chart.Column
          categories={_.map(nurseriesChart.header.slice(from, to), ({ name }) => name)}
          data={_.map(_.cloneDeep(nurseriesChart.data), (e) => {
            e.data = [...e.data].slice(from, to)

            return e
          })}
          enableLegends
          isCurrencyValue
        />
      </StyledChart>
      <StyledButtonContainer>
        <Button
          disabled={nurseriesChart.header.length <= to}
          hierarchy="secondary"
          icon="chevron-right"
          size="medium"
          onClick={() => onChangePage(1)}
        />
      </StyledButtonContainer>
    </StyledContainer>
  )
}

export default StatisticsChart
