import moment from 'moment'
import { createSelector } from 'reselect'

const COMPARE_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

const getEnquiryViewings = (state) => state.enquiryViewings

export const getEnquiryViewingsListSelector = createSelector(
  [getEnquiryViewings],
  (state) => state?.list,
)

export const getEnquiryViewingsListDataSelector = createSelector(
  [getEnquiryViewingsListSelector],
  (state) => state.data,
)

export const getCriteria = (filters) => {
  const {
    archived = false,
    membership,
    search,
    showAroundDate,
  } = filters || {}

  const criteria = []

  if (null !== archived && undefined !== archived) {
    criteria.push({
      field: 'archived',
      value: +archived,
    })
  }

  if (search) {
    criteria.push({
      field: 'concatenable',
      value: search,
    })
  }

  if (showAroundDate?.after && showAroundDate?.before) {
    if (showAroundDate?.after) {
      criteria.push({
        comparator: 'after',
        field: 'showAroundDate',
        value: moment(showAroundDate.after).startOf('day').format(COMPARE_DATE_TIME_FORMAT),
      })
    }

    if (showAroundDate?.before) {
      criteria.push({
        comparator: 'before',
        field: 'showAroundDate',
        value: moment(showAroundDate.before).endOf('day').format(COMPARE_DATE_TIME_FORMAT),
      })
    }
  }

  if (membership) {
    criteria.push({
      field: 'membership',
      value: membership,
    })
  }

  return criteria
}
