import React, { PropsWithChildren } from 'react'

import i18n from 'translations'

interface NameArchivedProps {
  defaultValue: string
  item: {
    archived?: boolean
    isArchived?: boolean
    name?: string
  }
}

// @ts-ignore
const NameArchived: React.FC<PropsWithChildren<NameArchivedProps>> = ({
  defaultValue,
  item = {},
}): string => {
  const { archived, isArchived, name } = item

  if (!name) {
    return defaultValue
  }

  if (archived || isArchived) {
    return `${name} (${i18n.t('global:archived')})`
  }

  return name
}

export default NameArchived
