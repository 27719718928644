import moment from 'moment'

import React, { Component } from 'react'

import { FILE_TYPES } from 'constants/mimetypes'
import { ALL_ROOMS } from 'services/legacy/rooms/constants'

import i18n from 'translations'

import { prepareExportErrors } from './StaffModalHelper'

import { ALL_STAFF_MEMBERS, REPORT_DATES, SEND_OPTIONS } from './constants'

/* eslint-disable react/no-unused-state */
const withStaffExportModal = (WrappedComponent) => class extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isExporting: false,
    }
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleBreakdownChange = ({ BREAKDOWN, changeFieldValue, value }) => {
    switch (value) {
      case BREAKDOWN.DAILY:
      case BREAKDOWN.GROUPED:
      case BREAKDOWN.PAYROLL:
      case BREAKDOWN.TOTAL:
        this.updateDates(REPORT_DATES.MONTH, changeFieldValue)

        return changeFieldValue('reportDates', REPORT_DATES.MONTH)
      case BREAKDOWN.WEEKLY:
        this.updateDates(REPORT_DATES.WEEK, changeFieldValue)

        return changeFieldValue('reportDates', REPORT_DATES.WEEK)
      default:
        return null
    }
  }

  handleReportDateChange = ({ changeFieldValue, value }) => {
    this.updateDates(value, changeFieldValue)
  }

  updateDates = (value, changeFieldValue) => {
    switch (value) {
      case REPORT_DATES.DAY:
        return changeFieldValue('reportDatesRange', [moment(), moment()])
      case REPORT_DATES.WEEK:
        return changeFieldValue('reportDatesRange', [moment().startOf('week'), moment().endOf('week')])
      case REPORT_DATES.MONTH:
        return changeFieldValue('reportDatesRange', [moment().startOf('month'), moment().endOf('month')])
      default:
        return null
    }
  }

  showExportSuccessModal = (email) => {
    const { modalActions, modalConsts } = this.props

    return modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Modals:ExportReportModal:alert', { email }),
    })
  }

  handleSendReportSuccess = ({ getAction, onSuccess, onSuccessCallback, sendTo }) => () => {
    onSuccessCallback?.()

    this.setState({ isExporting: false })

    if (ALL_STAFF_MEMBERS.value === sendTo.value) {
      return onSuccess(i18n.t('module:Modals:Staff:Export:allStaffMembers'))
    }

    if (!sendTo?.email) {
      return getAction(sendTo.value)
    }

    return onSuccess(sendTo.email)
  }

  handleSendReportFailed = (stopSubmitForm) => (error) => {
    this.setState({ isExporting: false })

    const errors = prepareExportErrors(error)

    if (errors.endDate) {
      errors.reportDatesRange = errors.endDate
    }

    return setTimeout(() => stopSubmitForm(errors))
  }

  handleSendReport = ({
    moduleType,
    onSuccess,
    params,
    sendTo,
    stopSubmitForm,
    type,
  }) => {
    const { membershipsActions } = this.props

    this.setState({ isExporting: true })

    return membershipsActions.sendReport(moduleType, type, {
      onFailed: this.handleSendReportFailed(stopSubmitForm),
      onSuccess: this.handleSendReportSuccess({
        getAction: (value) => membershipsActions.get(value, {
          onSuccess: ({ data }) => this.showExportSuccessModal(data.email),
        }),
        onSuccess: this.showExportSuccessModal,
        onSuccessCallback: onSuccess,
        sendTo,
      }),
      params,
    })
  }

  handleSendReportOther = ({
    action,
    onSuccess,
    params,
    payload,
    sendTo,
    stopSubmitForm,
    type,
  }) => {
    const { membershipsActions } = this.props

    this.setState({ isExporting: true })

    return action(type, {
      onFailed: this.handleSendReportFailed(stopSubmitForm),
      onSuccess: this.handleSendReportSuccess({
        getAction: (value) => membershipsActions.get(value, {
          onSuccess: ({ data }) => this.showExportSuccessModal(data.email),
        }),
        onSuccess: this.showExportSuccessModal,
        onSuccessCallback: onSuccess,
        sendTo,
      }),
      params,
      payload,
    })
  }

  getDateRange = ({ reportDates, reportDatesRange }) => {
    if (REPORT_DATES.MONTH === reportDates) {
      const [startDate, endDate] = reportDatesRange

      return [moment(startDate).startOf('month'), moment(endDate).endOf('month')]
    }

    return reportDatesRange
  }

  getInitialValues = ({ REPORT_TYPE_OPTIONS, breakdown }) => ({
    breakdown,
    fileFormat: FILE_TYPES.PDF.acceptHeader,
    reportDates: REPORT_DATES.MONTH,
    reportDatesRange: [moment().startOf('month'), moment().endOf('month')],
    rooms: ALL_ROOMS,
    sendOption: SEND_OPTIONS[0].value,
    ...(REPORT_TYPE_OPTIONS && { reportType: REPORT_TYPE_OPTIONS[0] }),
  })

  render() {
    const { ...other } = this.props

    const staffExportHelpers = {
      getDateRange: this.getDateRange,
      getInitialValues: this.getInitialValues,
      handleBreakdownChange: this.handleBreakdownChange,
      handleCloseClick: this.handleCloseClick,
      handleReportDateChange: this.handleReportDateChange,
      handleSendReport: this.handleSendReport,
      handleSendReportFailed: this.handleSendReportFailed,
      handleSendReportOther: this.handleSendReportOther,
      handleSendReportSuccess: this.handleSendReportSuccess,
      showExportSuccessModal: this.showExportSuccessModal,
      updateDates: this.updateDates,
    }

    return (
      <WrappedComponent
        {...other}
        staffExportHelpers={staffExportHelpers}
        staffExportState={this.state}
      />
    )
  }
}

export default withStaffExportModal
