import React from 'react'

import { generateRoute } from 'utils/routing'

import { BasicViewProps, Option } from 'constants/models'

import { Callout, EmptyState, Page, Pagination, Section, Select, Spinner, Table, Toolbar, Typography } from 'components'
import { TableColumn } from 'components/Table'
import { PaginationProps } from 'components/Pagination/Pagination'
import { TableProps } from 'components/Table/Table'

import i18n from 'translations'

import { STATUS_OPTIONS } from './constant'

const COLUMNS: TableColumn[] = [
  {
    align: 'left',
    field: 'name',
    sortable: true,
    title: i18n.t('module:Management:AttendancePeriods:Table:Header:name'),
  },
  {
    align: 'left',
    alignTh: 'left',
    field: 'period',
    title: i18n.t('module:Management:AttendancePeriods:Table:Header:period'),
  },
  {
    field: 'termDateAction',
  },
  {
    field: 'options',
  },
]

interface AttendancePeriodsViewProps extends BasicViewProps, PaginationProps, TableProps {
  onStatusChange?: (status: Option) => void
  selectedStatus?: Option
}

const AttendancePeriodsView: React.FC<AttendancePeriodsViewProps> = ({
  errorMessages,
  isLoading,
  onPageChange,
  onSortChange,
  onStatusChange,
  page,
  pageCount,
  perPage,
  selectedStatus,
  sortField,
  sortOrder,
  tableData,
  totalResults,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('global:pagination:entries')}
      titleSingular={i18n.t('global:pagination:entry')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const actions = (
    <Section.Actions
      primary={[{
        label: i18n.t('global:Add'),
        to: generateRoute('MANAGEMENT.ATTENDANCE_PERIODS.ADD'),
      }]}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!isLoading && !tableData?.length) {
      return (
        <EmptyState
          icon="enquiries"
          text1={i18n.t('module:Management:AttendancePeriods:emptyState')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <Table
          columns={COLUMNS}
          data={tableData}
          sortField={sortField}
          sortOrder={sortOrder}
          visualType="flat"
          onSortChange={onSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  return (
    <Page>
      <Page.Content>
        <Callout content={errorMessages} error />
        <Section
          actions={actions}
          title={i18n.t('module:Management:AttendancePeriods:title')}
        >
          <Typography margin="20px 0">
            {i18n.t('module:Management:AttendancePeriods:subtitle')}
          </Typography>
          <Toolbar>
            <Toolbar.Group>
              <Toolbar.Item>
                <Select
                  clearable={false}
                  options={STATUS_OPTIONS}
                  searchable={false}
                  value={selectedStatus}
                  onChange={onStatusChange}
                />
              </Toolbar.Item>
            </Toolbar.Group>
          </Toolbar>
          {renderContent()}
        </Section>
      </Page.Content>
    </Page>
  )
}

export default AttendancePeriodsView
