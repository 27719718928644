import _ from 'lodash'

import React, { PropsWithChildren } from 'react'

import { Child } from 'services/child/models'

import { Checkbox, EmptyState, InfiniteDropdowns, InfiniteScroll, Spinner, Toolbar, Typography } from 'components'

import i18n from 'translations'

import GridChildrenPickerItem from './GridChildrenPickerItem'
import {
  StyledActiveChildrenItemsCount,
  StyledContainer,
  StyledItemsContainer,
  StyledScrollableElement,
  StyledSpinnerContainer,
} from './GridChildrenPickerStyled'

interface GridChildrenPickerProps {
  isLoading: boolean
  onChange: (value: number[]) => void
  onPageChange: (page: number) => void
  onRoomChange: () => void
  onSelectAllClick: () => void
  options: Child[]
  page: number
  pageCount: number
  roomValue: number
  selectedAll: boolean
  value: number[]
  withEmptyState?: boolean
}

const GridChildrenPicker: React.FC<PropsWithChildren<GridChildrenPickerProps>> = ({
  isLoading,
  onChange,
  onPageChange,
  onRoomChange,
  onSelectAllClick,
  options,
  page,
  pageCount,
  roomValue,
  selectedAll,
  value,
  withEmptyState,
}) => {
  if (!options) {
    return null
  }

  const toggleChildItem = (id) => {
    if (onChange) {
      if (_.includes(value, id)) {
        onChange(_.filter(value, (item) => item !== id))

        return
      }

      onChange([...value, id])
    }
  }

  const renderChildItem = (item) => {
    const { firstName, id, photo, surname } = item

    const active = _.includes(value, item.id)

    return (
      <GridChildrenPickerItem
        active={active}
        id={id}
        initials={[firstName, surname]}
        key={id}
        label={`${firstName || ''} ${surname || ''}`}
        photo={photo}
        onClick={toggleChildItem}
      />
    )
  }

  const renderChildrenItems = () => {
    if ((!options || !options.length) && !isLoading && withEmptyState) {
      return (
        <EmptyState
          icon="children"
          text1={i18n.t('module:DailyDiary:global:notFoundChildren')}
        />
      )
    }

    if (isLoading && 1 === page) {
      return (
        <StyledItemsContainer>
          <StyledSpinnerContainer>
            <Spinner />
          </StyledSpinnerContainer>
        </StyledItemsContainer>
      )
    }

    return (
      <StyledScrollableElement id="scrollableDiv">
        <InfiniteScroll
          dataLength={options ? options.length : 0}
          hasMore={page < pageCount}
          isFetching={isLoading}
          next={() => onPageChange((+page) + 1)}
          scrollableTarget="scrollableDiv"
        >
          <StyledItemsContainer>
            {_.map(options, renderChildItem)}
          </StyledItemsContainer>
        </InfiniteScroll>
      </StyledScrollableElement>
    )
  }

  const valueCount = value.length

  return (
    <StyledContainer>
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <InfiniteDropdowns.Rooms
              value={roomValue}
              bodyTarget
              onChange={onRoomChange}
            />
          </Toolbar.Item>
          <Toolbar.Item>
            <Checkbox
              label={i18n.t('components:GridChildrenPicker:selectAllChildren')}
              value={selectedAll}
              onChange={onSelectAllClick}
            />
          </Toolbar.Item>
        </Toolbar.Group>
        <Toolbar.Group>
          <Toolbar.Item>
            <div>
              <Typography>
                {i18n.t('components:GridChildrenPicker:totalChildrenSelected')}
                {':'}
                <StyledActiveChildrenItemsCount $active={!!valueCount}>
                  {valueCount}
                </StyledActiveChildrenItemsCount>
              </Typography>
            </div>
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      {renderChildrenItems()}
    </StyledContainer>
  )
}

export default GridChildrenPicker
