import _ from 'lodash'

import React, { Component } from 'react'
import { Field as FormField, reduxForm } from 'redux-form'

import { roomMoveConstants } from 'services/legacy/roomMove'

import { Field, Form, GridLayout } from 'components'

import {
  StyledColumn,
  StyledFieldContainer,
  StyledMonthsRange,
  StyledParagraph,
  StyledRoomMove,
} from './RoomMoveStyled'

export const ROOM_MOVE_FORM = 'ROOM_MOVE_FORM'

class RoomMove extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEditMode: false,
      isProcessing: false,
    }
  }

  handleEditMode = (isEditMode) => {
    const { onCancelEdit, onSomeElementIsEdited, roomMove } = this.props

    if (onSomeElementIsEdited) {
      onSomeElementIsEdited(isEditMode)
    }

    if (!isEditMode && onCancelEdit) {
      onCancelEdit(roomMove)
    }

    this.setState({ isEditMode })
  }

  handleSubmit = (e) => {
    const {
      handleSubmit,
      onSubmit,
      roomMove,
    } = this.props
    const { isProcessing } = this.state

    if (isProcessing) {
      return false
    }

    this.setState({
      isProcessing: true,
    })

    return handleSubmit((values) => onSubmit(
      roomMove,
      values,
      (error) => {
        this.setState({
          isProcessing: false,
        })

        if (!error) {
          this.handleEditMode(false)
        }
      },
    ))(e)
  }

  render() {
    const {
      onCancelNewElement,
      onRemoveRoomMove,
      roomMove = {},
      rooms,
      roomsWithLeaving,
      someElementIsEdited,
    } = this.props

    const { isEditMode, isProcessing } = this.state
    const { fromNurseryClass, id, invalid, moveOn, toLeaving, toNurseryClass } = roomMove

    const actions = () => {
      if (!id) {
        return (
          <Field.InlineEdit.Actions
            isProcessing={isProcessing}
            isEdit
            submit
            onCancelClick={onCancelNewElement}
            onSaveClick={this.handleSubmit}
          />
        )
      }

      return (
        <Field.InlineEdit.Actions
          disabled={someElementIsEdited}
          isEdit={isEditMode}
          isProcessing={isProcessing}
          onCancelClick={() => this.handleEditMode(false)}
          onDeleteClick={() => onRemoveRoomMove(roomMove)}
          onEditClick={() => this.handleEditMode(true)}
          onSaveClick={this.handleSubmit}
        />
      )
    }

    const Columns = ({ firstColumn, secondColumn, thirdColumn }) => (
      <GridLayout>
        <GridLayout.Group disableRwd noVerticalMargin>
          <GridLayout.Item flex={1} padding="0" disableRwd>
            <Form.Row.FlexItem flex="0 0 200px">
              <StyledColumn>
                {firstColumn}
                {fromNurseryClass
                && Number.isInteger(fromNurseryClass.startAgeInMonths)
                && Number.isInteger(fromNurseryClass.endAgeInMonths)
                  ? (
                    <StyledMonthsRange>
                      {`${fromNurseryClass.startAgeInMonths} - ${fromNurseryClass.endAgeInMonths} months`}
                    </StyledMonthsRange>
                  ) : (fromNurseryClass && (
                    <StyledParagraph invalid>
                      Set age range
                    </StyledParagraph>
                  ))}
              </StyledColumn>
            </Form.Row.FlexItem>
          </GridLayout.Item>
          <GridLayout.Item flex={1} disableRwd>
            <Form.Row.FlexItem flex="0 0 200px">
              <StyledColumn>
                {secondColumn}
                {toNurseryClass
                && Number.isInteger(toNurseryClass.startAgeInMonths)
                && Number.isInteger(toNurseryClass.endAgeInMonths)
                  ? (
                    <StyledParagraph invalid={invalid}>
                      {`${toNurseryClass.startAgeInMonths} - ${toNurseryClass.endAgeInMonths} months`}
                    </StyledParagraph>
                  ) : (toNurseryClass && (
                    <StyledParagraph invalid>
                      Set age range
                    </StyledParagraph>
                  ))}
              </StyledColumn>
            </Form.Row.FlexItem>
          </GridLayout.Item>
          <GridLayout.Item flex={2} disableRwd>
            <Form.Row.FlexItem flex="0 0 340px">
              <StyledColumn>
                {thirdColumn}
              </StyledColumn>
            </Form.Row.FlexItem>
          </GridLayout.Item>
        </GridLayout.Group>
      </GridLayout>
    )

    const renderEditableComponent = (
      <Columns
        firstColumn={(
          <StyledFieldContainer>
            <FormField
              clearable={false}
              component={Form.Select}
              disabled={!!id}
              name="fromNurseryClass"
              options={rooms}
              placeholder="Move from"
            />
          </StyledFieldContainer>
        )}
        secondColumn={(
          <StyledFieldContainer>
            <FormField
              clearable={false}
              component={Form.Select}
              name="toNurseryClass"
              options={roomsWithLeaving}
              placeholder="Move to"
            />
          </StyledFieldContainer>
        )}
        thirdColumn={(
          <StyledFieldContainer>
            <FormField
              clearable={false}
              component={Form.Select}
              name="moveOn"
              options={roomMoveConstants.MOVE_ON_DROPDOWN}
              placeholder="Move on"
              searchable={false}
            />
          </StyledFieldContainer>
        )}
      />
    )

    const renderVisibleComponent = (
      <Columns
        firstColumn={(
          <strong>
            {fromNurseryClass && fromNurseryClass.name}
          </strong>
        )}
        secondColumn={(
          <strong>
            {toLeaving && !toNurseryClass && (
              <StyledParagraph noMargin>
                Leaving
              </StyledParagraph>
            )}
            {(toNurseryClass && !toLeaving) && toNurseryClass.name}
            {(!toNurseryClass && !toLeaving) && (
              <StyledParagraph invalid noMargin>
                Select room
              </StyledParagraph>
            )}
          </strong>
        )}
        thirdColumn={_.get(_.find(roomMoveConstants.MOVE_ON_DROPDOWN, ({ value }) => value === moveOn), 'label')}
      />
    )

    return (
      <StyledRoomMove>
        <Form onSubmit={this.handleSubmit}>
          <Field.InlineEdit
            actions={actions()}
            description={id && renderVisibleComponent}
            editableComponent={renderEditableComponent}
            isEditMode={!id ? true : isEditMode}
          />
        </Form>
      </StyledRoomMove>
    )
  }
}

export default reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
})(RoomMove)
