import moment from 'moment'
import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { DAILY_DIARY_DATE_FORMAT } from 'services/legacy/dailyDiary/constants'
import { REFERENCE_ASYNC_PAGE_TYPES } from 'services/legacy/upload/constants'

import { EVENTS, logEvent } from 'analytics'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withDailyDiaryActivitiesService } from 'services/legacy/dailyDiaryActivities'
import { withModalService } from 'services/utils/modal'
import { withSnackbarService } from 'services/utils/snackbar'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withRegisterService } from 'services/legacy/register'
import { withChildRegistersService } from 'services/legacy/childRegisters'
import { withUploadService } from 'services/legacy/upload'
import { withRouter } from 'services/router'

import { DAILY_DIARY_RECORD_MODAL_VARIANTS } from 'module/Modals/DailyDiaryRecordModal/DailyDiaryRecordModalContainer'

import i18n from 'translations'

import { ACTIVITY_RECORD_EDIT_FORM } from './components/ActivityRecord/ActivityRecord'
import ActivitiesView from './ActivitiesView'

export const ACTIVITIES_DAILY_DIARY_LIST_GROUPS = {
  read: [
    'medium',
    'activityRecord',
    'activityRecord.media',
    'activityRecord.media.children',
    'activityRecord.children',
    'activityRecord.children.nurseryClass',
  ],
}

const CHILD_REGISTERS_GROUPS = {
  read: [
    'child',
    'child.profile',
    'childRegister.child',
    'childInformation',
    'childRegister.diaryRecordStatistics',
    'childRegisterDiaryRecordStatistics',
  ],
}

const LIST_LIMIT = 10

class ActivitiesContainer extends Component {
  constructor(props) {
    super(props)

    const {
      paginationUtils: { setPageLocationQuery },
    } = props

    setPageLocationQuery(false)
  }

  componentDidMount() {
    const { paginationUtils } = this.props
    const { setPerPage } = paginationUtils

    logEvent(EVENTS.DIARY_PAGE_VIEWED, { context: 'activities' })

    setPerPage(LIST_LIMIT, this.fetch)
  }

  shouldComponentUpdate(nextProps) {
    const { location: { query: nextQuery } } = nextProps
    const { location: { query }, paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    if (
      query.date !== nextQuery.date
      || query.room !== nextQuery.room
      || query.timeType !== nextQuery.timeType
    ) {
      onPageChange(() => this.fetch(nextQuery))(1)
    }

    return true
  }

  componentWillUnmount() {
    const { childRegistersActions, dailyDiaryActivitiesActions } = this.props

    childRegistersActions.clearList()
    childRegistersActions.clearListStatistics()
    dailyDiaryActivitiesActions.clearList()
  }

  fetchStatistics = () => {
    const {
      childRegistersActions,
      childRegistersSelectors,
      location: { query },
    } = this.props
    const { date, room, timeType } = query
    const criteria = childRegistersSelectors.getListCriteria({ entryDate: date, room, timeType })

    childRegistersActions.listStatistics({
      params: {
        criteria,
        groups: CHILD_REGISTERS_GROUPS,
        page: 1,
      },
    })
  }

  fetch = (nextQuery, silent) => {
    const {
      dailyDiaryActivitiesActions,
      dailyDiaryActivitiesSelectors,
      location: { query },
      paginationUtils,
      setLocationQuery,
    } = this.props
    const { page } = paginationUtils

    const criteria = dailyDiaryActivitiesSelectors.getListCriteria(nextQuery || query)
    const { date, room, timeType } = (nextQuery || query)

    setLocationQuery({ date, room, timeType })

    dailyDiaryActivitiesActions.list({
      mergeResult: 1 !== page,
      onSuccess: this.fetchStatistics,
      params: {
        criteria,
        groups: ACTIVITIES_DAILY_DIARY_LIST_GROUPS,
        limit: LIST_LIMIT,
        page,
      },
      silent,
    })
  }

  handleUpdateRecordSuccess = (callback) => {
    const { snackbarActions } = this.props

    logEvent(EVENTS.DIARY_ITEM_UPDATED, { context: 'activities' })

    if (callback) {
      callback()
    }

    this.fetchStatistics()

    snackbarActions.show({
      message: i18n.t('module:DailyDiary:Activities:updatedSuccess'),
    })
  }

  handleRemoveRecordSuccess = () => {
    const { snackbarActions } = this.props

    logEvent(EVENTS.DIARY_ITEM_REMOVED, { context: 'activities' })

    this.fetchStatistics()

    snackbarActions.show({
      message: i18n.t('module:DailyDiary:Activities:removedSuccess'),
    })
  }

  handleRemoveRecordConfirmed = (activity) => {
    const { dailyDiaryActivitiesActions } = this.props

    dailyDiaryActivitiesActions.removeRecord(
      activity,
      this.handleRemoveRecordSuccess,
    )
  }

  handleRemoveRecord = (bottle) => {
    const { modalActions, modalConsts } = this.props

    logEvent(EVENTS.DIARY_REMOVE_ITEM_BTN_CLICKED, { context: 'activities' })

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      icon: 'trash',
      onConfirm: () => this.handleRemoveRecordConfirmed(bottle),
      text: i18n.t('module:DailyDiary:Activities:confirmRemove'),
    })
  }

  handleAddRecordSuccess = () => {
    const { snackbarActions } = this.props

    this.fetchStatistics()

    logEvent(EVENTS.DIARY_ITEM_ADDED, { context: 'activities' })

    snackbarActions.show({
      message: i18n.t('module:DailyDiary:Activities:addedSuccess'),
    })
  }

  handleAddRecordRequestClose = (next) => {
    const { dailyDiaryActivitiesActions, getFiles, temporaryActivityId, uploadActions } = this.props

    /*
     * when user do not submit the diary record intentionally, we notify API to remove it,
     * clear the linked files on cancel or close popup action
     */
    if (temporaryActivityId) {
      const files = getFiles([REFERENCE_ASYNC_PAGE_TYPES.ACTIVITY, temporaryActivityId])

      _.each(files, (file) => {
        uploadActions.markFileToRemove(file.id)
      })

      dailyDiaryActivitiesActions.setTemporaryActivityId(null)
      dailyDiaryActivitiesActions.removeRecord(
        temporaryActivityId,
      )
    }

    next()
  }

  handleAddRecord = () => {
    const {
      location: {
        query: {
          date = moment().format(DAILY_DIARY_DATE_FORMAT),
          timeType,
        },
      },
      modalActions,
      modalConsts,
    } = this.props

    logEvent(EVENTS.DIARY_ADD_ITEM_BTN_CLICKED, { context: 'activities' })

    modalActions.show(modalConsts.TYPES.DAILY_DIARY_RECORD, {
      date,
      onRequestClose: this.handleAddRecordRequestClose,
      onSuccess: this.handleAddRecordSuccess,
      timeType,
      variant: DAILY_DIARY_RECORD_MODAL_VARIANTS.ACTIVITY,
    }, {
      enableMultipleModal: true,
    })
  }

  handleUpdateRecordFailed = (response, record, callback) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    callback(true)

    return injectValidation(`${ACTIVITY_RECORD_EDIT_FORM}_${record.id}`, errors)
  }

  handleUpdateRecord = async (record, values, callback) => {
    const { dailyDiaryActivitiesActions, dailyDiaryActivitiesSelectors } = this.props
    const body = dailyDiaryActivitiesSelectors.getValuesForm({
      ...values,
    })

    delete body.media

    logEvent(EVENTS.DIARY_EDIT_ITEM_BTN_CLICKED, { context: 'activities' })

    dailyDiaryActivitiesActions.updateRecord(
      record.id,
      body,
      () => this.handleUpdateRecordSuccess(callback),
      (response) => this.handleUpdateRecordFailed(response, record, callback),
    )
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  render() {
    const {
      isFetching,
      isOffline,
      isSubmitting,
      paginationUtils,
      records,
      registers,
      totalResults,
    } = this.props
    const { getPageCount, page } = paginationUtils

    const pageCount = getPageCount(totalResults)

    return (
      <ActivitiesView
        isFetching={isFetching}
        isOffline={isOffline}
        isSubmitting={isSubmitting}
        page={page}
        pageCount={pageCount}
        records={records}
        registers={registers}
        totalResults={totalResults}
        onAddRecord={this.handleAddRecord}
        onPageChange={this.handlePageChange}
        onRemoveRecord={this.handleRemoveRecord}
        onUpdateRecord={this.handleUpdateRecord}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (formName, params) => stopSubmit(formName, params),
}

const mapState = (state, {
  appSelectors,
  dailyDiaryActivitiesListState,
  dailyDiaryActivitiesSelectors,
  dailyDiaryActivitiesSingleState,
  registerSelectors,
  registerState,
  uploadSelectors,
}) => ({
  getFiles: (reference) => uploadSelectors.getFilesByReference(reference)(state),
  isFetching: appSelectors.getIsFetching(
    dailyDiaryActivitiesListState,
    registerState,
  ),
  isOffline: appSelectors.getAppIsOffline(state),
  isSubmitting: appSelectors.getIsSubmitting(
    dailyDiaryActivitiesSingleState,
  ),
  records: dailyDiaryActivitiesSelectors.getDailyDiaryActivitiesListData(state),
  registers: registerSelectors.getRegisterDataSelector(state),
  temporaryActivityId: dailyDiaryActivitiesSelectors.getTemporaryActivityId(state),
  totalResults: appSelectors.getTotalResults(dailyDiaryActivitiesListState),
})

const enhance = compose(
  withAppService,
  withChildRegistersService,
  withDailyDiaryActivitiesService,
  withModalService,
  withPaginationUtils,
  withRegisterService,
  withRouter,
  withRouterUtils,
  withSnackbarService,
  withUploadService,
  connect(mapState, mapDispatch),
)

export default enhance(ActivitiesContainer)
