import _ from 'lodash'

import React from 'react'

import { Avatar, Checkbox, EmptyState, Table, Typography } from 'components'

import i18n from 'translations'

import EstimatedInvoiceTotalCell from './EstimatedInvoiceTotalCell'
import PreviousInvoicesTotalCells from './PreviousInvoicesTotalCells'

const BULK_INVOICES_TABLE_COLUMNS = (translate) => [
  {
    align: 'left',
    field: 'checkbox',
    width: '50px',
  },
  {
    align: 'left',
    field: 'child',
    title: _.upperFirst(translate.t('global:child')),
    width: '220px',
  },
  {
    align: 'left',
    field: 'generatedInvoice',
    title: translate.t('module:Finance:GenerateInvoices:generatedBatchInvoice'),
    width: '210px',
  },
  {
    align: 'left',
    field: 'previousInvoices',
    title: translate.t('module:Finance:GenerateInvoices:mostRecentInvoices'),
    width: `${4 * 210}px`,
  },
]

interface BulkInvoicesTableProps {
  // @TODO
  childList: any[]
  onAmountClick: (id: number) => void
  onChildSelected: (id: number) => void
  period: string
  selected: number[]
}

const BulkInvoicesTable: React.FC<BulkInvoicesTableProps> = ({
  childList,
  onAmountClick,
  onChildSelected,
  period,
  selected,
}) => {
  const renderTableRow = (item) => {
    const {
      bulkGenerated,
      estimatedInvoice,
      firstName,
      generatedInvoiceType,
      hasError,
      id,
      photo,
      previousInvoices,
      sentAt,
      surname,
    } = item

    const { hasItems, invoiceId: estimatedInvoiceId, total: estimatedInvoiceTotal } = estimatedInvoice || {}

    const disabled = (bulkGenerated && !hasError) || !hasItems
    const checked = !disabled && (0 <= _.find(selected, (childId) => childId === id))

    return ({
      checkbox: (
        <Checkbox
          disabled={disabled}
          value={checked}
          onChange={() => onChildSelected(id)}
        />
      ),
      child: (
        <Avatar
          avatarSize="medium"
          initials={[firstName, surname]}
          src={photo}
          title={(
            <Typography fontSize={14} primary>
              {`${firstName} ${surname}`}
            </Typography>
          )}
        />
      ),
      generatedInvoice: (
        <EstimatedInvoiceTotalCell
          child={item}
          generatedInvoiceType={generatedInvoiceType}
          hasError={hasError}
          hasItems={hasItems}
          invoiceId={estimatedInvoiceId}
          period={period}
          sentAt={sentAt}
          value={estimatedInvoiceTotal}
          onAmountClick={onAmountClick}
        />
      ),
      previousInvoices: (
        <PreviousInvoicesTotalCells child={item} invoices={previousInvoices} onAmountClick={onAmountClick} />
      ),
    })
  }

  if (!childList?.length) {
    return (
      <EmptyState
        icon="invoice"
        text1={i18n.t('module:Finance:GenerateInvoices:noChildrenWithGeneratedInvoices')}
      />
    )
  }

  return (
    <Table
      columns={BULK_INVOICES_TABLE_COLUMNS(i18n)}
      data={_.map(childList, renderTableRow)}
      tableLayout="fixed"
    />
  )
}

export default BulkInvoicesTable
