// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/fonts/Figtree/Figtree-Light.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "../assets/fonts/Figtree/Figtree-Medium.ttf";
import ___CSS_LOADER_URL_IMPORT_2___ from "../assets/fonts/Figtree/Figtree-SemiBold.ttf";
import ___CSS_LOADER_URL_IMPORT_3___ from "../assets/fonts/Figtree/Figtree-Bold.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: 'Figtree';\n    font-style: normal;\n    font-weight: 300;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n}\n\n@font-face {\n    font-family: 'Figtree';\n    font-style: normal;\n    font-weight: 400;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('truetype');\n  }\n\n@font-face {\n    font-family: 'Figtree';\n    font-style: normal;\n    font-weight: 600;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n}\n\n@font-face {\n    font-family: 'Figtree';\n    font-style: normal;\n    font-weight: 800;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('truetype');\n}\n", "",{"version":3,"sources":["webpack://./src/styled/fonts.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,+DAA0E;AAC9E;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,+DAA2E;EAC7E;;AAEF;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,+DAA6E;AACjF;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,+DAAyE;AAC7E","sourcesContent":["@font-face {\n    font-family: 'Figtree';\n    font-style: normal;\n    font-weight: 300;\n    src: url('/src/assets/fonts/Figtree/Figtree-Light.ttf') format('truetype');\n}\n\n@font-face {\n    font-family: 'Figtree';\n    font-style: normal;\n    font-weight: 400;\n    src: url('/src/assets/fonts/Figtree/Figtree-Medium.ttf') format('truetype');\n  }\n\n@font-face {\n    font-family: 'Figtree';\n    font-style: normal;\n    font-weight: 600;\n    src: url('/src/assets/fonts/Figtree/Figtree-SemiBold.ttf') format('truetype');\n}\n\n@font-face {\n    font-family: 'Figtree';\n    font-style: normal;\n    font-weight: 800;\n    src: url('/src/assets/fonts/Figtree/Figtree-Bold.ttf') format('truetype');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
