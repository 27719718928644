import _ from 'lodash'

import React from 'react'

import { ABSENCE_TYPE } from 'module/Register/constants'
import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'

import { getDateString, isSameDay } from 'utils/date'

import {
  Avatar,
  Banner,
  DateString,
  EmptyState,
  ModalBox,
  Space,
  Spinner,
  Timeline,
  Toolbar,
  Typography,
} from 'components'

import i18n from 'translations'

import EmptyStateImage from './assets/empty-state-staff-log.svg'

const RegisterStaffLogModal = ({ absence, child, hideModal, isLoading, staffLogs }) => {
  const renderTimelineItem = (item) => {
    const { date, id, staff, text } = item

    return <Timeline.Item date={date} key={id} text={text} user={staff} />
  }

  const renderAbsenceBannerText = () => {
    const childFirstName = child?.firstName?.trim()
    const { absenceType, endDate, startDate } = absence

    const isSameDayEntry = isSameDay(startDate, endDate)
    const displayStartDate = getDateString(startDate, DISPLAY_SHORT_DATE_FORMAT)
    const displayEndDate = getDateString(endDate, DISPLAY_SHORT_DATE_FORMAT)

    if (ABSENCE_TYPE.ABSENCE === absenceType) {
      if (isSameDayEntry) {
        return i18n.t('module:Register:Modal:AbsenceBanner:single', {
          childFirstName,
          date: displayStartDate,
        })
      }

      return i18n.t('module:Register:Modal:AbsenceBanner:range', {
        childFirstName,
        endDate: displayEndDate,
        startDate: displayStartDate,
      })
    }

    if (isSameDayEntry) {
      return i18n.t('module:Register:Modal:HolidayBanner:single', {
        childFirstName,
        date: displayStartDate,
      })
    }

    return i18n.t('module:Register:Modal:HolidayBanner:range', {
      childFirstName,
      endDate: displayEndDate,
      startDate: displayStartDate,
    })
  }

  const renderAbsenceBanner = () => (
    <React.Fragment>
      <Banner.Info>
        {renderAbsenceBannerText()}
      </Banner.Info>
      <Space padding="0 0 10px" />
    </React.Fragment>
  )

  const renderTimeline = () => {
    if (!staffLogs || 0 === staffLogs.length) {
      const { firstName, gender } = child

      const herOrHim = 'f' === gender ? 'her' : 'him'

      return (
        <EmptyState
          image={EmptyStateImage}
          text1={`Tap ${firstName} in, add a comment or mark ${herOrHim} as absent to view the staff log.`}
        />
      )
    }

    return (
      <Timeline>
        {_.map(staffLogs.reverse(), renderTimelineItem)}
      </Timeline>
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    const { ageMonths, birthDate, firstName, photo, surname } = child

    return (
      <div>
        <Toolbar>
          <Toolbar.Group>
            <Toolbar.Item>
              <Avatar
                initials={[firstName, surname]}
                src={photo}
                title={(
                  <Typography fontSize={14} primary>
                    {`${firstName} ${surname}`}
                  </Typography>
                )}
              />
            </Toolbar.Item>
          </Toolbar.Group>
          <Toolbar.Group>
            <Toolbar.Item>
              <DateString date={birthDate} />
              {` (${ageMonths} months)`}
            </Toolbar.Item>
          </Toolbar.Group>
        </Toolbar>
        {absence && renderAbsenceBanner()}
        {renderTimeline()}
      </div>
    )
  }

  return (
    <ModalBox title="Staff log" onCloseClick={hideModal}>
      {renderContent()}
    </ModalBox>
  )
}

export default RegisterStaffLogModal
