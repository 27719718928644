import { compose } from 'recompose'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { EVENTS, logEvent } from 'analytics'

import { getBackendErrors } from 'utils/backendErrors'

import { withAppService } from 'services/app'
import { withCreditNotesService } from 'services/legacy/creditNotes'
import { withModalService } from 'services/utils/modal'
import { withNurseriesService } from 'services/nurseries'

import IssueCreditNoteModalView from './IssueCreditNoteModalView'

import { ISSUE_CREDIT_NOTE_FORM } from './components/IssueCreditNoteModalForm'

const NURSERY_INVOICE_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
    'nurseryInvoiceSettings.numbers',
    'invoiceNumbers',
    'nursery.organizationSettings',
    'organizationSettings',
    'organizationSettings.invoice',
    'organizationInvoiceSettings',
  ],
}

class IssueCreditNoteModalContainer extends Component {
  constructor(props) {
    super(props)

    const { invoice: { id } } = props

    this.state = {
      values: {
        invoice: id,
      },
    }
  }

  componentDidMount() {
    const {
      nurseriesActions,
      nurseryOptions,
    } = this.props

    nurseriesActions.get(nurseryOptions.id, {
      params: { groups: NURSERY_INVOICE_SETTINGS_GROUPS },
    })
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmitSuccess = () => {
    const { onConfirm } = this.props

    logEvent(EVENTS.INVOICE_ISSUE_CREDIT_NOTE_SUBMITTED)

    this.handleCloseClick()
    onConfirm()
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props
    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    return injectValidation(ISSUE_CREDIT_NOTE_FORM, errors)
  }

  handleSubmit = (fields) => {
    const { creditNotesActions, creditNotesSelectors } = this.props
    const { values } = this.state

    const body = creditNotesSelectors.getCreditNoteValuesForm({
      ...fields,
      ...values,
    })

    creditNotesActions.create(
      body,
      this.handleSubmitSuccess,
      this.handleSubmitFailed,
    )
  }

  render() {
    const {
      creditNoteIsFetching,
      errorMessages,
      invoice,
      nurseryInvoiceNumberWithDescriptions,
    } = this.props
    const { values } = this.state
    const { creditNoteNumberDescription } = nurseryInvoiceNumberWithDescriptions

    return (
      <IssueCreditNoteModalView
        creditNoteIsFetching={creditNoteIsFetching}
        creditNoteNumber={creditNoteNumberDescription}
        errorMessages={errorMessages}
        invoice={invoice}
        values={values}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (formName, errors) => stopSubmit(formName, errors),
}

const mapState = (state, {
  appSelectors,
  creditNotesSelectors,
  creditNotesSingleState,
  nurseriesSelectors,
  params,
}) => ({
  creditNoteIsFetching: creditNotesSelectors.getCreditNotesSingleIsFetching(state),
  errorMessages: appSelectors.getErrorMessages(creditNotesSingleState),
  nurseryInvoiceNumberWithDescriptions: nurseriesSelectors.getNurseryInvoiceNumberWithDescriptions(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const enhance = compose(
  withAppService,
  withCreditNotesService,
  withModalService,
  withNurseriesService,
  connect(mapState, mapDispatch),
)

export default enhance(IssueCreditNoteModalContainer)
