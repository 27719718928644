import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

import { EVENTS, logEvent } from 'analytics'

import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { withSortingUtils } from 'services/utils/sorting'
import { withRouter } from 'services/router'
import { withAppService } from 'services/app'
import { withInvoicesService } from 'services/legacy/invoices'
import { withModalService } from 'services/utils/modal'

import { getCriteria, getInvoices, getStatusOptions } from './helpers'

import FinanceInvoicesView from './FinanceInvoicesView'

const groups = {
  read: [
    'child',
    'child.childBankDetail',
    'childBankDetail',
    'childBankDetail.paymentContact',
    'carerChildRelation.carer',
    'carer',
  ],
}

class FinanceInvoicesContainer extends Component {
  constructor(props) {
    super(props)

    const { invoicesConstants: { INVOICE_DATE_OPTIONS } } = props

    const {
      dateType = INVOICE_DATE_OPTIONS[0].value,
      endDate,
      room,
      searchBarValue,
      startDate,
      status,
      type,
    } = props.location.query

    this.state = {
      defaultSort: {
        sortField: 'issueDate',
        sortOrder: 'DESC',
      },
      filters: {
        dateType,
        endDate,
        room,
        searchBarValue,
        startDate,
        status,
        type,
      },
      statusOptions: getStatusOptions(),
    }
  }

  componentDidMount() {
    this.fetch()

    logEvent(EVENTS.INVOICE_LIST_PAGE_VIEWED, { context: 'invoice list' })
  }

  componentWillUnmount() {
    const { invoicesActions } = this.props

    invoicesActions.clear()
  }

  fetch = () => {
    const { invoicesActions, paginationUtils, setLocationQuery, sortingUtils } = this.props
    const { defaultSort, filters } = this.state

    const { page } = paginationUtils
    const { sortField: defaultSortField, sortOrder: defaultSortOrder } = defaultSort
    const { sortField, sortOrder } = sortingUtils

    const criteria = getCriteria(filters)

    setLocationQuery(filters)

    const params = {
      criteria,
      groups,
      order: {
        sortField: sortField || defaultSortField,
        sortOrder: sortOrder || defaultSortOrder,
      },
      page,
    }

    invoicesActions.list(params)
  }

  handleActionButtonClickConfirm = (type) => () => {
    const { modalActions, modalConsts } = this.props

    const params = {
      delete: {
        icon: 'trash',
        text: 'Your invoices has been deleted successfully.',
      },
      download: {
        icon: 'download',
        text: 'Your invoices are downloading.',
      },
    }[type]

    modalActions.show(modalConsts.TYPES.ALERT, params)
  }

  handleActionButtonClick = (type) => () => {
    const { modalActions, modalConsts } = this.props

    const params = {
      delete: {
        onConfirm: this.handleActionButtonClickConfirm('delete'),
        text: 'Are you sure you want to delete these invoices?',
      },
      download: {
        onConfirm: this.handleActionButtonClickConfirm('download'),
        text: 'Are you sure you want to download these invoices?',
      },
    }[type]

    modalActions.show(modalConsts.TYPES.CONFIRM, params)
  }

  handleSearchBarChange = (searchBarValue) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({ filters: { ...prevState.filters, searchBarValue } }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleTypeChange = (type) => {
    const { paginationUtils, sortingUtils } = this.props

    const { onPageChange } = paginationUtils
    const { onSortChange } = sortingUtils

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          type: type ? type.value : null,
        },
      }),
      () => onSortChange(onPageChange(this.fetch)(1)),
    )

    logEvent(EVENTS.INVOICE_LIST_FILTER_USED, { context: 'invoice list', type: 'invoice type' })
  }

  handleSortChange = () => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(1)
  }

  handleDateTypeChange = (dateType) => {
    const { paginationUtils, sortingUtils } = this.props

    const { onPageChange } = paginationUtils
    const { onSortChange } = sortingUtils

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          dateType: dateType ? dateType.value : null,
        },
      }),
      () => {
        const { filters } = this.state
        const { endDate, startDate } = filters

        if (startDate && endDate) {
          onSortChange(onPageChange(this.fetch)(1))
        }
      },
    )

    logEvent(EVENTS.INVOICE_LIST_FILTER_USED, { context: 'invoice list', type: 'date type' })
  }

  handleDateChange = (dateRange) => {
    const { paginationUtils, sortingUtils } = this.props

    const { onPageChange } = paginationUtils
    const { onSortChange } = sortingUtils
    const [startDate, endDate] = dateRange

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          endDate: endDate ? moment(endDate).format(DEFAULT_DATE_FORMAT) : undefined,
          startDate: startDate ? moment(startDate).format(DEFAULT_DATE_FORMAT) : undefined,
        },
      }),
      () => {
        if ((startDate && endDate) || (!startDate && !endDate)) {
          onSortChange(onPageChange(this.fetch)(1))
        }
      },
    )

    logEvent(EVENTS.INVOICE_LIST_FILTER_USED, { context: 'invoice list', type: 'date' })
  }

  handleRoomChange = (room) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, room: room ? room.value : null },
      }),
      () => onPageChange(this.fetch)(1),
    )

    logEvent(EVENTS.INVOICE_LIST_FILTER_USED, { context: 'invoice list', type: 'room' })
  }

  handleStatusChange = (status) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, status: status ? status.value : null },
      }),
      () => onPageChange(this.fetch)(1),
    )

    logEvent(EVENTS.INVOICE_LIST_FILTER_USED, { context: 'invoice list', type: 'status' })
  }

  getInvoices = () => {
    const { invoiceList } = this.props

    return getInvoices(invoiceList)
  }

  handleExportClick = () => {
    const { modalActions, modalConsts } = this.props

    logEvent(EVENTS.INVOICE_LIST_EXPORT_CLICKED)

    modalActions.show(modalConsts.TYPES.INVOICE_EXPORT)
  }

  render() {
    const { invoiceListMeta, invoicesConstants, isFetching, paginationUtils, sortingUtils } = this.props
    const { filters, statusOptions } = this.state
    const { INVOICE_DATE_OPTIONS, INVOICE_TYPES_DROPDOWN } = invoicesConstants

    const { getPageCount, onPageChange, page, perPage } = paginationUtils
    const { onSortChange, sortField, sortOrder } = sortingUtils
    const { dateType, endDate, room, searchBarValue, startDate, status, type } = filters

    const invoices = this.getInvoices()

    const isLoading = isFetching || !invoices

    const pageCount = getPageCount(invoiceListMeta ? invoiceListMeta.totalResults : null)
    const dateRange = [startDate, endDate]

    const isEmpty = !searchBarValue
      && (!invoices || 0 === invoices.length)
      && !dateRange[0]
      && !dateRange[1]
      && !room
      && !status

    return (
      <FinanceInvoicesView
        dateRange={dateRange}
        dateType={dateType}
        dateTypeOptions={INVOICE_DATE_OPTIONS}
        invoices={invoices}
        isEmpty={isEmpty}
        isLoading={isLoading}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        room={room}
        sortField={sortField}
        sortOrder={sortOrder}
        status={status}
        statusOptions={statusOptions}
        totalResults={invoiceListMeta && invoiceListMeta.totalResults}
        type={type}
        typeOptions={INVOICE_TYPES_DROPDOWN}
        onDateChange={this.handleDateChange}
        onDateTypeChange={this.handleDateTypeChange}
        onDownloadButtonClick={this.handleActionButtonClick('download')}
        onExportClick={this.handleExportClick}
        onPageChange={onPageChange(this.fetch)}
        onRoomChange={this.handleRoomChange}
        onSearchBarChange={this.handleSearchBarChange}
        onStatusChange={this.handleStatusChange}
        onTableSortChange={onSortChange(this.handleSortChange)}
        onTypeChange={this.handleTypeChange}
      />
    )
  }
}

const mapState = (state, { appSelectors, invoicesListState, invoicesSelectors }) => ({
  invoiceList: invoicesSelectors.getInvoiceListSelector(state),
  invoiceListMeta: invoicesSelectors.getInvoiceListMetaSelector(state),
  isFetching: appSelectors.getIsFetching(invoicesListState),
})

const enhance = compose(
  withRouter,
  withAppService,
  withInvoicesService,
  withModalService,
  withPaginationUtils,
  withRouterUtils,
  withSortingUtils,
  connect(mapState),
)

export default enhance(FinanceInvoicesContainer)
