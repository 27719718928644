import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import { EVENTS, logEvent } from 'analytics'

import {
  DatePicker,
  EmptyState,
  InfiniteDropdowns,
  List,
  Page,
  PageTabNav,
  Pagination,
  SearchBar,
  Section,
  Select,
  Spinner,
  Toolbar,
} from 'components'

import i18n from 'translations'

import ObservationItem from './components/ObservationItem'

const ObservationsListView = ({
  HomeObservationsGranted,
  authAccessMap,
  author,
  childId,
  date,
  eventLogContext,
  hasAccessToLikesAndComments,
  hasAccessToLikesAndCommentsSettings,
  isApprovalPage,
  isChildContext,
  isLoading,
  keyPerson,
  observations,
  onAuthorChange,
  onChildSearch,
  onDateChange,
  onExportClick,
  onKeyPersonChange,
  onPageChange,
  onRoomChange,
  onStatusChange,
  page,
  pageCount,
  pageType,
  perPage,
  room,
  router,
  status,
  statusOptions,
  totalResults,
}) => {
  const renderHeader = () => (
    <Toolbar bottomLine>
      <Toolbar.Group>
        <Toolbar.Item>
          <DatePicker
            value={date}
            clearable
            onChange={onDateChange}
          />
        </Toolbar.Item>
        {!isChildContext && (
          <Toolbar.Item>
            <InfiniteDropdowns.Users
              placeholder={i18n.t('global:createdBy')}
              value={author}
              onChange={onAuthorChange}
            />
          </Toolbar.Item>
        )}
        {!isApprovalPage && (
          <Toolbar.Item>
            <Select
              options={statusOptions}
              placeholder={_.upperFirst(i18n.t('global:status'))}
              value={status}
              onChange={onStatusChange}
            />
          </Toolbar.Item>
        )}
        {!isChildContext && (
          <Toolbar.Item>
            <InfiniteDropdowns.Users
              placeholder={i18n.t('global:KeyPerson')}
              value={keyPerson}
              onChange={onKeyPersonChange}
            />
          </Toolbar.Item>
        )}
        {!isChildContext && (
          <Toolbar.Item>
            <InfiniteDropdowns.Rooms
              value={room}
              onChange={onRoomChange}
            />
          </Toolbar.Item>
        )}
      </Toolbar.Group>
    </Toolbar>
  )

  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      titlePlural={i18n.t('module:Learning:Observations:ObservationsList:observations')}
      titleSingular={i18n.t('module:Learning:Observations:ObservationsList:observation')}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!observations?.length) {
      return (
        <EmptyState
          icon="observations"
          text1={i18n.t('module:Learning:Observations:ObservationsList:emptyState')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <List.Standard>
          {_.map(observations, (observation) => (
            <ObservationItem
              hasAccessToLikesAndComments={hasAccessToLikesAndComments}
              hasAccessToLikesAndCommentsSettings={hasAccessToLikesAndCommentsSettings}
              key={observation.id}
              observation={observation}
              pageType={pageType}
            />
          ))}
        </List.Standard>
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = (
    <Section.Actions
      options={[{
        auth: authAccessMap.section.pdfAutomation,
        onClick: onExportClick,
        type: 'export',
      }]}
      primary={[{
        label: _.upperFirst(i18n.t('module:Learning:Observations:ObservationsList:observation')),
        onClick: () => logEvent(EVENTS.OBSERVATION_ADD_BTN_CLICKED, { context: eventLogContext }),
        to: `${generateRoute('LEARNING.OBSERVATIONS.ADD')}${isChildContext ? `?c=${childId}` : ''}`,
      }]}
    />
  )

  const title = isApprovalPage
    ? `${i18n.t('module:Approvals:title')}:`
    : _.upperFirst(i18n.t('module:Learning:Observations:ObservationsList:observations'))

  return (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t('global:searchPlaceholder')}
        onChange={onChildSearch}
      />
      <Page isLoading={isLoading}>
        <Page.Content>
          {isChildContext && HomeObservationsGranted && (
            <PageTabNav>
              <PageTabNav.Item
                isActive={router.isActive(`/children/${childId}/learning-journey/observations`, true)}
                label={_.upperFirst(i18n.t('module:Learning:Observations:ObservationsList:observations'))}
                to={`/children/${childId}/learning-journey/observations`}
              />
              <PageTabNav.Item
                isActive={router.isActive(`/children/${childId}/learning-journey/observations/home-observations`, true)}
                label={i18n.t('module:Learning:Observations:ObservationsList:observationsFromHome')}
                to={`/children/${childId}/learning-journey/observations/home-observations`}
              />
            </PageTabNav>
          )}
          <Section
            actions={!isApprovalPage && actions}
            title={title}
            titleSecond={isApprovalPage
              && _.upperFirst(i18n.t('module:Learning:Observations:ObservationsList:observations'))}
          >
            {renderHeader()}
            {renderContent()}
          </Section>
        </Page.Content>
      </Page>
    </React.Fragment>
  )
}

export default ObservationsListView
