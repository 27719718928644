import _ from 'lodash'

import React, { PropsWithChildren } from 'react'

import { getBrandingColor } from 'utils/branding'

import { DropdownMenu, Icon } from 'components'

import i18n from 'translations'

import {
  StyledDropdownMenu,
  StyledLink,
  StyledMenu,
  StyledMenuContainer,
  StyledMenuItem,
  StyledMobileMenu,
  StyledNewBadge,
  StyledTabletMenu,
} from './ProfileHeaderStyled'

interface ProfileHeaderMenuItem {
  auth?: boolean
  label: string
  to: string
}

interface ProfileHeaderMenuProps {
  menuItems: ProfileHeaderMenuItem[]
}

const ProfileHeaderMenu: React.FC<PropsWithChildren<ProfileHeaderMenuProps>> = ({ menuItems }) => {
  const authorizedMenuItems = _.filter(menuItems, (item) => false !== item?.auth)

  const renderMenuItem = ({ active, isNew, label, noLeftBorder, to }: {
    active?: boolean
    isNew?: boolean
    label: string
    noLeftBorder?: boolean
    to?: string
  }) => (
    <StyledMenuItem key={label}>
      {active
        ? (
          <StyledLink
            $isNew={isNew}
            $noLeftBorder={noLeftBorder}
            activeClassName="active"
            to={to}
          >
            {label}
          </StyledLink>
        ) : (
          <StyledLink
            $isNew={isNew}
            $noLeftBorder={noLeftBorder}
            to={to}
          >
            {label}
          </StyledLink>
        )}
      {isNew && (
        <StyledNewBadge>
          <Icon
            color={getBrandingColor('tertiary-color')}
            height={14}
            icon="new"
          />
        </StyledNewBadge>
      )}
    </StyledMenuItem>
  )

  const renderMenuItems = (items) => _.map(items, (item) => renderMenuItem({ ...item, active: true }))

  const renderDropdownItem = (item) => (
    <DropdownMenu.Item
      key={item.label}
      label={item.label}
      to={item.to}
    />
  )

  const renderDropdown = (items, noLeftBorder = false) => (
    <StyledDropdownMenu>
      <DropdownMenu
        button={renderMenuItem({
          label: _.upperFirst(i18n.t('global:more')),
          noLeftBorder,
        })}
        zIndex={1000}
      >
        {_.map(items, (item) => renderDropdownItem(item))}
      </DropdownMenu>
    </StyledDropdownMenu>
  )

  const renderDesktopMenu = () => {
    const firstPart = _.slice(authorizedMenuItems, 0, 5)
    const secondPart = _.slice(authorizedMenuItems, 5)

    return (
      <StyledMenu>
        {renderMenuItems(firstPart)}
        {!!secondPart.length && renderDropdown(secondPart, true)}
      </StyledMenu>
    )
  }

  const renderTabletMenu = () => {
    const firstPart = _.slice(authorizedMenuItems, 0, 4)
    const secondPart = _.slice(authorizedMenuItems, 4)

    return (
      <StyledTabletMenu>
        {renderMenuItems(firstPart)}
        {!!secondPart.length && renderDropdown(secondPart, true)}
      </StyledTabletMenu>
    )
  }

  const renderMobileMenu = () => (
    <StyledMobileMenu>
      {renderDropdown(authorizedMenuItems)}
    </StyledMobileMenu>
  )

  return (
    <StyledMenuContainer>
      {renderDesktopMenu()}
      {renderTabletMenu()}
      {renderMobileMenu()}
    </StyledMenuContainer>
  )
}

export default ProfileHeaderMenu
