import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { getFormInitialValues } from 'redux-form'

import { withAppService } from 'services/app'
import { withModalService } from 'services/utils/modal'
import { withOrganizationService } from 'services/organization'
import { getAuthOrganization } from 'services/security/selectors'
import { withRouter } from 'services/router'

import SettingsInvoicesGlobalEditView from './SettingsInvoicesGlobalEditView'
import { FORM_NAME } from './components/GlobalInvoiceSettingsAddForm'

const ORGANIZATION_INVOICE_SETTINGS_GROUPS = {
  read: [
    'organization.organizationSettings',
    'organizationSettings.invoice',
    'organizationInvoiceSettings',
    'organizationInvoiceSettings.numbers',
    'invoiceNumbers',
    'organization.bankDetails',
  ],
}

class SettingsInvoicesGlobalEditContainer extends Component {
  componentDidMount() {
    const { authOrganization, organizationActions } = this.props

    const apiParams = { groups: ORGANIZATION_INVOICE_SETTINGS_GROUPS }

    organizationActions.get(authOrganization.id, { params: apiParams })
  }

  UNSAFE_componentWillMount() {
    const { organizationActions } = this.props

    organizationActions.clearSingle()
  }

  handleSuccess = () => {
    const { navigate } = this.props

    navigate('/settings/invoices')
  }

  updateSettings = ({ fields }) => {
    const {
      authOrganization,
      modalActions,
      organizationActions,
      organizationSelectors,
      organizationSingleState,
    } = this.props

    const apiParams = { groups: ORGANIZATION_INVOICE_SETTINGS_GROUPS }
    const payload = organizationSelectors.getPayload({
      fields,
      organizationSingleState,
    })

    modalActions.hide()
    organizationActions.update(authOrganization.id, {
      onSuccess: this.handleSuccess,
      params: apiParams,
      payload,
    })
  }

  handleSubmit = (fields) => {
    const {
      formInitialValues,
      modalActions,
      modalConsts,
      organizationConstants,
    } = this.props
    const { GLOBAL_SETTINGS } = organizationConstants
    const { globalInheritanceType: initialGlobalInheritanceType } = formInitialValues
    const { globalInheritanceType } = fields

    if (
      GLOBAL_SETTINGS.APPLY_NON_CUSTOMIZABLE === globalInheritanceType
      && GLOBAL_SETTINGS.APPLY_NON_CUSTOMIZABLE !== initialGlobalInheritanceType
    ) {
      modalActions.show(modalConsts.TYPES.CONFIRM, {
        confirmButtonLabel: 'Confirm',
        icon: 'warning',
        onConfirm: () => this.updateSettings({ fields }),
        text: (
          <div>
            <p>
              Some of your nurseries have customised settings.
            </p>
            <p>
              {'Switch to use the global settings will change each nursery\'s individual settings to global settings.'}
            </p>
          </div>
        ),
      })

      return
    }

    this.updateSettings({ fields })
  }

  render() {
    const { errorMessages, globalType, isFetching, organizationConstants, organizationSingleState } = this.props

    const globalSettingsOptions = organizationConstants.GLOBAL_SETTINGS_OPTIONS
    const initialValues = globalType ? { globalInheritanceType: globalType } : null
    const isLoading = isFetching
    const isFormLoading = organizationSingleState.isSubmitting

    return (
      <SettingsInvoicesGlobalEditView
        errorMessages={errorMessages}
        globalSettingsOptions={globalSettingsOptions}
        initialValues={initialValues}
        isFormLoading={isFormLoading}
        isLoading={isLoading}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, { appSelectors, organizationSelectors, organizationSingleState }) => ({
  authOrganization: getAuthOrganization(state),
  errorMessages: appSelectors.getErrorMessages(organizationSingleState),
  formInitialValues: getFormInitialValues(FORM_NAME)(state),
  globalType: organizationSelectors.getGlobalType(state),
  isFetching: appSelectors.getIsFetching(organizationSingleState),
})

const enhance = compose(
  withRouter,
  withAppService,
  withModalService,
  withOrganizationService,
  connect(mapState),
)

export default enhance(SettingsInvoicesGlobalEditContainer)
