import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { getAuthUser, hasRoleManagementAccess, isRoomLeader, isSeniorPractitioner } from 'services/security/selectors'
import { getApplicationProperties } from 'services/app/applicationContext/selectors'
import { verifyMediaStatus } from 'services/legacy/upload/selectors'

import { OBSERVATION_STATUSES } from '../constants'

const getObservationsSingleState = (state) => state.observations.single

export const getObservationsSingleStateDataSelector = createSelector(
  [getObservationsSingleState],
  (state) => state?.data,
)

export const isSubmittingAsDraft = createSelector(
  [getObservationsSingleState],
  (state) => state?.isSubmittingAsDraft,
)

export const isSubmittingAsReview = createSelector(
  [getObservationsSingleState],
  (state) => state?.isSubmittingAsReview,
)

export const autosaveProcessing = createSelector(
  [getObservationsSingleState],
  (state) => state?.autosave,
)

export const mapObservation = (data) => {
  if (!data) {
    return null
  }

  const { author, childObservations, comments, customMontessoriActivity, media, montessoriActivity } = data
  const childObservation = childObservations?.length ? childObservations[0] : []

  return {
    ...data,
    authorFullName: author?.fullName || null,
    child: childObservation.child,
    items: childObservation.items,
    media: _.map(media, verifyMediaStatus),
    montessoriActivityName: customMontessoriActivity || montessoriActivity?.name || null,
    nextMontessoriActivities: childObservation.nextMontessoriActivities || [],
    nextSteps: childObservation.nextSteps || [],
    notes: [comments, childObservation.comments || ''],
  }
}

export const getObservation = createSelector(
  [getObservationsSingleState],
  (state) => state && mapObservation(state.data),
)

export const getChildObservation = (childId) => createSelector(
  [getObservationsSingleState],
  (state) => _.find(state?.data?.childObservations, ({ child: { id } }) => id === +childId),
)

export const getObservationImages = createSelector([getObservation], (observation) => {
  const mapImage = ({ id, url }) => ({ id, url })

  return observation && observation.media.map(mapImage)
})

export const getObservationNextSteps = createSelector([getObservation], (observation) => {
  if (!observation) {
    return []
  }

  return [...observation.nextMontessoriActivities, ...observation.nextSteps]
})

export const getExportCriteria = createSelector((state) => state, ({ child, fields }) => {
  const criteria = []
  const { allObservations } = fields

  if (child) {
    const { id } = child

    criteria.push({
      field: 'id',
      value: id,
    })
  } else {
    criteria.push({
      field: 'isArchived',
      value: 0,
    })
  }

  _.forEach(fields, (item, key) => {
    switch (key) {
      case 'room':
        if (item && item.value) {
          criteria.push({
            field: 'nurseryClass',
            value: item.value,
          })
        }
        break

      case 'keyPerson':
        if (item && item.value) {
          criteria.push({
            field: 'keyWorkers',
            value: item.value,
          })
        }
        break

      case 'observationsDateRange':
        if (!allObservations && 'observationsDateRange' === key && item && 2 === item.length) {
          const [startDate, endDate] = item

          if (startDate) {
            criteria.push({
              field: 'startDate',
              value: moment(startDate).startOf('day').toISOString(),
            })
          }

          if (endDate) {
            criteria.push({
              field: 'endDate',
              value: moment(endDate).endOf('day').toISOString(),
            })
          }
        }
        break

      case 'memberships':
        _.forEach(item, (membership) => {
          criteria.push({
            field: 'memberships[]',
            value: membership.value,
          })
        })
        break

      case 'manager':
        if (item) {
          criteria.push({
            field: 'email',
            value: item.email,
          })
        }
        break

      default:
        break
    }
  })

  return criteria
})

export const canEditObservation = (observation, state) => {
  const authUser = getAuthUser(state)

  if (!observation || !observation.author || !authUser) {
    return null
  }

  const isRL = isRoomLeader(state)
  const isSP = isSeniorPractitioner(state)
  const isManager = hasRoleManagementAccess(state)

  if (isRL || isManager) {
    return true
  }

  if (observation.author.id !== authUser.id) {
    return false
  }

  // NOTE: Senior practitioner can edit/delete their own observation
  if (observation.author.id === authUser.id && isSP) {
    return true
  }

  const { status } = observation
  const { CHANGES_REQUESTED, DRAFT, PENDING_APPROVAL } = OBSERVATION_STATUSES

  return status === DRAFT.value || status === PENDING_APPROVAL.value || status === CHANGES_REQUESTED.value
}

export const getInitialValuesForBasicForm = createSelector([getObservationsSingleState], ({ data }) => {
  if (!data) {
    return null
  }

  const {
    comments,
    customMontessoriActivity,
    media,
    montessoriActivity,
    observationDate,
  } = data

  return {
    comments,
    customMontessoriActivity,
    media: _.map(media, verifyMediaStatus),
    montessoriActivity: montessoriActivity?.id && ({
      label: montessoriActivity.name,
      value: montessoriActivity.id,
    }),
    observationDate: moment(observationDate),
  }
})

export const getObservationStatesDropdown = createSelector(
  [getApplicationProperties],
  ({ observationStates }) => _.map(observationStates, ([value, label]) => ({ label, value })),
)

export const getInitialValuesForChildrenForm = createSelector(
  [getObservationsSingleState, getObservationStatesDropdown],
  ({ data }, observationStates) => {
    if (!data?.childObservations?.length) {
      return null
    }

    const children = []

    _.each(data?.childObservations, ({
      comments,
      id,
      montessoriActivityState,
      sharedWithParents,
    }) => {
      if (id) {
        children[id] = {
          comments,
          id,
          montessoriActivityState: _.find(observationStates, ({ value }) => value === montessoriActivityState),
          sharedWithParents,
        }
      }
    })

    if (!children.length) {
      return null
    }

    return children
  },
)

export const getUpdateObservationPayload = ({ basicForm, childrenForms }) => {
  const payload = {}

  const {
    comments,
    customMontessoriActivity,
    homeObservation,
    montessoriActivity,
    observationDate,
  } = basicForm || {}

  payload.comments = comments || ''
  payload.customMontessoriActivity = customMontessoriActivity || ''

  if (observationDate) {
    payload.observationDate = moment(observationDate).toISOString()
  }

  if (homeObservation) {
    payload.homeObservation = {
      id: homeObservation,
    }
  }

  payload.montessoriActivity = ''

  if (montessoriActivity && montessoriActivity.value) {
    payload.montessoriActivity = {
      id: montessoriActivity.value,
    }
  }

  payload.childObservations = _.map(childrenForms, ({ child, comments: childComments, id, sharedWithParents }) => ({
    ...(child?.id ? {
      child: {
        id: child.id,
      },
    } : {}),
    comments: childComments,
    id,
    sharedWithParents,
  }))

  return payload
}

const isOwnObservationSelector = createSelector(
  [getObservation, getAuthUser],
  (observation, user) => {
    if (!observation?.author?.id
      || !user?.id) {
      return false
    }

    const { author: { id } } = observation
    const { id: userId } = user

    return id === userId
  },
)

const canBeApproveBySeniorPractitionerSelector = createSelector(
  [isOwnObservationSelector, isSeniorPractitioner],
  (isOwnObservation, seniorPractitioner) => !(!isOwnObservation || !seniorPractitioner),
)

export const canApproveObservationSelector = createSelector(
  [getObservation, isRoomLeader, canBeApproveBySeniorPractitionerSelector],
  (observation, roomLeader, canBeApproveBySeniorPractitioner) => {
    if (
      !observation
      || OBSERVATION_STATUSES.APPROVED.value === observation.status
    ) {
      return false
    }

    return !!(roomLeader || canBeApproveBySeniorPractitioner)
  },
)

export const canSendObservationForApprovalSelector = createSelector(
  [getObservation, isSeniorPractitioner, isOwnObservationSelector],
  (observation, seniorPractitioner, isOwnObservation) => {
    if (
      !observation
      || OBSERVATION_STATUSES.DRAFT.value !== observation.status
    ) {
      return false
    }

    return !seniorPractitioner && isOwnObservation
  },
)
