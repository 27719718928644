import _ from 'lodash'
import moment from 'moment'

import { DEFAULT_DATE_FORMAT } from 'constants/date'

export const getHourDiffBetweenTime = (startTime, endTime) => {
  const startTimeWithDate = moment().startOf('day').add(startTime, 'hours')
  const endTimeWithDate = moment().startOf('day').add(endTime, 'hours')
  const timeDuration = moment.duration(endTimeWithDate.diff(startTimeWithDate))

  return timeDuration.asHours()
}

export const isOverLapping = (progressList) => {
  let overlap = false

  progressList.forEach((progress) => {
    overlap = overlap || !!progress.nestedProgressOverlapping
  })

  return overlap
}

export const isTimeRangesOverlapping = (timeRange) => {
  if (!timeRange || timeRange.length) {
    return false
  }

  const sortedRanges = timeRange.sort((previous, current) => {
    // get the start date from previous and current
    const previousTime = previous.startTime
    const currentTime = current.startTime

    // if the previous is earlier than the current
    if (previousTime < currentTime) {
      return -1
    }

    // if the previous time is the same as the current time
    if (previousTime === currentTime) {
      return 0
    }

    // if the previous time is later than the current time
    return 1
  })

  for (let index = 0; index < sortedRanges.length; index++) { // eslint-disable-line
    if (0 !== index) {
      // get the previous range
      const current = sortedRanges[index]
      const previous = sortedRanges[index - 1]

      // check for any overlap
      const previousEnd = previous.endTime
      const currentStart = current.startTime

      if (previousEnd >= currentStart) {
        return true
      }
    }
  }

  return false
}

export const getMaxNestedProgressCount = (progressList) => {
  const filteredData = _.filter(progressList, 'nestedProgressOverlapping')
  const mappedData = _.map(filteredData, (progress) => progress.nestedProgressList.length)

  return _.max(mappedData)
}

export const getProgressListWithOverlap = (progressList) => _.map(progressList, (progress) => {
  const { nestedProgressList } = progress

  const nestedProgressOverlapping = isTimeRangesOverlapping(nestedProgressList)

  return {
    ...progress,
    nestedProgressOverlapping,
  }
})

export const getIsDateBetweenSessions = (date, sessions) => {
  let isDateBetweenSession = false

  _.forEach(sessions, ({ endDate, isOngoing, startDate }) => {
    if (moment(date).format(DEFAULT_DATE_FORMAT) >= moment(startDate).format(DEFAULT_DATE_FORMAT) && isOngoing) {
      isDateBetweenSession = true

      return
    }

    if (moment(date).format(DEFAULT_DATE_FORMAT) >= moment(startDate).format(DEFAULT_DATE_FORMAT)
    && moment(date).format(DEFAULT_DATE_FORMAT) <= moment(endDate).format(DEFAULT_DATE_FORMAT)) {
      isDateBetweenSession = true
    }
  })

  return isDateBetweenSession
}
