export default {
  borderRadius: '4px',
  breakpointFullMenu: 1480,
  fontFamily: "'Figtree', helvetica, arial, sans-serif",
  tabletWidthResolution: 1024,
  layoutWidth: 1100,
  menuBarHeight: 92,
  mobileWidthResolution: 740,
  shadow: '0 0 4px 1px #D4D4D4',
  topBarHeight: 60,
}

/**
 * Number of z-index value should be between 0 and 1000
 */
/* eslint sort-keys: 0 sort-keys-fix/sort-keys-fix: 0 */
export const ZINDEX_ORDER = {
  STICKY_TABLE: 15,
  BASIC: 100,
  DRAWER: 200,
  STICKY_FOOTER: 300,
  SELECT: 400,
  SIDEBAR: 500,
  MAIN_HEADER: 600,
  UPLOAD_POPOVER: 700,
  MODAL: 750,
  POPOVER: 800,
  FIXED_SELECT: 900,
  TOOLTIP: 1000,
  GALLERY: 1100,
  MENU: 1200,
  MESSAGING: 1300,
  NOTIFICATION: 1300,
  NOTIFICATION_MENU: 1400,
  VARIOUS_PAGES: 2000,
}
