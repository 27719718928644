import React from 'react'

import { ModalBox } from 'components'

import i18n from 'translations'

import AddFundingToRegularBookingsModalForm, {
  AddFundingToRegularBookingsModalFormProps,
  AddFundingToRegularBookingsModalFormValues,
} from './components/AddFundingToRegularBookingsModalForm'

interface AddFundingToRegularBookingsModalViewProps {
  initialValues: AddFundingToRegularBookingsModalFormValues
  isEditMode: boolean
}

const AddFundingToRegularBookingsModalView: React.FC<
  AddFundingToRegularBookingsModalFormProps
  & AddFundingToRegularBookingsModalViewProps
> = ({
  alternate,
  isEditMode,
  isPreview,
  onCloseClick,
  ...rest
}) => {
  const renderTitle = () => {
    if (isPreview) {
      return i18n.t('modals:AddFundingToRegularBookings:previewTitle', { week: alternate })
    }

    return i18n.t(
      isEditMode ? 'modals:AddFundingToRegularBookings:editTitle' : 'modals:AddFundingToRegularBookings:title',
      { week: alternate },
    )
  }

  return (
    <ModalBox
      maxWidth={680}
      title={renderTitle()}
      onCloseClick={onCloseClick}
    >
      <AddFundingToRegularBookingsModalForm
        alternate={alternate}
        isPreview={isPreview}
        onCloseClick={onCloseClick}
        {...rest}
      />
    </ModalBox>
  )
}

export default AddFundingToRegularBookingsModalView
