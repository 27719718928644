import _ from 'lodash'

import React from 'react'

import { NEXT_STEPS_FILTERS_OPTIONS } from 'services/legacy/nextSteps/constants'

import {
  Callout,
  DatePicker,
  EmptyState,
  InfiniteDropdowns,
  List,
  Page,
  Pagination,
  SearchBar,
  Select,
  Spinner,
  Toolbar,
} from 'components'

import i18n from 'translations'

import NextStepRecord from './components/NextStepRecord'

const NextStepsListView = ({
  errorMessages,
  filters,
  isChildContext,
  isLoading,
  nextSteps,
  onDateChange,
  onFilterChange,
  onMarkAsAchieved,
  onPageChange,
  onReset,
  onSubmitAchieved,
  page,
  pageCount,
  perPage,
  singleItemIsFetching,
  totalResults,
}) => {
  const { date, keyPerson, room, type } = filters

  const renderHeader = () => (
    <Toolbar>
      <Toolbar.Group>
        {!isChildContext && (
          <Toolbar.Item>
            <DatePicker
              value={date}
              clearable
              range
              onChange={onDateChange}
            />
          </Toolbar.Item>
        )}
        <Toolbar.Item>
          <Select
            options={NEXT_STEPS_FILTERS_OPTIONS}
            placeholder={i18n.t('module:Learning:NextSteps:NextStepsList:typePlaceholder')}
            value={type}
            onChange={onFilterChange('type')}
          />
        </Toolbar.Item>
        {!isChildContext && (
          <Toolbar.Item>
            <InfiniteDropdowns.Rooms
              value={room}
              onChange={onFilterChange('room')}
            />
          </Toolbar.Item>
        )}
        {!isChildContext && (
          <Toolbar.Item>
            <InfiniteDropdowns.Users
              value={keyPerson}
              onChange={onFilterChange('keyPerson')}
            />
          </Toolbar.Item>
        )}
      </Toolbar.Group>
    </Toolbar>
  )

  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (!nextSteps?.length) {
      return (
        <EmptyState
          icon="next-steps"
          text1={i18n.t('module:Learning:NextSteps:NextStepsList:notFound')}
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <List.Standard>
          {_.map(nextSteps, (item) => (
            <NextStepRecord
              isChildContext={isChildContext}
              item={item}
              key={item.id}
              singleItemIsFetching={singleItemIsFetching}
              onMarkAsAchieved={onMarkAsAchieved}
              onReset={onReset}
              onSubmitAchieved={onSubmitAchieved}
            />
          ))}
        </List.Standard>
        {renderPagination()}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {!isChildContext && (
        <SearchBar
          placeholder={i18n.t('global:searchPlaceholder')}
          onChange={onFilterChange('search')}
        />
      )}
      <Page.Section
        isLoading={isLoading}
        title={i18n.t('module:Learning:NextSteps:NextStepsList:title')}
      >
        <Callout content={errorMessages} error />
        {renderHeader()}
        {renderContent()}
      </Page.Section>
    </React.Fragment>
  )
}

export default NextStepsListView
