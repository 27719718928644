import styled from 'styled-components'

import { StyledSelectedIconContainer } from 'components/UserTile/UserTileStyled'

export const StyledContainer = styled.div`
  margin-bottom: -10px;
`

export const StyledItemContainer = styled.div`
  padding-bottom: 10px;
  
  ${StyledSelectedIconContainer} {
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
`
