import _ from 'lodash'
import * as moment from 'moment'

import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'

import { Modifier } from 'react-day-picker/types/Modifiers'
import { PlacementType } from 'react-laag/dist/PlacementType'

import { typeByObject } from 'utils/typescript'

import { Popover } from 'components'

import { DATE_PICKER_TYPE } from './DatePickerConstants'
import DatePickerMonth from './DatePickerMonth'

import PopoverButton from './components/PopoverButton'
import YearMonthSelect from './components/YearMonthSelect'

import { StyledDatePicker, VISUAL_TYPE } from './DatePickerStyled'

export interface DatePickerProps {
  clearable?: boolean
  disabled?: boolean
  disabledDays?: Modifier | Modifier[]
  error?: string
  hideNavigationButton?: boolean
  label?: string
  onArrowClick?: (direction: 1 | -1, newDate: moment.Moment, onChange: () => any) => any
  onChange?: (date: moment.Moment | moment.Moment[]) => any
  onValueChange?: (date: moment.Moment | moment.Moment[]) => any
  placeholder?: string
  placement?: PlacementType
  range?: boolean
  type?: typeByObject<typeof DATE_PICKER_TYPE>
  value?: moment.MomentInput | moment.MomentInput[]
  visualType?: typeByObject<typeof VISUAL_TYPE>
  width?: string
}

const DatePicker: React.FC<PropsWithChildren<DatePickerProps>> = ({
  clearable,
  disabled,
  disabledDays,
  error,
  hideNavigationButton,
  label,
  onArrowClick,
  onChange,
  onValueChange,
  placeholder,
  placement = 'bottom-start',
  range,
  type = DATE_PICKER_TYPE.DAY,
  value,
  visualType,
  width,
}) => {
  const [hoverRange, setHoverRange] = useState(undefined)
  const [hoverEndDate, setHoverEndDate] = useState()
  const [yearMonthValue, setYearMonthValue] = useState()
  const [isOpen, setIsOpen] = useState(false)

  const getDateValue = useCallback(() => {
    const dateValue = value || undefined

    if (DATE_PICKER_TYPE.MONTH === type && !value) {
      return moment((value as moment.MomentInput || moment().format('MM-YYYY')), 'MM-YYYY')
    }

    if (DATE_PICKER_TYPE.WEEK === type && !value) {
      return [moment().startOf('week'), moment().endOf('week')]
    }

    if (DATE_PICKER_TYPE.MONTH === type && range && !value) {
      return [moment().startOf('month'), moment().endOf('month')]
    }

    if (range) {
      return _.isArray(value)
        ? [value[0] ? moment(value[0]) : undefined, value[1] ? moment(value[1]) : undefined]
        : [undefined, undefined]
    }

    return dateValue ? moment(dateValue as moment.MomentInput) : null
  }, [range, type, value])

  const [
    localDateValue,
    setLocalDateValue,
  ] = useState<moment.Moment | undefined | moment.Moment[] | undefined[]>(getDateValue())

  useEffect(() => {
    setLocalDateValue(getDateValue())
  }, [type, value, getDateValue])

  useEffect(() => {
    setYearMonthValue(undefined)
  }, [isOpen, type, range, value])

  const getWeekDays = (weekStart) => {
    const days = [weekStart.toDate()]

    _.times(6, (i) => {
      days.push(
        moment(weekStart)
          .add(i + 1, 'days')
          .toDate(),
      )
    })

    return days
  }

  const currentValue = _.isArray(localDateValue) ? localDateValue[0] : localDateValue

  const getWeekRange = (date) => ({
    from: moment(date)
      .startOf('week')
      .toDate(),
    to: moment(date)
      .endOf('week')
      .toDate(),
  })

  const isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from)
    const isRangeSelected = from && to

    return !from || isBeforeFirstDay || isRangeSelected
  }

  const handleDayChange = (date, modifiers = {} as any) => {
    if (modifiers.disabled) {
      return null
    }

    if (DATE_PICKER_TYPE.DAY === type && range) {
      if ((localDateValue[0] && localDateValue[1]) || (!localDateValue[0] && !localDateValue[1])) {
        setHoverEndDate(undefined)

        return setLocalDateValue([moment(date), undefined])
      }

      const dateRange = DateUtils.addDayToRange(date, {
        from: localDateValue[0]?.toDate(),
        to: localDateValue[1]?.toDate(),
      })
      const { from, to } = dateRange

      setIsOpen(false)
      setHoverEndDate(undefined)

      if (onValueChange) {
        onValueChange([
          from ? moment(from) : undefined,
          to ? moment(to) : undefined,
        ])
      }

      return onChange([
        from ? moment(from) : undefined,
        to ? moment(to) : undefined,
      ])
    }

    if (DATE_PICKER_TYPE.MONTH === type && range) {
      const dateRange = DateUtils.addDayToRange(date, {
        from: localDateValue[0].toDate(),
        to: localDateValue[1].toDate(),
      })
      const { from, to } = dateRange

      if (onValueChange) {
        onValueChange([
          from ? moment(from).startOf('month') : undefined,
          to ? moment(to).endOf('month') : undefined,
        ])
      }

      return onChange([
        from ? moment(from).startOf('month') : undefined,
        to ? moment(to).endOf('month') : undefined,
      ])
    }

    if (DATE_PICKER_TYPE.WEEK === type) {
      setIsOpen(false)

      if (onValueChange) {
        onValueChange([moment(getWeekRange(date).from), moment(getWeekRange(date).to)])
      }

      return onChange([moment(getWeekRange(date).from), moment(getWeekRange(date).to)])
    }

    setIsOpen(false)

    const oldDate = moment(localDateValue as moment.Moment)

    if (onValueChange) {
      onValueChange(
        moment(date)
          .set('hours', +oldDate.format('HH'))
          .set('minutes', +oldDate.format('mm')),
      )
    }

    return onChange(
      moment(date)
        .set('hours', +oldDate.format('HH'))
        .set('minutes', +oldDate.format('mm')),
    )
  }

  const handleDayEnter = (date) => {
    if (DATE_PICKER_TYPE.DAY === type && range) {
      if (!isSelectingFirstDay(localDateValue[0]?.toDate(0), localDateValue[1]?.toDate, date)) {
        setHoverEndDate(date)

        return
      }

      return
    }

    setHoverRange(getWeekRange(date))
  }

  const handleWeekClick = (weekNumber, days) => {
    if (onValueChange) {
      onValueChange(moment(days?.[0]))
    }

    onChange(moment(days?.[0]))
  }

  const handleChangeMonth = (selectedValue) => {
    setIsOpen(false)

    if (onValueChange) {
      onValueChange(selectedValue)
    }

    onChange(selectedValue)
  }

  // @ts-ignore
  const daysAreSelected = 0 < localDateValue?.length || DATE_PICKER_TYPE.WEEK === type

  let modifiers = {}

  if (DATE_PICKER_TYPE.WEEK === type) {
    modifiers = {
      hoverRange,
      hoverRangeEnd: hoverRange && hoverRange.to,
      hoverRangeStart: hoverRange && hoverRange.from,
      selectedRange: daysAreSelected && {
        from: localDateValue?.[0]?.toDate(),
        to: localDateValue?.[1]?.toDate(),
      },
      selectedRangeEnd: daysAreSelected && localDateValue?.[1]?.toDate(),
      selectedRangeStart: daysAreSelected && localDateValue?.[0]?.toDate(),
    }
  } else if (range) {
    modifiers = {
      end: localDateValue?.[1]?.toDate() || hoverEndDate || undefined,
      start: localDateValue?.[0]?.toDate() || undefined,
    }
  } else if (DATE_PICKER_TYPE.MONTH === type) {
    modifiers = {
      highlighted: moment(localDateValue as moment.Moment, 'MM-YYYY').toDate(),
    }
  } else {
    modifiers = {
      highlighted: moment(localDateValue as moment.Moment).toDate(),
    }
  }

  const getSelectedDays = () => {
    if (range) {
      // @ts-ignore
      const [from, to] = localDateValue

      return [from ? from.toDate() : undefined, {
        from: from ? from.toDate() : undefined,
        to: to?.toDate() || hoverEndDate || undefined,
      }]
    }

    if (DATE_PICKER_TYPE.WEEK === type) {
      return getWeekDays(currentValue)
    }

    // @ts-ignore
    return localDateValue?.toDate()
  }

  const handleYearMonthChange = (date) => {
    setYearMonthValue(date)
  }

  const shouldHandleDayMouseEnter = (DATE_PICKER_TYPE.DAY === type && range) || DATE_PICKER_TYPE.WEEK === type

  const renderContent = () => {
    if (DATE_PICKER_TYPE.MONTH === type) {
      return (
        <DatePickerMonth
          disabledDays={disabledDays}
          numberOfYears={range ? 2 : 1}
          range={range}
          value={localDateValue}
          onChangeMonth={handleChangeMonth}
        />
      )
    }

    const CaptionElement = ({ date, localeUtils }) => useMemo(() => (
      <YearMonthSelect
        date={date}
        localeUtils={localeUtils}
        onChangeDate={handleYearMonthChange}
      />
    ), [date, localeUtils])

    return (
      <StyledDatePicker>
        <DayPicker
          captionElement={CaptionElement}
          disabledDays={disabledDays}
          firstDayOfWeek={1}
          modifiers={modifiers}
          month={yearMonthValue || (!!value && moment(_.isArray(value) ? value[0] : value).toDate())}
          numberOfMonths={range && DATE_PICKER_TYPE.DAY === type ? 2 : 1}
          selectedDays={getSelectedDays()}
          showOutsideDays={!(range && DATE_PICKER_TYPE.DAY === type)}
          showWeekNumbers={DATE_PICKER_TYPE.WEEK === type}
          onDayClick={handleDayChange}
          onDayMouseEnter={shouldHandleDayMouseEnter && handleDayEnter}
          onWeekClick={handleWeekClick}
        />
      </StyledDatePicker>
    )
  }

  return (
    <Popover
      button={(
        <PopoverButton
          clearable={clearable && !disabled}
          currentValue={currentValue}
          disabled={disabled}
          disabledDays={disabledDays}
          error={error}
          hideNavigationButton={hideNavigationButton}
          label={label}
          localDateValue={localDateValue}
          placeholder={placeholder}
          range={range}
          type={type}
          value={value}
          visualType={visualType}
          width={width}
          onArrowClick={onArrowClick}
          onChange={(dateValue) => {
            if (onValueChange) {
              onValueChange(dateValue)
            }

            onChange(dateValue)
          }}
        />
      )}
      disabled={disabled}
      isOpen={isOpen}
      placement={placement as PlacementType}
      buttonFull
      disableCloseInside
      onClick={() => setIsOpen((oldValue) => !oldValue)}
      onOutsideClick={() => setIsOpen(false)}
    >
      {renderContent()}
    </Popover>
  )
}

export default DatePicker
