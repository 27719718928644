import _ from 'lodash'
import moment from 'moment'
import { flatten } from 'utils/flatnest'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { STATUS_TYPES_DROPDOWN } from 'services/legacy/requestedExtraSessions/constants'
import { DEFAULT_DATE_FORMAT } from 'constants/date'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withRequestedExtraSessionsService } from 'services/legacy/requestedExtraSessions'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSortingUtils } from 'services/utils/sorting'
import { withRouter } from 'services/router'

import { getTableColumns, getTableData } from './RequestedExtraSessionsListHelpers'

import RequestedExtraSessionsListView from './RequestedExtraSessionsListView'
import { REQUEST_EXTRA_SESSION_GROUPS, REQUEST_EXTRA_SESSION_GROUPS_V3 } from './constants'

const AVAILABILITY_STATISTICS_GROUPS = {
  read: [
    'availabilityStatistics',
    'availabilityStatistics.availableTimes',
    'availabilityStatistics.unavailableTimes',
    'requestExtraSessionStatistics.availabilityStatistics',
    'requestExtraSessionStatistics.parentRequestExtraSession',
    'requestExtraSessionStatistics.staffRequiredStatistics',
    'staffRequiredStatistics.times',
    'staffRequiredStatisticsTime',
    'statisticsTime',
  ],
}

class RequestedExtraSessionsListContainer extends Component {
  constructor(props) {
    super(props)

    const {
      endDate,
      search,
      session,
      startDate,
      status,
    } = props.location.query
    const { isFinanceV3Enabled } = props

    this.state = {
      filters: {
        endDate,
        search,
        session,
        startDate,
        status,
      },
      tableColumns: getTableColumns(isFinanceV3Enabled),
    }
  }

  componentDidMount() {
    const { isFinanceV3Enabled } = this.props

    if (_.isBoolean(isFinanceV3Enabled)) {
      this.fetch()
    }
  }

  componentDidUpdate(prevProps) {
    const { isFinanceV3Enabled } = this.props

    if (
      undefined !== isFinanceV3Enabled
      && null !== isFinanceV3Enabled
      && prevProps?.isFinanceV3Enabled !== isFinanceV3Enabled
    ) {
      this.fetch()
    }
  }

  componentWillUnmount() {
    const { requestedExtraSessionsActions } = this.props

    requestedExtraSessionsActions.clearAvailabilityStatistics()
    requestedExtraSessionsActions.clearCostStatistics()
    requestedExtraSessionsActions.clearList()
  }

  fetch = () => {
    const {
      isFinanceV3Enabled,
      paginationUtils,
      requestedExtraSessionsActions,
      requestedExtraSessionsSelectors,
      setLocationQuery,
      sortingUtils,
    } = this.props
    const { filters } = this.state

    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    setLocationQuery(flatten(filters))

    const criteria = requestedExtraSessionsSelectors.getListCriteria(filters, false, isFinanceV3Enabled)
    const statisticsCriteria = requestedExtraSessionsSelectors.getListCriteria(filters, true)

    const params = {
      criteria,
      groups: isFinanceV3Enabled ? REQUEST_EXTRA_SESSION_GROUPS_V3 : REQUEST_EXTRA_SESSION_GROUPS,
      order: {
        sortField: sortField || 'date',
        sortOrder: sortOrder || 'DESC',
      },
      page,
    }

    const statisticsParams = {
      criteria: statisticsCriteria,
    }

    requestedExtraSessionsActions.list({
      onSuccess: this.fetchAvailabilityStatistics,
      params,
    })

    if (1 === page) {
      requestedExtraSessionsActions.getCostStatistics({ params: statisticsParams })
    }
  }

  fetchAvailabilityStatistics = ({ data }) => {
    const { requestedExtraSessionsActions } = this.props

    if (!_.isEmpty(data)) {
      return requestedExtraSessionsActions.getAvailabilityStatistics({
        params: {
          criteria: _.map(data, ({ id }) => ({
            field: 'id[]',
            value: id,
          })),
          groups: AVAILABILITY_STATISTICS_GROUPS,
        },
      })
    }

    return null
  }

  handleDateChange = (dateRange) => {
    const { paginationUtils, sortingUtils } = this.props

    const { onPageChange } = paginationUtils
    const { onSortChange } = sortingUtils
    const [startDate, endDate] = dateRange

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          endDate: endDate ? moment(endDate).format(DEFAULT_DATE_FORMAT) : undefined,
          startDate: startDate ? moment(startDate).format(DEFAULT_DATE_FORMAT) : undefined,
        },
      }),
      () => {
        if ((startDate && endDate) || (!startDate && !endDate)) {
          onSortChange(onPageChange(this.fetch)(1))
        }
      },
    )
  }

  handleStatusChange = (status) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, status: status ? status.value : null },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleSessionChange = (session) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, session: session ? session.value : null },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSortChange = () => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(1)
  }

  handleSearch = (search) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, search },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleRedirectToDetails = (id) => {
    const { navigate } = this.props

    navigate(generateRoute('PARENT_INBOX.REQUESTED_EXTRA_SESSIONS.DETAILS', { id }))
  }

  render() {
    const {
      availabilityStatistics,
      costStatistics,
      isAvailabilityStatisticsFetching,
      isCostStatisticsFetching,
      isFetching,
      isFinanceV3Enabled,
      paginationUtils,
      requestedExtraSessions,
      sortingUtils,
      totalResults,
    } = this.props
    const { filters, tableColumns } = this.state
    const { endDate, search, session, startDate, status } = filters
    const { getPageCount, page, perPage } = paginationUtils
    const { onSortChange, sortField, sortOrder } = sortingUtils

    const dateRange = [startDate, endDate]
    const pageCount = getPageCount(totalResults)
    const tableData = getTableData({
      isAvailabilityStatisticsFetching,
      isFinanceV3Enabled,
      onClick: this.handleRedirectToDetails,
      requestedExtraSessions,
      statistics: availabilityStatistics,
    })

    return (
      <RequestedExtraSessionsListView
        costStatistics={costStatistics}
        dateRange={dateRange}
        isCostStatisticsFetching={isCostStatisticsFetching}
        isFinanceV3Enabled={isFinanceV3Enabled}
        isLoading={isFetching}
        page={page}
        pageCount={pageCount}
        perPage={perPage}
        search={search}
        session={session}
        sortField={sortField}
        sortOrder={sortOrder}
        status={status}
        statusOptions={STATUS_TYPES_DROPDOWN}
        tableColumns={tableColumns}
        tableData={tableData}
        totalResults={totalResults}
        onDateChange={this.handleDateChange}
        onPageChange={this.handlePageChange}
        onSearch={this.handleSearch}
        onSessionChange={this.handleSessionChange}
        onSortChange={onSortChange(this.handleSortChange)}
        onStatusChange={this.handleStatusChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  requestedExtraSessionsAvailabilityStatisticsState,
  requestedExtraSessionsCostStatisticsState,
  requestedExtraSessionsListState,
  requestedExtraSessionsSelectors,
}) => ({
  availabilityStatistics: requestedExtraSessionsSelectors.getRequestedExtraSessionsAvailabilityStatistics(state),
  costStatistics: requestedExtraSessionsSelectors.getRequestedExtraSessionsCostStatistics(state),
  isAvailabilityStatisticsFetching: appSelectors.getIsFetching(requestedExtraSessionsAvailabilityStatisticsState),
  isCostStatisticsFetching: appSelectors.getIsFetching(requestedExtraSessionsCostStatisticsState),
  isFetching: appSelectors.getIsFetching(requestedExtraSessionsListState),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  requestedExtraSessions: requestedExtraSessionsSelectors.getRequestedExtraSessionsList(state),
  totalResults: appSelectors.getTotalResults(requestedExtraSessionsListState),
})

const enhance = compose(
  withAppService,
  withPaginationUtils,
  withRequestedExtraSessionsService,
  withRouter,
  withSortingUtils,
  connect(mapState),
)

export default enhance(RequestedExtraSessionsListContainer)
