import React from 'react'
import { connect } from 'react-redux'

import { injectActions } from 'utils/service'

import actions from './actions'

import * as injuriesSelectors from './selectors'

const withInjuriesService = (WrappedComponent) => {
  const mapState = ({ injuries }) => ({ injuries })

  const mapDispatch = injectActions('injuriesActions', actions)

  const Component = (props) => {
    const { injuries, injuriesActions, ...other } = props

    return (
      <WrappedComponent
        injuriesActions={injuriesActions}
        injuriesListState={injuries.list}
        injuriesSelectors={injuriesSelectors}
        injuriesSignaturesState={injuries.signatures}
        injuriesSingleState={injuries.single}
        {...other}
      />
    )
  }

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withInjuriesService
