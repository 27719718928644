import _ from 'lodash'
import moment from 'moment'
import { flatten } from 'utils/flatnest'
import { v4 } from 'uuid'

import React, { useEffect, useState } from 'react'
import { change, getFormSyncErrors, getFormValues, stopSubmit } from 'redux-form'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'recompose'

import { ModalType } from 'modals'
import {
  Allocation,
  AllocationDefault,
  ChildProduct,
  ChildProductDiscount,
  ChildProductRegularAllocatedFunding,
  ChildProductRegularAmountDiscount,
  ChildProductRegularItem,
  ChildProductRegularLinkDiscount,
  ChildProductRegularPercentageDiscount,
  ChildProductRegularSession,
  FeeCalculationType,
} from 'services/booking/childBooking/constants'
import { ROLES } from 'constants/security'
import { ChildProductTypes } from 'services/booking/childProducts/constants'
import { DAYS_OF_WEEK_LIST, DEFAULT_DATE_FORMAT, DaysOfWeek } from 'constants/date'
/* eslint-disable max-len, import-newlines/enforce */
import {
  AddSessionToRegularBookingsModalFormValues,
} from 'modals/RegularBookings/AddSessionToRegularBookingsModal/components/AddSessionToRegularBookingsModalForm'
import {
  AddRegularItemsToRegularBookingsModalFormValues,
} from 'modals/RegularBookings/AddRegularItemsToRegularBookingsModal/components/AddRegularItemsToRegularBookingsModalForm'
import {
  AddFundingToRegularBookingsModalFormValues,
} from 'modals/RegularBookings/AddFundingToRegularBookingsModal/components/AddFundingToRegularBookingsModalForm'
import {
  AddDiscountToRegularBookingsModalFormValues,
  OTHER_OPTION,
} from 'modals/RegularBookings/AddDiscountToRegularBookingsModal/components/AddDiscountToRegularBookingsModalForm'
/* eslint-enable max-len, import-newlines/enforce */
import { ApplicableValues, NURSERY_DISCOUNT_BEFORE_AFTER } from 'services/product/nurseryDiscountsV3/constants'
import { UPDATE_CHILD_SESSION_SETUP } from 'module/Children/Child/ChildContainer'
import { RESPONSE } from 'constants/http'
import { WrappedComponentType } from 'constants/types'
import { STATUS_TYPES } from 'services/legacy/invoices/constants'

import auth from 'utils/auth'
import { getMonthOptions, removeDate, removeSeconds } from 'utils/date'
import { getBackendErrors } from 'utils/backendErrors'
import eventBus from 'utils/eventBus'
import { usePrevious } from 'utils/hooks'
import { XOR } from 'utils/typescript'
import { scrollToErrorFields } from 'utils/dom'
import { convertPayloadToDisplayValue, uuidV6 } from 'utils/data'
import { generateRoute } from 'utils/routing'

import { withAppService, withAppServiceProps } from 'services/app'
import { withChildService, withChildServiceProps } from 'services/legacy/child'
import { withChildBookingService, withChildBookingServiceProps } from 'services/booking/childBooking'
import { withEnquiriesService, withEnquiriesServiceProps } from 'services/legacy/enquiries'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withRouter, withRouterProps } from 'services/router'
import { withSnackbarService, withSnackbarServiceProps } from 'services/utils/snackbar'
import { withNurseryContextService, withNurseryContextServiceProps } from 'services/nurseryContext'
import { getCurrentPriceFromPriceChanges } from 'services/product/selectors'
import { withInvoicesService, withInvoicesServiceProps } from 'services/legacy/invoices'

import i18n from 'translations'

import {
  REGULAR_BOOKINGS_ADD_FORM,
  RegularBookingsAddFormValues,
} from './components/RegularBookingsAddForm/RegularBookingsAddForm'
import RegularBookingsAddView from './RegularBookingsAddView'

const mapDispatch = {
  changeField: (field, value) => change(REGULAR_BOOKINGS_ADD_FORM, field, value),
  injectValidation: (data) => stopSubmit(REGULAR_BOOKINGS_ADD_FORM, data),
}

const mapState = (state, {
  appSelectors,
  childBookingSingleState,
  childSelectors,
  nurseryContextSelectors,
  params,
}) => ({
  annualizedInvoiceSettings: nurseryContextSelectors.getAnnualizedInvoiceSettings(state),
  child: childSelectors.getChildSelector(state),
  formErrors: getFormSyncErrors(REGULAR_BOOKINGS_ADD_FORM)(state),
  formValues: getFormValues(REGULAR_BOOKINGS_ADD_FORM)(state) as RegularBookingsAddFormValues,
  hasAccessToViewPrice: auth.SELECTORS.getIsAuthorised(state, {
    roles: [
      ROLES.ORGANIZATION_DIRECTOR,
      ROLES.ORGANIZATION_NATIONAL_ADMIN,
      ROLES.ORGANIZATION_FINANCE_ADMIN,
      ROLES.ORGANIZATION_LINE_MANAGER,
      ROLES.NURSERY_MANAGER,
      ROLES.NURSERY_ADMIN,
      ROLES.SUPER_ADMIN,
    ],
  }),
  isSubmitting: appSelectors.getIsSubmitting(childBookingSingleState),
  nurseryOpeningDays: nurseryContextSelectors.getNurseryOpeningDays(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
})

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type RegularBookingsAddContainerProps = PropsFromRedux
  & withAppServiceProps
  & withRouterProps
  & withChildServiceProps
  & withChildBookingServiceProps
  & withEnquiriesServiceProps
  & withModalServiceProps
  & withSnackbarServiceProps
  & withNurseryContextServiceProps
  & withInvoicesServiceProps

export const TAB_SECTIONS = {
  DISCOUNTS: 3,
  FUNDING: 2,
  SESSION: 1,
}

export const VALIDATION_ERROR_MAP = {
  [TAB_SECTIONS.SESSION]: [
    'attendancePeriod',
    'endDate',
    'startDate',
    'untilChildLeaves',
    'settings.feeCalculation',
    'settings.calculationWeeks',
    'settings.calculationMonths',
    'settings.excludedMonths',
    'settings.packageLineItemName',
    'settings.packagePrice',
    'settings.packageLineItemLevelOfDetail',
    'settings.packageLineItemDeduction',
    'settings.packageLineItemDisplay',
  ],
}

const CHILD_BOOKING_GROUPS = {
  read: [
    'childBooking',
    'childBooking.attendancePeriod',
    'childBooking.childProducts',
    'childBooking.settings',
    'childBookingSettings',
    'childBookingPreview.product',
    'regularChildProduct.details',
    'regularChildProduct',
    'regularChildProduct.product',
    'nurserySessionProduct',
    'nurserySessionProduct.items',
    'nurseryDiscountProduct',
    'regularChildProduct.price',
    'regularChildProduct.settings',
    'nurseryItemProduct',
    'nurseryFundingProduct',
    'period',
    'regularChildProductSettings',
    'childFundingSessionSet.allocations',
    'productPrice',
    'productPriceChange',
    'productPriceChange.prices',
    'nurseryFundingProduct.priceChanges',
    'nurseryFundingProduct.settings',
    'nurseryFundingProductSettings',
    'nurseryDiscountProduct.settings',
    'nurseryDiscountProductSettings',
  ],
}

const ENQUIRY_GROUPS = {
  read: [
    'enquiry.sessionProductPlans',
    'nurserySessionProduct',
    'nurserySessionProduct.priceChanges',
    'productPrice',
    'productPriceChange',
    'productPriceChange.prices',
    'productPrice.priceGroup',
    'productPriceGroup',
  ],
}

const FUNDING_CALCULATOR_GROUPS = {
  read: [
    'regularChildProduct.product',
    'nurserySessionProduct',
  ],
}

const NURSERY_SETTINGS_GROUPS = {
  read: [
    'nursery.settings',
    'nurserySettings',
    'nurserySettings.invoice',
    'nurseryInvoiceSettings',
    'nurseryInvoiceSettings.annualisedInvoiceSettings',
    'annualisedInvoiceSettings',
  ],
}

const ERROR_PERIOD_HAS_AN_INVOICE = 'PERIOD_HAS_AN_INVOICE'

export const CHILD_BOOKING_QUERY_NAME = 'childBooking'

const RegularBookingsAddContainer: WrappedComponentType<RegularBookingsAddContainerProps> = ({
  annualizedInvoiceSettings,
  changeField,
  child,
  childActions,
  childBookingActions,
  childBookingSelectors,
  enquiriesActions,
  formErrors,
  formValues,
  hasAccessToViewPrice,
  injectValidation,
  invoicesActions,
  invoicesSelectors,
  isSubmitting,
  location,
  modalActions,
  navigate,
  nurseryContextActions,
  nurseryOpeningDays,
  nurseryOptions,
  params,
  snackbarActions,
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [isRecalculation, setIsRecalculation] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<number>(TAB_SECTIONS.SESSION)
  const [enquiryId, setEnquiryId] = useState<number>(null)
  const [showRecalculationPopup, setShowRecalculationPopup] = useState<boolean>(false)
  const [invoicesTotalResults, setInvoicesTotalResults] = useState<number>(0)
  const [errors, setErrors] = useState<string[]>([])
  const prevState = usePrevious({ isRecalculation })

  const { leavingDate } = child
  const { pathname } = location

  const formFeeCalculation = formValues?.settings?.feeCalculation

  const isEdit = pathname === generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS.EDIT', {
    bookingId: params.bookingId,
    childId: params.childId,
  })

  const injectInitialChildBookingParams = async () => {
    const response = await childBookingActions.get({
      onFailed: (data) => {
        if (RESPONSE.HTTP_404_NOT_FOUND === data.code) {
          navigate(generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS', {
            childId: params.childId,
          }))
        }
      },
      onlyData: true,
      params: [isEdit ? params.bookingId : +location.query[CHILD_BOOKING_QUERY_NAME], {
        groups: CHILD_BOOKING_GROUPS,
      }],
    })

    // @ts-ignore
    const { data } = response || {}
    let { childProducts } = data || {}
    const { alternates, attendancePeriod, endDate, settings, startDate } = data || {}
    const {
      calculationMonths,
      calculationWeeks,
      excludedMonths,
      feeCalculation,
      packageLineItemName,
      packagePrice,
    } = settings || {}

    if (startDate) {
      changeField('startDate', moment(startDate))
    }

    if (endDate) {
      changeField('endDate', moment(endDate))
    } else {
      changeField('untilChildLeaves', true)
      changeField('endDate', moment(leavingDate))
    }

    if (alternates) {
      changeField('alternates', +alternates)
    }

    if (attendancePeriod) {
      changeField('attendancePeriod', { value: +attendancePeriod.id })
    }

    if (feeCalculation) {
      changeField('settings.feeCalculation', feeCalculation)
    }

    if (calculationMonths) {
      changeField('settings.calculationMonths', +calculationMonths)
    }

    if (calculationWeeks) {
      changeField('settings.calculationWeeks', +calculationWeeks)
    }

    if (packageLineItemName) {
      changeField('settings.packageLineItemName', packageLineItemName)
    }

    if (packagePrice) {
      changeField('settings.packagePrice', (+packagePrice || 0) / 100)
    }

    if (excludedMonths?.length) {
      _.each(excludedMonths, (month, index) => {
        changeField(`settings.excludedMonths[${index}]`, { value: +month })
      })
    }

    const updatedChildProducts = []

    _.each(childProducts, (childProduct) => {
      let body = {} as ChildProduct

      if (childProduct.type === ChildProductTypes.REGULAR_SESSION) {
        const newUuid = isEdit ? childProduct.id : uuidV6()

        body = {
          _extra: {
            price: childProduct.price.value,
          },
          alternate: childProduct.alternate,
          dayOfWeek: childProduct.dayOfWeek,
          id: newUuid,
          product: {
            endTime: childProduct.endTime,
            hourly: childProduct.product.hourly,
            id: childProduct.product.id,
            name: childProduct.product.name,
            startTime: childProduct.startTime,
          },
          type: ChildProductTypes.REGULAR_SESSION,
        }

        if (!_.isUndefined(childProduct.settings.consumablesIncluded)) {
          body.settings = {
            consumablesIncluded: childProduct.settings.consumablesIncluded,
          }
        }

        if (childProduct.product.hourly) {
          body.startTime = childProduct.startTime
          body.endTime = childProduct.endTime
        }

        // @ts-ignore
        childProducts = JSON.parse(String(JSON.stringify(childProducts)).replaceAll(childProduct.id, newUuid))

        updatedChildProducts.push(body)
      } else if (childProduct.type === ChildProductTypes.REGULAR_ITEM) {
        const newUuid = isEdit ? childProduct.id : uuidV6()

        body = {
          _extra: {
            price: childProduct.price.value,
          },
          alternate: childProduct.alternate,
          dayOfWeek: childProduct.dayOfWeek,
          id: newUuid,
          product: {
            id: childProduct.product.id,
            name: childProduct.product.name,
          },
          settings: {
            quantity: childProduct.settings.quantity,
          },
          type: ChildProductTypes.REGULAR_ITEM,
        }

        // @ts-ignore
        childProducts = JSON.parse(String(JSON.stringify(childProducts)).replaceAll(childProduct.id, newUuid))

        updatedChildProducts.push(body)
      }
    })

    _.each(childProducts, (childProduct) => {
      let body = {} as ChildProduct

      if (childProduct.type === ChildProductTypes.REGULAR_ALLOCATED_FUNDING) {
        body = {
          alternate: childProduct.alternate,
          hoursPerWeekAllocated: childProduct.hoursPerWeekAllocated,
          id: isEdit ? childProduct.id : uuidV6(),
          product: {
            id: childProduct.product.id,
            name: childProduct.product.name,
            priceChanges: childProduct.product.priceChanges,
            settings: {
              exclusionPeriods: childProduct.product?.settings?.exclusionPeriods,
            },
          },
          type: childProduct.type,
        }

        body.settings = {
          migratedFunding: childProduct?.settings?.migratedFunding,
        }

        if (childProduct?.settings?.customLocalAuthority) {
          body.settings.customLocalAuthority = childProduct.settings.customLocalAuthority
        }

        if (childProduct?.settings?.hourlyRate) {
          body.settings.hourlyRate = childProduct.price.total
            ? convertPayloadToDisplayValue(childProduct.price.total)
            : convertPayloadToDisplayValue(childProduct.settings.hourlyRate)
        }

        if (childProduct?.settings?.hoursPerDay) {
          body.settings.hoursPerDay = +childProduct.settings.hoursPerDay
        }

        if (childProduct?.settings?.hoursPerWeek) {
          body.settings.hoursPerWeek = +childProduct.settings.hoursPerWeek
        }

        if (childProduct?.settings?.defaultAllocations && isEdit) {
          body.settings.defaultAllocations = _.map(childProduct.settings.defaultAllocations, (allocation) => ({
            ...allocation,
            _extra: {
              id: uuidV6(),
            },
            product: allocation.product ? {
              id: allocation.product.id,
            } : null,
          }))
        }

        if (childProduct?.settings?.allocations && isEdit) {
          body.settings.allocations = _.map(childProduct.settings.allocations, (allocation) => ({
            ...allocation,
            _extra: {
              id: uuidV6(),
            },
            product: allocation.product ? {
              id: allocation.product.id,
            } : null,
          }))
        }

        if (!_.keys(body.settings).length) {
          delete body.settings
        }

        updatedChildProducts.push(body)
      } else if (
        childProduct.type === ChildProductTypes.REGULAR_AMOUNT_DISCOUNT
        || childProduct.type === ChildProductTypes.REGULAR_PERCENTAGE_DISCOUNT
        || childProduct.type === ChildProductTypes.REGULAR_LINK_DISCOUNT
      ) {
        body = {
          _extra: {
            price: childProduct.price.total,
            value: childProduct.price.value || childProduct?.product?.settings?.value,
          },
          alternate: childProduct.alternate,
          dayOfWeek: childProduct.dayOfWeek,
          id: isEdit ? childProduct.id : uuidV6(),
          type: childProduct.type,
        } as ChildProductDiscount

        if (childProduct.type === ChildProductTypes.REGULAR_LINK_DISCOUNT) {
          (body as ChildProductRegularLinkDiscount).product = {
            id: childProduct.product.id,
            name: childProduct.product.name,
            type: childProduct.product.type,
          }

          if (childProduct.settings.overwritten) {
            (body as ChildProductRegularLinkDiscount).settings = {
              overwritten: childProduct.settings.overwritten,
              value: childProduct.settings.value,
            }
          }
        }

        if (
          childProduct.type === ChildProductTypes.REGULAR_AMOUNT_DISCOUNT
          || childProduct.type === ChildProductTypes.REGULAR_PERCENTAGE_DISCOUNT
        ) {
          (body as ChildProductRegularAmountDiscount | ChildProductRegularPercentageDiscount).settings = {
            applicable: childProduct.settings.applicable,
            name: childProduct.settings.name,
            value: childProduct.settings.value,
          }
        }

        updatedChildProducts.push(body)
      }
    })

    const allocatedFundings = _.filter(updatedChildProducts, ({ type }) => (
      ChildProductTypes.REGULAR_ALLOCATED_FUNDING === type
    ))

    if (allocatedFundings?.length && !isEdit) {
      setShowRecalculationPopup(true)
    }

    changeField('childProducts', updatedChildProducts)

    if (isEdit) {
      const criteria = {
        child: child.id,
        invoiceEndDate: endDate,
        invoiceStartDate: startDate,
        not: {
          status: [STATUS_TYPES.CANCELLED],
        },
        type: 'invoice',
      }

      invoicesActions.list({
        criteria: invoicesSelectors.getCriteriaSelector(criteria),
        limit: 10,
        page: 1,
      }, false, (invoicesResponse) => {
        setIsFetching(false)
        setInvoicesTotalResults(invoicesResponse?.meta?.total_results || 0)
      })
    } else {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    if (isRecalculation) {
      snackbarActions.show({
        autoDismiss: false,
        message: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:allocationRecalculationIsInProgress'),
        spinnerType: true,
      })
    }

    // @ts-ignore
    if (!isRecalculation && prevState?.isRecalculation) {
      snackbarActions.close()
    }
  }, [isRecalculation])

  useEffect(() => {
    if (location?.query?.[CHILD_BOOKING_QUERY_NAME] || isEdit) {
      injectInitialChildBookingParams()
    } else {
      setIsFetching(false)
    }

    if (location?.query?.enquiryId) {
      setEnquiryId(+location.query.enquiryId)
    }

    nurseryContextActions.getSettings({
      params: [nurseryOptions.id, {
        groups: NURSERY_SETTINGS_GROUPS,
      }],
    })
  }, [])

  const handleSetSessionData = ({ data }) => {
    const childProducts = []

    _.forEach(data.sessionProductPlans, ({ dayOfWeek, session }) => {
      const price = getCurrentPriceFromPriceChanges(session?.priceChanges, moment())

      const enquirySession = {
        _extra: {
          price: price ? price * 100 : undefined,
        },
        alternate: 1,
        dayOfWeek,
        id: uuidV6(),
        product: {
          endTime: session.endTime,
          hourly: session.hourly,
          id: session.id,
          name: session.name,
          startTime: session.startTime,
        },
        settings: {
          consumablesIncluded: true,
        },
        type: ChildProductTypes.REGULAR_SESSION,
      }

      childProducts.push(enquirySession)
    })

    changeField('childProducts', childProducts)
  }

  useEffect(() => {
    if (enquiryId) {
      enquiriesActions.get({
        onSuccess: handleSetSessionData,
        onlyData: true,
        params: [enquiryId, {
          groups: ENQUIRY_GROUPS,
        }],
      })
    }
  }, [enquiryId])

  useEffect(() => {
    const { excludedMonths, months, weeks } = annualizedInvoiceSettings || {}

    if (
      formFeeCalculation === FeeCalculationType.ANNUALISED
      && !formValues?.settings?.calculationMonths
      && !formValues?.settings?.calculationWeeks
      && months
    ) {
      const monthOptions = getMonthOptions()

      const excludedMonthsOptions = _.map(excludedMonths, (month) => _.find(monthOptions, { value: month }))

      changeField('settings.calculationMonths', months)
      changeField('settings.calculationWeeks', weeks)
      changeField('settings.excludedMonths', excludedMonthsOptions)
    }
  }, [formFeeCalculation])

  const recalculateAllocations = async () => {
    const allocatedFundings = _.filter(formValues.childProducts, ({ type }) => (
      ChildProductTypes.REGULAR_ALLOCATED_FUNDING === type
    ))

    setIsRecalculation(true)

    const childBooking = childBookingSelectors.getBody(formValues, child, true)

    await Promise.all(_.map(allocatedFundings, async (funding: ChildProductRegularAllocatedFunding) => {
      const childProduct = childBookingSelectors.getFundingBody({
        alternate: funding.alternate,
        defaultAllocations: [],
        id: funding.id as string,
        values: {
          product: funding.product,
          settings: funding.settings as any,
        },
      })

      const body = {
        childBooking,
        childProduct,
      }

      return childActions.getChildFundingCalculator({
        body,
        onFailed: () => snackbarActions.show({
          message: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:recalculationFailed'),
        }),
        onSuccess: ({ data: fundingResponse }) => {
          const updatedChildProducts = _.map(formValues.childProducts, (product) => {
            if (product.id === funding.id) {
              return {
                ...product,
                settings: {
                  ...product.settings,
                  ...fundingResponse,
                  allocations: _.map(fundingResponse.allocations, (allocation) => ({
                    ...allocation,
                    _extra: {
                      id: v4(),
                    },
                  })),
                  defaultAllocations: _.map(fundingResponse.defaultAllocations, (allocation) => ({
                    ...allocation,
                    _extra: {
                      id: v4(),
                    },
                  })),
                },
              }
            }

            return product
          })

          changeField('childProducts', updatedChildProducts)
        },
        onlyData: true,
        params: [child.id, {
          groups: FUNDING_CALCULATOR_GROUPS,
        }],
      })
    }))

    setIsRecalculation(false)
  }

  const checkIsNeedRecalculateAllocations = () => {
    const allocatedFundings = _.filter(formValues.childProducts, ({ type }) => (
      ChildProductTypes.REGULAR_ALLOCATED_FUNDING === type
    ))

    if (allocatedFundings?.length && !showRecalculationPopup) {
      setShowRecalculationPopup(true)
    }
  }

  const handleChangeAlternateWeeks = ({ value }) => {
    if (1 < value) {
      changeField('settings.feeCalculation', FeeCalculationType.ACTUAL)
    }

    const filteredChildProducts = _.filter(formValues?.childProducts, (item) => (
      item.alternate <= value
    ))

    if (formValues?.childProducts?.length && filteredChildProducts.length !== formValues?.childProducts?.length) {
      modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
        confirmButtonLabel: i18n.t('global:Confirm'),
        icon: 'trash',
        onCancel: () => setTimeout(() => changeField('alternates', formValues.alternates)),
        onConfirm: () => {
          changeField('alternates', value)
          changeField('childProducts', filteredChildProducts)
        },
        text: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:confirmChangeAlternateWeek'),
      })
    } else {
      changeField('alternates', value)
    }

    checkIsNeedRecalculateAllocations()
  }

  const handleAddRegularItemSuccess = (alternate: number) => (
    values: AddRegularItemsToRegularBookingsModalFormValues,
    price,
  ) => {
    const { daysApplicable, item, quantity } = values
    const { label } = item
    let nextIndex = formValues?.childProducts?.length || 0

    _.each(daysApplicable, (weekIndex) => {
      const body: ChildProductRegularItem = {
        _extra: {
          price,
        },
        alternate,
        dayOfWeek: DAYS_OF_WEEK_LIST[weekIndex] as DaysOfWeek,
        id: uuidV6(),
        product: {
          id: item.value,
          name: label,
        },
        settings: {
          quantity,
        },
        type: ChildProductTypes.REGULAR_ITEM,
      }

      changeField(`childProducts[${nextIndex}]`, body)
      nextIndex += 1
    })
  }

  const handleAddRegularItem = (alternate: number) => {
    modalActions.show<ModalType.ADD_REGULAR_ITEMS_TO_REGULAR_BOOKINGS>(
      ModalType.ADD_REGULAR_ITEMS_TO_REGULAR_BOOKINGS,
      {
        alternate,
        child,
        onSuccess: handleAddRegularItemSuccess(alternate),
        regularBookingsFormValues: formValues,
      },
    )
  }

  const handleAddSessionSuccess = (alternate: number) => (
    values: AddSessionToRegularBookingsModalFormValues,
    price,
  ) => {
    const { daysApplicable, endTime, includeConsumables, session, startTime } = values
    const { hourly, label } = session
    const sessionHasConsumables = _.find(session?.items, ({ type }) => 'nursery_session_consumable' === type)

    let nextIndex = formValues?.childProducts?.length || 0

    _.each(daysApplicable, (weekIndex) => {
      const body: ChildProductRegularSession = {
        _extra: {
          price,
        },
        alternate,
        dayOfWeek: DAYS_OF_WEEK_LIST[weekIndex] as DaysOfWeek,
        id: uuidV6(),
        product: {
          endTime: session.endTime,
          hourly,
          id: session.value,
          name: label,
          startTime: session.startTime,
        },
        type: ChildProductTypes.REGULAR_SESSION,
      }

      if (session?.hourly) {
        body.startTime = +removeSeconds(removeDate(startTime, { utc: true })).format('x')
        body.endTime = +removeSeconds(removeDate(endTime, { utc: true })).format('x')
      }

      if (sessionHasConsumables && !_.isUndefined(includeConsumables)) {
        body.settings = {
          consumablesIncluded: includeConsumables,
        }
      }

      changeField(`childProducts[${nextIndex}]`, body)
      nextIndex += 1
    })

    checkIsNeedRecalculateAllocations()
  }

  const handleAddSession = (alternate: number) => {
    modalActions.show<ModalType.ADD_SESSION_TO_REGULAR_BOOKINGS>(ModalType.ADD_SESSION_TO_REGULAR_BOOKINGS, {
      alternate,
      child,
      onSuccess: handleAddSessionSuccess(alternate),
      regularBookingsFormValues: formValues,
    })
  }

  const handleRemoveChildProductConfirmed = (id: string) => {
    const filteredChildProducts = _.filter(formValues?.childProducts, (item) => item.id !== id)
    const removedElement = _.find(formValues?.childProducts, (item) => item.id === id)

    if (removedElement.type === ChildProductTypes.REGULAR_SESSION) {
      checkIsNeedRecalculateAllocations()
    }

    changeField('childProducts', filteredChildProducts)
  }

  const handleRemoveChildProduct = (id: string) => {
    modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
      confirmButtonLabel: i18n.t('global:Delete'),
      icon: 'trash',
      onConfirm: () => handleRemoveChildProductConfirmed(id),
      text: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:removeChildProduct'),
    })
  }

  const handleAddFundingSuccess = (alternate: number, editId?: string) => (
    values: AddFundingToRegularBookingsModalFormValues,
    allocations: Allocation[],
    defaultAllocations: AllocationDefault[],
  ) => {
    const nextIndex = editId
      ? _.findIndex(formValues?.childProducts, (item) => item.id === editId)
      : (formValues?.childProducts?.length || 0)

    const body = childBookingSelectors.getFundingBody({
      addPriceChanges: true,
      allocations,
      alternate,
      defaultAllocations,
      id: editId,
      saveExtraFields: true,
      skipTransformHourlyRate: true,
      values,
    })

    changeField(`childProducts[${nextIndex}]`, body)
  }

  const handleEditFundingSuccess = (oldFunding) => handleAddFundingSuccess(oldFunding.alternate, oldFunding.id)

  const handleAddFunding = (alternate: number) => {
    modalActions.show<ModalType.ADD_FUNDING_TO_REGULAR_BOOKINGS>(ModalType.ADD_FUNDING_TO_REGULAR_BOOKINGS, {
      alternate,
      child,
      endDate: formValues.endDate,
      onSuccess: handleAddFundingSuccess(alternate),
      sessions: _.filter(formValues.childProducts, (item) => (
        item.type === ChildProductTypes.REGULAR_SESSION
        && alternate === item.alternate
      )),
      startDate: formValues.startDate,
      totalAlternates: +(formValues?.alternates || 1),
    })
  }

  const handleEditFunding = (id: string, isPreview?: boolean) => {
    const funding = _.find(formValues.childProducts, (item) => item.id === id)

    modalActions.show<ModalType.ADD_FUNDING_TO_REGULAR_BOOKINGS>(ModalType.ADD_FUNDING_TO_REGULAR_BOOKINGS, {
      alternate: funding.alternate,
      child,
      editData: funding,
      endDate: formValues.endDate,
      isPreview,
      onSuccess: handleEditFundingSuccess(funding),
      sessions: _.filter(formValues.childProducts, (item) => (
        item.type === ChildProductTypes.REGULAR_SESSION
        && funding.alternate === item.alternate
      )),
      startDate: formValues.startDate,
      totalAlternates: +(formValues?.alternates || 1),
    })
  }

  const handleAddDiscountSuccess = (alternate: number) => (
    values: AddDiscountToRegularBookingsModalFormValues,
    price,
    discountValue,
    daysBreakdown,
  ) => {
    const { applicable, applicableBeforeFunding, daysApplicable, discount, name, type, value } = values
    let nextIndex = formValues?.childProducts?.length || 0

    _.each(daysApplicable, (weekIndex) => {
      const body: XOR<
        ChildProductRegularLinkDiscount,
        XOR<ChildProductRegularAmountDiscount, ChildProductRegularPercentageDiscount>
      > = {
        _extra: {
          price: daysBreakdown?.[DAYS_OF_WEEK_LIST[weekIndex]],
          value: discountValue,
        },
        alternate,
        dayOfWeek: DAYS_OF_WEEK_LIST[weekIndex] as DaysOfWeek,
        id: uuidV6(),
      }

      if (discount.value === OTHER_OPTION.value) {
        body.type = type as ChildProductTypes.REGULAR_AMOUNT_DISCOUNT | ChildProductTypes.REGULAR_PERCENTAGE_DISCOUNT
        body.settings = {
          name,
          value: +value * 100,
        }

        if (
          !_.isUndefined(applicableBeforeFunding)
          && ChildProductTypes.REGULAR_PERCENTAGE_DISCOUNT === type
          && !!applicable?.sessions
        ) {
          (body as ChildProductRegularPercentageDiscount).settings.applicableBeforeFunding = (
            applicableBeforeFunding !== NURSERY_DISCOUNT_BEFORE_AFTER.AFTER
          )

          _.mapValues(applicable, (checked, key: ApplicableValues) => {
            if (applicable) {
              if (!(body as ChildProductRegularPercentageDiscount).settings.applicable) {
                (body as ChildProductRegularPercentageDiscount).settings.applicable = []
              }

              (body as ChildProductRegularPercentageDiscount).settings.applicable.push(key)
            }
          })
        }
      } else {
        body.type = ChildProductTypes.REGULAR_LINK_DISCOUNT
        body.product = {
          id: discount.value,
          name: discount.label,
          type: discount.type,
        }

        if (discount.settings.allowOverride) {
          body.settings = {
            overwritten: true,
            value: value * 100,
          }
        }
      }

      changeField(`childProducts[${nextIndex}]`, body)
      nextIndex += 1
    })
  }

  const handleAddDiscount = (alternate: number) => {
    modalActions.show<ModalType.ADD_DISCOUNT_TO_REGULAR_BOOKINGS>(ModalType.ADD_DISCOUNT_TO_REGULAR_BOOKINGS, {
      alternate,
      child,
      onSuccess: handleAddDiscountSuccess(alternate),
      regularBookingsFormValues: formValues,
    })
  }

  const handleSubmitFailed = (values) => (response) => {
    const { endDate, startDate } = values
    const currentErrors = getBackendErrors(response)
    let isFoundErrorInTab = false

    if (ERROR_PERIOD_HAS_AN_INVOICE === currentErrors?.startDate) {
      currentErrors.startDate = i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:periodHasInvoice')

      modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
        confirmButtonLabel: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:goToInvoices'),
        onConfirm: () => {
          const paramsObject = {
            invoiceEndDate: moment(endDate).format(DEFAULT_DATE_FORMAT),
            invoiceStartDate: moment(startDate).format(DEFAULT_DATE_FORMAT),
            status: [
              STATUS_TYPES.DRAFT,
              STATUS_TYPES.PAID,
              STATUS_TYPES.PARTIALLY_PAID,
              STATUS_TYPES.SENDING,
              STATUS_TYPES.SENT,
              STATUS_TYPES.BAD_DEBT,
              STATUS_TYPES.OVERDUE,
            ].join(','),
            type: 'invoice',
          }

          const searchParams = new URLSearchParams(paramsObject)
          navigate(`${generateRoute('CHILDREN.CHILD.FINANCE.INVOICING', {
            childId: child.id,
          })}?${searchParams.toString()}`)
        },
        text: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:periodHasInvoice'),
      })
    }

    _.each(TAB_SECTIONS, (tab) => {
      const fieldsOnTab = VALIDATION_ERROR_MAP[tab]
      const foundErrorInTab = _.find(fieldsOnTab, (name) => !!flatten(currentErrors)?.[name])

      if (foundErrorInTab) {
        setActiveTab(tab)
        isFoundErrorInTab = true

        setTimeout(() => {
          scrollToErrorFields()
        })
      }
    })

    if (!isFoundErrorInTab && currentErrors) {
      setErrors(_.uniq(_.values(response.extra)))
    }

    if (!currentErrors) {
      return false
    }

    return setTimeout(() => {
      injectValidation(currentErrors)
    })
  }

  const handleSubmitSuccess = () => {
    eventBus.dispatch(UPDATE_CHILD_SESSION_SETUP)

    navigate(generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS', { childId: child.id }))
  }

  const handleSubmit = (values: RegularBookingsAddFormValues) => {
    if (activeTab === TAB_SECTIONS.SESSION || activeTab === TAB_SECTIONS.FUNDING) {
      return false
    }

    setErrors([])

    const body = childBookingSelectors.getBody(values, child)

    if (isEdit) {
      return childBookingActions.update({
        body,
        onFailed: handleSubmitFailed(values),
        onSuccess: handleSubmitSuccess,
        params: params.bookingId,
      })
    }

    return childBookingActions.create({
      body,
      onFailed: handleSubmitFailed(values),
      onSuccess: handleSubmitSuccess,
    })
  }

  const handleChangeUntilChildLeaves = (value) => {
    if (value && child.leavingDate) {
      changeField('endDate', child.leavingDate)
      checkIsNeedRecalculateAllocations()
    }
  }

  const handleValidFailed = () => {
    if (activeTab === TAB_SECTIONS.SESSION || activeTab === TAB_SECTIONS.FUNDING) {
      return
    }

    _.each(TAB_SECTIONS, (tab) => {
      const fieldsOnTab = VALIDATION_ERROR_MAP[tab]
      let foundErrorInTab = !!_.find(fieldsOnTab, (name) => !!flatten(formErrors)?.[name])

      if (_.isArray((formErrors as any)?.settings?.excludedMonths)) {
        foundErrorInTab = true
      }

      if (foundErrorInTab) {
        setActiveTab(tab)

        setTimeout(() => {
          scrollToErrorFields()
        })
      }
    })
  }

  const handleGoNextTab = () => {
    if (activeTab === TAB_SECTIONS.SESSION) {
      if (showRecalculationPopup) {
        return modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
          confirmButtonLabel: i18n.t('global:Confirm'),
          icon: 'warning',
          onCancel: () => {
            setShowRecalculationPopup(false)
          },
          onConfirm: () => {
            recalculateAllocations().then(() => {
              setActiveTab(TAB_SECTIONS.FUNDING)
              setShowRecalculationPopup(false)
            })
          },
          text: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:fundingAllocationWillBeRecalculated'),
        })
      }

      if (_.keys(formErrors)?.length) {
        return snackbarActions.show({
          message: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:defineRequireFields'),
        })
      }

      return setActiveTab(TAB_SECTIONS.FUNDING)
    }

    if (activeTab === TAB_SECTIONS.FUNDING) {
      return setActiveTab(TAB_SECTIONS.DISCOUNTS)
    }

    return false
  }

  const handleChangeActiveTab = (newTab) => {
    if (showRecalculationPopup) {
      modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
        confirmButtonLabel: i18n.t('global:Confirm'),
        icon: 'warning',
        onCancel: () => {
          setActiveTab(newTab)
          setShowRecalculationPopup(false)
        },
        onConfirm: () => {
          recalculateAllocations().then(() => {
            setActiveTab(newTab)
            setShowRecalculationPopup(false)
          })
        },
        text: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:fundingAllocationWillBeRecalculated'),
      })
    } else {
      setActiveTab(newTab)
    }
  }

  const handleCancel = () => {
    if (activeTab === TAB_SECTIONS.DISCOUNTS) {
      return setActiveTab(TAB_SECTIONS.FUNDING)
    }

    if (activeTab === TAB_SECTIONS.FUNDING) {
      return setActiveTab(TAB_SECTIONS.SESSION)
    }

    return modalActions.show<ModalType.CONFIRM>(ModalType.CONFIRM, {
      onConfirm: () => {
        navigate(
          generateRoute('CHILDREN.CHILD.BOOKING_PATTERN.REGULAR_BOOKINGS', {
            childId: child.id,
          }),
        )
      },
      text: i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:confirmCancel'),
    })
  }

  const initialValues: RegularBookingsAddFormValues = {
    alternates: 1,
    settings: {
      feeCalculation: FeeCalculationType.ACTUAL,
    },
  }

  let notOpenedDaysWithAddedChildProduct = []
  const existChildProductAddedToNotOpenedDay = !!_.filter(formValues?.childProducts, (
    childProduct: ChildProduct,
  ) => {
    if (childProduct.type === ChildProductTypes.REGULAR_ALLOCATED_FUNDING) {
      return false
    }

    const notFound = !_.find(nurseryOpeningDays, ({ day }) => (
      day === (childProduct as Exclude<ChildProduct, ChildProductRegularAllocatedFunding>
      ).dayOfWeek
    ))

    if (notFound) {
      notOpenedDaysWithAddedChildProduct.push((childProduct as Exclude<
        ChildProduct,
        ChildProductRegularAllocatedFunding
      >
      ).dayOfWeek)

      return true
    }

    return false
  }).length

  notOpenedDaysWithAddedChildProduct = _.uniq(notOpenedDaysWithAddedChildProduct)

  return (
    <RegularBookingsAddView
      activeTab={activeTab}
      childLeavingDate={leavingDate}
      disabledEditing={0 < invoicesTotalResults}
      errors={errors}
      existChildProductAddedToNotOpenedDay={existChildProductAddedToNotOpenedDay}
      formValues={formValues}
      hasAccessToViewPrice={hasAccessToViewPrice}
      initialValues={initialValues}
      isEdit={isEdit}
      isFetching={isFetching}
      isSubmitting={isSubmitting || isRecalculation}
      notOpenedDaysWithAddedChildProduct={notOpenedDaysWithAddedChildProduct}
      onAddDiscount={handleAddDiscount}
      onAddFunding={handleAddFunding}
      onAddRegularItem={handleAddRegularItem}
      onAddSession={handleAddSession}
      onCancel={handleCancel}
      onChangeActiveTab={handleChangeActiveTab}
      onChangeAlternateWeeks={handleChangeAlternateWeeks}
      onChangeUntilChildLeaves={handleChangeUntilChildLeaves}
      onEditFunding={handleEditFunding}
      onGoNextTab={handleGoNextTab}
      onRecalculateAllocations={checkIsNeedRecalculateAllocations}
      onRemoveChildProduct={handleRemoveChildProduct}
      onSubmit={handleSubmit}
      onValidFailed={handleValidFailed}
    />
  )
}

RegularBookingsAddContainer.authParams = {
  roles: [
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
    ROLES.SUPER_ADMIN,
  ],
}

const enhance = compose(
  withAppService,
  withChildService,
  withChildBookingService,
  withEnquiriesService,
  withInvoicesService,
  withRouter,
  withModalService,
  withSnackbarService,
  withNurseryContextService,
  connector,
)

export default enhance(RegularBookingsAddContainer)
