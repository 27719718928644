import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { OCCUPANCY_REQUEST_BREAKDOWN, OCCUPANCY_REQUEST_TYPE } from 'services/legacy/occupancy/constants'
import { FEATURE_FLAGS } from 'constants/security'

import { withRouterUtils } from 'services/utils/router'
import { withAppService } from 'services/app'
import { withOccupancyService } from 'services/legacy/occupancy'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouter } from 'services/router'

import OccupancyReportView from './OccupancyReportView'

const DATE_FORMAT = 'YYYY/MM/DD'
const DEFAULT_SPLIT_AT = 27000000 // unix for '13:00'
const DEFAULT_PER_PAGE = 10

const OCCUPANCY_REPORT_GROUPS = {
  read: [
    'child',
  ],
}

export const WEEKS_PER_PAGE = 10

let forcedStop = false

class OccupancyReportContainer extends Component {
  constructor(props) {
    super(props)

    const { location, paginationUtils } = props
    const { query = {} } = location
    const {
      absences,
      breakdown,
      date,
      extraSessions,
      holidays,
      rooms,
      splitAt,
      type,
    } = query
    const { setPageLocationQuery, setPerPage } = paginationUtils

    this.state = {
      expandedRooms: {},
      filters: {
        absences: absences ? JSON.parse(absences) : null,
        breakdown,
        date: date || moment().format(DATE_FORMAT),
        extraSessions: extraSessions ? JSON.parse(extraSessions) : null,
        holidays: holidays ? JSON.parse(holidays) : null,
        rooms,
        splitAt: splitAt ? +splitAt : moment().unix(),
        type: type || OCCUPANCY_REQUEST_TYPE.WEEKLY,
      },
      itemsToDownload: null,
      overviewNextPageIsLoading: false,
      weeksPage: 1,
    }

    setPageLocationQuery(false)
    setPerPage(DEFAULT_PER_PAGE)
  }

  componentDidMount() {
    forcedStop = false

    this.validateParams()
  }

  componentWillUnmount() {
    const { occupancyActions } = this.props
    forcedStop = true

    occupancyActions.clear()
  }

  validateParams = () => {
    const { setLocationQuery } = this.props
    const { filters } = this.state
    const { breakdown, splitAt, type } = filters
    const newState = {}

    // We are not using isOverviewReport prop,
    // because we still not called api
    if (type === OCCUPANCY_REQUEST_TYPE.YEARLY) {
      if (breakdown) {
        newState.breakdown = null
      }

      if (splitAt) {
        newState.splitAt = null
      }
    }

    if (
      (type === OCCUPANCY_REQUEST_TYPE.MONTHLY || type === OCCUPANCY_REQUEST_TYPE.OVERVIEW)
      && breakdown === OCCUPANCY_REQUEST_BREAKDOWN.SESSIONS
    ) {
      newState.breakdown = OCCUPANCY_REQUEST_BREAKDOWN.AM_PM

      if (!splitAt) {
        newState.splitAt = DEFAULT_SPLIT_AT
      }
    }

    setLocationQuery({
      ...filters,
      ...newState,
    })

    this.setState(newState, this.getOccupancyReports)
  }

  getOccupancyReportForOverview = (weekIndex, successCallback) => {
    const { occupancyActions, occupancySelectors } = this.props
    const { filters, weeksPage } = this.state

    if (0 <= weekIndex) {
      this.setState({ itemsToDownload: WEEKS_PER_PAGE - weekIndex })
    }

    if (WEEKS_PER_PAGE < weekIndex) {
      this.setState({ overviewNextPageIsLoading: false, weeksPage: weeksPage + 1 })

      if (successCallback) {
        return successCallback()
      }

      return true
    }

    if (forcedStop) {
      forcedStop = false

      return null
    }

    const params = {
      ...filters,
      breakdown: OCCUPANCY_REQUEST_BREAKDOWN.AM_PM,
      week: weekIndex + ((weeksPage * WEEKS_PER_PAGE) - WEEKS_PER_PAGE) - 1,
    }

    const criteria = occupancySelectors.getCriteriaSelector(params)
    const apiParams = { criteria, groups: OCCUPANCY_REPORT_GROUPS }

    return occupancyActions.report(
      apiParams,
      weekIndex !== undefined,
      () => this.getOccupancyReportForOverview(weekIndex + 1, successCallback),
    )
  }

  getOccupancyReports = (clearData = true) => {
    const {
      occupancyActions,
      occupancySelectors,
      paginationUtils,
      setLocationQuery,
    } = this.props
    const { filters } = this.state
    const { type } = filters

    if (clearData) {
      occupancyActions.clear()
    }

    setLocationQuery({ ...filters })

    if (type === OCCUPANCY_REQUEST_TYPE.OVERVIEW) {
      return this.getOccupancyReportForOverview(1)
    }

    const { page } = paginationUtils
    const apiParams = {
      criteria: occupancySelectors.getCriteriaSelector(filters),
      groups: OCCUPANCY_REPORT_GROUPS,
      limit: DEFAULT_PER_PAGE,
      page,
    }

    return occupancyActions.report(apiParams, false)
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(() => this.getOccupancyReports(1 === page))(page)
  }

  handleChangeDate = (dimension) => {
    const { filters } = this.state
    const { date, type } = filters

    let newDate

    if (type === OCCUPANCY_REQUEST_TYPE.WEEKLY) {
      newDate = moment(date, DATE_FORMAT).add(0 < dimension ? 7 : -7, 'days').format(DATE_FORMAT)
    } else if (type === OCCUPANCY_REQUEST_TYPE.MONTHLY) {
      newDate = moment(date, DATE_FORMAT).add(0 < dimension ? 1 : -1, 'months').format(DATE_FORMAT)
    } else if (type === OCCUPANCY_REQUEST_TYPE.YEARLY) {
      newDate = moment(date, DATE_FORMAT).add(0 < dimension ? 1 : -1, 'years').format(DATE_FORMAT)
    }

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        date: newDate,
      },
    }), this.handlePageChange(1))
  }

  handleLoadMoreElements = () => {
    this.setState({
      itemsToDownload: null,
      overviewNextPageIsLoading: true,
    }, () => this.getOccupancyReports(false))
  }

  handleChildrenRowClick = (roomId) => {
    this.setState((prevState) => ({
      expandedRooms: {
        ...prevState.expandedRooms,
        [roomId]: !prevState.expandedRooms[roomId],
      },
    }))
  }

  handleFilterClick = () => {
    const { isOverviewReport, location: { query }, navigate } = this.props

    const newQuery = isOverviewReport
      ? { ...query, overview: true }
      : { ...query }

    const queryString = Object.keys(newQuery).map((key) => `${key}=${newQuery[key]}`).join('&')

    navigate(`${generateRoute('OCCUPANCY.GENERATOR')}?${queryString}`)
  }

  render() {
    const {
      authAccessMap: { section: { StaffingEnabled } },
      errorMessages,
      isFetching,
      isOverviewReport,
      occupancyReportState,
      paginationUtils,
      reportChart,
      reportData,
      reportHeader,
      rooms,
    } = this.props
    const { expandedRooms, filters, itemsToDownload, overviewNextPageIsLoading } = this.state
    const { getPageCount, page } = paginationUtils
    const pageCount = getPageCount(occupancyReportState.meta.total_results)

    return (
      <OccupancyReportView
        errorMessages={errorMessages}
        expandedRooms={expandedRooms}
        filters={filters}
        isFetching={isFetching}
        isOverviewReport={isOverviewReport}
        isStaffingEnabled={StaffingEnabled}
        itemsToDownload={itemsToDownload}
        overviewNextPageIsLoading={overviewNextPageIsLoading}
        page={page}
        pageCount={pageCount}
        reportChart={reportChart}
        reportData={reportData}
        reportHeader={reportHeader}
        rooms={rooms}
        onChangeDate={this.handleChangeDate}
        onChildrenRowClick={this.handleChildrenRowClick}
        onFilterClick={this.handleFilterClick}
        onLoadMoreElements={this.handleLoadMoreElements}
        onPageChange={this.handlePageChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  occupancyReportState,
  occupancySelectors,
}) => ({
  authAccessMap: {
    section: {
      StaffingEnabled: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.STAFF_REGISTER],
      }),
    },
  },
  errorMessages: appSelectors.getErrorMessages(occupancyReportState),
  isFetching: appSelectors.getIsFetching(occupancyReportState),
  isOverviewReport: occupancySelectors.getIsOverviewReport(state),
  reportChart: occupancySelectors.getOccupancyReportChartSelector(state),
  reportData: occupancySelectors.getOccupancyReportFormattedDataSelector(state),
  reportHeader: occupancySelectors.getOccupancyReportHeaderSelector(state),
  rooms: occupancySelectors.getOccupancyReportRoomsSelector(state),
})

const enhance = compose(
  withAppService,
  withOccupancyService,
  withPaginationUtils,
  withRouter,
  withRouterUtils,
  connect(mapState),
)

export default enhance(OccupancyReportContainer)
