import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { change, getFormValues } from 'redux-form'

import { OPEN_PREVIEW_PATH } from 'module/Shell/components/UploadRoot/UploadRoot'
import { FEATURE_FLAGS, ROLES } from 'constants/security'

import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withFilesService } from 'services/legacy/files'
import { withModalService } from 'services/utils/modal'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSnackbarService } from 'services/utils/snackbar'
import { withRouterUtils } from 'services/utils/router'
import { withMembershipsService } from 'services/legacy/memberships'
import { withSecurityService } from 'services/security'
import { withSortingUtils } from 'services/utils/sorting'
import { withUploadService } from 'services/legacy/upload'

import i18n from 'translations'

import StaffFileUploadsView from './StaffFileUploadsView'
import { STAFF_FILE_UPLOADS_FORM } from './components/StaffFileUploadsForm'

const GROUPS = {
  read: ['medium', 'file.file'],
}

const DEFAULT_SORT = {
  sortField: 'createdAt',
  sortOrder: 'DESC',
}

class StaffFileUploadsContainer extends Component {
  constructor(props) {
    super(props)

    const {
      paginationUtils: { setPageLocationQuery },
    } = this.props

    this.state = {
      search: '',
    }

    setPageLocationQuery(false)
  }

  componentDidMount() {
    this.fetchTotalRecords()
    this.fetch()
  }

  componentDidUpdate(prevProps) {
    const { initialValues: prevInitialValues } = prevProps
    const { changeFieldValue, initialValues } = this.props

    if (prevInitialValues?.length !== initialValues.length) {
      changeFieldValue('media', initialValues)
    }
  }

  componentWillUnmount() {
    const { membershipsActions } = this.props

    membershipsActions.clearFilesList()
  }

  getUserId = () => {
    const {
      currentUser,
      location: { pathname },
      params: { userId },
    } = this.props

    let id = userId

    if (generateRoute('ACCOUNT.FILES') === pathname) {
      id = currentUser?.membership?.id
    }

    return id
  }

  getStaffFilesSuccess = () => {
    const { changeFieldValue, initialValues } = this.props

    changeFieldValue('media', initialValues)
  }

  fetch = (silent) => {
    const {
      filesSelectors,
      membershipsActions,
      paginationUtils,
      sortingUtils,
    } = this.props
    const { search } = this.state
    const { page } = paginationUtils

    const userId = this.getUserId()
    const criteria = filesSelectors.getListCriteria({ search })
    const { sortField, sortOrder } = sortingUtils

    membershipsActions.listMembershipFiles({
      mergeResult: 1 !== page,
      onSuccess: this.getStaffFilesSuccess,
      params: [userId, {
        criteria,
        groups: GROUPS,
        order: {
          sortField: sortField || DEFAULT_SORT.sortField,
          sortOrder: sortField ? sortOrder : DEFAULT_SORT.sortOrder,
        },
        page,
      }],
      silent,
    })
  }

  fetchTotalRecords = () => {
    const { membershipsActions } = this.props
    const userId = this.getUserId()

    membershipsActions.listMembershipFilesForTotalResults({
      params: [userId, {
        limit: 0,
      }],
    })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleReloadMedia = (newFiles) => {
    const { changeFieldValue, filesSelectors, formValues, membershipsActions } = this.props
    const { media } = formValues || {}
    const files = _.map(newFiles, filesSelectors.normalizeFileBasedOnUploadingFormat)

    changeFieldValue('media', [
      ...files,
      ...(media || []),
    ])

    membershipsActions.addManuallyMembershipFiles(files)
  }

  handleSearchChange = (search) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    this.setState({
      search,
    }, () => onPageChange(this.fetch)(1))
  }

  handleRemoveFileSuccess = (file) => {
    const { changeFieldValue, formValues, snackbarActions } = this.props
    const { media } = formValues
    const updatedMedia = _.filter(media, (item) => item.id !== file.id)

    snackbarActions.show({
      message: i18n.t('services:Files:fileRemoved'),
    })

    changeFieldValue('media', updatedMedia)
  }

  handleRemoveFile = (file) => {
    const { filesActions, membershipsActions, uploadActions } = this.props

    uploadActions.markUploadedFileToRemove(file)
    membershipsActions.removeFileFromList(file)
    filesActions.removeMembershipFile({
      onSuccess: () => this.handleRemoveFileSuccess(file),
      params: [
        _.isString(file.id) && !file.itemFromBe
          ? file.beId
          : file.id,
      ],
    })
  }

  handleDescriptionUpdateSuccess = (callback, file, body) => {
    const { changeFieldValue, formValues, snackbarActions } = this.props
    const { media } = formValues
    const updatedMedia = _.map(media, (item) => {
      if (item.id === file.id) {
        return {
          ...item,
          ...body,
        }
      }

      return item
    })

    snackbarActions.show({
      message: i18n.t('services:Files:fileDetailsHasBeenChanged'),
    })

    changeFieldValue('media', updatedMedia)

    callback(null)
  }

  handleDescriptionChangeSuccess = (callback, file, body) => {
    const { filesActions } = this.props

    filesActions.updateMembershipFile({
      body,
      onFailed: callback,
      onSuccess: () => this.handleDescriptionUpdateSuccess(callback, file, body),
      params: [file.id],
    })
  }

  handleDescriptionChange = (file) => {
    const { modalActions, modalConsts } = this.props
    const { description, name } = file

    modalActions.show(modalConsts.TYPES.FILE_DETAILS, {
      initialValues: {
        description,
        name,
      },
      onSubmit: (callback, body) => this.handleDescriptionChangeSuccess(callback, file, body),
    })
  }

  handleSortChange = (key) => {
    const { paginationUtils, sortingUtils } = this.props
    const { onSortChange } = sortingUtils
    const { onPageChange } = paginationUtils

    onSortChange(onPageChange(this.fetch)(1))(key, undefined, [OPEN_PREVIEW_PATH])
  }

  render() {
    const {
      files,
      isFetching,
      paginationUtils,
      sortingUtils,
      totalResults,
      totalUnfilteredResults,
    } = this.props
    const { getPageCount, page } = paginationUtils
    const pageCount = getPageCount(totalResults)
    const userId = this.getUserId()

    const pagination = {
      onPageChange: this.handlePageChange,
      page,
      pageCount,
    }

    const sorted = { ...sortingUtils }
    sorted.sortField = sorted.sortField || DEFAULT_SORT.sortField

    return (
      <StaffFileUploadsView
        files={files}
        isFetching={isFetching}
        membershipId={userId}
        pagination={pagination}
        sorted={sorted}
        totalResults={totalUnfilteredResults}
        onDescriptionChange={this.handleDescriptionChange}
        onReloadMedia={this.handleReloadMedia}
        onRemoveFile={this.handleRemoveFile}
        onSearchChange={this.handleSearchChange}
        onSortChange={this.handleSortChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  membershipsFilesState,
  membershipsSelectors,
  securitySelectors,
}) => ({
  currentUser: securitySelectors.getAuthUser(state),
  files: membershipsSelectors.getMembershipFilesListData(state),
  formValues: getFormValues(STAFF_FILE_UPLOADS_FORM)(state),
  initialValues: membershipsSelectors.getMembershipFilesInitialValuesSelector(state),
  isFetching: appSelectors.getIsFetching(
    membershipsFilesState,
  ),
  totalResults: appSelectors.getTotalResults(membershipsFilesState),
  totalUnfilteredResults: appSelectors.getTotalUnfilteredResults(membershipsFilesState),
})

const mapDispatch = {
  changeFieldValue: (field, value) => change(STAFF_FILE_UPLOADS_FORM, field, value),
}

const enhance = compose(
  withAppService,
  withModalService,
  withFilesService,
  withMembershipsService,
  withPaginationUtils,
  withSecurityService,
  withSortingUtils,
  withSnackbarService,
  withUploadService,
  withRouterUtils,
  connect(mapState, mapDispatch),
)

StaffFileUploadsContainer.authParams = {
  flags: [FEATURE_FLAGS.FILES_MANAGEMENT],
  nurseryContext: true,
  roles: [
    ROLES.SUPER_ADMIN,
    ROLES.ORGANIZATION_DIRECTOR,
    ROLES.ORGANIZATION_NATIONAL_ADMIN,
    ROLES.ORGANIZATION_LINE_MANAGER,
    ROLES.ORGANIZATION_FINANCE_ADMIN,
    ROLES.DEPUTY_MANAGER,
    ROLES.NURSERY_MANAGER,
    ROLES.NURSERY_ADMIN,
  ],
}

export default enhance(StaffFileUploadsContainer)
