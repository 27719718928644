import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { RootState } from 'core/reducers'

import { withAppService, withAppServiceProps } from 'services/app'
import { withHomeObservationsService, withHomeObservationsServiceProps } from 'services/legacy/homeObservations'
import { withSecurityService, withSecurityServiceProps } from 'services/security'
import { withLikesAndCommentsService, withLikesAndCommentsServiceProps } from 'services/likesAndComments'
import { withRouteUtilsProps, withRouterUtils } from 'services/utils/router'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withRouter, withRouterProps } from 'services/router'

import HomeObservationsListView from './HomeObservationsListView'

const mapState = (state: RootState, {
  appSelectors,
  homeObservationsListState,
  homeObservationsSelectors,
  likesAndCommentsSelectors,
  securitySelectors,
}) => ({
  currentUser: securitySelectors.getAuthUser(state),
  currentUserIsSuperAdmin: securitySelectors.isSuperAdmin(state),
  hasAccessToLikesAndComments: likesAndCommentsSelectors.hasAccessToLikesAndComments(state),
  hasAccessToLikesAndCommentsSettings: likesAndCommentsSelectors.hasAccessToLikesAndCommentsSettings(state),
  homeObservations: homeObservationsSelectors.getHomeObservationsListDataState(state),
  isFetching: appSelectors.getIsFetching(homeObservationsListState),
  totalResults: appSelectors.getTotalResults(homeObservationsListState),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const GROUPS = {
  read: ['child', 'carer', 'like', 'homeObservation.carer', 'homeObservation.child', 'homeObservation.observations'],
}

const USER_LIKES_GROUPS = {
  read: ['homeObservation.userLike', 'homeObservation.counter'],
}

type HomeObservationsListContainerFullProps = {} & PropsFromRedux &
  withAppServiceProps &
  withHomeObservationsServiceProps &
  withLikesAndCommentsServiceProps &
  withPaginationUtilsProps &
  withRouterProps &
  withRouteUtilsProps &
  withSecurityServiceProps

const HomeObservationsListContainer: React.FC<HomeObservationsListContainerFullProps> = ({
  currentUser,
  currentUserIsSuperAdmin,
  hasAccessToLikesAndComments,
  hasAccessToLikesAndCommentsSettings,
  homeObservations,
  homeObservationsActions,
  homeObservationsSelectors,
  isFetching,
  location: { query },
  paginationUtils,
  params: { childId },
  router,
  setLocationQuery,
  totalResults,
}) => {
  const { getPageCount, onPageChange, page, perPage } = paginationUtils
  const isChildContext = !!childId

  const [filters, setFilters] = useState({
    keyWorker: !isChildContext
      ? query?.keyWorker || (currentUser && !currentUserIsSuperAdmin ? currentUser.id : null)
      : null,
    search: query?.search || null,
  })

  const fetch = () => {
    const criteria = homeObservationsSelectors.getCriteria({
      ...filters,
      childId: isChildContext && childId,
      isArchived: false,
    })

    homeObservationsActions.listHomeObservations({
      mergeResult: 1 !== page,
      params: [
        {
          criteria,
          groups: hasAccessToLikesAndComments ? { read: [...GROUPS.read, ...USER_LIKES_GROUPS.read] } : GROUPS,
          order: {
            sortField: 'id',
            sortOrder: 'DESC',
          },
          page,
        },
      ],
    })

    setLocationQuery({
      keyWorker: filters.keyWorker,
      page,
      search: filters.search,
    })
  }

  useEffect(() => {
    fetch()
  }, [page, filters])

  useEffect(() => () => {
    homeObservationsActions.clearListHomeObservations()
  }, [])

  const handleKeyWorkerChange = (keyWorker: any) => {
    setFilters((prevState) => ({
      ...prevState,
      keyWorker: keyWorker?.value,
    }))

    onPageChange()(1)
  }

  const handlePageChange = (newPage: number) => {
    onPageChange()(newPage)
  }

  const handleSearch = (newSearch: string) => {
    setFilters((prevState) => ({
      ...prevState,
      search: newSearch,
    }))
    onPageChange()(1)
  }

  const pageCount = getPageCount(totalResults)
  const isLoading = isFetching || !homeObservations
  const isEmpty = homeObservations && !homeObservations.length

  return (
    <HomeObservationsListView
      childContext={isChildContext}
      childId={childId}
      filters={filters}
      hasAccessToLikesAndComments={hasAccessToLikesAndComments}
      hasAccessToLikesAndCommentsSettings={hasAccessToLikesAndCommentsSettings}
      homeObservations={homeObservations}
      isEmpty={isEmpty}
      isLoading={isLoading}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      router={router}
      totalResults={totalResults}
      onKeyWorkerChange={handleKeyWorkerChange}
      onPageChange={handlePageChange}
      onSearch={handleSearch}
    />
  )
}

const enhance = compose(
  withAppService,
  withHomeObservationsService,
  withLikesAndCommentsService,
  withPaginationUtils,
  withRouter,
  withRouterUtils,
  withSecurityService,
  connector,
)

export default enhance(HomeObservationsListContainer)
