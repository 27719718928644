import _ from 'lodash'

import { compose } from 'recompose'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'

import { withChildService } from 'services/legacy/child'
import { withMembershipsService } from 'services/legacy/memberships'
import { withModalService } from 'services/utils/modal'
import { withSecurityService } from 'services/security'

import i18n from 'translations'

import ExportReportModalView from './ExportReportModalView'
import { EXPORT_REPORT_FORM } from './components/ExportReportForm'

class ExportReportModalContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      exportIsProcessed: false,
    }
  }

  componentWillUnmount() {
    const { membershipsActions } = this.props

    membershipsActions.clear()
  }

  handleCloseClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSendReportSuccess = (membership) => () => {
    const { membershipsActions, modalActions, modalConsts } = this.props

    this.setState({ exportIsProcessed: false })

    if (!membership?.email) {
      return membershipsActions.get(membership.value, {
        onSuccess: ({ data }) => modalActions.show(modalConsts.TYPES.ALERT, {
          text: i18n.t('module:Modals:ExportReportModal:alert', { email: data.email }),
        }),
      })
    }

    return modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Modals:ExportReportModal:alert', { email: membership.email }),
    })
  }

  handleSendReportFailed = ({ message }) => {
    const { stopSubmitForm } = this.props

    this.setState({ exportIsProcessed: false })

    stopSubmitForm({ user: message })
  }

  handleSendReport = ({ keyWorker, membership }) => {
    const {
      childActions,
      childSelectors,
      filters,
      headers,
      onSendReport,
      params,
    } = this.props

    const criteriaParams = {
      ...filters,
      ...params?.criteria || {},
      keyWorker: _.isObject(keyWorker) ? keyWorker.value : keyWorker,
      membership,
    }

    const criteria = childSelectors.getCriteria(criteriaParams)
    const body = { ...params, criteria, headers }

    this.setState({ exportIsProcessed: true })

    if (onSendReport) {
      return onSendReport(
        body,
        this.handleSendReportSuccess(membership),
        this.handleSendReportFailed,
      )
    }

    return childActions.sendReport(
      body,
      this.handleSendReportSuccess(membership),
      this.handleSendReportFailed,
    )
  }

  render() {
    const {
      currentUser,
      filters = {},
      hasOnlyLearningUserAccess,
      includeDeputyManager,
      includeTeachingUsers = false,
      keyWorkers,
      title,
      userLabel,
    } = this.props
    const { exportIsProcessed } = this.state
    const { keyWorker } = { ...filters }

    const initialValues = {
      keyWorker,
      membership: currentUser
        ? { value: currentUser.membership?.id }
        : null,
    }

    if (!includeTeachingUsers && hasOnlyLearningUserAccess) {
      initialValues.membership = null
    }

    return (
      <ExportReportModalView
        includeDeputyManager={includeDeputyManager}
        initialValues={initialValues}
        isProcessing={exportIsProcessed}
        keyWorkers={keyWorkers}
        title={title}
        userLabel={userLabel}
        onCloseClick={this.handleCloseClick}
        onSubmit={this.handleSendReport}
      />
    )
  }
}

const mapDispatch = {
  stopSubmitForm: (params) => stopSubmit(EXPORT_REPORT_FORM, params),
}

const mapState = (state, { securitySelectors }) => ({
  currentUser: securitySelectors.getAuthUser(state),
  hasOnlyLearningUserAccess: securitySelectors.hasOnlyLearningUserAccess(state),
})

const enhance = compose(
  withChildService,
  withMembershipsService,
  withModalService,
  withSecurityService,
  connect(mapState, mapDispatch),
)

export default enhance(ExportReportModalContainer)
