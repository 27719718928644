import React from 'react'
import { Field } from 'redux-form'

import { INDEPENDENT_NURSERY_OPTION } from 'services/nurseries/constants'

import { isEmailValid, isRequired, isSubdomainValid, isValidPhotoUploadType } from 'utils/fieldValidation'

import { Banner, Form, Space, Typography } from 'components'

import i18n from 'translations'

const NurseryForm = ({
  isAdministrationContext,
  isEditingContext,
  isFinanceLocked,
  isOrganizationContext,
  isOrganizationNursery,
  nurserySettings,
  onNameChange = () => { },
  prefix = '',
  suffix = '',
}) => {
  const currencyIsDisabled = isOrganizationContext || nurserySettings?.financeLocked

  return (
    <React.Fragment>
      <Form.Row
        label={i18n.t('module:Nurseries:Add:NurseryDetails:Form:Name:label')}
        required
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextField}
            name="name"
            placeholder={i18n.t('module:Nurseries:Add:NurseryDetails:Form:Name:placeholder')}
            validate={isRequired}
            onChange={onNameChange}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      {isAdministrationContext && (
        <Form.Row
          label={i18n.t('module:Organisations:Add:Form:organisation')}
          required={!isEditingContext && !isFinanceLocked}
        >
          <Form.Row.FlexItem mobileFull>
            <Field
              clearable={false}
              component={Form.InfiniteDropdowns.Organisations}
              disabled={isEditingContext || isFinanceLocked}
              extraOptions={[INDEPENDENT_NURSERY_OPTION]}
              name="organization"
              placeholder={i18n.t('module:Organisations:Add:Form:organisation')}
              validate={isEditingContext || isFinanceLocked ? [] : [isRequired]}
            />
          </Form.Row.FlexItem>
        </Form.Row>
      )}
      <Form.Row
        label={i18n.t('module:Nurseries:Add:NurseryDetails:Form:Subdomain:label')}
        required
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TemplateTextField}
            disabled={isEditingContext}
            name="subdomain"
            placeholder={i18n.t('module:Nurseries:Add:NurseryDetails:Form:Subdomain:placeholder')}
            prefix={prefix}
            suffix={suffix}
            validate={[isRequired, isSubdomainValid]}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Organisations:Add:Form:country')}
        required={!isOrganizationContext}
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            clearable={false}
            component={Form.InfiniteDropdowns.SupportedLocale}
            disabled={isOrganizationContext}
            name="nurserySettings.locale"
            placeholder={i18n.t('module:Organisations:Add:Form:country')}
            validate={isOrganizationContext ? [] : [isRequired]}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Organisations:Add:Form:currency')}
        required={!currencyIsDisabled}
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            clearable={false}
            component={Form.InfiniteDropdowns.SupportedCurrency}
            disabled={currencyIsDisabled}
            name="nurserySettings.currency"
            placeholder={i18n.t('module:Organisations:Add:Form:currency')}
            validate={currencyIsDisabled ? [] : [isRequired]}
          />
          <Space space="10px" />
          <Banner.Info>
            {isOrganizationNursery
              ? i18n.t('module:Organisations:Add:Form:nurseryHaveToOperateInTheSameCountry')
              : i18n.t('module:Organisations:Add:Form:currencyCannotBeChangedIndividualNursery')}
          </Banner.Info>
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Nurseries:Add:NurseryDetails:Form:Ofsted:label')}>
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextField}
            name="ofstedId"
            placeholder={i18n.t('module:Nurseries:Add:NurseryDetails:Form:Ofsted:label')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Nurseries:Add:NurseryDetails:Form:Email:label')}>
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextField}
            name="email"
            placeholder={i18n.t('module:Nurseries:Add:NurseryDetails:Form:Email:label')}
            validate={isEmailValid}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Nurseries:Add:NurseryDetails:Form:Logo:label')}
        multipleFieldsInARow
      >
        <Form.Row.FlexItem flex="0 0 120px" mobileFull>
          <Field
            component={Form.PhotoUpload}
            name="logo"
            validate={isValidPhotoUploadType}
          />
        </Form.Row.FlexItem>
        <Form.Row.FlexItem flex="0 0 390px" mobileFull>
          <Banner.Info hideIcon>
            <Typography>
              {i18n.t('module:Nurseries:Add:NurseryDetails:Form:Logo:text1')}
            </Typography>
            <Typography margin="10px 0 0">
              {i18n.t('module:Nurseries:Add:NurseryDetails:Form:Logo:text2')}
            </Typography>
          </Banner.Info>
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Nurseries:Add:NurseryDetails:Form:Address:label')}>
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextAreaField}
            name="address"
            placeholder={i18n.t('module:Nurseries:Add:NurseryDetails:Form:Address:label')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Nurseries:Add:NurseryDetails:Form:Postcode:label')}>
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextField}
            name="postCode"
            placeholder={i18n.t('module:Nurseries:Add:NurseryDetails:Form:Postcode:label')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Nurseries:Add:NurseryDetails:Form:Telephone:label')}
        required
      >
        <Form.Row.FlexItem mobileFull>
          <Field
            component={Form.TextField}
            name="telephone"
            placeholder={i18n.t('module:Nurseries:Add:NurseryDetails:Form:Telephone:label')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </React.Fragment>
  )
}

export default NurseryForm
