import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { EVENTS, logEvent } from 'analytics'

import auth from 'utils/auth'

import { withAppService } from 'services/app'
import { withDailyDiaryService } from 'services/legacy/dailyDiary'
import { withModalService } from 'services/utils/modal'
import { withNurseriesService } from 'services/nurseries'
import { withPaginationUtils } from 'services/utils/pagination'
import { withSortingUtils } from 'services/utils/sorting'

import i18n from 'translations'

import { FEATURE_FLAGS } from 'constants/security'
import { getTableData } from './helpers'
import DailyDiaryModalView from './DailyDiaryModalView'

const GROUPS = {
  read: [
    'dailyDiary.child',
    'child',
  ],
}

const LIST_LIMIT = 20

class DailyDiaryModalContainer extends Component {
  constructor(props) {
    super(props)

    const { paginationUtils: { setPageLocationQuery } } = props

    this.state = {
      filters: {
        all: false,
        dailyDiaries: [],
        date: moment(),
        room: null,
        skipIds: [],
      },
      isExporting: false,
    }

    setPageLocationQuery(false)
  }

  componentDidMount() {
    const { paginationUtils } = this.props
    const { setPerPage } = paginationUtils

    setPerPage(LIST_LIMIT)

    this.fetch()
  }

  componentWillUnmount() {
    const { dailyDiaryActions } = this.props

    dailyDiaryActions.clearList()
  }

  fetch = () => {
    const {
      dailyDiaryActions,
      dailyDiarySelectors,
      paginationUtils,
      sortingUtils,
    } = this.props
    const { filters } = this.state
    const { page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const criteria = dailyDiarySelectors.getDailyDiariesListCriteria(filters)

    const params = {
      criteria,
      groups: GROUPS,
      limit: LIST_LIMIT,
      order: {
        sortField: sortField || 'child.firstName',
        sortOrder: !sortField ? 'ASC' : sortOrder,
      },
      page,
    }

    return dailyDiaryActions.list({
      mergeResult: 1 !== page,
      params,
    })
  }

  handleRoomChange = (room) => {
    const { paginationUtils: { onPageChange } } = this.props

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          all: false,
          dailyDiaries: [],
          room: room?.value,
          skipIds: [],
        },
      }),
      () => onPageChange(this.fetch)(1),
    )
  }

  handleDateChange = (date) => {
    const { paginationUtils: { onPageChange } } = this.props

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        all: false,
        dailyDiaries: [],
        date,
        skipIds: [],
      },
    }), () => onPageChange(this.fetch)(1))
  }

  handleSelectAllClick = (value) => {
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          all: value,
          dailyDiaries: [],
          skipIds: [],
        },
      }),
    )
  }

  handleSelectItemClick = (value, id) => {
    const { totalResults } = this.props
    const { filters } = this.state
    const { all, skipIds } = filters

    if (all) {
      return this.setState(
        (prevState) => ({
          filters: {
            ...prevState.filters,
            all: false,
            skipIds: [
              ...prevState.filters.skipIds,
              id,
            ],
          },
        }),
      )
    }

    if (skipIds?.length) {
      if (totalResults - 1 === skipIds.length) {
        return this.setState(
          (prevState) => ({
            filters: {
              ...prevState.filters,
              skipIds: [],
            },
          }),
        )
      }

      if (!value) {
        return this.setState(
          (prevState) => ({
            filters: {
              ...prevState.filters,
              skipIds: [
                ...prevState.filters.skipIds,
                id,
              ],
            },
          }),
        )
      }

      const newSkipIds = _.clone(filters.skipIds)
      _.remove(newSkipIds, (skipId) => id === skipId)

      if (!newSkipIds?.length) {
        return this.setState(
          (prevState) => ({
            filters: {
              ...prevState.filters,
              all: true,
              skipIds: [],
            },
          }),
        )
      }

      return this.setState(
        (prevState) => ({
          filters: {
            ...prevState.filters,
            skipIds: [
              ...newSkipIds,
            ],
          },
        }),
      )
    }

    if (value) {
      return this.setState(
        (prevState) => ({
          filters: {
            ...prevState.filters,
            dailyDiaries: [
              ...prevState.filters.dailyDiaries,
              id,
            ],
          },
        }),
      )
    }

    const newDailyDiaries = _.clone(filters.dailyDiaries)
    _.remove(newDailyDiaries, (dailyDiary) => id === dailyDiary)

    return this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          dailyDiaries: [
            ...newDailyDiaries,
          ],
        },
      }),
    )
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleSortChange = (key) => {
    const { paginationUtils, sortingUtils } = this.props
    const { onSortChange } = sortingUtils
    const { onPageChange } = paginationUtils

    onSortChange(onPageChange(this.fetch)(1))(key)
  }

  handleCancelClick = () => {
    const { hideModal } = this.props

    hideModal()
  }

  handleSubmitSuccess = () => {
    const { modalActions, modalConsts } = this.props

    logEvent(EVENTS.DIARY_SENT, { context: 'dashboard' })

    this.setState({ isExporting: false })

    return modalActions.show(modalConsts.TYPES.ALERT, {
      text: i18n.t('module:Modals:DailyDiary:successAlert'),
    })
  }

  handleSubmit = () => {
    const { dailyDiaryActions, dailyDiarySelectors, totalResults } = this.props
    const { filters } = this.state

    this.setState({ isExporting: true })

    const criteria = dailyDiarySelectors.getDailyDiariesSendCriteria({
      ...filters,
      all: totalResults === filters.dailyDiaries?.length ? true : filters.all,
    })

    return dailyDiaryActions.send({
      onFailed: () => { this.setState({ isExporting: false }) },
      onSuccess: this.handleSubmitSuccess,
      params: { criteria },
    })
  }

  render() {
    const {
      autoSendDiarySetting,
      data,
      errorMessages,
      isDiaryAutoSendEnabled,
      isFetching,
      paginationUtils,
      sortingUtils,
      totalResults,
    } = this.props
    const { filters, isExporting } = this.state
    const { all, dailyDiaries, skipIds } = filters
    const { getPageCount, page } = paginationUtils
    const { sortField, sortOrder } = sortingUtils

    const pageCount = getPageCount(totalResults)
    const tableData = getTableData({
      all,
      dailyDiaries,
      data,
      onSelectItemClick: this.handleSelectItemClick,
      skipIds,
    })
    const isSubmitDisabled = (!all && !skipIds?.length && !dailyDiaries?.length)
      || (isFetching && 1 === page)
      || isExporting

    return (
      <DailyDiaryModalView
        autoSendDiarySetting={autoSendDiarySetting}
        data={tableData}
        error={errorMessages}
        filters={filters}
        isDiaryAutoSendEnabled={isDiaryAutoSendEnabled}
        isExporting={isExporting}
        isFetching={isFetching}
        isSubmitDisabled={isSubmitDisabled}
        page={page}
        pageCount={pageCount}
        sortField={sortField}
        sortOrder={sortOrder}
        totalResults={totalResults}
        onCancelClick={this.handleCancelClick}
        onDateChange={this.handleDateChange}
        onPageChange={this.handlePageChange}
        onRoomChange={this.handleRoomChange}
        onSelectAllClick={this.handleSelectAllClick}
        onSortChange={this.handleSortChange}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapState = (state, { appSelectors, dailyDiaryListState, dailyDiarySelectors, nurseriesSelectors }) => ({
  autoSendDiarySetting: nurseriesSelectors.getAutoSendDiarySetting(state),
  data: dailyDiarySelectors.getDailyDiariesListDataSelector(state),
  errorMessages: appSelectors.getErrorMessages(dailyDiaryListState),
  isDiaryAutoSendEnabled: auth.SELECTORS.getIsAuthorised(state, {
    flags: [FEATURE_FLAGS.DIARY_AUTOSEND],
  }),
  isFetching: appSelectors.getIsFetching(dailyDiaryListState),
  totalResults: appSelectors.getTotalResults(dailyDiaryListState),
})

const enhance = compose(
  withAppService,
  withDailyDiaryService,
  withModalService,
  withNurseriesService,
  withPaginationUtils,
  withSortingUtils,
  connect(mapState),
)

export default enhance(DailyDiaryModalContainer)
