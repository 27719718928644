import _ from 'lodash'
import { createSelector } from 'reselect'

import i18n from 'translations'

import * as constants from 'services/product/nurseryDiscountsV3/constants'

export const getNurseryDiscountsSelector = (state) => state.nurseryDiscounts

export const getNurseryDiscountsListSelector = createSelector(
  [getNurseryDiscountsSelector],
  (state) => state.list.data,
)

export const getDiscountOption = ({ applicable, archived, description, id, type, value }) => ({
  applicable,
  label: (
    `${description} `
      .concat(`(${value}${constants.NURSERY_DISCOUNT_TYPES.PERCENTAGE === type ? '% ' : ''})`)
      .concat(`${archived ? '(Archived)' : ''}`)
  ),
  type,
  value: id,
})

export const getNurseryDiscountsOptionsSelector = createSelector(
  [getNurseryDiscountsListSelector],
  (discounts) => {
    if (!discounts || !discounts.length) {
      return null
    }

    return _.map(discounts, getDiscountOption)
  },
)

export const getNurseryDiscountsOptionsWithOtherSelector = createSelector(
  [getNurseryDiscountsOptionsSelector],
  (options) => {
    if (!options || !options.length) {
      return null
    }

    return [
      ...options,
      {
        label: _.upperFirst(i18n.t('global:other')),
        value: constants.OTHER,
      },
    ]
  },
)

export const getCriteriaSelector = createSelector(
  [((filters) => filters)],
  (filters) => {
    const { search, statusFilter = constants.NURSERY_DISCOUNTS_FILTER.ACTIVE } = filters || {}

    const criteria = []

    if (statusFilter && statusFilter !== constants.NURSERY_DISCOUNTS_FILTER.ALL) {
      criteria.push({
        field: 'archived',
        value: statusFilter === constants.NURSERY_DISCOUNTS_FILTER.ARCHIVED,
      })
    }

    if (search) {
      criteria.push({
        field: 'description',
        value: `%${search}%`,
      })
    }

    return criteria
  },
)

export const isNurseryDiscountsEmpty = createSelector(
  [getNurseryDiscountsListSelector],
  (discounts) => (!discounts || !discounts.length),
)
