import _ from 'lodash'

import React from 'react'
import { Field, reduxForm } from 'redux-form'

import * as OccupancyConstants from 'services/legacy/occupancy/constants'

import { isRequired } from 'utils/fieldValidation'

import { generateRoute } from 'utils/routing'

import { Banner, Form, GridLayout, Section } from 'components'

import i18n from 'translations'

export const OCCUPANCY_REPORT_GENERATOR_FORM = 'OccupancyReportGeneratorForm'

const OccupancyReportGeneratorForm = ({
  formValues,
  handleSubmit,
  isOverviewGenerator,
  onSubmit,
}) => {
  const renderOverviewForm = () => (
    <Section title={i18n.t('module:Occupancy:Dashboard:OccupancyPlanner:title')}>
      <GridLayout>
        <GridLayout.Group noVerticalMargin>
          <GridLayout.Item>
            <Form.Row label="Room" labelMargin="0 20px 0 0">
              <Form.Row.FlexItem flex="0 0 200px" mobileFull>
                <Field
                  component={Form.InfiniteDropdowns.Rooms}
                  name="rooms"
                  placeholder="Room"
                />
              </Form.Row.FlexItem>
            </Form.Row>
          </GridLayout.Item>
          {formValues && (
            <GridLayout.Item>
              <Form.Row label="Date" labelMargin="0 20px 0 0" required>
                <Form.Row.FlexItem flex="0 0 200px" mobileFull>
                  <Field
                    component={Form.DatePicker}
                    name="date"
                    validate={isRequired}
                    clearable
                  />
                </Form.Row.FlexItem>
              </Form.Row>
            </GridLayout.Item>
          )}
        </GridLayout.Group>
      </GridLayout>
      <GridLayout.Group noVerticalMargin>
        <GridLayout.Item>
          <Form.Row label="Split AM/PM at" labelMargin="0 20px 0 0" required>
            <Form.Row.FlexItem flex="0 0 200px" mobileFull>
              <Field
                component={Form.TimePicker}
                name="splitAt"
                placeholder="Split AM/PM at"
                validate={isRequired}
                width={150}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        </GridLayout.Item>
      </GridLayout.Group>
    </Section>
  )

  const renderGeneralForm = () => (
    <React.Fragment>
      <Section title={i18n.t('module:Occupancy:Dashboard:Report:title')}>
        <GridLayout>
          <GridLayout.Group noVerticalMargin>
            <GridLayout.Item>
              <Form.Row label="Type of report" labelMargin="0 20px 0 0" required>
                <Form.Row.FlexItem flex="0 0 200px" mobileFull>
                  <Field
                    clearable={false}
                    component={Form.Select}
                    name="type"
                    options={_.filter(OccupancyConstants.OCCUPANCY_TYPE_DROPDOWN, (option) => (
                      option.value !== OccupancyConstants.OCCUPANCY_REQUEST_TYPE.OVERVIEW
                    ))}
                    placeholder="Type of report"
                    searchable={false}
                    validate={isRequired}
                  />
                </Form.Row.FlexItem>
              </Form.Row>
            </GridLayout.Item>
            {formValues && (
              <GridLayout.Item>
                <Form.Row label="Date" labelMargin="0 20px 0 0" required>
                  <Form.Row.FlexItem flex="0 0 200px" mobileFull>
                    <Field
                      component={Form.DatePicker}
                      name="date"
                      validate={isRequired}
                      clearable
                    />
                  </Form.Row.FlexItem>
                </Form.Row>
              </GridLayout.Item>
            )}
          </GridLayout.Group>
          <GridLayout.Group noVerticalMargin>
            <GridLayout.Item>
              <Form.Row label="Room" labelMargin="0 20px 0 0">
                <Form.Row.FlexItem flex="0 0 200px" mobileFull>
                  <Field
                    component={Form.InfiniteDropdowns.Rooms}
                    name="rooms"
                    placeholder="Room"
                  />
                </Form.Row.FlexItem>
              </Form.Row>
            </GridLayout.Item>
          </GridLayout.Group>
        </GridLayout>
      </Section>
      {!(
        formValues
        && formValues.type
        && formValues.type.value === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.YEARLY
      ) && (
        <Section
          title="Select the data you would like to view"
        >
          <GridLayout>
            {formValues
              && formValues.type
              && formValues.type.value === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.WEEKLY
              && (
                <GridLayout.Group noVerticalMargin>
                  <GridLayout.Item>
                    <Form.Row label="Breakdown by" labelMargin="0 20px 0 0" required>
                      <Form.Row.FlexItem flex="0 0 200px" mobileFull>
                        <Field
                          clearable={false}
                          component={Form.Select}
                          name="breakdown"
                          options={OccupancyConstants.OCCUPANCY_REQUEST_BREAKDOWN_DROPDOWN}
                          placeholder="Breakdown by"
                          searchable={false}
                          validate={isRequired}
                        />
                      </Form.Row.FlexItem>
                      {(formValues
                        && formValues.breakdown
                        && formValues.breakdown.value === OccupancyConstants.OCCUPANCY_REQUEST_BREAKDOWN.AM_PM) && (
                          <Banner.Info margin="5px 0 0 10px">
                            You will see a split by AM/PM per week
                          </Banner.Info>
                      )}
                      {(formValues
                        && formValues.breakdown
                        && formValues.breakdown.value === OccupancyConstants.OCCUPANCY_REQUEST_BREAKDOWN.SESSIONS) && (
                          <Banner.Info margin="5px 0 0 10px">
                            You will see a split by the start time of your sessions.
                          </Banner.Info>
                      )}
                    </Form.Row>
                  </GridLayout.Item>
                </GridLayout.Group>
              )}
            {formValues && ((
              formValues.breakdown
              && (
                formValues.breakdown.value === OccupancyConstants.OCCUPANCY_REQUEST_BREAKDOWN.AM_PM
                || formValues.type.value === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.MONTHLY
              )
            ) || formValues.type.value === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.OVERVIEW)
              && (
                <GridLayout.Group noVerticalMargin>
                  <GridLayout.Item>
                    <Form.Row label="Split AM/PM at" labelMargin="0 20px 0 0" required>
                      <Form.Row.FlexItem flex="0 0 200px" mobileFull>
                        <Field
                          component={Form.TimePicker}
                          name="splitAt"
                          placeholder="Split AM/PM at"
                          validate={isRequired}
                          width={150}
                        />
                      </Form.Row.FlexItem>
                    </Form.Row>
                  </GridLayout.Item>
                </GridLayout.Group>
              )}
            {formValues
              && formValues.type
              && formValues.type.value === OccupancyConstants.OCCUPANCY_REQUEST_TYPE.WEEKLY
              && (
                <Form.Row label="Show" labelMargin="0 20px 0 0">
                  <Form.Row.FlexItem flex="0 0 150px">
                    <Field
                      component={Form.Checkbox}
                      label="Absences"
                      name="absences"
                    />
                  </Form.Row.FlexItem>
                  <Form.Row.FlexItem flex="0 0 150px">
                    <Field
                      component={Form.Checkbox}
                      label="Holidays"
                      name="holidays"
                    />
                  </Form.Row.FlexItem>
                  <Form.Row.FlexItem flex="0 0 150px">
                    <Field
                      component={Form.Checkbox}
                      label="Extra sessions"
                      name="extraSessions"
                    />
                  </Form.Row.FlexItem>
                </Form.Row>
              )}
          </GridLayout>
        </Section>
      )}
    </React.Fragment>
  )

  const renderContent = () => {
    if (isOverviewGenerator) {
      return renderOverviewForm()
    }

    return renderGeneralForm()
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {renderContent()}
      <Form.FooterActions
        cancelLink={generateRoute('OCCUPANCY.INDEX')}
        submitLabel={i18n.t('global:Generate')}
      />
    </Form>
  )
}

export default reduxForm({
  form: OCCUPANCY_REPORT_GENERATOR_FORM,
})(OccupancyReportGeneratorForm)
