import _ from 'lodash'

import React from 'react'
import { createSelector } from 'reselect'

import { generateRoute } from 'utils/routing'

import { Avatar, Currency, Typography } from 'components'

const getAgedDebt = (state) => state.agedDebt

export const getAgedDebtListChildrenDataSelector = createSelector([getAgedDebt], (state) => state.listChildren.data)

export const getAgedDebtListMetaSelector = createSelector([getAgedDebt], (state) => state.list.meta)

export const getAgeDebtStatistics = createSelector(
  [getAgedDebtListChildrenDataSelector],
  (data) => _.map(data, (child) => {
    const { agedDebtStatistics } = child

    if (!agedDebtStatistics) {
      return {}
    }

    const { agedDebts, total } = agedDebtStatistics

    return {
      '0-30': <Currency value={agedDebts['0-30']} />,
      120: <Currency value={agedDebts['120+']} />,
      '30-60': <Currency value={agedDebts['31-60']} />,
      '60-90': <Currency value={agedDebts['61-90']} />,
      '90-120': <Currency value={agedDebts['91-120']} />,
      total: <Currency value={total} />,
    }
  }),
)

export const getAgedDebtTableData = createSelector(
  [getAgedDebtListChildrenDataSelector],
  (agedDebtListChildrenData) => {
    if (!agedDebtListChildrenData?.length) {
      return null
    }

    return _.map(agedDebtListChildrenData, (child) => {
      const { agedDebtStatistics, codeReference, firstName, id, photo, surname } = child
      const { agedDebts, total } = agedDebtStatistics || {}

      const agedDebtColumns = _.keys(agedDebts).reduce((obj, key) => {
        const newObj = obj

        newObj[key] = agedDebts[key] ? <Currency to="/" value={agedDebts[key]} /> : '-'

        return newObj
      }, {})

      return {
        child: (
          <Avatar
            avatarSize="small"
            initials={[firstName, surname]}
            src={photo}
            title={(
              <Typography fontSize={14} primary>
                {`${firstName} ${surname}`}
              </Typography>
            )}
            to={`${
              generateRoute('CHILDREN.CHILD.FINANCE.INVOICING', { childId: id })
            }?page=1&sortField=issueDate&sortOrder=ASC&status=OVERDUE`}
          />
        ),
        codeReference,
        total: <Currency value={total} />,
        ...agedDebtColumns,
      }
    })
  },
)
