import React from 'react'

import { generateRoute } from 'utils/routing'

import {
  Button,
  DatePicker,
  EmptyState,
  Hyperlink,
  InfiniteDropdowns,
  Page,
  SearchBar,
  Section,
  Select,
  Spinner,
  Toolbar,
} from 'components'

import i18n from 'translations'

import TrackerTable from './components/TrackerTable'
import { StyledContainer } from './ObservationTrackersStyled'

const ObservationTrackersView = ({
  filters,
  frameworkDetails,
  frameworksList,
  isFetching,
  isFetchingFrameworksList,
  onChangePage,
  onDateChange,
  onFrameworkChange,
  onKeyWorkerChange,
  onNurseryClassChange,
  onRefreshClick,
  onSearchPhase,
  page,
  pageCount,
  records,
}) => {
  const renderToolbar = () => (frameworksList?.length ? (
    <Toolbar>
      <Toolbar.Group>
        <Toolbar.Item>
          <Select
            clearable={false}
            disabled={isFetchingFrameworksList}
            options={frameworksList}
            placeholder={i18n.t('global:Framework')}
            value={filters.framework}
            width={220}
            onChange={onFrameworkChange}
          />
        </Toolbar.Item>
        <Toolbar.Item>
          <InfiniteDropdowns.Rooms
            value={filters.nurseryClass}
            onChange={onNurseryClassChange}
          />
        </Toolbar.Item>
        <Toolbar.Item>
          <InfiniteDropdowns.Users
            value={filters.keyWorker}
            onChange={onKeyWorkerChange}
          />
        </Toolbar.Item>
        <Toolbar.Item>
          <DatePicker
            value={[filters.dateFrom, filters.dateTo]}
            range
            onChange={onDateChange}
          />
        </Toolbar.Item>
      </Toolbar.Group>
      <Toolbar.Group>
        <Toolbar.Item>
          <Button
            hierarchy="tertiary"
            icon="refresh"
            label={i18n.t('global:Refresh')}
            size="small"
            negativeMargins
            onClick={onRefreshClick}
          />
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  ) : null)

  const renderContent = (expanded) => {
    if (isFetchingFrameworksList || (isFetching && 1 === page)) {
      return (
        <Spinner />
      )
    }

    if (!frameworksList?.length) {
      return (
        <EmptyState
          icon="observation-tracker"
          text1={i18n.t('module:Learning:ObservationTracker:EmptyState:noCurriculums')}
          text2={(
            <Hyperlink to={generateRoute('MANAGEMENT.LEARNING.CURRICULUMS')} primary>
              {i18n.t('module:Learning:ObservationTracker:EmptyState:goToSettings')}
            </Hyperlink>
          )}
        />
      )
    }

    if (!records?.length) {
      return (
        <EmptyState
          icon="observation-tracker"
          text1={i18n.t('module:Learning:ObservationTracker:EmptyState:noRecords')}
        />
      )
    }

    return (
      <TrackerTable
        expanded={expanded}
        frameworkDetails={frameworkDetails}
        isFetching={false}
        page={page}
        pageCount={pageCount}
        records={records}
        onChangePage={onChangePage}
      />
    )
  }

  return (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t('global:searchPlaceholder')}
        onChange={onSearchPhase}
      />
      <Page isLoading={isFetchingFrameworksList || (isFetching && 1 === page)}>
        {(expandPageButton, expanded) => (
          <StyledContainer>
            <Page.Content>
              <Section
                actions={frameworksList?.length ? (
                  <Toolbar noMargin>
                    <Toolbar.Group>
                      <Toolbar.Item>
                        {expandPageButton}
                      </Toolbar.Item>
                    </Toolbar.Group>
                  </Toolbar>
                ) : null}
                title={i18n.t('module:Learning:ObservationTracker:title')}
              >
                {renderToolbar()}
                {renderContent(expanded)}
              </Section>
            </Page.Content>
          </StyledContainer>
        )}
      </Page>
    </React.Fragment>
  )
}

export default ObservationTrackersView
