import styled from 'styled-components'

import colors from 'constants/colors'

export const StyledTableWrapper = styled.div`
  height: 62px;
  margin: -12px;

  table {
    height: 62px;
  }
`

export const StyledTotal = styled.div`
  cursor: pointer;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`

export const StyledStatus = styled.span`
  color: ${colors.gray};
  font-weight: 600;
  padding-left: 5px; 
`

export const StyledDateRangeContainer = styled.div`
  color: ${colors.gray};
  margin-top: 3px;
  font-size: 14px;
`
