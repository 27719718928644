import _ from 'lodash'

import React, { Component } from 'react'
import { compose } from 'recompose'

import { withAppService } from 'services/app'
import { withAbsenceReasonsService } from 'services/legacy/absenceReasons'

import { Select } from 'components'

import i18n from 'translations'

import withInfiniteDropdown from './withInfiniteDropdown'

class AbsenceReasonsDropdown extends Component {
  componentDidMount() {
    const { absenceReasonsActions, infiniteDropdownHelpers, value } = this.props

    if (!_.isArray(value)) {
      return infiniteDropdownHelpers.handleComponentDidMount({
        action: () => absenceReasonsActions.getDropdownItem({
          onFailed: this.notFoundValue,
          onSuccess: infiniteDropdownHelpers.handleComponentDidMountOnSuccess,
          params: [value?.value || value],
        }),
        value,
      })
    }

    return null
  }

  handleLoadMoreElements = async (phrase) => {
    const {
      absenceReasonsActions,
      absenceReasonsSelectors,
      extraFields = ['code'],
      infiniteDropdownHelpers,
      limit,
    } = this.props

    return infiniteDropdownHelpers.handleLoadMoreElements({
      clearAction: () => {},
      listAction: ({ newPage, newSearchPhrase, result }) => absenceReasonsActions.dropdownList({
        onSuccess: (response) => infiniteDropdownHelpers.handleLoadMoreElementsOnSuccess({
          extraFields,
          newPage,
          newSearchPhrase,
          response,
          result,
        }),
        params: {
          criteria: absenceReasonsSelectors.getDropdownListCriteria({ name: newSearchPhrase }),
          limit,
          page: newPage,
        },
      }),
      phrase,
    })
  }

  notFoundValue = () => {
    const { onChange } = this.props

    onChange(null)
  }

  handleChange = (e) => {
    const { infiniteDropdownHelpers, onChange } = this.props

    infiniteDropdownHelpers.handleOnChange(e, () => onChange(e))
  }

  render() {
    const {
      disabled,
      infiniteDropdownState,
      placeholder,
      rawValue,
      value,
      ...rest
    } = this.props
    const { cacheUniq, options, selectedValue } = infiniteDropdownState

    return (
      <Select
        {...rest}
        cacheUniq={cacheUniq}
        disabled={disabled}
        loadOptions={this.handleLoadMoreElements}
        options={options}
        placeholder={placeholder
          || _.upperFirst(i18n.t('components:InfiniteDropdowns:AbsenceReasons:placeholder'))}
        rawValue={rawValue || selectedValue || value}
        value={value}
        searchable
        onChange={this.handleChange}
      />
    )
  }
}

const enhance = compose(
  withAppService,
  withAbsenceReasonsService,
  withInfiniteDropdown,
)

export default enhance(AbsenceReasonsDropdown)
