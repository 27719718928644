import _ from 'lodash'

import { createSelector } from 'reselect'

import nurseriesConstants from 'services/nurseries/constants'

import childAdhocSessionsSelectors from 'services/legacy/childAdhocSessions/selectors'
import childExtraItemsSelectors from 'services/legacy/childExtraItems/selectors'
import { getDiscountDescription } from 'services/legacy/childDiscounts/single/selectors'

import { roundValueToTwoDecimal } from 'utils/data'

import invoiceConstants from '../constants'
import {
  getChildExtraItemProjections,
  getChildExtraSessionDropdownValue,
  getChildFundingDropdownValue,
  getFundingLineItemDisplaySettings,
} from './helpers'

const { INVOICE_LEVEL_DETAIL_DISPLAY } = nurseriesConstants

export const getInvoicePreview = (state) => state.invoices.preview

export const getInvoicePreviewData = createSelector([getInvoicePreview], (invoicePreview) => invoicePreview.data)

export const getInvoicePreviewMeta = createSelector([getInvoicePreview], (invoicePreview) => invoicePreview.meta)

// NOTE: Duplicate code need to remove (services/childFunding/list/selectors)
// ---------------------------------------------------------------------
export const getChildFundingSelectors = (state) => state.childFunding

export const getListDataSelectors = createSelector(
  [getChildFundingSelectors],
  (state) => state.list.data,
)

export const getFundingDropdownOptions = createSelector(
  [getListDataSelectors],
  (childFundingList) => {
    if (!childFundingList) {
      return null
    }

    return _.map(childFundingList, (item) => {
      const { amountUsed, funding, hourlyRate, hoursUsed, id, total, totalHours, type } = item
      const { fundingType, label, settings } = funding || {}
      const { name } = fundingType || {}
      const { hoursPerWeek, maxHoursPerDay } = settings || {}
      const fundingLineItemDisplay = getFundingLineItemDisplaySettings(item)

      return {
        amountUsed,
        fundingLineItemDisplay,
        hourlyRate,
        hoursPerWeek,
        hoursUsed,
        label: `${label} - ${name}`,
        maxHoursPerDay,
        total,
        totalHours,
        type,
        value: id,
      }
    })
  },
)
// ---------------------------------------------------------------------

const getEditableFlag = (invoiceItem) => {
  if (!invoiceItem) {
    return true
  }

  const { type } = invoiceItem

  return !!(
    _.includes(invoiceConstants.LINE_ITEM_TYPES, type)
    || _.includes(invoiceConstants.LEGACY_ITEM_TYPE, type)
  )
}

const getItems = (items) => {
  if (!items?.length) {
    return null
  }

  const newItems = []

  _.forEach(items, (item) => {
    const { extras = {}, invoiceItem = {}, items: subItems, label } = item
    const { applicable = [] } = extras
    const description = getDiscountDescription(applicable)
    const editable = getEditableFlag(invoiceItem)

    newItems.push({
      ...item,
      description,
      editable,
      label: invoiceConstants.INVOICE_PREVIEW_MAPPINGS[label],
      previewType: label,
    })

    if (subItems?.length) {
      _.forEach(subItems, (subItem) => {
        newItems.push({
          ...subItem,
          isSubItem: true,
          label: invoiceConstants.INVOICE_PREVIEW_MAPPINGS[subItem.label],
          previewType: label,
        })
      })
    }
  })

  return newItems
}

export const getInvoiceFormattedPreviewSelector = createSelector(
  [getInvoicePreviewData],
  (invoicePreviewData) => {
    if (!invoicePreviewData) {
      return null
    }

    return {
      ...invoicePreviewData,
      isDraft: (
        !invoicePreviewData?.invoice?.status
        || invoicePreviewData?.invoice?.status === invoiceConstants.STATUS_TYPES.DRAFT
      ),
      isGrouped: invoicePreviewData.type === INVOICE_LEVEL_DETAIL_DISPLAY.GROUPED_ITEM_TYPES_WITHOUT_COST_BREAKDOWN,
      items: getItems(invoicePreviewData.items),
    }
  },
)

export const getInitialValues = createSelector(
  [(invoicePreview) => invoicePreview],
  (invoicePreview) => {
    if (!invoicePreview) {
      return null
    }

    const { invoice } = invoicePreview || {}
    const { endDate, startDate } = invoice

    return {
      ...invoice,
      endDate: undefined,
      invoicePeriod: [startDate, endDate],
      items: undefined,
      startDate: undefined,
    }
  },
)

const getTypeOption = (type, typeOptions) => _.find(
  typeOptions,
  ({ nonCustomType, value }) => value === type || (nonCustomType && nonCustomType.includes(type)),
)

const getDeposit = (deposit) => {
  if (!deposit) {
    return undefined
  }

  return {
    amount: deposit.amount,
    label: deposit.description,
    value: deposit.id,
  }
}

export const getItemInitialValues = (itemIndex, isReadOnly) => createSelector(
  [
    getInvoicePreviewData,
    childAdhocSessionsSelectors.getAdhocSessionOptions,
    getFundingDropdownOptions,
    childExtraItemsSelectors.getProjectionsDropdownOptions,
  ],
  (invoicePreview, adhocSessionOptions, fundingOptions, projectionsOptions) => {
    if (!itemIndex) {
      return {
        type: invoiceConstants.LINE_ITEM_OPTIONS[0],
      }
    }

    if (!invoicePreview) {
      return null
    }

    const { invoice } = invoicePreview
    const { items } = invoice
    const invoiceItem = _.find(items, ({ id, uid }) => {
      const key = id?.toString() || uid

      return key === itemIndex.toString()
    })

    const {
      childExtraItemProjections,
      childExtraSession,
      childFunding,
      deposit,
      name,
      quantity,
      total,
      type,
      unitPrice,
    } = invoiceItem || {}
    const newType = getTypeOption(type, invoiceConstants.LINE_ITEM_OPTIONS)

    return {
      ...invoiceItem,
      childExtraItemProjections: getChildExtraItemProjections(
        childExtraItemProjections,
        projectionsOptions,
        isReadOnly,
        name,
      ),
      childExtraSession: getChildExtraSessionDropdownValue(childExtraSession, adhocSessionOptions, isReadOnly, name),
      childFunding: getChildFundingDropdownValue(childFunding, fundingOptions, isReadOnly, name),
      deposit: getDeposit(deposit),
      quantity: quantity ? roundValueToTwoDecimal(Math.abs(+quantity)) : null,
      total: roundValueToTwoDecimal(Math.abs(+total)),
      type: newType,
      unitPrice: unitPrice ? roundValueToTwoDecimal(Math.abs(+unitPrice)) : undefined,
    }
  },
)

export const getTotal = (total, type) => {
  if (!total) {
    return undefined
  }

  if (
    invoiceConstants.LINE_ITEM_TYPES.FUNDING === type.value
    || invoiceConstants.LINE_ITEM_TYPES.DISCOUNT === type.value
    || invoiceConstants.LINE_ITEM_TYPES.ONE_OFF_DISCOUNT === type.value
    || invoiceConstants.LINE_ITEM_TYPES.REFUND_DEPOSIT === type.value
  ) {
    return -total
  }

  return +total
}
