import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { toFloat } from 'utils/data'

import { getChildSelector } from 'services/legacy/child/single/selectors'
import { getCurrentPriceFromPriceChanges } from 'services/product/selectors'

import * as constants from '../constants'

export const getList = (state) => state.childAdhocSessions.list.data
export const getMeta = (state) => state.childAdhocSessions.list.meta

export const findCost = (ageYears, nurserySession) => {
  if (2 <= ageYears && 3 > ageYears) {
    return toFloat(nurserySession.costTwoYears)
  }

  if (3 < ageYears) {
    return toFloat(nurserySession.costTwoYearsAbove)
  }

  return toFloat(nurserySession.costUnderTwo)
}

export const getCost = (childDob, takenDate, nurserySession, startTime, endTime) => {
  if (!childDob || !takenDate || !nurserySession) {
    return 0
  }

  const getAgeYears = moment(takenDate).diff(childDob, 'years', true)

  const baseCost = findCost(getAgeYears, nurserySession)

  if (nurserySession && nurserySession.isHourly) {
    if (!startTime || !endTime) {
      return 0
    }

    const momentStartTime = moment(startTime)
    const momentEndTime = moment(endTime)
    const hoursRealCount = momentEndTime.diff(momentStartTime, 'hours', true)
    const hoursCount = 0 < hoursRealCount ? hoursRealCount : 1

    return baseCost && hoursCount ? baseCost * hoursCount : 0
  }

  return baseCost
}

export const getFinanceV3Cost = ({
  birthDate,
  endTime,
  isHourly,
  priceChanges,
  startDate,
  startTime,
}) => {
  const childAge = Math.floor(moment(startDate).diff(birthDate, 'months', true))

  const baseCost = getCurrentPriceFromPriceChanges(priceChanges, startDate, childAge)

  if (isHourly) {
    if (!startTime || !endTime) {
      return 0
    }

    if (startTime > endTime) {
      return 0
    }

    const momentStartTime = moment(startTime)
    const momentEndTime = moment(endTime)
    const hoursRealCount = momentEndTime.diff(momentStartTime, 'hours', true)
    const hoursCount = 0 < hoursRealCount ? hoursRealCount : 1

    return baseCost && hoursCount ? baseCost * hoursCount : 0
  }

  return baseCost
}

export const getFormattedAdhocSessions = createSelector(
  [getList, getChildSelector],
  (list, child) => {
    if (!list || !child) {
      return []
    }

    const { birthDate } = child

    return _.map(list, (item) => {
      const { archived, endTime, id, nurserySession, startTime, takenDate } = item
      const { name } = nurserySession

      return {
        cost: getCost(birthDate, takenDate, nurserySession, startTime, endTime),
        id,
        name: `${name}${archived ? ' (archived)' : ''}`,
        takenDate,
      }
    })
  },
)

export const getCriteria = createSelector(
  [(filters) => filters],
  (filters) => {
    if (!filters) {
      return null
    }

    const { date: { after, before }, status } = filters

    const criteria = []

    if (after) {
      criteria.push({
        comparator: 'gte',
        field: 'takenDate',
        value: moment(after).format('YYYY-MM-DD'),
      })
    }

    if (before) {
      criteria.push({
        comparator: 'lte',
        field: 'takenDate',
        value: moment(before).set('hour', 23).set('minute', 59).format('YYYY-MM-DD'),
      })
    }

    if (constants.CHILD_ADHOC_SESSIONS_FILTER.ALL !== status) {
      criteria.push({
        comparator: 'eq',
        field: 'archived',
        value: constants.CHILD_ADHOC_SESSIONS_FILTER.ARCHIVED === status ? 1 : 0,
      })
    }

    return criteria
  },
)

export const getAdhocSessionOptions = createSelector(
  [getList, getChildSelector],
  (list, child) => {
    if (!list || !child) {
      return []
    }

    const { birthDate } = child

    return _.map(list, (item) => {
      const { archived, endTime, id, nurserySession, startTime, takenDate } = item
      const { name } = nurserySession

      return {
        cost: getCost(birthDate, takenDate, nurserySession, startTime, endTime),
        label: `${moment(takenDate).format('DD/MM/YYYY')} - ${name}${archived ? ' (archived)' : ''}`,
        takenDate,
        value: id,
      }
    })
  },
)

export const getTotalResult = createSelector([getMeta], (meta) => meta.total_results)
