import amplitude, { AmplitudeClient } from 'amplitude-js'

import { properties } from 'app-config'
import { Profile } from 'services/authentication/models'
import { Subdomain } from 'services/subdomain/models'

import { EVENTS as LIST } from './constants'

// eslint-disable-next-line import/no-mutable-exports
let instance: AmplitudeClient = null

interface UserProperties {
  createdAt?: string
  featureFlags?: string[]
  isAdminUser?: boolean
  nursery?: { createdAt: string, id?: number, isMontessori?: boolean, subdomain: string }
  organization?: { createdAt: string, id: number, subdomain: string }
  roles?: string[]
  userNurseries?: string[]
  userOrganizations?: string[]
}

interface EventProperties {
  authenticated?: boolean
  context?: string
  type?: string
}

export const EVENTS = LIST

export const logEvent = (name: LIST, params?: EventProperties) => {
  if ('LOCAL' !== properties.env) {
    if (!instance) {
      setTimeout(() => {
        instance.logEvent(name, params)
      })
    } else {
      instance.logEvent(name, params)
    }
  }
}

export const identifyAnonymous = (subdomain: string, isSessionNew: boolean = false) => {
  // NOTE: that can happen in scenario when user isn't authorised, and we don't need the analytics at that time
  if (!instance) {
    return
  }

  const userIdentify = new amplitude.Identify()

  instance.identify(userIdentify)
  instance.setUserId(null)
  instance.regenerateDeviceId()
  instance.setUserProperties({ nursery: { subdomain } })

  if (isSessionNew) {
    logEvent(EVENTS.SESSION_STARTED, { authenticated: false, context: 'app initialised - anonymous' })
  }
}

export const identifyAuthenticated = (profile: Profile, isSessionNew: boolean = false) => {
  const userIdentify = new amplitude.Identify()

  const { featureFlags, nursery, organization, roles, user } = profile
  const { createdAt, isAdminUser, nurseries: userNurseries, organizations: userOrganizations } = user

  const userProperties: UserProperties = {
    createdAt,
    featureFlags,
    isAdminUser,
    roles,
    userNurseries,
    userOrganizations,
  }

  if (nursery) {
    userProperties.nursery = {
      createdAt: nursery.createdAt,
      id: nursery.id,
      isMontessori: !!nursery.isMontessori,
      subdomain: nursery.subdomain,
    }
  }

  const org = organization || nursery?.organization

  if (org) {
    userProperties.organization = {
      createdAt: org.createdAt,
      id: org.id,
      subdomain: org.subdomain,
    }
  }

  if (instance) {
    instance.identify(userIdentify)
    instance.setUserProperties(userProperties)
    instance.setUserId(user.id)
  }

  if (isSessionNew) {
    logEvent(EVENTS.SESSION_STARTED, { authenticated: true, context: 'app initialised - authenticated' })
  }
}

export const installAnalytics = (subdomain: Subdomain, profile?: Profile) => {
  if (!instance) {
    instance = amplitude.getInstance()

    instance.init(properties.amplitudeApiKey)
  }

  instance.setVersionName(properties.codeVersion)

  if (!profile) {
    return identifyAnonymous(subdomain.id, true)
  }

  return identifyAuthenticated(profile, true)
}

export default instance
