import {
  ADD_BATCH_INVOICE,
  CLEAR_BATCH_INVOICES,
  CREATE_BATCH_INVOICES,
  CREATE_BATCH_INVOICES_FAILED,
  CREATE_BATCH_INVOICES_SUCCESS,
  REMOVE_BATCH_INVOICE,
  UPDATE_BATCH_INVOICE,
  UPDATE_BATCH_INVOICES,
  UPDATE_BATCH_INVOICES_FAILED,
  UPDATE_BATCH_INVOICES_SUCCESS,
} from './constants'

export const createBatch = (payload, options = {}) => ({ invoicesApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {} } = options

  dispatch({
    params,
    type: CREATE_BATCH_INVOICES,
  })

  invoicesApiClient.createBatch(payload, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: CREATE_BATCH_INVOICES_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: CREATE_BATCH_INVOICES_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const updateBatch = (payload, options = {}) => ({ invoicesApiClient }) => (dispatch) => {
  const { onFailed, onSuccess, params = {} } = options

  dispatch({
    params,
    type: UPDATE_BATCH_INVOICES,
  })

  invoicesApiClient.updateBatch(payload, params).then(
    (response) => {
      dispatch({
        payload: response,
        type: UPDATE_BATCH_INVOICES_SUCCESS,
      })

      if (onSuccess) {
        onSuccess(response)
      }
    },
    ({ response }) => {
      dispatch({
        error: response,
        type: UPDATE_BATCH_INVOICES_FAILED,
      })

      if (onFailed) {
        onFailed(response)
      }
    },
  )
}

export const addBatchInvoice = (invoice) => ({
  payload: { invoice },
  type: ADD_BATCH_INVOICE,
})

export const updateBatchInvoice = (invoice, invoiceId) => ({
  payload: { invoice, invoiceId },
  type: UPDATE_BATCH_INVOICE,
})

export const removeBatchInvoice = (invoiceId) => ({
  payload: { invoiceId },
  type: REMOVE_BATCH_INVOICE,
})

export const clearBatch = () => ({
  type: CLEAR_BATCH_INVOICES,
})
