import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import {
  Button,
  DatePicker,
  EmptyState,
  InfiniteDropdowns,
  Page,
  Pagination,
  SearchBar,
  Section,
  Select,
  Spinner,
  Toolbar,
} from 'components'
import CreditNoteIcon from 'module/Finance/components/CreditNoteIcon'

import i18n from 'translations'

import InvoicesTable from './components/InvoicesTable'

const FinanceInvoicesView = ({
  dateRange,
  dateType,
  dateTypeOptions,
  invoices,
  isEmpty,
  isLoading,
  onDateChange,
  onDateTypeChange,
  onExportClick,
  onPageChange,
  onRoomChange,
  onSearchBarChange,
  onStatusChange,
  onTableSortChange,
  onTypeChange,
  page,
  pageCount,
  perPage,
  room,
  sortField,
  sortOrder,
  status,
  statusOptions,
  totalResults,
  type,
  typeOptions,
}) => {
  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isEmpty) {
      return (
        <EmptyState
          actions={(
            <Button
              label="Accounts"
              to={generateRoute('CHILDREN.INDEX')}
            />
          )}
          icon="invoice"
          text1="There are no invoices to review. Create an invoice for a child in their account."
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <InvoicesTable
          invoices={invoices}
          noDataText="There are no invoices matching your search results"
          sortField={sortField}
          sortOrder={sortOrder}
          onTableSortChange={onTableSortChange}
        />
        {renderPagination()}
      </React.Fragment>
    )
  }

  const actions = <Section.Actions options={[{ onClick: onExportClick, type: 'export' }]} />

  return (
    <div>
      {!isEmpty && (
        <SearchBar
          placeholder={i18n.t('global:searchPlaceholder')}
          onChange={onSearchBarChange}
        />
      )}
      <Page.Section
        actions={actions}
        isLoading={isLoading}
        title="Invoices"
      >
        {!isEmpty && (
          <Toolbar>
            <Toolbar.Group>
              <Toolbar.Item>
                <Select
                  clearable={false}
                  options={dateTypeOptions}
                  placeholder="Date type"
                  value={dateType}
                  onChange={onDateTypeChange}
                />
              </Toolbar.Item>
              <Toolbar.Item>
                <DatePicker
                  value={dateRange}
                  clearable
                  range
                  onChange={onDateChange}
                />
              </Toolbar.Item>
              <Toolbar.Item>
                <InfiniteDropdowns.Rooms
                  value={room}
                  onChange={onRoomChange}
                />
              </Toolbar.Item>
              <Toolbar.Item>
                <Select
                  options={statusOptions}
                  placeholder="Select status"
                  value={status}
                  onChange={onStatusChange}
                />
              </Toolbar.Item>
              <Toolbar.Item>
                <Select
                  options={typeOptions}
                  placeholder="Select"
                  value={type}
                  onChange={onTypeChange}
                />
              </Toolbar.Item>
              <Toolbar.Item>
                {_.find(invoices, (invoice) => invoice.containCreditNotes) && (
                  <CreditNoteIcon
                    label="Credit note linked"
                  />
                )}
              </Toolbar.Item>
            </Toolbar.Group>
          </Toolbar>
        )}
        {renderContent()}
      </Page.Section>
    </div>
  )
}

export default FinanceInvoicesView
