import _ from 'lodash'

import { createSelector } from 'reselect'

import { FORMATIVE_REPORTS_STATUSES } from '../constants'

const getFormativeReports = (state) => state.formativeReports

export const getFormativeReportsList = createSelector(
  [getFormativeReports],
  (state) => state.list,
)

export const getCriteria = (params) => {
  const { assessmentPeriod, reportType, room, searchPhrase, status, term } = params
  const criteria = []

  if (term || assessmentPeriod) {
    criteria.push({
      field: 'period.key',
      value: term || assessmentPeriod,
    })
  }

  if (status) {
    criteria.push({
      field: 'status',
      value: status,
    })
  }

  if (room) {
    criteria.push({
      field: 'childSnapshot.nurseryClass',
      value: room,
    })
  }

  if (reportType) {
    criteria.push({
      field: 'type',
      value: reportType,
    })
  }

  if (searchPhrase) {
    criteria.push({
      field: 'concatenable',
      value: searchPhrase,
    })
  }

  return criteria
}

export const getFormativeReportsData = createSelector(
  [getFormativeReportsList],
  ({ data }) => _.map(data, (item) => {
    const { id, status } = item

    return {
      borderColor: _.get(
        _.find(FORMATIVE_REPORTS_STATUSES, (value, key) => key === status),
        'color',
        () => '#808080',
      )(),
      borderTitle: _.get(_.find(FORMATIVE_REPORTS_STATUSES, (value, key) => key === status), 'label', null),
      id,
      raw: item,
    }
  }),
)
