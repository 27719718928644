import moment from 'moment'

import React, { PropsWithChildren } from 'react'

import { DISPLAY_SHORT_DATE_FORMAT } from 'constants/date'

import DateStringRange from './DateStringRange'

interface DateStringRoot<T> extends React.FC<PropsWithChildren<T>> {
  Range?: typeof DateStringRange
}

interface DateStringProps {
  date: Date | moment.Moment | number
  dateFormat?: string
  format?: string
  local?: boolean
  relative?: boolean
}

const DateString: DateStringRoot<DateStringProps> = ({
  date,
  dateFormat,
  format = DISPLAY_SHORT_DATE_FORMAT,
  local = true,
  relative,
}) => {
  if (!date && 0 !== date) {
    return null
  }

  const renderContent = () => {
    let dateMoment = moment(date, dateFormat)

    if (false === local) {
      dateMoment = dateMoment.utc()
    }

    return relative ? dateMoment.calendar() : dateMoment.format(format)
  }

  return (
    <span>
      {renderContent()}
    </span>
  )
}

export default DateString
