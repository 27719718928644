import _ from 'lodash'

import React from 'react'
import { Field } from 'redux-form'

import { FEE_CALCULATION_OPTIONS, FeeCalculationType } from 'services/booking/childBooking/constants'

import { isNumberGreaterOrEqualThan, isNumberLessOrEqualThan, isRequired, isValidInteger } from 'utils/fieldValidation'

import { Box, Form, Grid, Space } from 'components'
import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'
import ExcludedMonths from 'module/Settings/SettingsInvoices/SettingsInvoicesV3EditAnnualised/components/ExcludedMonths'

import i18n from 'translations'

import { RegularBookingsAddFormValues } from '../RegularBookingsAddForm'

interface InvoicingProps {
  disabledEditing: boolean
  formValues: RegularBookingsAddFormValues
  isSubmitting: boolean
}

const isNumberLessOrEqualThan12 = isNumberLessOrEqualThan(12)
const isNumberLessOrEqualThan53 = isNumberLessOrEqualThan(53)
const isNumberGreaterOrEqualThan0 = isNumberGreaterOrEqualThan(0)
const isNumberGreaterOrEqualThan01 = isNumberGreaterOrEqualThan(0.1)
const isNumberGreaterOrEqualThan1 = isNumberGreaterOrEqualThan(1)

const Invoicing: React.FC<InvoicingProps> = ({
  disabledEditing,
  formValues,
  isSubmitting,
}) => {
  const finalFeeCalculationOptions = 1 < formValues.alternates
    ? [
      {
        label: i18n.t('services:Product:NurseryDiscounts:feeCalculation:actual'),
        value: FeeCalculationType.ACTUAL,
      },
    ]
    : FEE_CALCULATION_OPTIONS

  const renderSettingsDetail = () => {
    if (formValues?.settings?.feeCalculation === FeeCalculationType.ANNUALISED) {
      return (
        <Box withPadding>
          <Space margin="-10px 0 0 0" />
          <Grid>
            <Grid.Item width={{ desktop: '140px', mobile: '100%' }}>
              <Form.Row
                label={_.upperFirst(i18n.t('global:weeks'))}
                margin="0 0 10px 0"
                width={{ field: '100%' }}
                verticalLabel
              >
                <Field
                  component={Form.TextField}
                  disabled={disabledEditing || isSubmitting}
                  max={53}
                  min={0}
                  name="settings.calculationWeeks"
                  placeholder={_.upperFirst(i18n.t('global:weeks'))}
                  type="number"
                  validate={[isNumberGreaterOrEqualThan01, isNumberLessOrEqualThan53]}
                />
              </Form.Row>
            </Grid.Item>
            <Grid.Item width={{ desktop: '140px', mobile: '100%' }}>
              <Form.Row
                label={_.upperFirst(i18n.t('global:months'))}
                margin="0 0 10px 0"
                width={{ field: '100%' }}
                verticalLabel
              >
                <Field
                  component={Form.TextField}
                  disabled={disabledEditing || isSubmitting}
                  max={12}
                  min={1}
                  name="settings.calculationMonths"
                  placeholder={_.upperFirst(i18n.t('global:months'))}
                  step={1}
                  type="number"
                  validate={[isValidInteger, isNumberGreaterOrEqualThan1, isNumberLessOrEqualThan12]}
                />
              </Form.Row>
            </Grid.Item>
          </Grid>
          <ExcludedMonths
            disabled={disabledEditing || isSubmitting}
            months={+(formValues?.settings?.calculationMonths || 0)}
            name="settings.excludedMonths"
            usedMonths={formValues?.settings?.excludedMonths || []}
          />
        </Box>
      )
    }

    if (
      formValues?.settings?.feeCalculation === FeeCalculationType.MONTHLY_PACKAGE
      || formValues?.settings?.feeCalculation === FeeCalculationType.WEEKLY_PACKAGE
    ) {
      return (
        <Box withPadding>
          <Form.Row
            label={i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:packageInvoiceLineItem')}
            margin="-10px 0 5px 0"
            width={{ field: '380px' }}
            required
            verticalLabel
          >
            <Field
              component={Form.TextField}
              disabled={disabledEditing || isSubmitting}
              name="settings.packageLineItemName"
              placeholder={
                i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:packageInvoiceLineItemPlaceholder')
              }
              validate={[isRequired]}
            />
          </Form.Row>
          <Grid>
            <Grid.Item width={{ desktop: '120px', mobile: '100%' }}>
              <Form.Row
                label={_.upperFirst(i18n.t('global:weeks'))}
                width={{ field: '100%' }}
                verticalLabel
              >
                <Field
                  component={Form.TextField}
                  disabled={disabledEditing || isSubmitting}
                  max={53}
                  min={0}
                  name="settings.calculationWeeks"
                  placeholder={_.upperFirst(i18n.t('global:weeks'))}
                  step={1}
                  type="number"
                  validate={[isValidInteger, isNumberGreaterOrEqualThan01, isNumberLessOrEqualThan53]}
                />
              </Form.Row>
            </Grid.Item>
            <Grid.Item width={{ desktop: '120px', mobile: '100%' }}>
              <Form.Row
                label={_.upperFirst(i18n.t('global:months'))}
                width={{ field: '100%' }}
                verticalLabel
              >
                <Field
                  component={Form.TextField}
                  disabled={disabledEditing || isSubmitting}
                  max={12}
                  min={1}
                  name="settings.calculationMonths"
                  placeholder={_.upperFirst(i18n.t('global:months'))}
                  step={1}
                  type="number"
                  validate={[isValidInteger, isNumberGreaterOrEqualThan1, isNumberLessOrEqualThan12]}
                />
              </Form.Row>
            </Grid.Item>
            <Grid.Item width={{ desktop: '260px', mobile: '100%' }}>
              <Form.Row
                label={formValues?.settings?.feeCalculation === FeeCalculationType.MONTHLY_PACKAGE
                  ? i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:monthlyPackagePrice')
                  : i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:weeklyPackagePrice')}
                width={{ field: '100%' }}
                required
                verticalLabel
              >
                <SubdomainCurrencyProvider>
                  {({ currencySymbol }) => (
                    <Field
                      component={Form.TextField}
                      disabled={disabledEditing || isSubmitting}
                      min={0}
                      name="settings.packagePrice"
                      placeholder={formValues?.settings?.feeCalculation === FeeCalculationType.MONTHLY_PACKAGE
                        ? i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:monthlyPackagePrice')
                        : i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:weeklyPackagePrice')}
                      prefix={currencySymbol}
                      prefixWidth="30px"
                      type="number"
                      validate={[isRequired, isNumberGreaterOrEqualThan0]}
                    />
                  )}
                </SubdomainCurrencyProvider>
              </Form.Row>
            </Grid.Item>
          </Grid>
          <ExcludedMonths
            disabled={disabledEditing || isSubmitting}
            months={+(formValues?.settings?.calculationMonths || 0)}
            name="settings.excludedMonths"
            usedMonths={formValues?.settings?.excludedMonths || []}
          />
        </Box>
      )
    }

    return null
  }

  return (
    <React.Fragment>
      <Form.Row
        label={i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:feeCalculation')}
        margin="0 0 15px 0"
        width={{ field: '340px' }}
        verticalLabel
      >
        <Field
          component={Form.Select}
          disabled={disabledEditing || isSubmitting || 1 < formValues.alternates}
          name="settings.feeCalculation"
          options={finalFeeCalculationOptions}
          placeholder={i18n.t('module:Children:Child:BookingPattern:RegularBookings:Add:feeCalculation')}
          v2
        />
      </Form.Row>
      {renderSettingsDetail()}
    </React.Fragment>
  )
}

export default Invoicing
