import _ from 'lodash'
import moment from 'moment'

import React from 'react'

import { getBrandingColor } from 'utils/branding'
import { generateRoute } from 'utils/routing'
import { getInitials } from 'utils/string'

import i18n from 'translations'

import { Avatar, Currency, DataTable, DateString, Typography } from 'components'

import { StyledTitle, StyledValue, StyledValueContainer } from './ForecastTableStyled'

const ForecastTable = ({
  forecastData,
  isFinanceV3Enabled,
  isOrganizationContext,
  monthList,
  statisticsData = {},
  year,
}) => {
  const renderColumn = (monthStartDate, key) => (
    <React.Fragment>
      {_.map(forecastData, ({ id, monthlyForecastStatistics }) => {
        const statistics = _.find(
          monthlyForecastStatistics,
          ({ date }) => moment(date).format('YYYY-MM-DD') === monthStartDate,
        )
        const { invoices = {}, toBeInvoiced = {} } = statistics || {}

        const toBeInvoicedValue = toBeInvoiced[key]
        const invoiceValue = invoices[key]

        return (
          <DataTable.Row key={`${id}_${key}`}>
            <StyledValueContainer>
              <StyledValue>
                {invoiceValue ? <Currency color={getBrandingColor('primary-color')} value={invoiceValue} /> : '-'}
              </StyledValue>
              <StyledValue>{toBeInvoicedValue ? <Currency value={toBeInvoicedValue} /> : '-'}</StyledValue>
            </StyledValueContainer>
          </DataTable.Row>
        )
      })}
    </React.Fragment>
  )

  const renderTotal = (monthStartDate, key) => {
    const statistics = _.find(statisticsData, ({ date }) => moment(date).format('YYYY-MM-DD') === monthStartDate)
    const { invoices = {}, toBeInvoiced = {} } = statistics || {}

    const toBeInvoicedValue = toBeInvoiced[key]
    const invoiceValue = invoices[key]
    const formattedDate = moment(monthStartDate).format('YYYYMMDD')

    return (
      <DataTable.Row key={`${formattedDate}_${key}`}>
        <StyledValueContainer>
          <StyledValue>
            {invoiceValue ? <Currency color={getBrandingColor('primary-color')} value={invoiceValue} /> : '-'}
          </StyledValue>
          <StyledValue>{toBeInvoicedValue ? <Currency value={toBeInvoicedValue} /> : '-'}</StyledValue>
        </StyledValueContainer>
      </DataTable.Row>
    )
  }

  const renderChildColumn = (key, cb) => (
    <React.Fragment>
      {_.map(forecastData, (child) => {
        const value = child[key]

        return (
          <DataTable.Row key={`${child.id}_${key}`}>
            <StyledValueContainer>
              <StyledValue>
                {cb ? cb(value) : value}
              </StyledValue>
            </StyledValueContainer>
          </DataTable.Row>
        )
      })}
    </React.Fragment>
  )

  const renderChildContent = () => (
    <React.Fragment>
      <DataTable.Column noBorder noHidden>
        <DataTable.Row gray>
          <StyledTitle>{i18n.t('global:Child:DOB')}</StyledTitle>
        </DataTable.Row>
        {renderChildColumn('birthDate', (value) => (
          <DateString date={value} />
        ))}
        <DataTable.Row background="#EEE" />
      </DataTable.Column>
      <DataTable.Column noBorder>
        <DataTable.Row gray>
          <StyledTitle>{i18n.t('global:Child:StartDate')}</StyledTitle>
        </DataTable.Row>
        {renderChildColumn('startDate', (value) => (
          <DateString date={value} />
        ))}
        <DataTable.Row background="#EEE" />
      </DataTable.Column>
      <DataTable.Column noBorder>
        <DataTable.Row gray>
          <StyledTitle>{i18n.t('global:Child:FinishDate')}</StyledTitle>
        </DataTable.Row>
        {renderChildColumn('leavingDate', (value) => (
          <DateString date={value} />
        ))}
        <DataTable.Row background="#EEE" />
      </DataTable.Column>
      <DataTable.Column noBorder>
        <DataTable.Row gray>
          <StyledTitle>{i18n.t('global:Child:RegistrationDate')}</StyledTitle>
        </DataTable.Row>
        {renderChildColumn('registeredAt', (value) => (
          <DateString date={value} />
        ))}
        <DataTable.Row background="#EEE" />
      </DataTable.Column>
      <DataTable.Column noBorder>
        <DataTable.Row gray>
          <StyledTitle>{i18n.t('global:Child:childRefCode')}</StyledTitle>
        </DataTable.Row>
        {renderChildColumn('codeReference', (value) => value)}
        <DataTable.Row background="#EEE" />
      </DataTable.Column>
    </React.Fragment>
  )

  const renderFinanceContent = () => (
    _.map(monthList, (monthStartDate) => (
      <React.Fragment>
        {isFinanceV3Enabled && (
          <DataTable.Column noBorder>
            <DataTable.Row gray>
              <StyledTitle>
                {i18n.t('global:Finance:Packages')}
              </StyledTitle>
            </DataTable.Row>
            {renderColumn(monthStartDate, 'totalPackages')}
            <DataTable.Row background="#EEE">
              <StyledTitle>
                {renderTotal(monthStartDate, 'totalPackages')}
              </StyledTitle>
            </DataTable.Row>
          </DataTable.Column>
        )}
        <DataTable.Column noBorder>
          <DataTable.Row gray>
            <StyledTitle>
              {isFinanceV3Enabled ? i18n.t('global:Finance:RegularSessions') : i18n.t('global:Finance:Sessions')}
            </StyledTitle>
          </DataTable.Row>
          {renderColumn(monthStartDate, 'totalSessions')}
          <DataTable.Row background="#EEE">
            <StyledTitle>
              {renderTotal(monthStartDate, 'totalSessions')}
            </StyledTitle>
          </DataTable.Row>
        </DataTable.Column>
        <DataTable.Column noBorder>
          <DataTable.Row gray>
            <StyledTitle>
              {isFinanceV3Enabled ? i18n.t('global:Finance:RegularConsumables') : i18n.t('global:Finance:Consumables')}
            </StyledTitle>
          </DataTable.Row>
          {renderColumn(monthStartDate, 'totalConsumables')}
          <DataTable.Row background="#EEE">
            <StyledTitle>
              {renderTotal(monthStartDate, 'totalConsumables')}
            </StyledTitle>
          </DataTable.Row>
        </DataTable.Column>
        {isFinanceV3Enabled && (
          <DataTable.Column noBorder>
            <DataTable.Row gray>
              <StyledTitle>
                {i18n.t('global:Finance:RegularItems')}
              </StyledTitle>
            </DataTable.Row>
            {renderColumn(monthStartDate, 'totalRegularItems')}
            <DataTable.Row background="#EEE">
              <StyledTitle>
                {renderTotal(monthStartDate, 'totalRegularItems')}
              </StyledTitle>
            </DataTable.Row>
          </DataTable.Column>
        )}
        <DataTable.Column noBorder>
          <DataTable.Row gray>
            <StyledTitle>
              {i18n.t('global:Finance:ExtraItems')}
            </StyledTitle>
          </DataTable.Row>
          {renderColumn(monthStartDate, 'totalExtraItems')}
          <DataTable.Row background="#EEE">
            <StyledTitle>
              {renderTotal(monthStartDate, 'totalExtraItems')}
            </StyledTitle>
          </DataTable.Row>
        </DataTable.Column>
        <DataTable.Column noBorder>
          <DataTable.Row gray>
            <StyledTitle>
              {i18n.t('global:Finance:ExtraSessions')}
            </StyledTitle>
          </DataTable.Row>
          {renderColumn(monthStartDate, 'totalExtraSessions')}
          <DataTable.Row background="#EEE">
            <StyledTitle>
              {renderTotal(monthStartDate, 'totalExtraSessions')}
            </StyledTitle>
          </DataTable.Row>
        </DataTable.Column>
        {isFinanceV3Enabled && (
          <DataTable.Column noBorder>
            <DataTable.Row gray>
              <StyledTitle>
                {i18n.t('global:Finance:ExtraConsumables')}
              </StyledTitle>
            </DataTable.Row>
            {renderColumn(monthStartDate, 'totalExtraConsumables')}
            <DataTable.Row background="#EEE">
              <StyledTitle>
                {renderTotal(monthStartDate, 'totalExtraConsumables')}
              </StyledTitle>
            </DataTable.Row>
          </DataTable.Column>
        )}
        <DataTable.Column noBorder>
          <DataTable.Row gray>
            <StyledTitle>
              {i18n.t('global:Finance:Other')}
            </StyledTitle>
          </DataTable.Row>
          {renderColumn(monthStartDate, 'totalOther')}
          <DataTable.Row background="#EEE">
            <StyledTitle>
              {renderTotal(monthStartDate, 'totalOther')}
            </StyledTitle>
          </DataTable.Row>
        </DataTable.Column>
        <DataTable.Column noBorder>
          <DataTable.Row gray>
            <StyledTitle>
              {i18n.t('global:Finance:FundingDeficit')}
            </StyledTitle>
          </DataTable.Row>
          {renderColumn(monthStartDate, 'totalFundingDeficit')}
          <DataTable.Row background="#EEE">
            <StyledTitle>
              {renderTotal(monthStartDate, 'totalFundingDeficit')}
            </StyledTitle>
          </DataTable.Row>
        </DataTable.Column>
        <DataTable.Column noBorder>
          <DataTable.Row gray>
            <StyledTitle>
              {i18n.t('global:Finance:FundingDeducted')}
            </StyledTitle>
          </DataTable.Row>
          {renderColumn(monthStartDate, 'totalFundingDeducted')}
          <DataTable.Row background="#EEE">
            <StyledTitle>
              {renderTotal(monthStartDate, 'totalFundingDeducted')}
            </StyledTitle>
          </DataTable.Row>
        </DataTable.Column>
        <DataTable.Column noBorder>
          <DataTable.Row gray>
            <StyledTitle>
              {i18n.t('global:Finance:Discounts')}
            </StyledTitle>
          </DataTable.Row>
          {renderColumn(monthStartDate, 'totalDiscount')}
          <DataTable.Row background="#EEE">
            <StyledTitle>
              {renderTotal(monthStartDate, 'totalDiscount')}
            </StyledTitle>
          </DataTable.Row>
        </DataTable.Column>
        <DataTable.Column noBorder>
          <DataTable.Row gray>
            <StyledTitle>
              {i18n.t('global:Finance:FeeIncome')}
            </StyledTitle>
          </DataTable.Row>
          {renderColumn(monthStartDate, 'totalInvoices')}
          <DataTable.Row background="#EEE">
            <StyledTitle>
              {renderTotal(monthStartDate, 'totalInvoices')}
            </StyledTitle>
          </DataTable.Row>
        </DataTable.Column>
        <DataTable.Column noBorder>
          <DataTable.Row gray>
            <StyledTitle>
              {i18n.t('global:Finance:FundingIncome')}
            </StyledTitle>
          </DataTable.Row>
          {renderColumn(monthStartDate, 'totalFundingIncome')}
          <DataTable.Row background="#EEE">
            <StyledTitle>
              {renderTotal(monthStartDate, 'totalFundingIncome')}
            </StyledTitle>
          </DataTable.Row>
        </DataTable.Column>
        <DataTable.Column noBorder noHidden>
          <DataTable.Row gray>
            <StyledTitle>
              {i18n.t('global:Finance:TotalRevenue')}
            </StyledTitle>
          </DataTable.Row>
          {renderColumn(monthStartDate, 'totalIncome')}
          <DataTable.Row background="#EEE">
            <StyledTitle>
              {renderTotal(monthStartDate, 'totalIncome')}
            </StyledTitle>
          </DataTable.Row>
        </DataTable.Column>
      </React.Fragment>
    ))
  )

  return (
    <div>
      <Typography margin="30px 0" variant="span">
        {i18n.t('module:Finance:Reports:Forecasting:reportTitle')}
      </Typography>
      <DataTable>
        <DataTable.Head borderRight>
          <DataTable.Row height={88}>
            <StyledTitle>
              {
                isOrganizationContext
                  ? i18n.t('module:Finance:Reports:Forecasting:site')
                  : _.upperFirst(i18n.t('global:child'))
              }
            </StyledTitle>
          </DataTable.Row>
          {_.map(forecastData, ({ firstName, id, logo, name, photo, publicUrl, surname }) => (
            <DataTable.Row key={id}>
              {isOrganizationContext
                ? (
                  <Avatar
                    avatarSize="small"
                    initials={getInitials(name)}
                    src={logo}
                    target="_blank"
                    title={(
                      <Typography color={getBrandingColor('primary-color')} fontSize={14}>
                        {name}
                      </Typography>
                    )}
                    to={(
                      `${publicUrl}${generateRoute('FINANCE.REPORT.FORECASTING')}${year && `?year=${year.value}`}`
                    )}
                  />
                )
                : (
                  <Avatar
                    avatarSize="small"
                    initials={[firstName, surname]}
                    src={photo}
                    title={`${firstName} ${surname}`}
                  />
                )}
            </DataTable.Row>
          ))}
          <DataTable.Row background="#EEE">
            <strong>
              {i18n.t('global:Finance:Total')}
            </strong>
          </DataTable.Row>
        </DataTable.Head>
        <DataTable.ContentScrollable
          data={
            isOrganizationContext
              ? renderFinanceContent()
              : [
                renderChildContent(),
                ...renderFinanceContent()]
          }
          groupHeaders={
            isOrganizationContext
              ? _.map(monthList, (monthStartDate) => (
                <DataTable.Row noBorder>
                  <StyledTitle>{moment(monthStartDate).format('MMM YYYY')}</StyledTitle>
                </DataTable.Row>
              ))
              : [
                <DataTable.Row noBorder>
                  <StyledTitle>Child details</StyledTitle>
                </DataTable.Row>,
                ..._.map(monthList, (monthStartDate) => (
                  <DataTable.Row noBorder>
                    <StyledTitle>{moment(monthStartDate).format('MMM YYYY')}</StyledTitle>
                  </DataTable.Row>
                )),
              ]
          }
          defaultCollapse
          showSeparatorOnLastGroup
        />
      </DataTable>
    </div>
  )
}

export default ForecastTable
