import _ from 'lodash'
import { AnimatePresence, motion } from 'framer-motion'
import { Property } from 'csstype'
import ResizeObserver from 'resize-observer-polyfill'

import React, { PropsWithChildren, useEffect } from 'react'
import { Arrow, useLayer } from 'react-laag'
import { PlacementType } from 'react-laag/dist/PlacementType'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { ZINDEX_ORDER } from 'constants/layout'

import { StyledBox, StyledButton } from './PopoverStyled'

interface PopoverProps {
  button?: React.ReactNode
  buttonFull?: boolean
  buttonWrapper?: (props: any) => React.ReactNode
  disableArrow?: boolean
  /**
   * This param disable automatically disabling the popover after clicking in something inside popover
   */
  disableCloseInside?: boolean
  disabled?: boolean
  isOpen?: boolean
  margin?: Property.Margin
  onClick?: (value: boolean) => void
  onOpen?: () => void
  onOutsideClick?: (value: boolean) => void
  parentZindex?: number
  placement?: PlacementType
  renderLayerWrapper?: (content: React.ReactNode) => React.ReactElement
  zIndex?: number
}

let blockade = false

const Popover: React.FC<PropsWithChildren<PopoverProps>> = ({
  button,
  buttonFull,
  buttonWrapper,
  children,
  disableArrow,
  disableCloseInside,
  disabled,
  isOpen: isOpenExternal,
  margin,
  onClick,
  onOpen,
  onOutsideClick,
  parentZindex = ZINDEX_ORDER.POPOVER,
  placement = 'bottom-center',
  renderLayerWrapper,
  zIndex,
}) => {
  const [isOpen, setOpen] = React.useState<boolean>(false)
  const finalIsOpen = !_.isUndefined(isOpenExternal) ? isOpenExternal : isOpen

  useEffect(() => {
    if (zIndex > (+document.getElementById('layers').style.zIndex || parentZindex)) {
      document.getElementById('layers').style.zIndex = `${zIndex}`
      blockade = true

      setTimeout(() => {
        blockade = false
      }, 200)
    }
  }, [zIndex, isOpen, isOpenExternal])

  useEffect(() => {
    const value = !_.isUndefined(isOpenExternal) ? false === isOpenExternal : !isOpen

    if (value) {
      setTimeout(() => {
        if (!blockade) {
          document.getElementById('layers').style.zIndex = `${parentZindex}`
        }
      }, 100)
    }
  }, [isOpenExternal, isOpen])

  const handleClickButton = (clickedButton) => {
    if (disabled) {
      return null
    }

    if (!clickedButton && disableCloseInside && finalIsOpen) {
      return null
    }

    if (!_.isUndefined(isOpenExternal) && onClick) {
      onClick(!isOpenExternal)
    }

    onOpen?.()

    return setOpen(!isOpen)
  }

  const handleOutsideClick = () => {
    if (_.isUndefined(isOpenExternal)) {
      setOpen(false)
    }

    onOutsideClick?.(false)
  }

  const handleDisappear = () => {
    if (_.isUndefined(isOpenExternal)) {
      setOpen(false)
    }

    onClick?.(false)
  }

  const { arrowProps, layerProps, renderLayer, triggerProps } = useLayer({
    ResizeObserver,
    arrowOffset: 20,
    auto: true,
    containerOffset: 25,
    isOpen: finalIsOpen,
    onDisappear: handleDisappear,
    onOutsideClick: handleOutsideClick,
    onParentClose: handleOutsideClick,
    placement,
    triggerOffset: 20,
  })

  const renderContent = () => {
    const content = (
      <StyledBox
        $margin={margin}
        $zIndex={zIndex}
        onClick={() => handleClickButton(false)}
      >
        {children}
        {!disableArrow && (
          <Arrow
            backgroundColor={NEUTRAL_COLOURS.WHITE}
            borderColor={NEUTRAL_COLOURS.WHITE}
            borderWidth={1}
            roundness={0.5}
            size={12}
            {...arrowProps as any}
          />
        )}
      </StyledBox>
    )

    if (renderLayerWrapper) {
      return renderLayerWrapper(content)
    }

    return content
  }

  const renderButton = () => (
    <StyledButton buttonFull={buttonFull} {...triggerProps} onClick={() => handleClickButton(true)}>
      {button}
    </StyledButton>
  )

  const renderButtonWrapper = () => {
    const props = {
      buttonFull,
      onClick: () => handleClickButton(true),
      ...triggerProps,
    }

    return buttonWrapper(props)
  }

  return (
    <React.Fragment>
      {buttonWrapper ? renderButtonWrapper() : renderButton()}
      {renderLayer(
        // @ts-ignore
        <AnimatePresence>
          {finalIsOpen && (
            <motion.div
              {...layerProps}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              initial={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.2 }}
            >
              {renderContent()}
            </motion.div>
          )}
        </AnimatePresence>,
      )}
    </React.Fragment>
  )
}

export default Popover
