import _ from 'lodash'

import React from 'react'

import invoicesConstants from 'services/legacy/invoices/constants'

import { Currency, DateString, Spinner, Table } from 'components'

import {
  StyledDateRangeContainer,
  StyledStatus,
  StyledTableWrapper,
  StyledTotal,
} from './PreviousInvoicesTotalCellsStyled'

const PreviousInvoicesTotalCells = ({ child, invoices, onAmountClick }) => {
  if (!invoices) {
    return (
      <Spinner />
    )
  }

  const { STATUS_TYPES } = invoicesConstants

  const renderInvoiceCell = (i) => {
    const invoice = invoices[i]

    if (!invoice) {
      return <Table.Td key={i} style={{ borderBottom: 0, borderTop: 0 }} width="210px" />
    }

    const { endDate, id, startDate, status, total } = invoice

    const renderDraftFlag = () => {
      if (status !== STATUS_TYPES.DRAFT) {
        return null
      }

      return (
        <StyledStatus>
          (D)
        </StyledStatus>
      )
    }

    return (
      <Table.Td align="left" key={i} style={{ borderBottom: 0, borderTop: 0 }} width="210px">
        <StyledTotal onClick={onAmountClick(child.id, id)}>
          <Currency value={total} />
          {renderDraftFlag()}
        </StyledTotal>
        <StyledDateRangeContainer>
          <DateString date={startDate} />
          &nbsp;-&nbsp;
          <DateString date={endDate} />
        </StyledDateRangeContainer>
      </Table.Td>
    )
  }

  return (
    <StyledTableWrapper>
      <Table minWidth={210 * 4} noMargin>
        {_.times(4, renderInvoiceCell)}
      </Table>
    </StyledTableWrapper>
  )
}

export default PreviousInvoicesTotalCells
