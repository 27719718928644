import React from 'react'
import { connect } from 'react-redux'

import { WrappedComponentType } from 'constants/types'

import { injectActions } from 'utils/service'

import actions from './actions'
import * as homeObservationsSelectors from './selectors'

export interface withHomeObservationsServiceProps {
  homeObservationsActions: typeof actions
  homeObservationsSelectors: typeof homeObservationsSelectors
}

const withHomeObservationsService = <P extends withHomeObservationsServiceProps>(
  WrappedComponent: WrappedComponentType<P>,
) => {
  const mapState = ({ homeObservations }) => ({ homeObservations })

  const mapDispatch = injectActions('homeObservationsActions', actions)

  const Component = ({ homeObservations, homeObservationsActions, ...others }) => (
    <WrappedComponent
      homeObservationsActions={homeObservationsActions}
      homeObservationsListState={homeObservations.list}
      homeObservationsSelectors={homeObservationsSelectors}
      {...(others as P)}
    />
  )

  Component.authParams = WrappedComponent.authParams

  return connect(mapState, mapDispatch)(Component)
}

export default withHomeObservationsService
