import React from 'react'

import { Field, Label, Section, Toolbar } from 'components'

import i18n from 'translations'

const InvoiceBankSettingView = ({
  bankDetails,
  disableEdit,
  editLink,
  isUkCountry,
  title = i18n.t('components:Settings:InvoiceBankSettings:defaultTitle'),
}) => {
  const { bankAccountName, bankAccountNotes, bankAccountNumber, bankName, sortCode } = bankDetails || {}

  return (
    <Section
      actions={!disableEdit && <Section.Actions tertiary={{ to: editLink }} />}
      title={title}
    >
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <Label text={i18n.t('components:Settings:InvoiceBankSettings:subtitle')} />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      <Field.InlineEdit
        description={bankAccountName}
        label={i18n.t('components:Settings:InvoiceBankSettings:accountName')}
        padding="10px 0"
        noBorder
      />
      {isUkCountry && (
        <Field.InlineEdit
          description={sortCode || '-'}
          label={i18n.t('components:Settings:InvoiceBankSettings:sortCode')}
          noBorder
        />
      )}
      <Field.InlineEdit
        description={bankAccountNumber}
        label={i18n.t('components:Settings:InvoiceBankSettings:accountNumber')}
        padding="10px 0"
        noBorder
      />
      {!isUkCountry && (
        <Field.InlineEdit
          description={bankAccountNotes || '-'}
          label={i18n.t('components:Settings:InvoiceBankSettings:notes')}
          noBorder
        />
      )}
      <Field.InlineEdit
        description={bankName}
        label={i18n.t('components:Settings:InvoiceBankSettings:bank')}
        padding="10px 0"
        noBorder
      />
    </Section>
  )
}

export default InvoiceBankSettingView
