import _ from 'lodash'
import moment from 'moment'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { compose } from 'recompose'
import { change, getFormValues, stopSubmit } from 'redux-form'

import { NEXT_STEP_TYPE } from 'services/legacy/nextSteps/constants'
import { REFERENCE_ASYNC_PAGE_TYPES, UPLOADED_FILE_STATUS } from 'services/legacy/upload/constants'
import { OBSERVATIONS_PAGE_TYPE } from 'module/Learning/Observations/constants'

import { generateRoute } from 'utils/routing'
import { getBackendErrors } from 'utils/backendErrors'

import { EVENTS, logEvent } from 'analytics'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withModalService } from 'services/utils/modal'
import { withNextMontessoriActivitiesService } from 'services/legacy/nextMontessoriActivities'
import { withSecurityService } from 'services/security'
import { withObservationsService } from 'services/legacy/observations'
import { withChildObservationsService } from 'services/legacy/childObservations'
import { withNurseriesService } from 'services/nurseries'
import { withNextStepsService } from 'services/legacy/nextSteps'
import { withFrameworksService } from 'services/legacy/frameworks'
import { withMontessoriCategoriesService } from 'services/legacy/montessoriCategories'
import { withUploadService } from 'services/legacy/upload'
import { withSnackbarService } from 'services/utils/snackbar'
import { withRouter } from 'services/router'
import { getObservationEventLogContext } from 'services/legacy/observations/helpers'

import i18n from 'translations'

import ObservationsEditView, { OBSERVATION_CHILD_FORM } from './ObservationsEditView'
import { OBSERVATIONS_EDIT_FORM } from './components/ObservationsEditForm/ObservationsEditForm'

const OBSERVATION_GROUPS = {
  read: [
    'child',
    'childInformation',
    'childObservation',
    'childObservation.child',
    'medium',
    'medium.children',
    'montessoriActivity',
    'observation.author',
    'observation.childObservations',
    'observation.media',
    'observation.montessoriActivity',
    'user',
    'user.details',
    'childObservation.linkToFrameworks',
    'childObservation.linkToNextSteps',
  ],
}

const NURSERY_SETTINGS_GROUP = {
  read: [
    'nursery.settings',
    'nurserySettings.learning',
    'nurseryLearningSettings',
  ],
}

const MONTESSORI_CATEGORIES_GROUPS = {
  read: [
    'montessoriCategory',
    'montessoriCategory.subcategories',
    'montessoriSubcategory.activities',
    'montessoriSubcategory',
    'montessoriActivity',
  ],
}

const NEXT_STEPS_GROUPS = {
  read: [
    'child',
    'childObservation.child',
    'childObservation.observation',
    'frameworkArea',
    'nextMontessoriActivity',
    'nextMontessoriActivity.childObservation',
    'nextMontessoriActivity.montessoriActivity',
    'nextStep',
    'nextStep.childObservation',
    'nextStep.frameworkAreas',
    'nextStep.nextObservation',
    'observation',
    'observation.montessoriActivity',
    'montessoriActivity',
    'observation.author',
    'user',
  ],
}

const CHILDREN_GROUPS = {
  read: [
    'childProfile',
    'childInformation',
    'child.nurseryClass',
    'nurseryClass',
  ],
}

export const SAVE_TYPE = {
  DRAFT: 'DRAFT',
  REVIEW: 'REVIEW',
}

// NOTE: Bugfix for bug in Safari browser
let continueGenerate = false
let tmpNewObservation = null

class ObservationsEditContainer extends Component {
  constructor(props) {
    super(props)

    const { pathname } = props.location

    this.state = {
      disableUnsavedPopup: false,
      eventLogContext: getObservationEventLogContext(pathname),
      isFromNew: false,
      isSubmitting: false,
      pageType: this.getPageType(),
    }
  }

  componentDidMount() {
    const {
      isMontessori,
      location: routerLocation,
      montessoriCategoriesActions,
      navigate,
      nurseriesActions,
      nurseryOptions,
      observationsActions,
      params,
      router,
    } = this.props
    const { eventLogContext } = this.state
    const { observationId } = params
    const { query } = routerLocation
    const fromAdd = _.has(query, 'fromAdd')

    if (fromAdd) {
      const location = _.assign({}, browserHistory.getCurrentLocation())

      delete location.query.fromAdd

      this.setState({ isFromNew: true }, () => (
        browserHistory.push(location)
      ))
    }

    // NOTE: Call nursery learning settings to check shareAllObservationWithParents props
    nurseriesActions.get(nurseryOptions.id, {
      onSuccess: ({ data }) => this.handleNurseryGetSuccess(data),
      params: { groups: NURSERY_SETTINGS_GROUP },
    })

    if (isMontessori) {
      montessoriCategoriesActions.list({
        params: [{
          groups: MONTESSORI_CATEGORIES_GROUPS,
        }],
      })
    }

    if (!this.isAddPage()) {
      const { pageType } = this.state

      // NOTE: In dropdown item we can have either 'to' or 'onClick' prop
      // therefore log is added in did mount
      logEvent(EVENTS.OBSERVATION_EDIT_BTN_CLICKED, { context: eventLogContext })

      observationsActions.get({
        onFailed: () => {
          navigate(pageType === OBSERVATIONS_PAGE_TYPE.APPROVALS
            ? generateRoute('APPROVALS.OBSERVATIONS')
            : generateRoute('LEARNING.OBSERVATIONS'))
        },
        onSuccess: () => {
          if (fromAdd && tmpNewObservation) {
            setTimeout(() => {
              this.fillFormValues(tmpNewObservation)
            })
          }
        },
        params: [observationId, {
          groups: OBSERVATION_GROUPS,
        }],
      })
    } else {
      logEvent(EVENTS.OBSERVATION_ADD_PAGE_VIEWED, { context: eventLogContext })
    }

    router.setRouteLeaveHook(router.routes[router.routes.length - 1], this.handleUnsavedPopup)
  }

  componentDidUpdate(prevProps) {
    if (continueGenerate) {
      const { observation } = this.props
      const { observation: prevObservation } = prevProps

      if (!_.isEqual(observation, prevObservation)) {
        this.handleSubmitCreate()
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      nextMontessoriActivitiesActions,
      nextStep,
      nextStepsActions,
      observation,
    } = this.props

    if (
      nextProps?.observation?.id
      && observation
      && !observation.id
      && nextStep?.id
    ) {
      const body = { nextObservation: { id: nextProps.observation.id } }
      const params = [nextStep.id, {}]

      if (nextStep?.montessoriActivity) {
        return nextMontessoriActivitiesActions.update({ body, params })
      }

      return nextStepsActions.update({ body, params })
    }

    return null
  }

  componentWillUnmount() {
    const { childActions, montessoriCategoriesActions, nurseriesActions, observationsActions } = this.props

    observationsActions.clear()
    nurseriesActions.clearSingle()
    montessoriCategoriesActions.clearList()

    // To prevent unnecessary all children api call
    // while moving form add to edit with same container
    if (!this.isAddPage()) {
      childActions.clear()
    }
  }

  handleGetNextStepSuccess = ({ data, shareAllObservationsWithParents }) => {
    const {
      changeFieldValue,
      observationsActions,
    } = this.props
    const {
      achievedAt,
      childObservation,
      comments,
      montessoriActivity,
    } = data
    const { child, observation } = childObservation
    const { id: childId } = child
    const finalMontessoriActivity = montessoriActivity || observation?.montessoriActivity
    const newObservation = {}

    newObservation.montessoriActivity = finalMontessoriActivity || null
    newObservation.comments = finalMontessoriActivity ? null : comments
    newObservation.childObservations = [{
      child,
      comments: newObservation.comments,
      sharedWithParents: shareAllObservationsWithParents ?? false,
    }]
    newObservation.observationDate = achievedAt

    if (newObservation.montessoriActivity) {
      changeFieldValue(
        OBSERVATIONS_EDIT_FORM,
        'montessoriActivity',
        {
          label: newObservation.montessoriActivity.name,
          value: newObservation.montessoriActivity.id,
        },
      )
    }
    changeFieldValue(OBSERVATIONS_EDIT_FORM, 'comments', newObservation.comments)
    changeFieldValue(`${OBSERVATION_CHILD_FORM}_${childId}`, 'comments', newObservation.comments)
    changeFieldValue(OBSERVATIONS_EDIT_FORM, 'observationDate', newObservation.observationDate)

    observationsActions.setNewObservation(newObservation)
    continueGenerate = true
    tmpNewObservation = newObservation

    this.handleAutosave()
  }

  handleNurseryGetSuccess = (nurserySettingsData) => {
    const {
      childActions,
      location,
      nextMontessoriActivitiesActions,
      nextStepsActions,
      observationsActions,
    } = this.props
    const { query } = location
    const { c: childId, ns: nextStepId, type: nextStepType } = query
    const { nurserySettings } = nurserySettingsData || {}
    const { learning } = nurserySettings || {}
    const { shareAllObservationsWithParents } = learning || {}

    if (childId) {
      return childActions.get({
        onSuccess: ({ data }) => {
          observationsActions.addChildrenToObservation([{
            child: data,
            sharedWithParents: shareAllObservationsWithParents ?? false,
          }])

          this.handleAutosave()
        },
        params: [childId],
      })
    }

    if (nextStepId && nextStepType) {
      const params = [nextStepId, { groups: NEXT_STEPS_GROUPS }]
      const onSuccess = ({ data }) => this.handleGetNextStepSuccess({ data, shareAllObservationsWithParents })

      if (NEXT_STEP_TYPE.MONTESSORI === nextStepType) {
        return nextMontessoriActivitiesActions.get({ onSuccess, params })
      }

      return nextStepsActions.get({ onSuccess, params })
    }

    return null
  }

  getPageType = () => {
    const { location: { pathname }, params: { observationId } } = this.props

    if (generateRoute('APPROVALS.OBSERVATIONS.EDIT', { observationId }) === pathname) {
      return OBSERVATIONS_PAGE_TYPE.APPROVALS
    }

    return OBSERVATIONS_PAGE_TYPE.LEARNING
  }

  isAddPage = () => {
    const { location: { pathname } } = this.props

    return pathname === generateRoute('LEARNING.OBSERVATIONS.ADD')
  }

  handleUnsavedPopup = (nextLocation) => {
    const { disableUnsavedPopup, isFromNew } = this.state

    if (isFromNew && !disableUnsavedPopup) {
      this.handleOpenUnsavedPopup(nextLocation)

      return false
    }

    return null
  }

  handleOpenUnsavedPopup = (nextLocation) => {
    const { getFiles, modalActions, modalConsts, observation, uploadActions } = this.props

    const files = getFiles([REFERENCE_ASYNC_PAGE_TYPES.OBSERVATION, observation.id])
    const uploadingFiles = _.filter(files, ({ dummy }) => !dummy)

    modalActions.show(modalConsts.TYPES.CONFIRM, {
      cancelButtonLabel: 'Discard',
      confirmButtonLabel: 'Save',
      icon: 'trash',
      onCancel: () => {
        _.each(uploadingFiles, (file) => {
          uploadActions.markFileToRemove(file.id)
        })

        this.handleDeleteObservation(observation.id, nextLocation)
      },
      onConfirm: () => {
        this.handleUpdateUnsavedChanges(nextLocation)
      },
      text: 'Do you want to discard this observation?',
    })

    return false
  }

  handleUpdateUnsavedChanges = (nextLocation) => {
    const { navigate } = this.props
    const { pathname } = nextLocation

    this.setState({ isFromNew: false }, () => {
      navigate(pathname)
    })

    return true
  }

  handleDeleteObservation = (observationId, nextLocation) => {
    const { navigate, observationsActions } = this.props
    const { pathname } = nextLocation

    observationsActions.remove({ params: [observationId, {}] })

    this.setState({ isFromNew: false }, () => {
      navigate(pathname)
    })

    return true
  }

  handleSelectChild = () => {
    const {
      modalActions,
      modalConsts,
      observation,
    } = this.props

    const selected = _.map(observation.childObservations, ({ child }) => child)

    modalActions.show(modalConsts.TYPES.CHILD_PICKER, {
      childrenGroups: CHILDREN_GROUPS,
      emptyStateText: "Look's like you don't have any children yet. Add a child to make an observation.",
      onConfirm: this.handleSelectChildrenSuccess,
      selected,
    })
  }

  handleSelectChildrenSuccess = (selectedChildren) => {
    const { observation: { childObservations }, observationsActions, shareObservationWithParents } = this.props

    const oldChildObservations = _.cloneDeep(childObservations)

    observationsActions.addChildrenToObservation(
      _.map(selectedChildren, (child) => {
        const oldChildObservation = _.find(
          oldChildObservations,
          ({ child: { id: oldChildId } }) => child.id === oldChildId,
        )

        if (oldChildObservation) {
          return oldChildObservation
        }

        return { child, sharedWithParents: shareObservationWithParents ?? false }
      }),
    )

    setTimeout(() => this.handleSubmit(false)(null))
  }

  handleRemoveChild = (e, child) => {
    const { changeFieldValue, formValues, observationsActions } = this.props
    const { media } = formValues(OBSERVATIONS_EDIT_FORM)

    const newMedia = _.map(media, (item) => {
      if (!_.find(item.children, ({ id }) => child === id)) {
        return item
      }

      this.handleUpdateMedia({
        ...item,
        children: _.filter(item.children, ({ id }) => (
          child !== id
        )),
        itemFromBe: true,
      })

      return {
        ...item,
        children: _.filter(item.children, ({ id }) => (
          child !== id
        )),
      }
    })

    if (e) {
      e.stopPropagation()
    }

    changeFieldValue(OBSERVATIONS_EDIT_FORM, 'media', newMedia)
    observationsActions.removeChildFromObservation(child)

    setTimeout(this.handleSubmit(false))
  }

  handleLinkFramework = (childObservation) => {
    const { navigate, observation } = this.props
    const { pageType } = this.state

    this.setState({ disableUnsavedPopup: true }, () => {
      const routeKey = pageType === OBSERVATIONS_PAGE_TYPE.APPROVALS
        ? 'APPROVALS.OBSERVATIONS.EDIT.FRAMEWORKS'
        : 'LEARNING.OBSERVATIONS.EDIT.FRAMEWORKS'

      navigate(generateRoute(routeKey, {
        childObservationId: childObservation.id,
        observationId: observation.id,
      }))
    })
  }

  handleNextSteps = (childObservation) => {
    const { navigate, observation } = this.props
    const { pageType } = this.state

    this.setState({ disableUnsavedPopup: true }, () => {
      const routeKey = pageType === OBSERVATIONS_PAGE_TYPE.APPROVALS
        ? 'APPROVALS.OBSERVATIONS.EDIT.NEXT_STEPS'
        : 'LEARNING.OBSERVATIONS.EDIT.NEXT_STEPS'

      navigate(`${generateRoute(routeKey, {
        childObservationId: childObservation.id,
        observationId: observation.id,
      })}?lastUpdated=${moment(observation.updatedAt)}`)
    })
  }

  handleCheckProcessingFiles = async (readyFiles) => {
    const { changeFieldValue, formValues } = this.props
    let { media: currentMedia } = formValues(OBSERVATIONS_EDIT_FORM)

    currentMedia = _.map(currentMedia, (item) => {
      const newFile = _.find(readyFiles, ({ id }) => id === item.id)

      if (newFile) {
        return {
          ...item,
          status: UPLOADED_FILE_STATUS.READY,
        }
      }

      return item
    })

    changeFieldValue(OBSERVATIONS_EDIT_FORM, 'media', currentMedia)
  }

  handleReloadMedia = (newFiles) => {
    const { changeFieldValue, formValues, observation } = this.props
    const { media } = formValues(OBSERVATIONS_EDIT_FORM)
    const { childObservations } = observation
    const updatedNewFiles = _.map(newFiles, (item) => ({
      ...item,
      children: _.filter(item.children, ({ id }) => (
        _.find(childObservations, ({ child: { id: childId } }) => childId === id)
      )),
      itemFromBe: true,
    }))

    changeFieldValue(OBSERVATIONS_EDIT_FORM, 'media', _.uniqBy([
      ...updatedNewFiles,
      ...media,
    ], ({ id }) => id))
  }

  handleRemoveFileSuccess = (file) => {
    const { changeFieldValue, formValues, snackbarActions } = this.props
    const { media } = formValues(OBSERVATIONS_EDIT_FORM)
    const updatedMedia = _.filter(media, ({ id }) => id !== file.id)

    snackbarActions.show({
      message: i18n.t('services:Files:fileRemoved'),
    })

    changeFieldValue(OBSERVATIONS_EDIT_FORM, 'media', updatedMedia)
  }

  handleRemoveFile = async (file) => {
    const { observationsActions, params } = this.props
    const { observationId } = params
    const { id } = file
    const body = [{ id }]

    observationsActions.removeObservationMedia({
      body,
      onSuccess: () => this.handleRemoveFileSuccess(file),
      params: [observationId, {}],
    })
  }

  getRedirectRouteName = (type) => {
    const { pageType } = this.state

    if (pageType === OBSERVATIONS_PAGE_TYPE.APPROVALS) {
      return SAVE_TYPE.REVIEW === type ? 'APPROVALS.OBSERVATIONS.REVIEW' : 'APPROVALS.OBSERVATIONS'
    }

    return SAVE_TYPE.REVIEW === type ? 'LEARNING.OBSERVATIONS.REVIEW' : 'LEARNING.OBSERVATIONS'
  }

  fillFormValues = (data) => {
    const { changeFieldValue } = this.props
    const { childObservations, comment, montessoriActivity, observationDate } = data

    changeFieldValue(
      OBSERVATIONS_EDIT_FORM,
      'montessoriActivity',
      montessoriActivity ? { label: montessoriActivity.name, value: montessoriActivity.id } : null,
    )
    changeFieldValue(OBSERVATIONS_EDIT_FORM, 'comment', comment)
    changeFieldValue(OBSERVATIONS_EDIT_FORM, 'observationDate', observationDate)

    _.each(childObservations, (child) => {
      changeFieldValue(
        `${OBSERVATION_CHILD_FORM}_${child.child.id}`,
        'sharedWithParents',
        child.sharedWithParents,
      )
      changeFieldValue(`${OBSERVATION_CHILD_FORM}_${child.child.id}`, 'comments', child.comments)
    })
  }

  handleUpdateObservationSuccess = ({ data, fromAutoSave, observationId, type }) => {
    const { location, navigate, observation } = this.props
    const { query } = location

    if (!fromAutoSave || _.has(query, 'fromAdd')) {
      this.fillFormValues(data)
    }

    const redirectRouteName = this.getRedirectRouteName(type)

    this.setState({ isSubmitting: false })

    return type && (
      navigate(`${generateRoute(redirectRouteName, {
        observationId: observationId || observation.id,
      })}`)
    )
  }

  handleUpdateObservationFailed = (response) => {
    const {
      injectValidation,
      observation,
    } = this.props

    const errors = getBackendErrors(response)

    this.setState({ isSubmitting: false })

    if (!errors) {
      return false
    }

    const { children, ...otherErrors } = errors

    if (otherErrors) {
      injectValidation(OBSERVATIONS_EDIT_FORM, otherErrors)
    }

    if (children?.length) {
      _.each(children, (child, index) => {
        const { child: { id } } = observation.childObservations[index]
        injectValidation(`${OBSERVATION_CHILD_FORM}_${id}`, child)
      })
    }

    return true
  }

  handleUpdateObservation = (fromAutoSave) => async (type, observationId) => {
    const {
      formValues,
      observation,
      observationsActions,
      observationsSelectors,
      shareObservationWithParents,
    } = this.props

    const formData = {
      basicForm: formValues(OBSERVATIONS_EDIT_FORM),
      childrenForms: _.map(observation.childObservations, (item) => {
        const values = {}

        values.id = item?.id

        if (!item.id) {
          values.child = {
            id: item.child?.id,
          }

          const formName = `${OBSERVATION_CHILD_FORM}_${item.child.id}`
          values.comments = formValues(formName)?.comments

          if (shareObservationWithParents) {
            values.sharedWithParents = true
          }
        }

        return values
      }),
    }

    const body = observationsSelectors.getUpdateObservationPayload(formData)

    if (SAVE_TYPE.DRAFT === type) {
      return observationsActions.updateDraft({
        body,
        onFailed: this.handleUpdateObservationFailed,
        onSuccess: ({ data }) => this.handleUpdateObservationSuccess({ data, fromAutoSave, observationId, type }),
        params: [observationId || observation.id, { groups: OBSERVATION_GROUPS }],
      })
    }

    if (SAVE_TYPE.REVIEW === type) {
      return observationsActions.updateReview({
        body,
        onFailed: this.handleUpdateObservationFailed,
        onSuccess: ({ data }) => this.handleUpdateObservationSuccess({ data, fromAutoSave, observationId, type }),
        params: [observationId || observation.id, { groups: OBSERVATION_GROUPS }],
      })
    }

    return observationsActions.updateAutosave({
      body,
      onFailed: this.handleUpdateObservationFailed,
      onSuccess: ({ data }) => this.handleUpdateObservationSuccess({ data, fromAutoSave, observationId, type }),
      params: [observationId || observation.id, { groups: OBSERVATION_GROUPS }],
    })
  }

  handleCreateObservationSuccess = ({ data }) => {
    const { navigate } = this.props
    const { id } = data

    continueGenerate = false

    navigate(`${generateRoute('LEARNING.OBSERVATIONS.EDIT', {
      observationId: id,
    })}?fromAdd`)
  }

  handleSubmitCreate = () => {
    const {
      observation,
      observationsActions,
      observationsSelectors,
      shareObservationWithParents,
    } = this.props

    const formData = {
      childrenForms: _.map(observation.childObservations, (item) => {
        const values = {
          sharedWithParents: shareObservationWithParents ?? false,
        }

        if (!item.id) {
          values.child = {
            id: item.child?.id,
          }
        }

        return values
      }),
    }

    const body = observationsSelectors.getUpdateObservationPayload(formData)

    return observationsActions.create({
      body,
      onSuccess: ({ data }) => this.handleCreateObservationSuccess({ data }),
      params: [{}],
    })
  }

  handleSubmit = (fromAutoSave) => (type) => {
    if (SAVE_TYPE.DRAFT === type || SAVE_TYPE.REVIEW === type) {
      this.setState({ disableUnsavedPopup: true })
    }

    const { isSubmitting } = this.state

    if (fromAutoSave && isSubmitting) {
      return true
    }

    this.setState({ isSubmitting: true })

    if (this.isAddPage()) {
      if (continueGenerate) {
        return false
      }

      return this.handleSubmitCreate()
    }

    return this.handleUpdateObservation(fromAutoSave)(type)
  }

  handleChildFormChange = (childObservationId, childId) => setTimeout(() => {
    const { childObservationsActions, childObservationsSelectors, formValues } = this.props
    const values = formValues(`${OBSERVATION_CHILD_FORM}_${childId}`)
    const body = childObservationsSelectors.getBody(values)

    childObservationsActions.update({
      params: [childObservationId, body],
    })
  })

  handleUpdateMediaSuccess = (file) => {
    const { changeFieldValue, formValues, snackbarActions } = this.props
    const { media } = formValues(OBSERVATIONS_EDIT_FORM)
    const updatedMedia = _.map(media, (item) => {
      if (file.id === item.id) {
        return {
          ...item,
          children: file.children,
          name: file.name,
        }
      }

      return item
    })

    snackbarActions.show({
      message: i18n.t('services:Files:fileDetailsHasBeenChanged'),
    })

    changeFieldValue(OBSERVATIONS_EDIT_FORM, 'media', updatedMedia)
  }

  handleUpdateMedia = (file) => {
    const { observationsActions, params } = this.props
    const { children, id: fileId, itemFromBe, name } = file
    const { observationId } = params

    if (itemFromBe) {
      const body = [{
        children: _.map(children, ({ id }) => ({ id })),
        id: fileId,
        name,
      }]

      observationsActions.updateObservationMedia({
        body,
        onSuccess: () => this.handleUpdateMediaSuccess(file),
        params: [observationId, {}],
      })
    }
  }

  handleAutosave = () => setTimeout(this.handleSubmit(true))

  render() {
    const {
      autosaveProcessing,
      formValues,
      hasParentAppAccess,
      isFetching,
      isMontessori,
      isSubmittingAsDraft,
      isSubmittingAsReview,
      location,
      montessoriCategories,
      observation,
      observationBasicFormInitialValues,
      observationChildrenFormInitialValues,
      observationStatesDropdown,
    } = this.props

    let isFromAdd = false
    const { media } = formValues(OBSERVATIONS_EDIT_FORM) || {}
    const { query } = location

    if (_.has(query, 'fromAdd')) {
      isFromAdd = true
    }

    return (
      <ObservationsEditView
        autosaveProcessing={autosaveProcessing}
        hasParentAppAccess={hasParentAppAccess}
        isAddMode={this.isAddPage()}
        isFetching={isFetching}
        isFromAdd={isFromAdd}
        isMontessori={isMontessori}
        isSubmittingAsDraft={isSubmittingAsDraft}
        isSubmittingAsReview={isSubmittingAsReview}
        montessoriCategories={montessoriCategories}
        observation={observation}
        observationBasicFormInitialValues={observationBasicFormInitialValues}
        observationChildrenFormInitialValues={observationChildrenFormInitialValues}
        observationStatesDropdown={observationStatesDropdown}
        totalResults={media?.length || 0}
        onCheckProcessingFiles={this.handleCheckProcessingFiles}
        onChildFormChange={this.handleChildFormChange}
        onFormChange={this.handleAutosave}
        onLinkFramework={this.handleLinkFramework}
        onNextSteps={this.handleNextSteps}
        onReloadMedia={this.handleReloadMedia}
        onRemoveChild={this.handleRemoveChild}
        onRemoveFile={this.handleRemoveFile}
        onSelectChild={this.handleSelectChild}
        onSubmit={this.handleSubmit(false)}
        onUpdateMedia={this.handleUpdateMedia}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  montessoriCategoriesListState,
  montessoriCategoriesSelectors,
  nextStepsSelectors,
  nurseriesSelectors,
  observationsSelectors,
  observationsSingleState,
  params,
  securitySelectors,
  uploadSelectors,
}) => ({
  autosaveProcessing: observationsSelectors.autosaveProcessing(state),
  formValues: (formName) => getFormValues(formName)(state),
  getFiles: (reference) => uploadSelectors.getFilesByReference(reference)(state),
  hasParentAppAccess: securitySelectors.hasParentAppAccessSelector(state),
  isFetching: appSelectors.getIsFetching(observationsSingleState, montessoriCategoriesListState),
  isMontessori: securitySelectors.isMontessori(state),
  isSubmittingAsDraft: observationsSelectors.isSubmittingAsDraft(state),
  isSubmittingAsReview: observationsSelectors.isSubmittingAsReview(state),
  montessoriCategories: montessoriCategoriesSelectors.getMontessoriCategoriesDropdown(state),
  nextStep: nextStepsSelectors.getNextStepsSingleDataState(state),
  nurseryOptions: appSelectors.getContextNurseryRouterConfig(state, params),
  observation: observationsSelectors.getObservationsSingleStateDataSelector(state),
  observationBasicFormInitialValues: observationsSelectors.getInitialValuesForBasicForm(state),
  observationChildrenFormInitialValues: observationsSelectors.getInitialValuesForChildrenForm(state, state),
  observationStatesDropdown: observationsSelectors.getObservationStatesDropdown(state),
  shareObservationWithParents: nurseriesSelectors.getShareObservationWithParents(state),
})

const mapDispatch = {
  changeFieldValue: (formName, field, value) => change(formName, field, value),
  injectValidation: (formName, data) => stopSubmit(formName, data),
}

const enhance = compose(
  withAppService,
  withChildService,
  withChildObservationsService,
  withFrameworksService,
  withModalService,
  withNextMontessoriActivitiesService,
  withNextStepsService,
  withNurseriesService,
  withObservationsService,
  withRouter,
  withSecurityService,
  withSnackbarService,
  withMontessoriCategoriesService,
  withUploadService,
  connect(mapState, mapDispatch),
)

export default enhance(ObservationsEditContainer)

