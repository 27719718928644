import { createSelector } from 'reselect'
import moment from 'moment'

import { DAILY_DIARY_DATE_FORMAT, TIME_TYPE } from 'services/legacy/dailyDiary/constants'

import { changeHours, isPastDay } from 'utils/date'

const getDailyDiarySleeps = (state) => state.dailyDiarySleeps

export const getDailyDiarySleepsList = createSelector([getDailyDiarySleeps], (state) => state.list)

export const getDailyDiarySleepsListData = createSelector([getDailyDiarySleepsList], (state) => state.data)

export const getListCriteria = (query = {}) => {
  const {
    childrenWithoutRecords,
    entryDate = moment().format(DAILY_DIARY_DATE_FORMAT),
    not,
    recordsAndPlaceholders,
    room,
    timeType,
  } = query
  const criteria = []

  criteria.push({
    comparator: 'after',
    field: 'entryDate',
    value: moment(entryDate).format(DAILY_DIARY_DATE_FORMAT),
  })

  criteria.push({
    comparator: 'before',
    field: 'entryDate',
    value: moment(entryDate).format(DAILY_DIARY_DATE_FORMAT),
  })

  if (room) {
    criteria.push({
      field: 'child.nurseryClass',
      value: room,
    })
  }

  if (childrenWithoutRecords) {
    criteria.push({
      field: 'childRegisterDiaryRecordStatistics.sleepRecordsCount[lt]',
      value: 1,
    })
  }

  if (recordsAndPlaceholders) {
    criteria.push({
      field: 'or[0][childRegisterDiaryRecordStatistics.sleepRecordsCount][gt]',
      value: 0,
    })

    criteria.push({
      field: 'or[0][child.information.daytimeSleepingBehavior][like]',
      value: '%yes%',
    })
  }

  if (not) {
    const { daytimeSleepingBehavior: notDaytimeSleepingBehavior } = not

    if (notDaytimeSleepingBehavior) {
      criteria.push({
        field: 'not[child.information.daytimeSleepingBehavior]',
        value: '%yes%',
      })
    }
  }

  if (timeType === TIME_TYPE.CURRENTLY_TIMED_IN && !isPastDay(entryDate)) {
    criteria.push({
      comparator: 'after',
      field: 'signIns.signedOutAt',
      value: changeHours(entryDate, moment()).toISOString(),
    })
  }

  criteria.push({
    field: 'present',
    value: true,
  })

  criteria.push({
    field: 'order[child.firstName]',
    value: 'ASC',
  })

  return criteria
}
