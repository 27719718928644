import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getFormValues, stopSubmit } from 'redux-form'

import { FORMATIVE_REPORTS_PAGE_TYPE } from 'module/Learning/FormativeReports/constants'
import { TYPE_OF_TRACKING } from 'services/legacy/formativeReports/constants'

import { getBackendErrors } from 'utils/backendErrors'
import { generateRoute } from 'utils/routing'

import { withAppService } from 'services/app'
import { withChildService } from 'services/legacy/child'
import { withFormativeReportsService } from 'services/legacy/formativeReports'
import { withRouter } from 'services/router'

import FormativeReportsCreateView from './FormativeReportsCreateView'

import { CHILD_LEARNING_JOURNEY_FORMATIVE_REPORTS_CREATE_FORM } from './components/FormativeReportsCreateForm'

class FormativeReportsCreateContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      child: null,
      pageType: this.getPageType(),
    }
  }

  componentWillUnmount() {
    const { formativeReportsActions } = this.props

    formativeReportsActions.clear()
  }

  getPageType = () => {
    const { location: { pathname } } = this.props

    if (generateRoute('LEARNING.FORMATIVE_REPORTS.CREATE') === pathname) {
      return FORMATIVE_REPORTS_PAGE_TYPE.LEARNING
    }

    return FORMATIVE_REPORTS_PAGE_TYPE.CHILD
  }

  redirectToPreview = (id) => {
    const { navigate, params } = this.props
    const { pageType } = this.state

    if (pageType === FORMATIVE_REPORTS_PAGE_TYPE.LEARNING) {
      navigate(generateRoute('LEARNING.FORMATIVE_REPORTS.PREVIEW', {
        formativeReportId: id,
      }))
    }

    if (pageType === FORMATIVE_REPORTS_PAGE_TYPE.CHILD) {
      navigate(generateRoute('CHILDREN.CHILD.LEARNING_JOURNEY.FORMATIVE_REPORTS.PREVIEW', {
        childId: params?.childId,
        formativeReportId: id,
      }))
    }
  }

  handleSubmitSuccess = (response) => {
    const { id } = response.data

    this.redirectToPreview(id)
  }

  handleSubmitFailed = (response) => {
    const { injectValidation } = this.props

    const errors = getBackendErrors(response)

    if (!errors) {
      return false
    }

    if (errors.dateFrom || errors.dateTo) {
      errors.date = errors.dateFrom || errors.dateTo
    }

    if (errors.period) {
      errors.term = errors.period
    }

    return injectValidation(CHILD_LEARNING_JOURNEY_FORMATIVE_REPORTS_CREATE_FORM, errors)
  }

  handleSubmit = (fields) => {
    const { formativeReportsActions, formativeReportsSelectors, params } = this.props
    const { child } = this.state

    const body = formativeReportsSelectors.getFormData(
      fields,
      params?.childId || child?.value,
    )

    formativeReportsActions.createReport(
      body,
      this.handleSubmitSuccess,
      this.handleSubmitFailed,
    )
  }

  handleChildChange = (child) => {
    this.setState(
      { child },
      () => {
        const { childActions } = this.props

        if (child?.value) {
          childActions.get({ params: [child.value] })
        }
      },
    )
  }

  render() {
    const {
      errorMessages,
      formValues,
      isSubmitting,
      params,
      terms,
    } = this.props
    const { child, pageType } = this.state

    const initialValues = {
      typeOfTracking: TYPE_OF_TRACKING.TERM,
    }

    return (
      <FormativeReportsCreateView
        child={child}
        childId={params?.childId || child?.value}
        errorMessages={errorMessages}
        formValues={formValues}
        initialValues={initialValues}
        isSubmitting={isSubmitting}
        pageType={pageType}
        terms={terms}
        onChildChange={this.handleChildChange}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const mapDispatch = {
  injectValidation: (formName, errors) => stopSubmit(formName, errors),
}

const mapState = (state, {
  appSelectors,
  childSelectors,
  childSingleState,
  formativeReportsSelectors,
  formativeReportsSingleState,
}) => ({
  child: childSelectors.getChildSelector(state),
  errorMessages: appSelectors.getErrorMessages(
    childSingleState,
    formativeReportsSingleState,
  ),
  formValues: getFormValues(CHILD_LEARNING_JOURNEY_FORMATIVE_REPORTS_CREATE_FORM)(state),
  isSubmitting: appSelectors.getIsSubmitting(formativeReportsSingleState),
  selectedChild: childSelectors.getChildSelector(state),
  terms: formativeReportsSelectors.getTermsDropdown(state),
})

const enhance = compose(
  withAppService,
  withChildService,
  withFormativeReportsService,
  withRouter,
  connect(mapState, mapDispatch),
)

export default enhance(FormativeReportsCreateContainer)
