import _ from 'lodash'
import i18n from 'translations'

export const ERROR_CODES = {
  DUPLICATE_PAYMENT: 400501,
  MISSING_FIELDS_VALIDATION: 400,
  MISSING_PAYMENT_CONTACT: 409001,
}

export const STATUS_TYPES = {
  ALL: 'ALL',
  BAD_DEBT: 'BAD_DEBT',
  CANCELLED: 'CANCELLED',
  DRAFT: 'DRAFT',
  OVERDUE: 'OVERDUE',
  PAID: 'PAID',
  PARTIALLY_PAID: 'PARTIALLY_PAID',
  SENDING: 'SENDING',
  SENT: 'SENT',
}

export const STATUSES = {
  [STATUS_TYPES.ALL]: i18n.t('services:Invoices:Constants:StatusesLabels:all'),
  [STATUS_TYPES.BAD_DEBT]: i18n.t('services:Invoices:Constants:StatusesLabels:badDebt'),
  [STATUS_TYPES.CANCELLED]: i18n.t('services:Invoices:Constants:StatusesLabels:cancelled'),
  [STATUS_TYPES.DRAFT]: i18n.t('services:Invoices:Constants:StatusesLabels:draft'),
  [STATUS_TYPES.OVERDUE]: i18n.t('services:Invoices:Constants:StatusesLabels:overdue'),
  [STATUS_TYPES.PARTIALLY_PAID]: i18n.t('services:Invoices:Constants:StatusesLabels:partiallyPaid'),
  [STATUS_TYPES.PAID]: i18n.t('services:Invoices:Constants:StatusesLabels:paid'),
  [STATUS_TYPES.SENDING]: i18n.t('services:Invoices:Constants:StatusesLabels:sending'),
  [STATUS_TYPES.SENT]: i18n.t('services:Invoices:Constants:StatusesLabels:sent'),
}

export const STATUSES_DROPDOWN = _.map(STATUSES, (label, value) => ({
  label,
  value,
}))

export const LINE_ITEM_TYPES = {
  DEPOSIT: 'customDeposit',
  DISCOUNT: 'customDiscount',
  EXTRA_CONSUMABLE: 'customExtraConsumable',
  EXTRA_ITEM: 'customExtraItem',
  EXTRA_ITEM_CHILD_PRODUCT: 'customExtraItemChildProduct',
  EXTRA_SESSION: 'customExtraSession',
  EXTRA_SESSION_CHILD_PRODUCT: 'customExtraSessionChildProduct',
  FUNDING: 'customFunding',
  ITEM: 'custom',
  ONE_OFF_DISCOUNT: 'customOneOffDiscountChildProduct',
  ONE_OFF_FUNDING: 'customOneOffFundingChildProduct',
  ONE_OFF_NO_DEDUCT_FUNDING: 'customOneOffNoDeductFundingChildProduct',
  OTHER: 'customOther',
  PACKAGE: 'customPackage',
  REFUND_DEPOSIT: 'customRefundDeposit',
  REGULAR_CONSUMABLE: 'customRegularConsumable',
  REGULAR_DISCOUNT: 'customRegularDiscount',
  REGULAR_FUNDING: 'customRegularFunding',
  REGULAR_ITEM: 'customRegularItem',
  REGULAR_SESSION: 'customRegularSession',
}

const LINE_ITEMS = {
  [LINE_ITEM_TYPES.REGULAR_ITEM]: i18n.t('services:Invoices:Constants:LineItemsLabels:regularItem'),
  [LINE_ITEM_TYPES.REGULAR_DISCOUNT]: i18n.t('services:Invoices:Constants:LineItemsLabels:regularDiscount'),
  [LINE_ITEM_TYPES.REGULAR_SESSION]: i18n.t('services:Invoices:Constants:LineItemsLabels:regularSession'),
  [LINE_ITEM_TYPES.REGULAR_FUNDING]: i18n.t('services:Invoices:Constants:LineItemsLabels:funding'),
  [LINE_ITEM_TYPES.REGULAR_CONSUMABLE]: i18n.t('services:Invoices:Constants:LineItemsLabels:regularConsumable'),
  [LINE_ITEM_TYPES.ITEM]: i18n.t('services:Invoices:Constants:LineItemsLabels:fees'),
  [LINE_ITEM_TYPES.FUNDING]: i18n.t('services:Invoices:Constants:LineItemsLabels:funding'),
  [LINE_ITEM_TYPES.DISCOUNT]: i18n.t('services:Invoices:Constants:LineItemsLabels:discount'),
  [LINE_ITEM_TYPES.EXTRA_CONSUMABLE]: i18n.t('services:Invoices:Constants:LineItemsLabels:extraConsumable'),
  [LINE_ITEM_TYPES.EXTRA_SESSION]: i18n.t('services:Invoices:Constants:LineItemsLabels:extraSession'),
  [LINE_ITEM_TYPES.EXTRA_SESSION_CHILD_PRODUCT]: i18n.t('services:Invoices:Constants:LineItemsLabels:extraSession'),
  [LINE_ITEM_TYPES.EXTRA_ITEM_CHILD_PRODUCT]: i18n.t('services:Invoices:Constants:LineItemsLabels:extraSession'),
  [LINE_ITEM_TYPES.ONE_OFF_FUNDING]: i18n.t('services:Invoices:Constants:LineItemsLabels:oneOffFunding'),
  [LINE_ITEM_TYPES.ONE_OFF_NO_DEDUCT_FUNDING]: i18n.t('services:Invoices:Constants:LineItemsLabels:oneOffFunding'),
  [LINE_ITEM_TYPES.ONE_OFF_DISCOUNT]: i18n.t('services:Invoices:Constants:LineItemsLabels:oneOffDiscount'),
  [LINE_ITEM_TYPES.EXTRA_ITEM]: i18n.t('services:Invoices:Constants:LineItemsLabels:extraItem'),
  [LINE_ITEM_TYPES.OTHER]: i18n.t('services:Invoices:Constants:LineItemsLabels:other'),
  [LINE_ITEM_TYPES.DEPOSIT]: i18n.t('services:Invoices:Constants:LineItemsLabels:deposit'),
  [LINE_ITEM_TYPES.REFUND_DEPOSIT]: i18n.t('services:Invoices:Constants:LineItemsLabels:refundDeposit'),
  [LINE_ITEM_TYPES.PACKAGE]: i18n.t('services:Invoices:Constants:LineItemsLabels:package'),
}

const LINE_SUB_ITEM_TYPES = {
  CONSUMABLE: 'consumable',
}

const LINE_SUB_ITEMS = {
  [LINE_SUB_ITEM_TYPES.CONSUMABLE]: i18n.t('services:Invoices:Constants:LineSubItemsLabels:consumable'),
}

const AUTO_CALCULATE_ALT_FUNDING_TYPE = {
  MANUALLY_ALLOCATED_FUNDING: 'manuallyAllocatedFunding',
  MANUALLY_ALLOCATED_FUNDING_DEFICIT: 'manuallyAllocatedFundingDeficit',
  OMIT_MANUALLY_ALLOCATED_FUNDING_DEDUCT: 'omitManuallyAllocatedFundingDeficit',
}

const AUTO_CALCULATION_FUNDING_TYPE = {
  FUNDING: 'funding',
  FUNDING_DEFICIT: 'fundingDeficit',
  FUNDING_NO_DEDUCT: 'fundingNoDeduct',
  OMIT_FUNDING_DEDUCT: 'omitFundingDeficit',
}

const AUTO_CALCULATION_ITEM_TYPE = {
  DEPOSIT: 'deposit',
  DISCOUNTS: 'discount',
  EXTRA_ITEMS: 'extraItem',
  EXTRA_SESSIONS: 'extraSession',
  REFUND_DEPOSIT: 'refundDeposit',
  SESSIONS: 'session',
  ...AUTO_CALCULATION_FUNDING_TYPE,
  ...AUTO_CALCULATE_ALT_FUNDING_TYPE,
}

export const AUTO_CALCULATION_ITEM_TYPE_V3 = {
  EXTRA_ITEM_CHILD_PRODUCT: 'extraItemChildProduct',
  EXTRA_SESSION_CHILD_PRODUCT: 'extraSessionChildProduct',
  EXTRA_SESSION_CONSUMABLE_CHILD_PRODUCT: 'extraSessionConsumableChildProduct',
  OMIT_REGULAR_FUNDING_DEFICIT_CHILD_PRODUCT: 'omitRegularFundingDeficitChildProduct',
  ONE_OFF_DISCOUNT_CHILD_PRODUCT: 'oneOffDiscountChildProduct',
  ONE_OFF_FUNDING_CHILD_PRODUCT: 'oneOffFundingChildProduct',
  ONE_OFF_NO_DEDUCT_FUNDING_CHILD_PRODUCT: 'oneOffNoDeductFundingChildProduct',
  PACKAGE_CHILD_PRODUCT: 'packageChildProduct',
  REGULAR_DISCOUNT: 'regularDiscountChildProduct',
  REGULAR_FUNDING: 'regularFundingChildProduct',
  REGULAR_FUNDING_DEFICIT_CHILD_PRODUCT: 'regularFundingDeficitChildProduct',
  REGULAR_ITEM: 'regularItemChildProduct',
  REGULAR_SESSION: 'regularSessionChildProduct',
  REGULAR_SESSION_CONSUMABLE_CHILD_PRODUCT: 'regularSessionConsumableChildProduct',
}

const LEGACY_ITEM_TYPE = {
  LEGACY_EXTRA_ITEM: 'legacyCustomExtraItem',
  LEGACY_EXTRA_SESSION: 'legacyCustomExtraSession',
  LEGACY_FUNDING: 'legacyCustomFunding',
}

const INVOICE_PREVIEW_TYPES = {
  CONSUMABLE: 'consumable',
  DEPOSIT: 'deposit',
  DISCOUNT: 'discount',
  EXTRA_CONSUMABLE: 'extraConsumable',
  EXTRA_ITEM: 'extraItem',
  EXTRA_SESSION: 'extraSession',
  FEES: 'fees',
  FUNDING: 'funding',
  ONE_OFF_DISCOUNT: 'oneOffDiscount',
  ONE_OFF_FUNDING: 'oneOffFunding',
  OTHER: 'other',
  PACKAGE: 'package',
  REFUND_DEPOSIT: 'refundDeposit',
  REGULAR_CONSUMABLE: 'regularConsumable',
  REGULAR_DISCOUNT: 'regularDiscount',
  REGULAR_FUNDING: 'regularFunding',
  REGULAR_ITEM: 'regularItem',
  REGULAR_SESSION: 'regularSession',
}

const INVOICE_PREVIEW_MAPPINGS = {
  [INVOICE_PREVIEW_TYPES.REGULAR_ITEM]: LINE_ITEMS[LINE_ITEM_TYPES.REGULAR_ITEM],
  [INVOICE_PREVIEW_TYPES.REGULAR_DISCOUNT]: LINE_ITEMS[LINE_ITEM_TYPES.REGULAR_DISCOUNT],
  [INVOICE_PREVIEW_TYPES.REGULAR_SESSION]: LINE_ITEMS[LINE_ITEM_TYPES.REGULAR_SESSION],
  [INVOICE_PREVIEW_TYPES.PACKAGE]: LINE_ITEMS[LINE_ITEM_TYPES.PACKAGE],
  [INVOICE_PREVIEW_TYPES.REGULAR_CONSUMABLE]: LINE_ITEMS[LINE_ITEM_TYPES.REGULAR_CONSUMABLE],
  [INVOICE_PREVIEW_TYPES.FEES]: LINE_ITEMS[LINE_ITEM_TYPES.ITEM],
  [INVOICE_PREVIEW_TYPES.EXTRA_ITEM]: LINE_ITEMS[LINE_ITEM_TYPES.EXTRA_ITEM],
  [INVOICE_PREVIEW_TYPES.EXTRA_SESSION]: LINE_ITEMS[LINE_ITEM_TYPES.EXTRA_SESSION],
  [INVOICE_PREVIEW_TYPES.EXTRA_CONSUMABLE]: LINE_ITEMS[LINE_ITEM_TYPES.EXTRA_CONSUMABLE],
  [INVOICE_PREVIEW_TYPES.ONE_OFF_FUNDING]: LINE_ITEMS[LINE_ITEM_TYPES.ONE_OFF_FUNDING],
  [INVOICE_PREVIEW_TYPES.ONE_OFF_DISCOUNT]: LINE_ITEMS[LINE_ITEM_TYPES.ONE_OFF_DISCOUNT],
  [INVOICE_PREVIEW_TYPES.FUNDING]: LINE_ITEMS[LINE_ITEM_TYPES.FUNDING],
  [INVOICE_PREVIEW_TYPES.DISCOUNT]: LINE_ITEMS[LINE_ITEM_TYPES.DISCOUNT],
  [INVOICE_PREVIEW_TYPES.DEPOSIT]: LINE_ITEMS[LINE_ITEM_TYPES.DEPOSIT],
  [INVOICE_PREVIEW_TYPES.REFUND_DEPOSIT]: LINE_ITEMS[LINE_ITEM_TYPES.REFUND_DEPOSIT],
  [INVOICE_PREVIEW_TYPES.OTHER]: LINE_ITEMS[LINE_ITEM_TYPES.OTHER],
  [INVOICE_PREVIEW_TYPES.CONSUMABLE]: LINE_SUB_ITEMS[LINE_SUB_ITEM_TYPES.CONSUMABLE],
}

export const REGULAR_LINE_ITEM_OPTIONS_V3 = [
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.REGULAR_SESSION],
    nonCustomType: [AUTO_CALCULATION_ITEM_TYPE_V3.REGULAR_SESSION],
    order: 0,
    previewType: INVOICE_PREVIEW_TYPES.REGULAR_SESSION,
    showCost: true,
    value: LINE_ITEM_TYPES.REGULAR_SESSION,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.REGULAR_ITEM],
    nonCustomType: [AUTO_CALCULATION_ITEM_TYPE_V3.REGULAR_ITEM],
    order: 1,
    previewType: INVOICE_PREVIEW_TYPES.REGULAR_ITEM,
    showCost: true,
    value: LINE_ITEM_TYPES.REGULAR_ITEM,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.REGULAR_DISCOUNT],
    nonCustomType: [AUTO_CALCULATION_ITEM_TYPE_V3.REGULAR_DISCOUNT],
    order: 1,
    previewType: INVOICE_PREVIEW_TYPES.REGULAR_DISCOUNT,
    showCost: true,
    value: LINE_ITEM_TYPES.REGULAR_DISCOUNT,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.REGULAR_CONSUMABLE],
    nonCustomType: [AUTO_CALCULATION_ITEM_TYPE_V3.REGULAR_SESSION_CONSUMABLE_CHILD_PRODUCT],
    order: 1,
    previewType: INVOICE_PREVIEW_TYPES.REGULAR_CONSUMABLE,
    showCost: true,
    value: LINE_ITEM_TYPES.REGULAR_CONSUMABLE,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.REGULAR_FUNDING],
    nonCustomType: [AUTO_CALCULATION_ITEM_TYPE_V3.REGULAR_FUNDING],
    order: 0,
    previewType: INVOICE_PREVIEW_TYPES.REGULAR_FUNDING,
    showCost: true,
    value: LINE_ITEM_TYPES.REGULAR_FUNDING,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.EXTRA_CONSUMABLE],
    nonCustomType: [AUTO_CALCULATION_ITEM_TYPE_V3.EXTRA_SESSION_CONSUMABLE_CHILD_PRODUCT],
    order: 1,
    previewType: INVOICE_PREVIEW_TYPES.EXTRA_CONSUMABLE,
    showCost: true,
    value: LINE_ITEM_TYPES.EXTRA_CONSUMABLE,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.ONE_OFF_DISCOUNT],
    nonCustomType: [AUTO_CALCULATION_ITEM_TYPE_V3.ONE_OFF_DISCOUNT_CHILD_PRODUCT],
    order: 1,
    previewType: INVOICE_PREVIEW_TYPES.ONE_OFF_DISCOUNT,
    showCost: true,
    value: LINE_ITEM_TYPES.ONE_OFF_DISCOUNT,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.ONE_OFF_FUNDING],
    nonCustomType: [
      AUTO_CALCULATION_ITEM_TYPE_V3.ONE_OFF_FUNDING_CHILD_PRODUCT,
      AUTO_CALCULATION_ITEM_TYPE_V3.ONE_OFF_NO_DEDUCT_FUNDING_CHILD_PRODUCT,
    ],
    order: 1,
    previewType: INVOICE_PREVIEW_TYPES.ONE_OFF_FUNDING,
    showCost: true,
    value: LINE_ITEM_TYPES.ONE_OFF_FUNDING,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.PACKAGE],
    nonCustomType: [AUTO_CALCULATION_ITEM_TYPE_V3.PACKAGE_CHILD_PRODUCT],
    order: 1,
    previewType: INVOICE_PREVIEW_TYPES.PACKAGE,
    showCost: true,
    value: LINE_ITEM_TYPES.PACKAGE,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.OTHER],
    nonCustomType: [
      AUTO_CALCULATION_ITEM_TYPE_V3.REGULAR_FUNDING_DEFICIT_CHILD_PRODUCT,
      AUTO_CALCULATION_ITEM_TYPE_V3.OMIT_REGULAR_FUNDING_DEFICIT_CHILD_PRODUCT,
    ],
    order: 1,
    previewType: INVOICE_PREVIEW_TYPES.OTHER,
    showCost: true,
    value: LINE_ITEM_TYPES.OTHER,
  },
]

export const LINE_ITEM_OPTIONS_V3 = [
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.ITEM],
    nonCustomType: [AUTO_CALCULATION_ITEM_TYPE.SESSIONS],
    order: 0,
    previewType: INVOICE_PREVIEW_TYPES.FEES,
    showCost: true,
    value: LINE_ITEM_TYPES.ITEM,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.EXTRA_SESSION],
    nonCustomType: [
      AUTO_CALCULATION_ITEM_TYPE_V3.EXTRA_SESSION_CHILD_PRODUCT,
      AUTO_CALCULATION_ITEM_TYPE.EXTRA_SESSIONS,
      LEGACY_ITEM_TYPE.LEGACY_EXTRA_SESSION,
    ],
    order: 1,
    previewType: INVOICE_PREVIEW_TYPES.EXTRA_SESSION,
    showCost: false,
    value: LINE_ITEM_TYPES.EXTRA_SESSION_CHILD_PRODUCT,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.EXTRA_ITEM],
    nonCustomType: [
      AUTO_CALCULATION_ITEM_TYPE_V3.EXTRA_ITEM_CHILD_PRODUCT,
      AUTO_CALCULATION_ITEM_TYPE.EXTRA_ITEMS,
      LEGACY_ITEM_TYPE.LEGACY_EXTRA_ITEM,
    ],
    order: 2,
    previewType: INVOICE_PREVIEW_TYPES.EXTRA_ITEM,
    showCost: true,
    value: LINE_ITEM_TYPES.EXTRA_ITEM_CHILD_PRODUCT,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.OTHER],
    order: 3,
    previewType: INVOICE_PREVIEW_TYPES.OTHER,
    showCost: true,
    value: LINE_ITEM_TYPES.OTHER,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.ONE_OFF_FUNDING],
    nonCustomType: [
      ..._.values(AUTO_CALCULATION_FUNDING_TYPE),
      ..._.values(AUTO_CALCULATE_ALT_FUNDING_TYPE),
      LEGACY_ITEM_TYPE.LEGACY_FUNDING,
    ],
    order: 4,
    previewType: INVOICE_PREVIEW_TYPES.ONE_OFF_FUNDING,
    showCost: false,
    value: LINE_ITEM_TYPES.ONE_OFF_FUNDING,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.ONE_OFF_DISCOUNT],
    nonCustomType: [AUTO_CALCULATION_ITEM_TYPE.DISCOUNTS],
    order: 5,
    previewType: INVOICE_PREVIEW_TYPES.DISCOUNT,
    showCost: false,
    value: LINE_ITEM_TYPES.ONE_OFF_DISCOUNT,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.DEPOSIT],
    nonCustomType: [AUTO_CALCULATION_ITEM_TYPE.DEPOSIT],
    order: 6,
    previewType: INVOICE_PREVIEW_TYPES.DEPOSIT,
    showCost: false,
    value: LINE_ITEM_TYPES.DEPOSIT,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.REFUND_DEPOSIT],
    nonCustomType: [AUTO_CALCULATION_ITEM_TYPE.REFUND_DEPOSIT],
    order: 7,
    previewType: INVOICE_PREVIEW_TYPES.REFUND_DEPOSIT,
    showCost: false,
    value: LINE_ITEM_TYPES.REFUND_DEPOSIT,
  },
]

export const LINE_ITEM_OPTIONS = [
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.ITEM],
    nonCustomType: [AUTO_CALCULATION_ITEM_TYPE.SESSIONS],
    order: 0,
    previewType: INVOICE_PREVIEW_TYPES.FEES,
    showCost: true,
    value: LINE_ITEM_TYPES.ITEM,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.EXTRA_SESSION],
    nonCustomType: [
      AUTO_CALCULATION_ITEM_TYPE.EXTRA_SESSIONS,
      LEGACY_ITEM_TYPE.LEGACY_EXTRA_SESSION,
    ],
    order: 1,
    previewType: INVOICE_PREVIEW_TYPES.EXTRA_SESSION,
    showCost: false,
    value: LINE_ITEM_TYPES.EXTRA_SESSION,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.EXTRA_ITEM],
    nonCustomType: [
      AUTO_CALCULATION_ITEM_TYPE.EXTRA_ITEMS,
      LEGACY_ITEM_TYPE.LEGACY_EXTRA_ITEM,
    ],
    order: 2,
    previewType: INVOICE_PREVIEW_TYPES.EXTRA_ITEM,
    showCost: true,
    value: LINE_ITEM_TYPES.EXTRA_ITEM,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.OTHER],
    order: 3,
    previewType: INVOICE_PREVIEW_TYPES.OTHER,
    showCost: true,
    value: LINE_ITEM_TYPES.OTHER,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.FUNDING],
    nonCustomType: [
      ..._.values(AUTO_CALCULATION_FUNDING_TYPE),
      ..._.values(AUTO_CALCULATE_ALT_FUNDING_TYPE),
      LEGACY_ITEM_TYPE.LEGACY_FUNDING,
    ],
    order: 4,
    previewType: INVOICE_PREVIEW_TYPES.FUNDING,
    showCost: false,
    value: LINE_ITEM_TYPES.FUNDING,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.DISCOUNT],
    nonCustomType: [AUTO_CALCULATION_ITEM_TYPE.DISCOUNTS],
    order: 5,
    previewType: INVOICE_PREVIEW_TYPES.DISCOUNT,
    showCost: false,
    value: LINE_ITEM_TYPES.DISCOUNT,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.DEPOSIT],
    nonCustomType: [AUTO_CALCULATION_ITEM_TYPE.DEPOSIT],
    order: 6,
    previewType: INVOICE_PREVIEW_TYPES.DEPOSIT,
    showCost: false,
    value: LINE_ITEM_TYPES.DEPOSIT,
  },
  {
    label: LINE_ITEMS[LINE_ITEM_TYPES.REFUND_DEPOSIT],
    nonCustomType: [AUTO_CALCULATION_ITEM_TYPE.REFUND_DEPOSIT],
    order: 7,
    previewType: INVOICE_PREVIEW_TYPES.REFUND_DEPOSIT,
    showCost: false,
    value: LINE_ITEM_TYPES.REFUND_DEPOSIT,
  },
]
export const INVOICE_TYPES = {
  CREDIT_NOTE: 'creditNote',
  INVOICE: 'invoice',
}

const INVOICE_TYPES_DROPDOWN = [
  {
    label: i18n.t('services:Invoices:Constants:InvoiceTypesDropdownLabels:invoices'),
    value: INVOICE_TYPES.INVOICE,
  },
  {
    label: i18n.t('services:Invoices:Constants:InvoiceTypesDropdownLabels:creditNotes'),
    value: INVOICE_TYPES.CREDIT_NOTE,
  },
]

const INVOICE_DATE_TYPES = {
  createdAt: 'createdAt',
  issueDate: 'issueDate',
  processedDate: 'processedDate',
}

const INVOICE_DATE_OPTIONS = [
  {
    label: i18n.t('services:Invoices:Constants:InvoiceDateOptionLabels:createdDate'),
    value: INVOICE_DATE_TYPES.processedDate,
  },
  {
    label: i18n.t('services:Invoices:Constants:InvoiceDateOptionLabels:invoiceDate'),
    value: INVOICE_DATE_TYPES.issueDate,
  },
]

const INVOICE_PREVIEW_UNIT_TYPES = {
  CURRENCY: 'CURRENCY',
  HOURS: 'HOURS',
}

const INVOICE_PREVIEW_ITEM_TYPE = {
  HEADER: 'header',
  ITEM: 'item',
}

const SEND_INVOICE_NUMBER_TYPES = {
  AUTOMATIC: 'AUTOMATIC',
  CUSTOM: 'CUSTOM',
}

const SEND_INVOICE_NUMBER_OPTIONS = [
  {
    label: i18n.t('services:Invoices:Constants:SendInvoiceNumberOptionLabels:automaticInvoiceNumber'),
    value: SEND_INVOICE_NUMBER_TYPES.AUTOMATIC,
  },
  {
    label: i18n.t('services:Invoices:Constants:SendInvoiceNumberOptionLabels:customInvoiceNumber'),
    value: SEND_INVOICE_NUMBER_TYPES.CUSTOM,
  },
]

const SAVE_INVOICE_ACTION = {
  KEEP_AS_DRAFT: 'KEEP_AS_DRAFT',
  MARK_AS_SEND: 'MARK_AS_SEND',
  SEND: 'SEND',
}

const SAVE_INVOICE_ACTION_DROPDOWN = [
  {
    label: i18n.t('services:Invoices:Constants:SaveInvoiceAction:saveAsDraft'),
    value: SAVE_INVOICE_ACTION.KEEP_AS_DRAFT,
  },
  {
    label: i18n.t('services:Invoices:Constants:SaveInvoiceAction:saveAndMarkAsSent'),
    value: SAVE_INVOICE_ACTION.MARK_AS_SEND,
  },
  {
    label: i18n.t('services:Invoices:Constants:SaveInvoiceAction:saveAndSend'),
    value: SAVE_INVOICE_ACTION.SEND,
  },
]

const SAVE_BATCH_INVOICE_ACTION_MAPPING = {
  [SAVE_INVOICE_ACTION.MARK_AS_SEND]: 'markAsSent',
  [SAVE_INVOICE_ACTION.SEND]: 'sendInvoice',
}

export default {
  AUTO_CALCULATE_ALT_FUNDING_TYPE,
  AUTO_CALCULATION_FUNDING_TYPE,
  AUTO_CALCULATION_ITEM_TYPE,
  ERROR_CODES,
  INVOICE_DATE_OPTIONS,
  INVOICE_DATE_TYPES,
  INVOICE_PREVIEW_ITEM_TYPE,
  INVOICE_PREVIEW_MAPPINGS,
  INVOICE_PREVIEW_UNIT_TYPES,
  INVOICE_TYPES,
  INVOICE_TYPES_DROPDOWN,
  LEGACY_ITEM_TYPE,
  LINE_ITEM_OPTIONS,
  LINE_ITEM_OPTIONS_V3,
  LINE_ITEM_TYPES,
  REGULAR_LINE_ITEM_OPTIONS_V3,
  SAVE_BATCH_INVOICE_ACTION_MAPPING,
  SAVE_INVOICE_ACTION,
  SAVE_INVOICE_ACTION_DROPDOWN,
  SEND_INVOICE_NUMBER_OPTIONS,
  SEND_INVOICE_NUMBER_TYPES,
  STATUSES,
  STATUSES_DROPDOWN,
  STATUS_TYPES,
}
