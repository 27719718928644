import _ from 'lodash'
import { flatten, nest } from 'utils/flatnest'
import moment from 'moment'

import React, { Component } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { OBSERVATION_STATUSES, OBSERVATION_STATUSES_OPTIONS } from 'services/legacy/observations/constants'
import { OBSERVATIONS_PAGE_TYPE } from 'module/Learning/Observations/constants'
import { FEATURE_FLAGS } from 'constants/security'

import auth from 'utils/auth'
import { generateRoute } from 'utils/routing'

import { EVENTS, logEvent } from 'analytics'

import { withChildService } from 'services/legacy/child'
import { withAppService } from 'services/app'
import { withObservationsService } from 'services/legacy/observations'
import { withLikesAndCommentsService } from 'services/likesAndComments'
import { withModalService } from 'services/utils/modal'
import { withSecurityService } from 'services/security'
import { withPaginationUtils } from 'services/utils/pagination'
import { withRouterUtils } from 'services/utils/router'
import { getObservationEventLogContext } from 'services/legacy/observations/helpers'
import { withRouter } from 'services/router'

import AddFromHomeObservationWrapper from 'module/HomeObservations/AddFromHomeObservationWrapper'

import ObservationsListView from './ObservationsListView'

const DATE_FORMAT = 'YYYY-MM-DD'

const OBSERVATION_GROUPS = {
  read: [
    'like',
    'child',
    'childObservation',
    'childObservation.child',
    'observation.preview',
  ],
}

const USER_LIKES_GROUPS = {
  read: [
    'observation.userLike',
    'observation.counter',
    'observation.childObservationsIds',
  ],
}

const OBSERVATION_MONTESSORI_GROUPS = {
  read: [
    'observation.montessoriActivity',
    'montessoriActivity',
  ],
}

class ObservationsListContainer extends Component {
  constructor(props) {
    super(props)

    const { pathname, query } = props.location
    const { author, date, keyPerson, room, status } = nest(query)

    this.state = {
      eventLogContext: getObservationEventLogContext(pathname),
      filters: {
        author,
        childSearch: null,
        date: date ? moment(date).format(DATE_FORMAT) : null,
        keyPerson,
        room,
        status,
      },
      pageType: this.getPageType(),
    }

    this.handleChildSearch = _.debounce(this.handleChildSearch, 300)
  }

  componentDidMount() {
    const { eventLogContext } = this.state

    this.fetch()

    logEvent(EVENTS.OBSERVATION_LIST_PAGE_VIEWED, { context: eventLogContext })
  }

  getPageType = () => {
    const { location: { pathname } } = this.props

    if (generateRoute('APPROVALS.OBSERVATIONS') === pathname) {
      return OBSERVATIONS_PAGE_TYPE.APPROVALS
    }

    if (generateRoute('LEARNING.OBSERVATIONS') === pathname) {
      return OBSERVATIONS_PAGE_TYPE.LEARNING
    }

    return OBSERVATIONS_PAGE_TYPE.CHILD
  }

  fetch = () => {
    const {
      hasAccessToLikesAndComments,
      isMontessori,
      observationsActions,
      observationsSelectors,
      paginationUtils,
      params,
      setLocationQuery,
    } = this.props
    const { filters } = this.state
    const { childId } = params
    const { page } = paginationUtils

    setLocationQuery(flatten(filters))

    if (childId) {
      filters.child = childId
    }

    if (this.isApprovalPage()) {
      filters.status = [
        OBSERVATION_STATUSES.PENDING_APPROVAL.value,
        OBSERVATION_STATUSES.CHANGES_REQUESTED.value,
      ]
    }

    const criteria = observationsSelectors.getCriteria({ ...filters, childId })
    let readGroups = [...OBSERVATION_GROUPS.read]

    if (isMontessori) {
      readGroups = [...OBSERVATION_GROUPS.read, ...OBSERVATION_MONTESSORI_GROUPS.read]
    }

    if (hasAccessToLikesAndComments) {
      readGroups = [...readGroups, ...USER_LIKES_GROUPS.read]
    }

    const apiParams = {
      criteria,
      groups: { read: readGroups },
      order: [
        {
          sortField: 'status',
          sortOrder: 'DESC',
        },
        {
          sortField: 'observationDate',
          sortOrder: 'DESC',
        },
      ],
      page,
    }

    return observationsActions.list({ params: apiParams })
  }

  componentWillUnmount() {
    const { observationsActions } = this.props

    observationsActions.clearList()
  }

  isApprovalPage = () => {
    const { location: { pathname } } = this.props

    return generateRoute('APPROVALS.OBSERVATIONS') === pathname
  }

  isChildContext = () => {
    const { location: { pathname }, params: { childId } } = this.props

    return generateRoute('CHILDREN.CHILD.LEARNING_JOURNEY.OBSERVATIONS', { childId }) === pathname
  }

  handleChildSearch = (searchPhrase) => {
    const { paginationUtils } = this.props
    const { eventLogContext } = this.state
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, childSearch: searchPhrase },
      }),
      () => onPageChange(this.fetch)(1),
    )

    logEvent(EVENTS.OBSERVATION_LIST_FILTER_USED, { context: eventLogContext, type: 'search' })
  }

  handleAuthorChange = (author) => {
    const { paginationUtils } = this.props
    const { eventLogContext } = this.state
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, author: author && +author.value },
      }),
      () => onPageChange(this.fetch)(1),
    )

    logEvent(EVENTS.OBSERVATION_LIST_FILTER_USED, { context: eventLogContext, type: 'author' })
  }

  handleDateChange = (date) => {
    const { paginationUtils } = this.props
    const { eventLogContext } = this.state
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, date: date ? moment(date).format(DATE_FORMAT) : null },
      }),
      () => onPageChange(this.fetch)(1),
    )

    logEvent(EVENTS.OBSERVATION_LIST_FILTER_USED, { context: eventLogContext, type: 'date' })
  }

  handleKeyPersonChange = (keyPerson) => {
    const { paginationUtils } = this.props
    const { eventLogContext } = this.state
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, keyPerson: keyPerson?.value },
      }),
      () => onPageChange(this.fetch)(1),
    )

    logEvent(EVENTS.OBSERVATION_LIST_FILTER_USED, { context: eventLogContext, type: 'key person' })
  }

  handleRoomChange = (room) => {
    const { paginationUtils } = this.props
    const { eventLogContext } = this.state
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, room: room?.value },
      }),
      () => onPageChange(this.fetch)(1),
    )

    logEvent(EVENTS.OBSERVATION_LIST_FILTER_USED, { context: eventLogContext, type: 'room' })
  }

  handleStatusChange = (status) => {
    const { paginationUtils } = this.props
    const { eventLogContext } = this.state
    const { onPageChange } = paginationUtils

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, status: status?.value },
      }),
      () => onPageChange(this.fetch)(1),
    )

    logEvent(EVENTS.OBSERVATION_LIST_FILTER_USED, { context: eventLogContext, type: 'status' })
  }

  handlePageChange = (page) => {
    const { paginationUtils } = this.props
    const { onPageChange } = paginationUtils

    onPageChange(this.fetch)(page)
  }

  handleExportClick = () => {
    const { child, modalActions, modalConsts } = this.props
    const { eventLogContext } = this.state

    logEvent(EVENTS.OBSERVATION_LIST_EXPORT_CLICKED, { context: eventLogContext })

    modalActions.show(modalConsts.TYPES.OBSERVATIONS_EXPORT, { child })
  }

  render() {
    const {
      HomeObservationsGranted,
      authAccessMap,
      canEditObservation,
      hasAccessToLikesAndComments,
      hasAccessToLikesAndCommentsSettings,
      isFetchingList,
      observations,
      observationsMeta,
      paginationUtils,
      params,
      router,
    } = this.props
    const { eventLogContext, filters, pageType } = this.state
    const { getPageCount, page, perPage } = paginationUtils
    const { author, date, keyPerson, room, status } = filters

    const isApprovalPage = this.isApprovalPage()
    const isChildContext = this.isChildContext()
    const childId = isChildContext && params && params.childId
    const pageCount = observationsMeta && getPageCount(observationsMeta.total_results)

    return (
      <ObservationsListView
        HomeObservationsGranted={HomeObservationsGranted}
        authAccessMap={authAccessMap}
        author={author}
        canEditObservation={canEditObservation}
        childId={childId}
        date={date}
        eventLogContext={eventLogContext}
        hasAccessToLikesAndComments={hasAccessToLikesAndComments}
        hasAccessToLikesAndCommentsSettings={hasAccessToLikesAndCommentsSettings}
        isApprovalPage={isApprovalPage}
        isChildContext={isChildContext}
        isLoading={isFetchingList}
        keyPerson={keyPerson}
        observations={observations}
        page={page}
        pageCount={pageCount}
        pageType={pageType}
        perPage={perPage}
        room={room}
        router={router}
        status={status}
        statusOptions={OBSERVATION_STATUSES_OPTIONS}
        totalResults={observationsMeta?.total_results}
        onAuthorChange={this.handleAuthorChange}
        onChildSearch={this.handleChildSearch}
        onDateChange={this.handleDateChange}
        onExportClick={this.handleExportClick}
        onKeyPersonChange={this.handleKeyPersonChange}
        onPageChange={this.handlePageChange}
        onRoomChange={this.handleRoomChange}
        onStatusChange={this.handleStatusChange}
      />
    )
  }
}

const mapState = (state, {
  appSelectors,
  childSelectors,
  likesAndCommentsSelectors,
  observationsListState,
  observationsSelectors,
  securitySelectors,
}) => ({
  HomeObservationsGranted: auth.SELECTORS.getComponentIsAuthorised(state, AddFromHomeObservationWrapper),
  authAccessMap: {
    section: {
      pdfAutomation: auth.SELECTORS.getIsAuthorised(state, {
        flags: [FEATURE_FLAGS.PDF_AUTOMATION],
      }),
    },
  },
  canEditObservation: (observation) => observationsSelectors.canEditObservation(observation, state),
  child: childSelectors.getChildSelector(state),
  currentUser: securitySelectors.getAuthProfile(state),
  hasAccessToLikesAndComments: likesAndCommentsSelectors.hasAccessToLikesAndComments(state),
  hasAccessToLikesAndCommentsSettings: likesAndCommentsSelectors.hasAccessToLikesAndCommentsSettings(state),
  isFetchingList: appSelectors.getIsFetching(observationsListState),
  isMontessori: securitySelectors.isMontessori(state),
  observations: observationsSelectors.getObservationsListSelector(state),
  observationsMeta: observationsSelectors.getObservationsListMetaState(state),
})

const enhance = compose(
  withAppService,
  withChildService,
  withLikesAndCommentsService,
  withModalService,
  withObservationsService,
  withPaginationUtils,
  withRouter,
  withRouterUtils,
  withSecurityService,
  connect(mapState),
)

export default enhance(ObservationsListContainer)
