import _ from 'lodash'

import React, { PropsWithChildren, useEffect, useState } from 'react'
import useEventListener from '@use-it/event-listener'

import { KEYBOARD_CODE } from 'constants/keyboard'
import { NEUTRAL_COLOURS } from 'constants/colors'

import { Icon, Spinner } from 'components'

import {
  StyledBackspace,
  StyledErrorContainer,
  StyledNumericButton,
  StyledNumericButtonContent,
  StyledNumericKeypad,
  StyledPinDot,
  StyledPinNumberContainer,
  StyledPinpadWrapper,
  StyledSpinnerContainer,
} from './PinpadStyled'

export const PIN_LENGTH = 4

interface SubmitOptionsProps {
  reset: () => void
}

export interface PinpadProps {
  error?: string
  isLoading?: boolean
  length?: number
  onSubmit: (pin: string, options?: SubmitOptionsProps) => {}
  valid?: boolean
  value: string
}

const Pinpad: React.FC<PropsWithChildren<PinpadProps>> = ({
  error,
  isLoading,
  length = PIN_LENGTH,
  onSubmit,
  valid,
  value,
}) => {
  const [pin, setPin] = useState([...Array(PIN_LENGTH)])
  const [isDone, setIsDone] = useState(false)

  const reset = () => {
    setPin([...Array(PIN_LENGTH)])
    setIsDone(false)
  }

  useEffect(() => {
    reset()
  }, [error, value])

  const changePin = (number) => {
    _.each(pin, (part, index) => {
      if (_.isUndefined(part)) {
        const newPin = [...pin]
        newPin[index] = number

        setPin(newPin)

        return false
      }

      return true
    })
  }

  const undo = () => {
    _.each(pin, (part, index) => {
      if (_.isUndefined(part)) {
        const newPin = [...pin]
        newPin[index - 1] = undefined

        setPin(newPin)

        return false
      }

      return true
    })
  }

  const getLastSelected = () => (
    _.findIndex(pin, (part) => _.isUndefined(part)) - 1
  )

  useEffect(() => {
    const parsedPin = pin.toString().replace(/,/g, '')

    if (PIN_LENGTH === parsedPin.length) {
      setIsDone(true)
      onSubmit(parsedPin, { reset })
    }
  }, [pin])

  useEventListener('keyup', ({ key, keyCode }: KeyboardEvent) => {
    if (KEYBOARD_CODE.BACKSPACE === keyCode && !isDone && !isLoading) {
      return undo()
    }

    if (_.includes([
      KEYBOARD_CODE.NUMBER_0,
      KEYBOARD_CODE.NUMBER_1,
      KEYBOARD_CODE.NUMBER_2,
      KEYBOARD_CODE.NUMBER_3,
      KEYBOARD_CODE.NUMBER_4,
      KEYBOARD_CODE.NUMBER_5,
      KEYBOARD_CODE.NUMBER_6,
      KEYBOARD_CODE.NUMBER_7,
      KEYBOARD_CODE.NUMBER_8,
      KEYBOARD_CODE.NUMBER_9,
    ], keyCode) && !isLoading) {
      return changePin(+key)
    }

    return false
  })

  return (
    <StyledPinpadWrapper>
      <StyledPinNumberContainer $error={error} $length={length}>
        {_.map(pin, (partValue, index) => (
          <StyledPinDot
            $hasValue={!_.isUndefined(partValue)}
            $invalid={!pin[0] && false === valid}
            $isLastSelected={getLastSelected() === index}
            key={`pinPart_${index}`}
          />
        ))}
      </StyledPinNumberContainer>
      <StyledErrorContainer>
        {!pin[0] && error ? error : ''}
      </StyledErrorContainer>
      <StyledNumericKeypad>
        {isLoading && (
          <StyledSpinnerContainer>
            <Spinner size="large" />
          </StyledSpinnerContainer>
        )}
        {_.map([...Array(9)], (item, index) => (
          <StyledNumericButton
            $isDone={isDone}
            key={`numericButton_${index}`}
            onClick={() => !isLoading && !isDone && changePin(index + 1)}
          >
            <StyledNumericButtonContent
              $isDone={isDone}
            >
              {index + 1}
            </StyledNumericButtonContent>
          </StyledNumericButton>
        ))}
        <div />
        <StyledNumericButton
          $isDone={isDone}
          onClick={() => !isLoading && !isDone && changePin(0)}
        >
          <StyledNumericButtonContent
            $isDone={isDone}
          >
            0
          </StyledNumericButtonContent>
        </StyledNumericButton>
        <StyledBackspace
          $isDone={isDone}
          onClick={!isLoading && !isDone ? undo : undefined}
        >
          <Icon
            color={NEUTRAL_COLOURS.BASIC}
            icon="backspace"
          />
        </StyledBackspace>
      </StyledNumericKeypad>
    </StyledPinpadWrapper>
  )
}

export default Pinpad
