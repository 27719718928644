import _ from 'lodash'

import React from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'
import { Field, InjectedFormProps, formValueSelector, reduxForm } from 'redux-form'

import { isNumberGreaterOrEqualThan, isNumberLessOrEqualThan, isRequired } from 'utils/fieldValidation'

import { Form, Grid, Section, Typography } from 'components'
import ExcludedMonths from 'module/Settings/SettingsInvoices/SettingsInvoicesV3EditAnnualised/components/ExcludedMonths'

import i18n from 'translations'

export const INVOICE_ANNUALISED_SETTING_FORM = 'InvoiceAnnualisedSettingForm'

const isNumberLessOrEqualThan12 = isNumberLessOrEqualThan(12)
const isNumberLessOrEqualThan53 = isNumberLessOrEqualThan(53)
const isNumberGreaterOrEqualThan01 = isNumberGreaterOrEqualThan(0.1)
const isNumberGreaterOrEqualThan1 = isNumberGreaterOrEqualThan(1)

export type SubmitFunctionType = {
  onSubmit?: (fields: any) => void
}

interface InvoiceAnnualisedSettingFormProps {
  cancelLink?: string
  handleSubmit?: (submitFunc: SubmitFunctionType) => () => void
  hideGlobalOption?: boolean
  isLoading?: boolean
  onSubmit?: SubmitFunctionType
  subtitle?: string
  title?: string
}

const mapState = (state) => ({
  annualisedInvoiceSettingsAppliedFromGlobal: formValueSelector(INVOICE_ANNUALISED_SETTING_FORM)(
    state,
    'annualisedInvoiceSettingsAppliedFromGlobal',
  ),
  excludedMonths: formValueSelector(INVOICE_ANNUALISED_SETTING_FORM)(state, 'excludedMonths'),
  months: formValueSelector(INVOICE_ANNUALISED_SETTING_FORM)(state, 'months'),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

type InvoiceAnnualisedSettingFormFullProps = InjectedFormProps<{} & InvoiceAnnualisedSettingFormProps>
  & InvoiceAnnualisedSettingFormProps
  & PropsFromRedux

const InvoiceAnnualisedSettingForm: React.FC<InvoiceAnnualisedSettingFormFullProps> = ({
  annualisedInvoiceSettingsAppliedFromGlobal,
  cancelLink,
  excludedMonths,
  handleSubmit,
  hideGlobalOption,
  isLoading,
  months,
  onSubmit,
  subtitle = i18n.t('module:Management:Finance:Annualised:subtitle'),
  title = i18n.t('module:Management:Finance:Annualised:title'),
}) => (
  <Section title={title}>
    <Typography margin="0 0 25px 0">{subtitle}</Typography>
    <Form onSubmit={handleSubmit(onSubmit)}>
      {!hideGlobalOption && (
        <Form.Row
          label={i18n.t('module:Management:Finance:Annualised:Edit:Form:UseOrganizationSetting:label')}
          verticalLabel
        >
          <Field
            component={Form.Switch}
            name="annualisedInvoiceSettingsAppliedFromGlobal"
            validate={isRequired}
          />
        </Form.Row>
      )}
      <Grid>
        <Grid.Item width={{ desktop: '140px', mobile: '100%' }}>
          <Form.Row
            label={_.upperFirst(i18n.t('global:weeks'))}
            margin="0 0 10px 0"
            width={{ field: '100%' }}
            verticalLabel
          >
            <Field
              component={Form.TextField}
              disabled={annualisedInvoiceSettingsAppliedFromGlobal}
              max={53}
              min={0}
              name="weeks"
              placeholder={_.upperFirst(i18n.t('global:weeks'))}
              type="number"
              validate={[isNumberGreaterOrEqualThan01, isNumberLessOrEqualThan53]}
            />
          </Form.Row>
        </Grid.Item>
        <Grid.Item width={{ desktop: '140px', mobile: '100%' }}>
          <Form.Row
            label={_.upperFirst(i18n.t('global:months'))}
            margin="0 0 10px 0"
            width={{ field: '100%' }}
            verticalLabel
          >
            <Field
              component={Form.TextField}
              disabled={annualisedInvoiceSettingsAppliedFromGlobal}
              max={12}
              min={1}
              name="months"
              placeholder={_.upperFirst(i18n.t('global:months'))}
              type="number"
              validate={[isNumberGreaterOrEqualThan1, isNumberLessOrEqualThan12]}
            />
          </Form.Row>
        </Grid.Item>
      </Grid>
      <ExcludedMonths
        disabled={annualisedInvoiceSettingsAppliedFromGlobal}
        months={months}
        name="excludedMonths"
        usedMonths={excludedMonths || []}
      />
      <Form.Row
        label={i18n.t('module:Management:Finance:Annualised:Edit:Form:AllowOverwrite:label')}
        verticalLabel
      >
        <Field
          component={Form.Switch}
          disabled={annualisedInvoiceSettingsAppliedFromGlobal}
          name="allowOverwrite"
          validate={isRequired}
        />
      </Form.Row>
      <Form.FooterActions
        cancelLink={cancelLink}
        isSubmitting={isLoading}
        submitLabel={i18n.t('global:Save')}
      />
    </Form>
  </Section>
)

const validate = ({ excludedMonths }) => {
  const errors = {
    excludedMonths: [],
  }

  if (excludedMonths?.length) {
    _.forEach(excludedMonths, (month, index) => {
      if (month) {
        if (_.findIndex(excludedMonths, { value: month.value }) !== +index) {
          errors.excludedMonths[index] = i18n.t('module:Management:Finance:Annualised:Edit:Form:duplicateMonthError')
        }
      }
    })
  }

  return errors
}

const enhance = compose(
  reduxForm<{}, InvoiceAnnualisedSettingFormProps>({
    form: INVOICE_ANNUALISED_SETTING_FORM,
    validate,
  }),
  connector,
)

export default enhance(InvoiceAnnualisedSettingForm)

