import _ from 'lodash'

import React from 'react'

import { generateRoute } from 'utils/routing'

import {
  EmptyState,
  InfiniteDropdowns,
  List,
  Page,
  PageTabNav,
  Pagination,
  SearchBar,
  Section,
  Spinner,
  Toolbar,
} from 'components'

import i18n from 'translations'

import HomeObservationItem from './components/HomeObservationItem'

const HomeObservationsListView = ({
  childContext,
  childId,
  filters,
  hasAccessToLikesAndComments,
  hasAccessToLikesAndCommentsSettings,
  homeObservations,
  isEmpty,
  isLoading,
  onKeyWorkerChange,
  onPageChange,
  onSearch,
  page,
  pageCount,
  perPage,
  router,
  totalResults,
}) => {
  const { keyWorker, search } = filters

  const renderPagination = () => !isLoading && (
    <Pagination
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      totalResults={totalResults}
      onPageChange={onPageChange}
    />
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (isEmpty) {
      return (
        <EmptyState
          icon="parent"
          text1="No observations from home found."
          text2="Let your parents know they can share observations from home with their nursery on the Blossom parent app." // eslint-disable-line
        />
      )
    }

    return (
      <React.Fragment>
        {renderPagination()}
        <List.Standard>
          {_.map(homeObservations, (observation) => (
            <HomeObservationItem
              hasAccessToLikesAndComments={hasAccessToLikesAndComments}
              hasAccessToLikesAndCommentsSettings={hasAccessToLikesAndCommentsSettings}
              key={observation.id}
              observation={observation}
            />
          ))}
        </List.Standard>
        {renderPagination()}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <SearchBar
        placeholder={i18n.t('global:searchPlaceholder')}
        value={search}
        onChange={onSearch}
      />
      <Page isLoading={isLoading}>
        <Page.Content>
          {childContext && (
            <PageTabNav>
              <PageTabNav.Item
                isActive={router.isActive(
                  generateRoute('CHILDREN.CHILD.LEARNING_JOURNEY.OBSERVATIONS', { childId }),
                  true,
                )}
                label={_.upperFirst(i18n.t('module:Learning:Observations:ObservationsList:observations'))}
                to={generateRoute('CHILDREN.CHILD.LEARNING_JOURNEY.OBSERVATIONS', { childId })}
              />
              <PageTabNav.Item
                isActive={router.isActive(
                  generateRoute('CHILDREN.CHILD.LEARNING_JOURNEY.OBSERVATIONS.HOME_OBSERVATIONS', { childId }),
                  true,
                )}
                label={i18n.t('module:Learning:Observations:ObservationsList:observationsFromHome')}
                to={generateRoute('CHILDREN.CHILD.LEARNING_JOURNEY.OBSERVATIONS.HOME_OBSERVATIONS', { childId })}
              />
            </PageTabNav>
          )}
          <Section title="View all observations from home created by parents/carers below">
            <Toolbar>
              <Toolbar.Group>
                {!childContext && (
                  <Toolbar.Item>
                    <InfiniteDropdowns.Users
                      placeholder="Key Person"
                      value={keyWorker}
                      onChange={onKeyWorkerChange}
                    />
                  </Toolbar.Item>
                )}
              </Toolbar.Group>
            </Toolbar>
            {renderContent()}
          </Section>
        </Page.Content>
      </Page>
    </React.Fragment>
  )
}

export default HomeObservationsListView
