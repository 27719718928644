import _ from 'lodash'

import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isNegativeNumber, isPositiveNumber, isRequired } from 'utils/fieldValidation'
import { getBrandingColor } from 'utils/branding'

import { PAYMENT_TYPE_VALUES, REFUND_TYPE, REFUND_TYPE_OPTION } from 'services/legacy/invoicePayments/constants'
import { AmountSummary, CircleIcon, DefinitionList, Form, Spinner, Toolbar, Tooltip } from 'components'

import i18n from 'translations'

export const isPositiveNumberLocal = isPositiveNumber(2)
export const isNegativeNumberLocal = isNegativeNumber(2)

export const CHILD_FINANCE_INVOICES_PAYMENT_FORM = 'ChildFinanceInvoicesPaymentForm'

const ChildFinanceInvoicesPaymentForm = ({
  balance = 0,
  cancelButtonLink,
  formValues,
  handleSubmit,
  isSubmitButtonDisabled,
  isSubmitting,
  onSubmit,
  receiptNumber,
  showForm,
}) => {
  const { amount = 0 } = formValues || {}
  const newAmount = balance + +amount
  const amountValidation = formValues?.paymentType?.value === REFUND_TYPE
    ? isNegativeNumberLocal
    : isPositiveNumberLocal

  const renderForm = () => (
    <React.Fragment>
      <Toolbar>
        <Toolbar.Group>
          <Toolbar.Item>
            <DefinitionList>
              <DefinitionList.Item
                label={i18n.t('module:Children:Child:Finance:Payment:Add:receiptNumberLabel')}
                value={receiptNumber || <Spinner size="small" />}
              />
            </DefinitionList>
          </Toolbar.Item>
        </Toolbar.Group>
        <Toolbar.Group>
          <Toolbar.Item>
            <AmountSummary
              amount={Math.abs(newAmount)}
              color={getBrandingColor('tertiary-color')}
              label={i18n.t('module:Children:Child:Finance:Payment:Add:totalOutstandingLabel')}
              suffix={0 < newAmount && i18n.t('module:Children:Child:Finance:Payment:Add:totalOutstandingSuffix')}
              grayBackground
            />
          </Toolbar.Item>
        </Toolbar.Group>
      </Toolbar>
      <Form.Row label={_.upperFirst(i18n.t('global:date'))} required>
        <Form.Row.FlexItem flex="0 0 150px">
          <Field
            component={Form.DateTimePicker}
            name="paymentDate"
            placeholder={_.upperFirst(i18n.t('global:date'))}
            validate={isRequired}
            clearable
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={_.upperFirst(i18n.t('global:amount'))} required>
        <Form.Row.FlexItem flex="0 0 150px">
          <Field
            component={Form.TextField}
            name="amount"
            placeholder={_.upperFirst(i18n.t('global:amount'))}
            step="any"
            type="number"
            validate={[isRequired, amountValidation]}
          />
        </Form.Row.FlexItem>
        <Form.Row.Item margin="0 0 0 5px">
          <Tooltip
            placement="right"
            title={i18n.t('module:Children:Child:Finance:Payment:Add:amountTooltip')}
          >
            <CircleIcon
              cursor="pointer"
              icon="info"
              iconSize={16}
              size={24}
            />
          </Tooltip>
        </Form.Row.Item>
      </Form.Row>
      <Form.Row label={i18n.t('module:Finance:Payments:paymentMethod')} required>
        <Form.Row.FlexItem flex="0 0 250px">
          <Field
            component={Form.InfiniteDropdowns.PaymentTypes}
            extraOptions={[REFUND_TYPE_OPTION]}
            name="paymentType"
            placeholder={i18n.t('module:Finance:Payments:paymentMethod')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      {formValues?.paymentType?.code === PAYMENT_TYPE_VALUES.CHILD_CARE_VOUCHER
        && (
          <Form.Row label={i18n.t('module:Children:Child:Finance:Payment:Add:voucherNameLabel')} required>
            <Form.Row.FlexItem flex="0 0 250px">
              <Field
                component={Form.TextField}
                name="name"
                placeholder={i18n.t('module:Children:Child:Finance:Payment:Add:voucherNameLabel')}
                validate={isRequired}
              />
            </Form.Row.FlexItem>
          </Form.Row>
        )}
      <Form.Row label={_.upperFirst(i18n.t('global:comment'))}>
        <Form.Row.FlexItem flex="0 0 560px">
          <Field
            component={Form.TextAreaField}
            name="comment"
            placeholder={i18n.t('module:Children:Child:Finance:Payment:Add:commentPlaceholder')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Children:Child:Finance:Payment:Add:sendReceiptLabel')}>
        <Form.Row.Item>
          <Field
            component={Form.Checkbox}
            name="sendReceipt"
          />
        </Form.Row.Item>
      </Form.Row>
    </React.Fragment>
  )

  const renderFooter = () => (
    <Form.FooterActions
      cancelLink={cancelButtonLink}
      isSubmitting={isSubmitting}
      submitDisabled={isSubmitButtonDisabled}
    />
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {showForm && renderForm()}
      {renderFooter()}
    </Form>
  )
}

export default reduxForm({ form: CHILD_FINANCE_INVOICES_PAYMENT_FORM })(ChildFinanceInvoicesPaymentForm)
