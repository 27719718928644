import React from 'react'

import { NEUTRAL_COLOURS } from 'constants/colors'

import { generateRoute } from 'utils/routing'

import { Callout, Currency, DefinitionList, Section, Spinner, Table, TimeString, Typography } from 'components'

const SettingsSessionsItemView = ({
  authAccessMap,
  errorMessages,
  isLoading,
  session,
}) => {
  const renderNurserySession = (nurserySession) => {
    const { costTwoYears, costTwoYearsAbove, costUnderTwo, id, nursery } = nurserySession
    const { name } = nursery

    if (!authAccessMap.section.FinanceAutomation) {
      return (
        <Table.Tr key={id}>
          <Table.Td align="left">
            {name}
          </Table.Td>
        </Table.Tr>
      )
    }

    return (
      <Table.Tr key={id}>
        <Table.Td align="left">
          {name}
        </Table.Td>
        <Table.Td>
          <Currency value={costUnderTwo} />
        </Table.Td>
        <Table.Td>
          <Currency value={costTwoYears} />
        </Table.Td>
        <Table.Td>
          <Currency value={costTwoYearsAbove} />
        </Table.Td>
      </Table.Tr>
    )
  }

  if (isLoading) {
    return <Spinner />
  }

  const { archived, endTime, id, name, nurserySessions, startTime } = session

  const title = (
    <span>
      {name}
      {' '}
      {startTime && endTime && (
        <Typography color={NEUTRAL_COLOURS.BLUE} fontSize="inherit" variant="span" bold>
          <TimeString date={startTime} local={false} />
          {' '}
          -
          {' '}
          <TimeString date={endTime} local={false} />
        </Typography>
      )}
      {archived && ' (Archived)'}
    </span>
  )

  const renderTableHeader = () => {
    if (!authAccessMap.section.FinanceAutomation) {
      return (
        <Table.Tr>
          <Table.Th align="left">
            Nursery
          </Table.Th>
        </Table.Tr>
      )
    }

    return (
      <Table.Tr>
        <Table.Th align="left">
          Nursery
        </Table.Th>
        <Table.Th>
          Under 2s
        </Table.Th>
        <Table.Th>
          2-3 years
        </Table.Th>
        <Table.Th>
          3-5 years
        </Table.Th>
      </Table.Tr>
    )
  }

  let infoMsg = 'This is where you can set the session for each of your nurseries and age groups.'

  if (authAccessMap.section.FinanceAutomation) {
    infoMsg = 'This is where you can set the session fees for each of your nurseries and age groups.'
  }

  return (
    <Section
      actions={authAccessMap.module.SettingsSessionsAdd && (
        <Section.Actions
          tertiary={{ to: generateRoute('SETTINGS.SESSIONS.ITEM.EDIT', { sessionId: id }) }}
        />
      )}
      title="Session details"
    >
      <Callout content={errorMessages} error />
      <Typography margin="20px 0">
        <p>
          {infoMsg}
        </p>
        <p>
          <DefinitionList>
            <DefinitionList.Item label="Session" value={title} />
          </DefinitionList>
        </p>
      </Typography>
      {nurserySessions && (
        <Table>
          {renderTableHeader()}
          {nurserySessions.map(renderNurserySession)}
        </Table>
      )}
    </Section>
  )
}

export default SettingsSessionsItemView
