import moment from 'moment'
import { createSelector } from 'reselect'

import { PAYMENT_TYPE, PAYMENT_TYPE_VALUES, REFUND_TYPE } from 'services/legacy/invoicePayments/constants'

const getInvoicePaymentsSingle = (state) => state.invoicePayments.single

export const getInvoicePaymentsSingleData = createSelector([getInvoicePaymentsSingle], (state) => (
  state.data || {}
))

export const getInvoicePaymentsSingleIsSubmitting = createSelector([getInvoicePaymentsSingle], (state) => {
  if (!state) {
    return null
  }

  return state.isSubmitting
})

export const getFormatedListSelector = createSelector([getInvoicePaymentsSingleData], (state) => {
  if (!state) {
    return null
  }
  const { amount, comment, name, paymentDate, paymentType } = state

  return {
    amount,
    comment,
    name,
    paymentDate: new Date(paymentDate),
    paymentType: paymentType ? {
      label: paymentType.name,
      value: paymentType.id,
    } : null,
  }
})

export const getInvoicePaymentValuesForm = (fields) => {
  const body = {}
  const {
    amount,
    child,
    comment,
    name,
    paymentDate,
    paymentType,
    sendReceipt,
  } = fields

  const isRefundSelected = paymentType?.value === REFUND_TYPE

  body.type = isRefundSelected ? REFUND_TYPE : PAYMENT_TYPE

  if (paymentDate) {
    body.paymentDate = moment(paymentDate).set({ second: 0 }).format('YYYY-MM-DD HH:mm:ss')
  }

  if (amount) {
    body.amount = +amount
  }

  if (!isRefundSelected && paymentType?.value) {
    body.paymentType = {
      id: paymentType.value,
    }

    if (name && paymentType.code === PAYMENT_TYPE_VALUES.CHILD_CARE_VOUCHER) {
      body.name = name
    }
  }

  if (comment) {
    body.comment = comment
  }

  if (sendReceipt) {
    body.sendReceipt = sendReceipt
  }

  if (child) {
    body.child = {
      id: child,
    }
  }

  return body
}

export const getInitialValuesForPaymentForm = () => ({
  paymentDate: moment(),
})
