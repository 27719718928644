import _ from 'lodash'
import moment from 'moment'

import { createSelector } from 'reselect'

import { DEFAULT_DATE_FORMAT } from 'constants/date'
import { CHILD_FUNDING_FILTERS } from 'services/legacy/childFunding/constants'

const getFinanceReports = (state) => state.financeFundingReport

export const getFinanceFundingReportStatistics = createSelector(
  [getFinanceReports],
  (state) => state.statistics,
)

export const getFinanceFundingReportStatisticsData = createSelector(
  [getFinanceFundingReportStatistics],
  (state) => state.data,
)

export const getStatisticsCriteria = ({ ageRange, child, fundingTypes, nursery, room, status }) => {
  const criteria = []

  if (status && status !== CHILD_FUNDING_FILTERS.ALL) {
    criteria.push({
      field: 'archived',
      value: status === CHILD_FUNDING_FILTERS.ARCHIVED ? 1 : 0,
    })
  }

  if (ageRange) {
    const { from, to } = ageRange

    if (from) {
      criteria.push({
        field: 'birthEndDate',
        value: moment()
          .add(-from, 'months')
          .format(DEFAULT_DATE_FORMAT),
      })
    }

    if (to) {
      criteria.push({
        field: 'birthStartDate',
        value: moment().add(-(to + 1), 'months').add(-1, 'day').format(DEFAULT_DATE_FORMAT),
      })
    }
  }

  if (nursery) {
    criteria.push({
      field: 'nursery',
      value: nursery.value,
    })
  }

  if (room) {
    criteria.push({
      field: 'nurseryClass',
      value: room,
    })

    criteria.push({
      field: 'child.nurseryClass',
      value: room,
    })
  }

  if (child) {
    criteria.push({
      field: 'child',
      value: child,
    })
  }

  if (fundingTypes) {
    if (_.isArray(fundingTypes)) {
      _.each(fundingTypes, (id) => {
        if (id) {
          criteria.push({
            field: 'funding[]',
            value: id,
          })
        }
      })
    } else {
      criteria.push({
        field: 'funding[]',
        value: fundingTypes,
      })
    }
  }

  return criteria
}
