import _ from 'lodash'
import moment from 'moment'

import { generateRoute } from 'utils/routing'

import { NEUTRAL_COLOURS } from 'constants/colors'
import { NOTIFICATION_TYPES } from './Constants'

export const replaceBodyWithValues = (body, content, placeholders) => {
  let newBody = body
  let newContent = content

  _.forEach(placeholders, ({ key, value }) => {
    newBody = newBody.replace(key, `<strong>${value}</strong>`)

    if (null !== newContent) {
      newContent = '<content>' !== key
        ? newContent.replace(key, `<strong>${value}</strong>`)
        : newContent.replace(key, value)
    }
  })

  if (null !== newContent) {
    return `${newBody}. </br><div style="color:${NEUTRAL_COLOURS.GRAY}"> ${newContent} </div>`
  }

  return newBody
}

export const generateRedirectUrl = (item) => {
  const { data, type } = item
  const dataType = _.find(data, { key: 'type' })?.value
  const id = _.find(data, { key: 'id' })?.value
  const childId = _.find(data, { key: 'childId' })?.value
  const observationId = _.find(data, { key: 'observationId' })?.value
  const objectId = _.find(data, { key: 'objectId' })?.value
  const dailyDiaryDay = _.find(data, { key: 'dailyDiaryDay' })?.value

  switch (type) {
    case NOTIFICATION_TYPES.NEW_OBSERVATION_TO_APPROVE:
      return generateRoute('LEARNING.OBSERVATIONS.REVIEW', { observationId: id })

    case NOTIFICATION_TYPES.NEW_OBSERVATION_FEEDBACK:
      return generateRoute('LEARNING.OBSERVATIONS.REVIEW', { observationId: id })

    case NOTIFICATION_TYPES.NEW_FORMATIVE_REPORT_TO_APPROVAL:
      return generateRoute('LEARNING.FORMATIVE_REPORTS.PREVIEW', { formativeReportId: id })

    case NOTIFICATION_TYPES.NEW_COMMENT:
      if (dataType === NOTIFICATION_TYPES.CHILD_OBSERVATION) {
        return `${generateRoute('LEARNING.OBSERVATIONS.REVIEW', { observationId })}?objectId=${objectId}`
      }

      if (dataType === NOTIFICATION_TYPES.HOME_OBSERVATION) {
        return generateRoute('PARENT_INBOX.HOME_OBSERVATIONS.DETAILS', { id: objectId })
      }

      return `${generateRoute('CHILDREN.CHILD.DAILY_DIARY',
        { childId },
      )}?date=${moment(dailyDiaryDay).utc().format('YYYY-MM-DD')}&from=notification`
    case NOTIFICATION_TYPES.NEW_ENQUIRY:
      return generateRoute('ENQUIRIES.DETAIL', { enquiryId: id })

    case NOTIFICATION_TYPES.NEW_INJURY_TO_APPROVE:
      return generateRoute('SAFEGUARDING.INJURY.SINGLE_PREVIEW', { injuryId: id })

    case NOTIFICATION_TYPES.NEW_REQUEST_EXTRA_SESSION:
      return generateRoute('PARENT_INBOX.REQUESTED_EXTRA_SESSIONS.DETAILS', { id })

    case NOTIFICATION_TYPES.NEW_CHILD_ABSENCE:
    case NOTIFICATION_TYPES.UPDATE_CHILD_ABSENCE:
    case NOTIFICATION_TYPES.DELETE_CHILD_ABSENCE:
      return generateRoute('CHILDREN.CHILD.ABOUT.ABSENCES', { childId })

    case NOTIFICATION_TYPES.CREATE_CHILD_LOG:
      return generateRoute('CHILDREN.CHILD.ABOUT.PROFILE_HISTORY', { childId })

    case NOTIFICATION_TYPES.NEW_HOME_OBSERVATION_TO_READ:
      return generateRoute('PARENT_INBOX.HOME_OBSERVATIONS.DETAILS', { id })

    default:
      return ''
  }
}
