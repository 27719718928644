import {
  ADD_INJURY_SIGNATURE_CARER,
  ADD_INJURY_SIGNATURE_STAFF_MEMBER,
  CLEAR_INJURY_SIGNATURES,
  CREATE_INJURY_SIGNATURE,
  CREATE_INJURY_SIGNATURE_FAILED,
  CREATE_INJURY_SIGNATURE_ID_FAILED,
  CREATE_INJURY_SIGNATURE_ID_SUCCESS,
  CREATE_INJURY_SIGNATURE_SUCCESS,
  GET_INJURY_SIGNATURES,
  GET_INJURY_SIGNATURES_FAILED,
  GET_INJURY_SIGNATURES_SUCCESS,
  REMOVE_INJURY_SIGNATURE,
  REMOVE_INJURY_SIGNATURE_FAILED,
  REMOVE_INJURY_SIGNATURE_STAFF_SUCCESS,
  REMOVE_INJURY_SIGNATURE_SUCCESS,
  TOGGLE_ON_PAPER_SIGNATURE_ITEM_VALUE,
  UPDATE_INJURY_SIGNATURE,
  UPDATE_INJURY_SIGNATURES,
  UPDATE_INJURY_SIGNATURES_FAILED,
  UPDATE_INJURY_SIGNATURES_SUCCESS,
  UPDATE_INJURY_SIGNATURE_CARER,
  UPDATE_INJURY_SIGNATURE_DOCUMENTS,
  UPDATE_INJURY_SIGNATURE_FAILED,
  UPDATE_INJURY_SIGNATURE_ID_FAILED,
  UPDATE_INJURY_SIGNATURE_ID_SUCCESS,
  UPDATE_INJURY_SIGNATURE_MANUALLY,
  UPDATE_INJURY_SIGNATURE_OTHER_CARER,
  UPDATE_INJURY_SIGNATURE_STAFF_MEMBER,
  UPDATE_INJURY_SIGNATURE_STATISTICS,
  UPDATE_INJURY_SIGNATURE_SUCCESS,
} from './constants'

export const getSignatures = (injuryId, options = {}) => ({ injuriesApiClient }) => (dispatch) => {
  const { onSuccess = () => {}, params, silent = false } = options

  dispatch({ silent, type: GET_INJURY_SIGNATURES })

  injuriesApiClient.get(injuryId, params).then(
    (response) => {
      dispatch({ payload: response, type: GET_INJURY_SIGNATURES_SUCCESS })

      return onSuccess(response)
    },
    (error) => dispatch({ payload: error, type: GET_INJURY_SIGNATURES_FAILED }),
  )
}

export const updateSignatureId = (item, injuryId, options = {}) => ({ injuriesApiClient }) => (dispatch) => {
  const { body, onFailed = () => {}, onSuccess = () => {}, params, uid } = options

  injuriesApiClient.update(injuryId, body, params).then(
    (response) => {
      dispatch({ payload: { item, response }, type: UPDATE_INJURY_SIGNATURE_ID_SUCCESS })

      return onSuccess(response)
    },
    ({ response }) => {
      dispatch({ error: response, type: UPDATE_INJURY_SIGNATURE_ID_FAILED, uid })

      return onFailed(response)
    },
  )
}

export const createSignatureId = (item, options = {}) => ({ injuriesApiClient }) => (dispatch) => {
  const { body, onFailed = () => {}, onSuccess = () => {}, params } = options
  const signatureId = item.id ? item.id : item.uid

  injuriesApiClient.createSignature(body, params).then(
    (response) => {
      dispatch({ payload: { item, response }, type: CREATE_INJURY_SIGNATURE_ID_SUCCESS })

      return onSuccess(response)
    },
    ({ response }) => {
      dispatch({ error: response, type: CREATE_INJURY_SIGNATURE_ID_FAILED, uid: signatureId })

      return onFailed(response)
    },
  )
}

export const updateSignatures = (injuryId, options = {}) => ({ injuriesApiClient }) => (dispatch) => {
  const { body, onFailed = () => {}, onSuccess = () => {}, params, updateInjuryRecord } = options

  dispatch({ payload: body, type: UPDATE_INJURY_SIGNATURES })

  injuriesApiClient.update(injuryId, body, params).then(
    (response) => {
      dispatch({ payload: response, type: UPDATE_INJURY_SIGNATURES_SUCCESS, updateInjuryRecord })

      return onSuccess(response)
    },
    ({ response }) => {
      dispatch({ payload: response, type: UPDATE_INJURY_SIGNATURES_FAILED })

      return onFailed(response)
    },
  )
}

export const clearInjurySignatures = () => ({
  type: CLEAR_INJURY_SIGNATURES,
})

export const createSignature = (options = {}) => ({ injuriesApiClient }) => (dispatch) => {
  const { body, onFailed = () => {}, onSuccess = () => {}, params, uid } = options

  dispatch({ payload: body, type: CREATE_INJURY_SIGNATURE })

  injuriesApiClient.createSignature(body, params).then(
    (response) => {
      dispatch({ payload: response, type: CREATE_INJURY_SIGNATURE_SUCCESS, uid })

      return onSuccess(response)
    },
    ({ response }) => {
      dispatch({ error: response, type: CREATE_INJURY_SIGNATURE_FAILED, uid })

      return onFailed(response)
    },
  )
}

export const updateSignature = (id, options = {}) => ({ injuriesApiClient }) => (dispatch) => {
  const { body, onFailed = () => {}, onSuccess = () => {}, params, updateToSignatureList } = options

  dispatch({
    payload: { body, id },
    type: UPDATE_INJURY_SIGNATURE,
  })

  injuriesApiClient.updateSignature(id, body, params).then(
    (response) => {
      dispatch({ payload: response, type: UPDATE_INJURY_SIGNATURE_SUCCESS, updateToSignatureList })

      return onSuccess(response)
    },
    ({ response }) => {
      dispatch({ payload: { id, response }, type: UPDATE_INJURY_SIGNATURE_FAILED })

      return onFailed(response)
    },
  )
}

export const removeSignature = (id, uid, options = {}) => ({ injuriesApiClient }) => (dispatch) => {
  const { onFailed = () => {}, onSuccess = () => {} } = options
  dispatch({ type: REMOVE_INJURY_SIGNATURE })

  if (uid) {
    dispatch({ type: REMOVE_INJURY_SIGNATURE_SUCCESS, uid })
    onSuccess()

    return
  }

  injuriesApiClient.removeSignature(id).then(
    () => {
      onSuccess()

      return dispatch({ payload: { signatureId: id }, type: REMOVE_INJURY_SIGNATURE_SUCCESS })
    },
    ({ response }) => {
      onFailed(response)

      return dispatch({ type: REMOVE_INJURY_SIGNATURE_FAILED })
    },
  )
}

export const addCarerItem = (child, isHomeAccidents) => ({
  payload: { child, isHomeAccidents },
  type: ADD_INJURY_SIGNATURE_CARER,
})

export const addStaffMemberItem = (staff, isRemoveSignature) => ({
  payload: { isRemoveSignature, staff },
  type: ADD_INJURY_SIGNATURE_STAFF_MEMBER,
})

export const updateStaffMember = (item, option) => ({
  payload: { item, option },
  type: UPDATE_INJURY_SIGNATURE_STAFF_MEMBER,
})

export const updateCarer = (item, option) => ({
  payload: { item, option },
  type: UPDATE_INJURY_SIGNATURE_CARER,
})

export const updateOtherCarer = (item, value) => ({
  payload: { item, value },
  type: UPDATE_INJURY_SIGNATURE_OTHER_CARER,
})

export const toggleOnPaperSignatureItemValue = (signatureId) => ({
  payload: { signatureId },
  type: TOGGLE_ON_PAPER_SIGNATURE_ITEM_VALUE,
})

export const updateSignatureDocuments = (signatureDocuments) => ({
  payload: { signatureDocuments },
  type: UPDATE_INJURY_SIGNATURE_DOCUMENTS,
})

export const updateSignatureItem = (signatureId, body) => ({
  payload: { body, signatureId },
  type: UPDATE_INJURY_SIGNATURE_MANUALLY,
})

export const removeStaff = (signatureId) => ({
  payload: { signatureId },
  type: REMOVE_INJURY_SIGNATURE_STAFF_SUCCESS,
})

export const updateSignatureStatistics = ({ newSignatureStatistics, status }) => ({
  payload: { newSignatureStatistics, status },
  type: UPDATE_INJURY_SIGNATURE_STATISTICS,
})
