import moment from 'moment'

import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { isRequired } from 'utils/fieldValidation'

import { Field as FieldComponent, Form, Typography } from 'components'

import i18n from 'translations'

export const NEXT_STEPS_DATE_ACHIEVED_FORM = 'NextStepsDateAchievedForm'

const NextStepsDateAchievedForm = ({
  handleSubmit,
  isEditMode,
  isLoading,
  item,
  onCancelClick,
  onSaveClick,
  onSubmit,
  singleItemIsFetching,
}) => {
  const { achievedAt, nextObservation } = item

  const renderDescription = (
    <Typography fontSize={14}>
      {`${i18n.t('module:Learning:NextSteps:NextStepsList:dateAchieved')}:`}
      {' '}
      <Typography variant="span" bold>
        {moment(achievedAt).format('L')}
      </Typography>
    </Typography>
  )

  const actions = !nextObservation && achievedAt && isEditMode && (
    <FieldComponent.InlineEdit.Actions
      isProcessing={singleItemIsFetching}
      isEdit
      onCancelClick={onCancelClick}
      onSaveClick={onSaveClick}
    />
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FieldComponent.InlineEdit
        actions={actions}
        description={renderDescription}
        editableComponent={(
          <Form.Row>
            <Form.Row.FlexItem>
              <Field
                component={Form.DatePicker}
                disabled={singleItemIsFetching}
                name="date"
                validate={isRequired}
                clearable
              />
            </Form.Row.FlexItem>
          </Form.Row>
        )}
        isEditMode={isEditMode}
        isLoading={isLoading}
        noMargin
        noPadding
      />
    </Form>
  )
}

export default reduxForm({
  enableReinitialize: true,
})(NextStepsDateAchievedForm)
