import i18n from 'translations'

import { FEATURE_FLAGS } from 'constants/security'

export const FEATURE_FLAGS_OPTIONS = [
  {
    label: i18n.t('module:Nurseries:FeatureFlags:cohortTracker'),
    value: FEATURE_FLAGS.COHORT_TRACKER,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:financeAutomation'),
    value: FEATURE_FLAGS.FINANCE_AUTOMATION,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:finance'),
    value: FEATURE_FLAGS.FINANCE_MVP,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:financeV3'),
    value: FEATURE_FLAGS.FINANCE_V3,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:diaryAutoSend'),
    value: FEATURE_FLAGS.DIARY_AUTOSEND,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:halfTermEnabled'),
    value: FEATURE_FLAGS.HALF_TERM_ENABLED,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:hasParentAppAccess'),
    value: FEATURE_FLAGS.HAS_PARENT_APP_ACCESS,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:ljReports'),
    value: FEATURE_FLAGS.LJ_REPORTS,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:occupancyEnabled'),
    value: FEATURE_FLAGS.OCCUPANCY_ENABLED,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:pdfAutomation'),
    value: FEATURE_FLAGS.PDF_AUTOMATION,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:staffRegister'),
    value: FEATURE_FLAGS.STAFF_REGISTER,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:autoCreditNoting'),
    value: FEATURE_FLAGS.AUTO_CREDIT_NOTING,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:accountingExtendedExport'),
    value: FEATURE_FLAGS.ACCOUNTING_EXTENDED_EXPORT,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:filesManagement'),
    value: FEATURE_FLAGS.FILES_MANAGEMENT,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:videoEnabled'),
    value: FEATURE_FLAGS.VIDEO_ENABLED,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:messaging'),
    value: FEATURE_FLAGS.MESSAGING,
  },
  {
    hideForOrganization: true,
    label: i18n.t('module:Nurseries:FeatureFlags:mobilePayments'),
    value: FEATURE_FLAGS.MOBILE_PAYMENTS,
  },
  {
    hideForOrganization: true,
    label: i18n.t('module:Nurseries:FeatureFlags:mobilePaymentsBacs'),
    value: FEATURE_FLAGS.MOBILE_PAYMENTS_BACS_CAPABILITY,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:requestingExtraSessions'),
    value: FEATURE_FLAGS.REQUESTING_EXTRA_SESSIONS,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:parentBookingChileLeaves'),
    value: FEATURE_FLAGS.PARENT_BOOKING_CHILD_LIVES,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:enquiries'),
    value: FEATURE_FLAGS.ENQUIRIES,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:deposits'),
    value: FEATURE_FLAGS.DEPOSITS,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:userflow'),
    value: FEATURE_FLAGS.USERFLOW,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:groupReporting'),
    value: FEATURE_FLAGS.GROUP_REPORTING_V2,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:paUpdateChild'),
    value: FEATURE_FLAGS.PA_UPDATE_CHILD,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:likesAndComments'),
    value: FEATURE_FLAGS.LIKES_AND_COMMENTS,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:offlineMode'),
    value: FEATURE_FLAGS.OFFLINE_MODE,
  },
  {
    label: i18n.t('module:Nurseries:FeatureFlags:practitionerChildAccess'),
    value: FEATURE_FLAGS.PRACTITIONER_CHILD_ACCESS,
  },
]
